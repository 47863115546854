(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fr.br === region.fU.br)
	{
		return 'on line ' + region.fr.br;
	}
	return 'on lines ' + region.fr.br + ' through ' + region.fU.br;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hM,
		impl.iD,
		impl.iw,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS
//
// For some reason, tabs can appear in href protocols and it still works.
// So '\tjava\tSCRIPT:alert("!!!")' and 'javascript:alert("!!!")' are the same
// in practice. That is why _VirtualDom_RE_js and _VirtualDom_RE_js_html look
// so freaky.
//
// Pulling the regular expressions out to the top level gives a slight speed
// boost in small benchmarks (4-10%) but hoisting values to reduce allocation
// can be unpredictable in large programs where JIT may have a harder time with
// functions are not fully self-contained. The benefit is more that the js and
// js_html ones are so weird that I prefer to see them near each other.


var _VirtualDom_RE_script = /^script$/i;
var _VirtualDom_RE_on_formAction = /^(on|formAction$)/i;
var _VirtualDom_RE_js = /^\s*j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:/i;
var _VirtualDom_RE_js_html = /^\s*(j\s*a\s*v\s*a\s*s\s*c\s*r\s*i\s*p\s*t\s*:|d\s*a\s*t\s*a\s*:\s*t\s*e\s*x\s*t\s*\/\s*h\s*t\s*m\s*l\s*(,|;))/i;


function _VirtualDom_noScript(tag)
{
	return _VirtualDom_RE_script.test(tag) ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return _VirtualDom_RE_on_formAction.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return _VirtualDom_RE_js.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return _VirtualDom_RE_js_html.test(value)
		? /**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlJson(value)
{
	return (typeof _Json_unwrap(value) === 'string' && _VirtualDom_RE_js_html.test(_Json_unwrap(value)))
		? _Json_wrap(
			/**/''//*//**_UNUSED/'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'//*/
		) : value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		aq: func(record.aq),
		fs: record.fs,
		fp: record.fp
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.aq;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.fs;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.fp) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hM,
		impl.iD,
		impl.iw,
		function(sendToApp, initialModel) {
			var view = impl.iF;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.hM,
		impl.iD,
		impl.iw,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fq && impl.fq(sendToApp)
			var view = impl.iF;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.hb);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.iA) && (_VirtualDom_doc.title = title = doc.iA);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.h6;
	var onUrlRequest = impl.h7;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fq: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gx === next.gx
							&& curr.f5 === next.f5
							&& curr.gt.a === next.gt.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		hM: function(flags)
		{
			return A3(impl.hM, flags, _Browser_getUrl(), key);
		},
		iF: impl.iF,
		iD: impl.iD,
		iw: impl.iw
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { hI: 'hidden', he: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { hI: 'mozHidden', he: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { hI: 'msHidden', he: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { hI: 'webkitHidden', he: 'webkitvisibilitychange' }
		: { hI: 'hidden', he: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		gF: _Browser_getScene(),
		gV: {
			gY: _Browser_window.pageXOffset,
			gZ: _Browser_window.pageYOffset,
			gX: _Browser_doc.documentElement.clientWidth,
			f3: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		gX: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		f3: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			gF: {
				gX: node.scrollWidth,
				f3: node.scrollHeight
			},
			gV: {
				gY: node.scrollLeft,
				gZ: node.scrollTop,
				gX: node.clientWidth,
				f3: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			gF: _Browser_getScene(),
			gV: {
				gY: x,
				gZ: y,
				gX: _Browser_doc.documentElement.clientWidth,
				f3: _Browser_doc.documentElement.clientHeight
			},
			hv: {
				gY: x + rect.left,
				gZ: y + rect.top,
				gX: rect.width,
				f3: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});
var $author$project$Update$NoOp = {$: 0};
var $author$project$Update$UserLoggedIn = function (a) {
	return {$: 11, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.p) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.s);
		} else {
			var treeLen = builder.p * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.t) : builder.t;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.p);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.s);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{t: nodeList, p: (len / $elm$core$Array$branchFactor) | 0, s: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {f$: fragment, f5: host, gr: path, gt: port_, gx: protocol, gy: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Update$Now = function (a) {
	return {$: 6, a: a};
};
var $author$project$Update$Timezone = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $author$project$Domain$User$Broker = 7;
var $author$project$Domain$Total$Amount$CAD = 0;
var $author$project$Domain$User$CTGreenhouse = 4;
var $author$project$Language$English = 0;
var $author$project$Language$French = 1;
var $author$project$Domain$User$Guest = 6;
var $author$project$Domain$User$Harnois = 2;
var $author$project$Domain$User$Orisha = 0;
var $author$project$Domain$User$Tessier = 1;
var $author$project$Domain$Total$Amount$USD = 1;
var $author$project$Domain$User$Westland = 5;
var $author$project$Domain$User$Wolpin = 3;
var $author$project$Domain$User$accreditedUsers = function () {
	var tessierUser = F2(
		function (id, firstName) {
			return {
				hl: 0,
				I: $elm$core$Maybe$Just(0.25),
				hG: 1,
				y: id,
				hR: 1,
				Q: 'Tessier',
				U: firstName
			};
		});
	return _List_fromArray(
		[
			{hl: 0, I: $elm$core$Maybe$Nothing, hG: 0, y: 0, hR: 1, Q: 'BananaPricing', U: 'Orisha'},
			A2(tessierUser, 1, 'Martin'),
			A2(tessierUser, 2, 'Daniel'),
			A2(tessierUser, 3, 'Alex'),
			A2(tessierUser, 4, 'Francine'),
			{hl: 0, I: $elm$core$Maybe$Nothing, hG: 6, y: 5, hR: 1, Q: 'Atlantide800', U: 'Invité'},
			{hl: 1, I: $elm$core$Maybe$Nothing, hG: 6, y: 6, hR: 0, Q: 'Atlantis800', U: 'Guest'},
			{
			hl: 0,
			I: $elm$core$Maybe$Just(0.1),
			hG: 2,
			y: 7,
			hR: 1,
			Q: 'Harnois',
			U: 'Harnois'
		},
			{
			hl: 0,
			I: $elm$core$Maybe$Just(0.25),
			hG: 3,
			y: 8,
			hR: 0,
			Q: 'Eggplant1@3',
			U: 'wolpinenterprises'
		},
			{
			hl: 0,
			I: $elm$core$Maybe$Just(0.2),
			hG: 7,
			y: 9,
			hR: 0,
			Q: '@ndrewsmom',
			U: 'Andrew'
		},
			{
			hl: 0,
			I: $elm$core$Maybe$Just(0.2),
			hG: 7,
			y: 10,
			hR: 0,
			Q: '$tarbucks',
			U: 'Brandon'
		},
			{
			hl: 1,
			I: $elm$core$Maybe$Just(0.2),
			hG: 4,
			y: 11,
			hR: 0,
			Q: 'toby',
			U: 'CTGreenhouse'
		},
			{hl: 1, I: $elm$core$Maybe$Nothing, hG: 7, y: 12, hR: 1, Q: 'BananaPricing', U: 'philippe'},
			{
			hl: 1,
			I: $elm$core$Maybe$Just(0.25),
			hG: 5,
			y: 13,
			hR: 0,
			Q: '1Screen',
			U: 'Westland'
		}
		]);
}();
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Domain$User$decode = function (token) {
	return A2(
		$elm$core$Maybe$andThen,
		function (t) {
			return $elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (u) {
						return _Utils_eq(u.y, t);
					},
					$author$project$Domain$User$accreditedUsers));
		},
		$elm$core$String$toInt(token));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $author$project$Update$QuoteBuilder = 0;
var $author$project$Update$QuoteBuilderV3 = 1;
var $author$project$Login$Components$initialModel = {gb: false, Q: '', U: ''};
var $author$project$Login$Page$initialModel = {al: $author$project$Login$Components$initialModel, bk: $elm$core$Maybe$Nothing};
var $author$project$Page$PackingSlip$initialModel = {b5: false};
var $author$project$Page$PackingSlipV3$initialModel = {b5: false};
var $author$project$Domain$Quote$Actuator$ActivationAndSideCurtainsUnits = 1;
var $author$project$Domain$Quote$Documentation$CustomPro = 3;
var $author$project$Domain$Quote$Mode$Pro = 0;
var $author$project$Domain$Quote$Plan$Purchase = 0;
var $author$project$Page$QuoteBuilder$Discount$Update$Edited = {$: 1};
var $author$project$Page$QuoteBuilder$Discount$Update$initialModel = {hm: _List_Nil, K: $author$project$Page$QuoteBuilder$Discount$Update$Edited, ie: _List_Nil};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Domain$Discount$Listing$isAllowed = F2(
	function (user, predefined) {
		var inGroup = function (group) {
			return _Utils_eq(
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.hG;
					},
					user),
				$elm$core$Maybe$Just(group));
		};
		switch (predefined) {
			case 0:
				return true;
			case 1:
				return true;
			case 2:
				return true;
			case 3:
				return inGroup(0);
			case 4:
				return inGroup(0);
			case 5:
				return true;
			default:
				return inGroup(0) || inGroup(2);
		}
	});
var $author$project$Page$QuoteBuilder$Discount$Update$fromQuote = F2(
	function (user, discounts) {
		return _Utils_update(
			$author$project$Page$QuoteBuilder$Discount$Update$initialModel,
			{
				hm: discounts.hm,
				ie: A2(
					$elm$core$List$filter,
					$author$project$Domain$Discount$Listing$isAllowed(user),
					discounts.ie)
			});
	});
var $author$project$Domain$Product$Controller = 0;
var $author$project$Domain$Product$OutdoorClimateSensor = 1;
var $author$project$Domain$Product$RainSensor = 2;
var $author$project$Domain$Product$SolarSensor = 3;
var $author$project$Domain$Product$WeatherBox = 5;
var $author$project$Domain$Product$WindSensor = 4;
var $author$project$Domain$Product$allFarmProducts = _List_fromArray(
	[0, 1, 2, 3, 5, 4]);
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$Edited = {$: 1};
var $author$project$Page$QuoteBuilder$CustomField$Update$fromQuote = function (ps) {
	return {
		hn: A2(
			$elm$core$List$map,
			function (p) {
				return {hr: p.hr, fk: p.fk, $8: p.$8, ih: p.ih, il: p.il};
			},
			ps),
		K: $author$project$Page$QuoteBuilder$CustomField$Update$Edited
	};
};
var $author$project$Domain$Quote$Equipment$farmProductIsActive = F2(
	function (farm, product) {
		switch (product) {
			case 0:
				return farm.hk;
			case 1:
				return farm.ib;
			case 2:
				return farm.ii;
			case 3:
				return farm.bz;
			case 4:
				return farm.bG;
			default:
				return farm.iG;
		}
	});
var $author$project$Domain$Quote$Equipment$numberOfFarmProduct = F2(
	function (farm, product) {
		return A2($author$project$Domain$Quote$Equipment$farmProductIsActive, farm, product) ? 1 : 0;
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $author$project$Page$QuoteBuilder$Farm$Update$fromQuote = function (farm) {
	return {
		aR: $author$project$Page$QuoteBuilder$CustomField$Update$fromQuote(farm.hn),
		ig: A2(
			$elm$core$List$concatMap,
			function (p) {
				return A2(
					$elm$core$List$repeat,
					A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, farm, p),
					p);
			},
			$author$project$Domain$Product$allFarmProducts),
		ba: false
	};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$Edited = function (a) {
	return {$: 1, a: a};
};
var $author$project$Domain$Product$BackUpThermostat = 5;
var $author$project$Domain$Product$CirculationFan = 13;
var $author$project$Domain$Product$ClimateSensor = 0;
var $author$project$Domain$Product$Co2Sensor = 3;
var $author$project$Domain$Product$DryWetSensor = 1;
var $author$project$Domain$Product$Fan = 11;
var $author$project$Domain$Product$FanWithLouvers = 12;
var $author$project$Domain$Product$HeatPipe = 18;
var $author$project$Domain$Product$Heater = 6;
var $author$project$Domain$Product$HeatingStage = 7;
var $author$project$Domain$Product$Mister = 15;
var $author$project$Domain$Product$Motor24VDC = 16;
var $author$project$Domain$Product$OpeningWith24VInverter = 10;
var $author$project$Domain$Product$Roof = 8;
var $author$project$Domain$Product$SideCurtain = 9;
var $author$project$Domain$Product$SoilTemperatureSensor = 2;
var $author$project$Domain$Product$Tensiometer12 = 4;
var $author$project$Domain$Product$ThermalScreen = 17;
var $author$project$Domain$Product$Valve = 14;
var $author$project$Domain$Product$allGreenhouseProducts = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]);
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$Default = 0;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$initialModel = {ae: $elm$core$Maybe$Nothing, K: 0};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$fromQuote = function (m) {
	return _Utils_update(
		$author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$initialModel,
		{ae: m});
};
var $author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse = F2(
	function (greenhouse, product) {
		switch (product) {
			case 0:
				return greenhouse.hh;
			case 1:
				return greenhouse.aS;
			case 2:
				return greenhouse.iu;
			case 3:
				return greenhouse.hi;
			case 4:
				return greenhouse.iz;
			case 5:
				return greenhouse.g6;
			case 6:
				return greenhouse.cO;
			case 7:
				return greenhouse.hH;
			case 8:
				return greenhouse.iq.ih;
			case 9:
				return greenhouse.gH;
			case 10:
				return greenhouse.h9;
			case 11:
				return greenhouse.cA;
			case 12:
				return greenhouse.hC;
			case 13:
				return greenhouse.b$;
			case 14:
				return greenhouse.eR;
			case 15:
				return greenhouse.dk;
			case 16:
				return greenhouse.dl;
			case 17:
				return greenhouse.eC;
			default:
				return greenhouse.cN;
		}
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Update$fromQuote = F2(
	function (index, g) {
		return {
			aM: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$fromQuote(g.ae),
			aR: $author$project$Page$QuoteBuilder$CustomField$Update$fromQuote(g.hn),
			cY: index,
			fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$Edited(g.fk),
			ig: A2(
				$elm$core$List$concatMap,
				function (p) {
					return A2(
						$elm$core$List$repeat,
						A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, g, p),
						p);
				},
				$author$project$Domain$Product$allGreenhouseProducts),
			d8: g.iq.hO
		};
	});
var $author$project$Page$QuoteBuilder$Client$initialLanguage = function (user) {
	var _v0 = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.hR;
		},
		user);
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return 0;
		} else {
			var _v2 = _v0.a;
			return 1;
		}
	} else {
		return 1;
	}
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Page$QuoteBuilder$Client$userCurrency = function (user) {
	return A2(
		$elm$core$Maybe$withDefault,
		1,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.hl;
			},
			user));
};
var $author$project$Page$QuoteBuilder$Client$initialModel = function (user) {
	return {
		g1: '',
		hl: $author$project$Page$QuoteBuilder$Client$userCurrency(user),
		hD: '',
		fZ: '',
		hR: $author$project$Page$QuoteBuilder$Client$initialLanguage(user),
		gh: ''
	};
};
var $author$project$Page$QuoteBuilder$CustomField$Update$initialModel = {hn: _List_Nil, K: $author$project$Page$QuoteBuilder$CustomField$Update$Edited};
var $author$project$Page$QuoteBuilder$Farm$Update$initialModel = {
	aR: $author$project$Page$QuoteBuilder$CustomField$Update$initialModel,
	ig: _List_fromArray(
		[0]),
	ba: false
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$Default = {$: 2};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$initialModel = function (index) {
	return {aM: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$initialModel, aR: $author$project$Page$QuoteBuilder$CustomField$Update$initialModel, cY: index, fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$Default, ig: _List_Nil, d8: false};
};
var $author$project$Page$QuoteBuilder$Update$initialModel = F2(
	function (user, quoteFromUrl) {
		if (!quoteFromUrl.$) {
			var q = quoteFromUrl.a;
			return {
				ae: q.ae,
				hg: q.hg,
				I: A2($author$project$Page$QuoteBuilder$Discount$Update$fromQuote, user, q.fR),
				ht: q.ht,
				fd: $author$project$Page$QuoteBuilder$Farm$Update$fromQuote(q.fd),
				aX: A2($elm$core$List$indexedMap, $author$project$Page$QuoteBuilder$Greenhouse$Update$fromQuote, q.aX),
				hX: q.hX,
				ab: q.ab
			};
		} else {
			return {
				ae: 1,
				hg: $author$project$Page$QuoteBuilder$Client$initialModel(user),
				I: $author$project$Page$QuoteBuilder$Discount$Update$initialModel,
				ht: 3,
				fd: $author$project$Page$QuoteBuilder$Farm$Update$initialModel,
				aX: _List_fromArray(
					[
						$author$project$Page$QuoteBuilder$Greenhouse$Update$initialModel(0)
					]),
				hX: 0,
				ab: 0
			};
		}
	});
var $author$project$Page$QuoteBuilderV3$Discount$Update$Edited = {$: 1};
var $author$project$Page$QuoteBuilderV3$Discount$Update$initialModel = {hm: _List_Nil, K: $author$project$Page$QuoteBuilderV3$Discount$Update$Edited};
var $author$project$Page$QuoteBuilderV3$Discount$Update$fromQuote = function (discounts) {
	return _Utils_update(
		$author$project$Page$QuoteBuilderV3$Discount$Update$initialModel,
		{hm: discounts});
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Update$Edited = {$: 1};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$initialModel = {hn: _List_Nil, K: $author$project$Page$QuoteBuilderV3$CustomField$Update$Edited};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$fromQuote = function (customProducts) {
	return _Utils_update(
		$author$project$Page$QuoteBuilderV3$CustomField$Update$initialModel,
		{hn: customProducts});
};
var $author$project$Page$QuoteBuilderV3$Quote$isExtra = function (product) {
	switch (product) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 5:
			return true;
		case 6:
			return true;
		case 7:
			return true;
		case 8:
			return true;
		case 9:
			return true;
		case 10:
			return false;
		case 11:
			return false;
		case 12:
			return true;
		case 13:
			return true;
		case 14:
			return true;
		default:
			return true;
	}
};
var $author$project$Page$QuoteBuilderV3$Quote$PerFarm = 1;
var $author$project$Page$QuoteBuilderV3$Quote$PerGreenhouse = 0;
var $author$project$Page$QuoteBuilderV3$Quote$association = function (product) {
	switch (product) {
		case 0:
			return 0;
		case 1:
			return 0;
		case 2:
			return 0;
		case 3:
			return 0;
		case 4:
			return 0;
		case 5:
			return 0;
		case 6:
			return 0;
		case 7:
			return 0;
		case 8:
			return 0;
		case 9:
			return 0;
		case 10:
			return 1;
		case 11:
			return 1;
		case 12:
			return 1;
		case 13:
			return 1;
		case 14:
			return 1;
		default:
			return 1;
	}
};
var $author$project$Page$QuoteBuilderV3$Quote$isPerFarm = function (product) {
	return $author$project$Page$QuoteBuilderV3$Quote$association(product) === 1;
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$fromQuote = function (farm) {
	return {
		aR: $author$project$Page$QuoteBuilderV3$CustomField$Update$fromQuote(farm.hn),
		ig: farm.ig,
		by: A2(
			$elm$core$List$any,
			function (p) {
				return $author$project$Page$QuoteBuilderV3$Quote$isExtra(p) && $author$project$Page$QuoteBuilderV3$Quote$isPerFarm(p);
			},
			farm.ig)
	};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Edited = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Quote$isPerGreenhouse = function (product) {
	return !$author$project$Page$QuoteBuilderV3$Quote$association(product);
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$fromQuote = F2(
	function (index, greenhouse) {
		return {
			g9: greenhouse.g9,
			aR: $author$project$Page$QuoteBuilderV3$CustomField$Update$fromQuote(greenhouse.hn),
			cY: index,
			fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Edited(greenhouse.fk),
			ig: greenhouse.ig,
			by: A2(
				$elm$core$List$any,
				function (p) {
					return $author$project$Page$QuoteBuilderV3$Quote$isExtra(p) && $author$project$Page$QuoteBuilderV3$Quote$isPerGreenhouse(p);
				},
				greenhouse.ig)
		};
	});
var $author$project$Page$QuoteBuilderV3$Client$initialLanguage = function (user) {
	var _v0 = A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.hR;
		},
		user);
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return 0;
		} else {
			var _v2 = _v0.a;
			return 1;
		}
	} else {
		return 0;
	}
};
var $author$project$Page$QuoteBuilderV3$Client$userCurrency = function (user) {
	return A2(
		$elm$core$Maybe$withDefault,
		1,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.hl;
			},
			user));
};
var $author$project$Page$QuoteBuilderV3$Client$initialModel = function (user) {
	return {
		g1: '',
		hl: $author$project$Page$QuoteBuilderV3$Client$userCurrency(user),
		hD: '',
		fZ: '',
		hR: $author$project$Page$QuoteBuilderV3$Client$initialLanguage(user),
		gh: ''
	};
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$initialModel = {aR: $author$project$Page$QuoteBuilderV3$CustomField$Update$initialModel, ig: _List_Nil, by: false};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Default = {$: 2};
var $author$project$Page$QuoteBuilderV3$Quote$Seed = 1;
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$initialModel = function (index) {
	return {g9: 1, aR: $author$project$Page$QuoteBuilderV3$CustomField$Update$initialModel, cY: index, fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Default, ig: _List_Nil, by: false};
};
var $author$project$Page$QuoteBuilderV3$Update$initialModel = F2(
	function (user, urlQuote) {
		if (!urlQuote.$) {
			var q = urlQuote.a;
			return {
				hg: q.hg,
				I: $author$project$Page$QuoteBuilderV3$Discount$Update$fromQuote(q.fR),
				fd: $author$project$Page$QuoteBuilderV3$Farm$Update$fromQuote(q.fd),
				aX: A2($elm$core$List$indexedMap, $author$project$Page$QuoteBuilderV3$Greenhouse$Update$fromQuote, q.aX),
				ab: q.ab
			};
		} else {
			return {
				hg: $author$project$Page$QuoteBuilderV3$Client$initialModel(user),
				I: $author$project$Page$QuoteBuilderV3$Discount$Update$initialModel,
				fd: $author$project$Page$QuoteBuilderV3$Farm$Update$initialModel,
				aX: _List_fromArray(
					[
						$author$project$Page$QuoteBuilderV3$Greenhouse$Update$initialModel(0)
					]),
				ab: 0
			};
		}
	});
var $author$project$Domain$User$isOrisha = function (user) {
	var _v0 = user.hG;
	if (!_v0) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Route$Quote$Entry$param = 'quote';
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$map = F2(
	function (func, _v0) {
		var a = _v0;
		return function (dict) {
			return func(
				a(dict));
		};
	});
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {ax: frag, aB: params, at: unvisited, gU: value, aK: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.at;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.gU);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.gU);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.gr),
					$elm$url$Url$Parser$prepareQuery(url.gy),
					url.f$,
					$elm$core$Basics$identity)));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aK;
		var unvisited = _v1.at;
		var params = _v1.aB;
		var frag = _v1.ax;
		var value = _v1.gU;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$QueryParameters$parseQueryParam = F3(
	function (url, key, decoder) {
		var decodeStringToMaybe = function (value) {
			return $elm$core$Result$toMaybe(
				A2($elm$json$Json$Decode$decodeString, decoder, value));
		};
		var decode = function (value) {
			return A2(
				$elm$core$Maybe$andThen,
				decodeStringToMaybe,
				A2($elm$core$Maybe$andThen, $elm$url$Url$percentDecode, value));
		};
		var parser = A2(
			$elm$url$Url$Parser$Query$map,
			decode,
			$elm$url$Url$Parser$Query$string(key));
		return $elm_community$maybe_extra$Maybe$Extra$join(
			A2(
				$elm$url$Url$Parser$parse,
				A2($elm$url$Url$Parser$questionMark, $elm$url$Url$Parser$top, parser),
				url));
	});
var $author$project$Domain$Quote$Entry$Quote = F8(
	function (client, farm, greenhouses, discounts, plan, mode, documentation, actuator) {
		return {ae: actuator, hg: client, fR: discounts, ht: documentation, fd: farm, aX: greenhouses, hX: mode, ab: plan};
	});
var $author$project$Domain$Quote$Actuator$Multibox = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Route$Quote$Entry$actuatorDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (actuator) {
		switch (actuator) {
			case 'Multibox':
				return $elm$json$Json$Decode$succeed(0);
			case 'ControlModule':
				return $elm$json$Json$Decode$succeed(1);
			case 'ActivationAndSideCurtainsUnits':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Actuators');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Route$Quote$Entry$communicationCurrency = 0;
var $author$project$Domain$Quote$Client$Client = F6(
	function (firstName, lastName, farmName, address, language, currency) {
		return {g1: address, hl: currency, hD: farmName, fZ: firstName, hR: language, gh: lastName};
	});
var $author$project$Route$Quote$Client$currencyDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (plan) {
		switch (plan) {
			case 'CAD':
				return $elm$json$Json$Decode$succeed(0);
			case 'USD':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Currency');
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm_community$json_extra$Json$Decode$Extra$withDefault = F2(
	function (fallback, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(fallback),
			$elm$json$Json$Decode$maybe(decoder));
	});
var $author$project$Route$Quote$Utils$fieldDefault = F3(
	function (name, decoder, _default) {
		return A2(
			$elm_community$json_extra$Json$Decode$Extra$withDefault,
			_default,
			A2($elm$json$Json$Decode$field, name, decoder));
	});
var $author$project$Route$Quote$Client$languageDecoder = A2(
	$elm$json$Json$Decode$map,
	function (str) {
		return (str === 'EN') ? 0 : 1;
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Route$Quote$Client$decoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Domain$Quote$Client$Client,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'firstName', $elm$json$Json$Decode$string, ''),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'lastName', $elm$json$Json$Decode$string, ''),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'farmName', $elm$json$Json$Decode$string, ''),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'address', $elm$json$Json$Decode$string, ''),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'language', $author$project$Route$Quote$Client$languageDecoder, 1),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'currency', $author$project$Route$Quote$Client$currencyDecoder, 0));
var $author$project$Domain$Quote$Discounts$Discounts = F2(
	function (custom, predefined) {
		return {hm: custom, ie: predefined};
	});
var $elm_community$json_extra$Json$Decode$Extra$andMap = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $author$project$Domain$Discount$Custom$Amount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Domain$Discount$Custom$CustomDiscount = F3(
	function (name, discountType, precision) {
		return {fb: discountType, fk: name, id: precision};
	});
var $author$project$Domain$Discount$Custom$Percentage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Domain$Total$Amount$Amount = $elm$core$Basics$identity;
var $author$project$Domain$Total$Amount$cadToUsdRate = 0.8;
var $elm$core$Basics$round = _Basics_round;
var $author$project$Domain$Total$Amount$fromCurrency = F2(
	function (currency, amount) {
		var inCAD = function () {
			if (!currency) {
				return amount;
			} else {
				return $elm$core$Basics$round(
					function (n) {
						return n / $author$project$Domain$Total$Amount$cadToUsdRate;
					}(amount));
			}
		}();
		return {cQ: inCAD};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Route$Quote$Discount$discountTypeDecoder = function (currency) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (tag) {
			switch (tag) {
				case 'Percentage':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Domain$Discount$Custom$Percentage,
						A2($elm$json$Json$Decode$field, 'percentage', $elm$json$Json$Decode$int));
				case 'Amount':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Domain$Discount$Custom$Amount,
						A2(
							$elm$json$Json$Decode$map,
							$author$project$Domain$Total$Amount$fromCurrency(currency),
							A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$int)));
				default:
					return $elm$json$Json$Decode$fail('Discount Type');
			}
		},
		A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
};
var $author$project$Domain$Total$Amount$fromCAD = $author$project$Domain$Total$Amount$fromCurrency(0);
var $author$project$Domain$Total$Amount$free = $author$project$Domain$Total$Amount$fromCAD(0);
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $elm_community$json_extra$Json$Decode$Extra$optionalField = F2(
	function (fieldName, decoder) {
		var finishDecoding = function (json) {
			var _v0 = A2(
				$elm$json$Json$Decode$decodeValue,
				A2($elm$json$Json$Decode$field, fieldName, $elm$json$Json$Decode$value),
				json);
			if (!_v0.$) {
				var val = _v0.a;
				return A2(
					$elm$json$Json$Decode$map,
					$elm$core$Maybe$Just,
					A2($elm$json$Json$Decode$field, fieldName, decoder));
			} else {
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			}
		};
		return A2($elm$json$Json$Decode$andThen, finishDecoding, $elm$json$Json$Decode$value);
	});
var $elm_community$json_extra$Json$Decode$Extra$optionalNullableField = F2(
	function (fieldName, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$andThen($elm$core$Basics$identity),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$optionalField,
				fieldName,
				$elm$json$Json$Decode$nullable(decoder)));
	});
var $author$project$Route$Quote$Discount$customDiscountDecoder = function (currency) {
	return A4(
		$elm$json$Json$Decode$map3,
		$author$project$Domain$Discount$Custom$CustomDiscount,
		A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Discount name'),
		A3(
			$author$project$Route$Quote$Utils$fieldDefault,
			'discountType',
			$author$project$Route$Quote$Discount$discountTypeDecoder(currency),
			$author$project$Domain$Discount$Custom$Amount($author$project$Domain$Total$Amount$free)),
		A2($elm_community$json_extra$Json$Decode$Extra$optionalNullableField, 'precision', $elm$json$Json$Decode$string));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Domain$Discount$Predefined$BasicMode = 5;
var $author$project$Domain$Discount$Predefined$DryWetSensor = 2;
var $author$project$Domain$Discount$Predefined$EfaoMember = 4;
var $author$project$Domain$Discount$Predefined$MarketGardenerStudent = 3;
var $author$project$Domain$Discount$Predefined$RoofInverter = 6;
var $author$project$Domain$Discount$Predefined$SolarSensor = 0;
var $author$project$Domain$Discount$Predefined$WindSensor = 1;
var $author$project$Route$Quote$Discount$predefinedDiscountDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'SolarSensor':
				return $elm$json$Json$Decode$succeed(0);
			case 'WindSensor':
				return $elm$json$Json$Decode$succeed(1);
			case 'DryWetSensor':
				return $elm$json$Json$Decode$succeed(2);
			case 'MarketGardenerStudent':
				return $elm$json$Json$Decode$succeed(3);
			case 'EfaoMember':
				return $elm$json$Json$Decode$succeed(4);
			case 'BasicMode':
				return $elm$json$Json$Decode$succeed(5);
			case 'StarterKit':
				return $elm$json$Json$Decode$succeed(5);
			case 'RoofInverter':
				return $elm$json$Json$Decode$succeed(6);
			default:
				return $elm$json$Json$Decode$fail('PredefinedDiscount');
		}
	},
	$elm$json$Json$Decode$string);
var $elm_community$maybe_extra$Maybe$Extra$cons = F2(
	function (item, list) {
		if (!item.$) {
			var v = item.a;
			return A2($elm$core$List$cons, v, list);
		} else {
			return list;
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$values = A2($elm$core$List$foldr, $elm_community$maybe_extra$Maybe$Extra$cons, _List_Nil);
var $author$project$Route$Quote$Discount$decoder = function (currency) {
	return A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		function (d) {
			return A3($author$project$Route$Quote$Utils$fieldDefault, 'predefined', d, _List_Nil);
		}(
			A2(
				$elm$json$Json$Decode$map,
				$elm_community$maybe_extra$Maybe$Extra$values,
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$maybe($author$project$Route$Quote$Discount$predefinedDiscountDecoder)))),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			function (d) {
				return A3($author$project$Route$Quote$Utils$fieldDefault, 'custom', d, _List_Nil);
			}(
				$elm$json$Json$Decode$list(
					$author$project$Route$Quote$Discount$customDiscountDecoder(currency))),
			$elm$json$Json$Decode$succeed($author$project$Domain$Quote$Discounts$Discounts)));
};
var $author$project$Domain$Quote$Farm$Farm = F7(
	function (controller, windSensor, rainSensor, solarSensor, weatherBox, outdoorClimateSensor, customProducts) {
		return {hk: controller, hn: customProducts, ib: outdoorClimateSensor, ii: rainSensor, bz: solarSensor, iG: weatherBox, bG: windSensor};
	});
var $author$project$Domain$Quote$CustomProduct$CustomProduct = F5(
	function (name, price, quantity, device, relay) {
		return {hr: device, fk: name, $8: price, ih: quantity, il: relay};
	});
var $author$project$Domain$Quote$CustomProduct$Fan = 4;
var $author$project$Domain$Quote$CustomProduct$Heater = 2;
var $author$project$Domain$Quote$CustomProduct$Mister = 7;
var $author$project$Domain$Quote$CustomProduct$NoDevice = 0;
var $author$project$Domain$Quote$CustomProduct$Opening = 3;
var $author$project$Domain$Quote$CustomProduct$Tensiometer = 1;
var $author$project$Domain$Quote$CustomProduct$Valve = 6;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Route$Quote$CustomProduct$decoder = function (currency) {
	var deviceDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'Tensiometer':
					return $elm$json$Json$Decode$succeed(1);
				case 'Furnace':
					return $elm$json$Json$Decode$succeed(2);
				case 'Opening':
					return $elm$json$Json$Decode$succeed(3);
				case 'RoofVenting':
					return $elm$json$Json$Decode$succeed(3);
				case 'SideCurtain':
					return $elm$json$Json$Decode$succeed(3);
				case 'Fan':
					return $elm$json$Json$Decode$succeed(4);
				case 'Valve':
					return $elm$json$Json$Decode$succeed(6);
				case 'Mister':
					return $elm$json$Json$Decode$succeed(7);
				case 'NoDevice':
					return $elm$json$Json$Decode$succeed(0);
				default:
					return $elm$json$Json$Decode$fail('Custom Product App Device Type');
			}
		},
		$elm$json$Json$Decode$string);
	return A6(
		$elm$json$Json$Decode$map5,
		$author$project$Domain$Quote$CustomProduct$CustomProduct,
		A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Produit custom'),
		A3(
			$author$project$Route$Quote$Utils$fieldDefault,
			'price',
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Domain$Total$Amount$fromCurrency(currency),
				$elm$json$Json$Decode$int),
			$author$project$Domain$Total$Amount$free),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'quantity', $elm$json$Json$Decode$int, 0),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'device', deviceDecoder, 0),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'relay', $elm$json$Json$Decode$int, 0));
};
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Route$Quote$Farm$decoder = function (currency) {
	return A8(
		$elm$json$Json$Decode$map7,
		$author$project$Domain$Quote$Farm$Farm,
		A3($author$project$Route$Quote$Utils$fieldDefault, 'controller', $elm$json$Json$Decode$bool, true),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'windSensor', $elm$json$Json$Decode$bool, false),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'rainSensor', $elm$json$Json$Decode$bool, false),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'solarSensor', $elm$json$Json$Decode$bool, false),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'weatherBox', $elm$json$Json$Decode$bool, false),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'outdoorSensor', $elm$json$Json$Decode$bool, false),
		function (d) {
			return A3($author$project$Route$Quote$Utils$fieldDefault, 'customProducts', d, _List_Nil);
		}(
			$elm$json$Json$Decode$list(
				$author$project$Route$Quote$CustomProduct$decoder(currency))));
};
var $author$project$Domain$Quote$Greenhouse$Actuator = F4(
	function (activationUnit, sideCurtainUnit, multibox, multiWithMotorDriver) {
		return {g$: activationUnit, hY: multiWithMotorDriver, hZ: multibox, it: sideCurtainUnit};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Route$Quote$Greenhouse$actuatorDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Domain$Quote$Greenhouse$Actuator,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'activationUnit', $elm$json$Json$Decode$int, 0),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'sideCurtainUnit', $elm$json$Json$Decode$int, 0),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'multibox', $elm$json$Json$Decode$int, 0),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'multiWithMotorDriver', $elm$json$Json$Decode$int, 0));
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Domain$Quote$Greenhouse$Roof = F2(
	function (quantity, inverter) {
		return {hO: inverter, ih: quantity};
	});
var $author$project$Route$Quote$Greenhouse$roofDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Domain$Quote$Greenhouse$Roof,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'quantity', $elm$json$Json$Decode$int, 0),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'inverter', $elm$json$Json$Decode$bool, false));
var $author$project$Route$Quote$Greenhouse$roofDefault = A2($author$project$Domain$Quote$Greenhouse$Roof, 0, false);
var $author$project$Route$Quote$Greenhouse$toGreenhouse = function (actuator) {
	return function (backUpThermostat) {
		return function (circulationFan) {
			return function (climateSensor) {
				return function (co2Sensor) {
					return function (customProducts) {
						return function (dryBulbWetBulbSensor) {
							return function (fan) {
								return function (fanWithLouvers) {
									return function (heater) {
										return function (heatingStage) {
											return function (heatPipe) {
												return function (mister) {
													return function (motor24VDC) {
														return function (name) {
															return function (openingWith24VInverter) {
																return function (roof) {
																	return function (sideCurtain) {
																		return function (sideCurtainWithInverter) {
																			return function (soilTemperatureSensor) {
																				return function (tensiometer) {
																					return function (thermalScreen) {
																						return function (valve) {
																							return {ae: actuator, g6: backUpThermostat, b$: circulationFan, hh: climateSensor, hi: co2Sensor, hn: customProducts, aS: dryBulbWetBulbSensor, cA: fan, hC: fanWithLouvers, cN: heatPipe, cO: heater, hH: heatingStage, dk: mister, dl: motor24VDC, fk: name, h9: openingWith24VInverter + sideCurtainWithInverter, iq: roof, gH: sideCurtain, iu: soilTemperatureSensor, iz: tensiometer, eC: thermalScreen, eR: valve};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Route$Quote$Greenhouse$decoder = function (currency) {
	return A2(
		$elm_community$json_extra$Json$Decode$Extra$andMap,
		A3($author$project$Route$Quote$Utils$fieldDefault, 'valve', $elm$json$Json$Decode$int, 0),
		A2(
			$elm_community$json_extra$Json$Decode$Extra$andMap,
			A3($author$project$Route$Quote$Utils$fieldDefault, 'thermalScreen', $elm$json$Json$Decode$int, 0),
			A2(
				$elm_community$json_extra$Json$Decode$Extra$andMap,
				A3($author$project$Route$Quote$Utils$fieldDefault, 'tensiometer', $elm$json$Json$Decode$int, 0),
				A2(
					$elm_community$json_extra$Json$Decode$Extra$andMap,
					A3($author$project$Route$Quote$Utils$fieldDefault, 'soilTemperatureSensor', $elm$json$Json$Decode$int, 0),
					A2(
						$elm_community$json_extra$Json$Decode$Extra$andMap,
						A3($author$project$Route$Quote$Utils$fieldDefault, 'sideCurtainVWithInverter', $elm$json$Json$Decode$int, 0),
						A2(
							$elm_community$json_extra$Json$Decode$Extra$andMap,
							A3($author$project$Route$Quote$Utils$fieldDefault, 'sideCurtain', $elm$json$Json$Decode$int, 0),
							A2(
								$elm_community$json_extra$Json$Decode$Extra$andMap,
								A3($author$project$Route$Quote$Utils$fieldDefault, 'roof', $author$project$Route$Quote$Greenhouse$roofDecoder, $author$project$Route$Quote$Greenhouse$roofDefault),
								A2(
									$elm_community$json_extra$Json$Decode$Extra$andMap,
									A3($author$project$Route$Quote$Utils$fieldDefault, 'opening24VWithInverter', $elm$json$Json$Decode$int, 0),
									A2(
										$elm_community$json_extra$Json$Decode$Extra$andMap,
										A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Serre'),
										A2(
											$elm_community$json_extra$Json$Decode$Extra$andMap,
											A3($author$project$Route$Quote$Utils$fieldDefault, 'motor24VDC', $elm$json$Json$Decode$int, 0),
											A2(
												$elm_community$json_extra$Json$Decode$Extra$andMap,
												A3($author$project$Route$Quote$Utils$fieldDefault, 'mister', $elm$json$Json$Decode$int, 0),
												A2(
													$elm_community$json_extra$Json$Decode$Extra$andMap,
													A3($author$project$Route$Quote$Utils$fieldDefault, 'heatPipe', $elm$json$Json$Decode$int, 0),
													A2(
														$elm_community$json_extra$Json$Decode$Extra$andMap,
														A2(
															$elm_community$json_extra$Json$Decode$Extra$withDefault,
															0,
															A2(
																$elm$json$Json$Decode$at,
																_List_fromArray(
																	['heater', 'heatingStage']),
																$elm$json$Json$Decode$int)),
														A2(
															$elm_community$json_extra$Json$Decode$Extra$andMap,
															A2(
																$elm_community$json_extra$Json$Decode$Extra$withDefault,
																0,
																A2(
																	$elm$json$Json$Decode$at,
																	_List_fromArray(
																		['heater', 'quantity']),
																	$elm$json$Json$Decode$int)),
															A2(
																$elm_community$json_extra$Json$Decode$Extra$andMap,
																A2(
																	$elm_community$json_extra$Json$Decode$Extra$withDefault,
																	0,
																	A2(
																		$elm$json$Json$Decode$at,
																		_List_fromArray(
																			['fanWithLouvers', 'quantity']),
																		$elm$json$Json$Decode$int)),
																A2(
																	$elm_community$json_extra$Json$Decode$Extra$andMap,
																	A2(
																		$elm_community$json_extra$Json$Decode$Extra$withDefault,
																		0,
																		A2(
																			$elm$json$Json$Decode$at,
																			_List_fromArray(
																				['fan', 'quantity']),
																			$elm$json$Json$Decode$int)),
																	A2(
																		$elm_community$json_extra$Json$Decode$Extra$andMap,
																		A3($author$project$Route$Quote$Utils$fieldDefault, 'dryBulbWetBulbSensor', $elm$json$Json$Decode$int, 0),
																		A2(
																			$elm_community$json_extra$Json$Decode$Extra$andMap,
																			function (d) {
																				return A3($author$project$Route$Quote$Utils$fieldDefault, 'customProducts', d, _List_Nil);
																			}(
																				$elm$json$Json$Decode$list(
																					$author$project$Route$Quote$CustomProduct$decoder(currency))),
																			A2(
																				$elm_community$json_extra$Json$Decode$Extra$andMap,
																				A3($author$project$Route$Quote$Utils$fieldDefault, 'co2Sensor', $elm$json$Json$Decode$int, 0),
																				A2(
																					$elm_community$json_extra$Json$Decode$Extra$andMap,
																					A3($author$project$Route$Quote$Utils$fieldDefault, 'climateSensor', $elm$json$Json$Decode$int, 0),
																					A2(
																						$elm_community$json_extra$Json$Decode$Extra$andMap,
																						A2(
																							$elm_community$json_extra$Json$Decode$Extra$withDefault,
																							0,
																							A2(
																								$elm$json$Json$Decode$at,
																								_List_fromArray(
																									['circulationFan', 'quantity']),
																								$elm$json$Json$Decode$int)),
																						A2(
																							$elm_community$json_extra$Json$Decode$Extra$andMap,
																							A3($author$project$Route$Quote$Utils$fieldDefault, 'backUpThermostat', $elm$json$Json$Decode$int, 0),
																							A2(
																								$elm_community$json_extra$Json$Decode$Extra$andMap,
																								A2($elm_community$json_extra$Json$Decode$Extra$optionalNullableField, 'actuators', $author$project$Route$Quote$Greenhouse$actuatorDecoder),
																								$elm$json$Json$Decode$succeed($author$project$Route$Quote$Greenhouse$toGreenhouse))))))))))))))))))))))));
};
var $author$project$Domain$Quote$Documentation$Core = 1;
var $author$project$Domain$Quote$Documentation$CustomBasic = 2;
var $author$project$Domain$Quote$Documentation$None = 5;
var $author$project$Domain$Quote$Documentation$Replacement = 4;
var $author$project$Domain$Quote$Documentation$Seed = 0;
var $author$project$Route$Quote$Entry$documentationDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (documentation) {
		switch (documentation) {
			case 'Seed':
				return $elm$json$Json$Decode$succeed(0);
			case 'Core':
				return $elm$json$Json$Decode$succeed(1);
			case 'CustomPro':
				return $elm$json$Json$Decode$succeed(3);
			case 'CustomBasic':
				return $elm$json$Json$Decode$succeed(2);
			case 'Replacement':
				return $elm$json$Json$Decode$succeed(4);
			case 'None':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Documentation');
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$Domain$Quote$Mode$Basic = 1;
var $author$project$Route$Quote$Entry$modeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (mode) {
		switch (mode) {
			case 'Pro':
				return $elm$json$Json$Decode$succeed(0);
			case 'Basic':
				return $elm$json$Json$Decode$succeed(1);
			case 'StarterKit':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Mode');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Domain$Quote$Plan$Subscription = 1;
var $author$project$Route$Quote$Entry$planDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (plan) {
		switch (plan) {
			case 'Purchase':
				return $elm$json$Json$Decode$succeed(0);
			case 'Subscription':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Plan');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Route$Quote$Entry$quoteDecoder = function () {
	var greenhousesDecoder = $elm$json$Json$Decode$list(
		$author$project$Route$Quote$Greenhouse$decoder($author$project$Route$Quote$Entry$communicationCurrency));
	return A9(
		$elm$json$Json$Decode$map8,
		$author$project$Domain$Quote$Entry$Quote,
		A2($elm$json$Json$Decode$field, 'client', $author$project$Route$Quote$Client$decoder),
		A2(
			$elm$json$Json$Decode$field,
			'farm',
			$author$project$Route$Quote$Farm$decoder($author$project$Route$Quote$Entry$communicationCurrency)),
		A2($elm$json$Json$Decode$field, 'greenhouses', greenhousesDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'discount',
			$author$project$Route$Quote$Discount$decoder($author$project$Route$Quote$Entry$communicationCurrency)),
		A2($elm$json$Json$Decode$field, 'plan', $author$project$Route$Quote$Entry$planDecoder),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'mode', $author$project$Route$Quote$Entry$modeDecoder, 0),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'documentation', $author$project$Route$Quote$Entry$documentationDecoder, 3),
		A3($author$project$Route$Quote$Utils$fieldDefault, 'actuator', $author$project$Route$Quote$Entry$actuatorDecoder, 1));
}();
var $author$project$Route$Quote$Entry$parseQuote = function (url) {
	return A3($author$project$Route$QueryParameters$parseQueryParam, url, $author$project$Route$Quote$Entry$param, $author$project$Route$Quote$Entry$quoteDecoder);
};
var $author$project$Page$QuoteBuilderV3$Quote$Quote = F5(
	function (client, greenhouses, farm, plan, discounts) {
		return {hg: client, fR: discounts, fd: farm, aX: greenhouses, ab: plan};
	});
var $author$project$Page$QuoteBuilderV3$Quote$Amount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Quote$Discount = F3(
	function (name, discountType, precision) {
		return {fb: discountType, fk: name, id: precision};
	});
var $author$project$Page$QuoteBuilderV3$Quote$Percentage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Quote$discountTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (tag) {
		switch (tag) {
			case 'Percentage':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$QuoteBuilderV3$Quote$Percentage,
					A2($elm$json$Json$Decode$field, 'percentage', $elm$json$Json$Decode$int));
			case 'Amount':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Page$QuoteBuilderV3$Quote$Amount,
					A2($elm$json$Json$Decode$field, 'amount', $elm$json$Json$Decode$int));
			default:
				var x = tag;
				return $elm$json$Json$Decode$fail('Discount Type: ' + x);
		}
	},
	A2($elm$json$Json$Decode$field, 'tag', $elm$json$Json$Decode$string));
var $author$project$Page$QuoteBuilderV3$Quote$discountDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Page$QuoteBuilderV3$Quote$Discount,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Discount name'),
	A3(
		$author$project$Route$Quote$Utils$fieldDefault,
		'discountType',
		$author$project$Page$QuoteBuilderV3$Quote$discountTypeDecoder,
		$author$project$Page$QuoteBuilderV3$Quote$Amount(0)),
	A2($elm_community$json_extra$Json$Decode$Extra$optionalNullableField, 'precision', $elm$json$Json$Decode$string));
var $author$project$Page$QuoteBuilderV3$Quote$Farm = F2(
	function (products, customProducts) {
		return {hn: customProducts, ig: products};
	});
var $author$project$Page$QuoteBuilderV3$Quote$CustomProduct = F3(
	function (name, price, quantity) {
		return {fk: name, $8: price, ih: quantity};
	});
var $author$project$Page$QuoteBuilderV3$Quote$customProductDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Page$QuoteBuilderV3$Quote$CustomProduct,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Custom product'),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'price', $elm$json$Json$Decode$int, 0),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'quantity', $elm$json$Json$Decode$int, 0));
var $author$project$Page$QuoteBuilderV3$Quote$AdditionalBrain = 14;
var $author$project$Page$QuoteBuilderV3$Quote$AdvancedClimateSensor = 6;
var $author$project$Page$QuoteBuilderV3$Quote$AdvancedVentilation = 2;
var $author$project$Page$QuoteBuilderV3$Quote$ClimateSensor = 5;
var $author$project$Page$QuoteBuilderV3$Quote$DiseasePrevention = 0;
var $author$project$Page$QuoteBuilderV3$Quote$HeatPipe = 9;
var $author$project$Page$QuoteBuilderV3$Quote$Heating = 3;
var $author$project$Page$QuoteBuilderV3$Quote$HumidityConservation = 4;
var $author$project$Page$QuoteBuilderV3$Quote$Irrigation = 1;
var $author$project$Page$QuoteBuilderV3$Quote$OutdoorClimateSensor = 15;
var $author$project$Page$QuoteBuilderV3$Quote$RainProtection = 10;
var $author$project$Page$QuoteBuilderV3$Quote$RangeExtensionKit = 13;
var $author$project$Page$QuoteBuilderV3$Quote$SolarSensor = 12;
var $author$project$Page$QuoteBuilderV3$Quote$Tensiometer = 7;
var $author$project$Page$QuoteBuilderV3$Quote$ThermalScreen = 8;
var $author$project$Page$QuoteBuilderV3$Quote$WindProtection = 11;
var $author$project$Page$QuoteBuilderV3$Quote$productDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'DiseasePrevention':
				return $elm$json$Json$Decode$succeed(0);
			case 'Irrigation':
				return $elm$json$Json$Decode$succeed(1);
			case 'AdvancedVentilation':
				return $elm$json$Json$Decode$succeed(2);
			case 'Heating':
				return $elm$json$Json$Decode$succeed(3);
			case 'ClimateSensor':
				return $elm$json$Json$Decode$succeed(5);
			case 'AdvancedClimateSensor':
				return $elm$json$Json$Decode$succeed(6);
			case 'Tensiometer':
				return $elm$json$Json$Decode$succeed(7);
			case 'HumidityConservation':
				return $elm$json$Json$Decode$succeed(4);
			case 'ThermalScreen':
				return $elm$json$Json$Decode$succeed(8);
			case 'HeatPipe':
				return $elm$json$Json$Decode$succeed(9);
			case 'RainProtection':
				return $elm$json$Json$Decode$succeed(10);
			case 'WindProtection':
				return $elm$json$Json$Decode$succeed(11);
			case 'SolarSensor':
				return $elm$json$Json$Decode$succeed(12);
			case 'RangeExtensionKit':
				return $elm$json$Json$Decode$succeed(13);
			case 'AdditionalBrain':
				return $elm$json$Json$Decode$succeed(14);
			case 'OutdoorClimateSensor':
				return $elm$json$Json$Decode$succeed(15);
			default:
				var x = str;
				return $elm$json$Json$Decode$fail('Product error: ' + x);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Page$QuoteBuilderV3$Quote$productListDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm_community$maybe_extra$Maybe$Extra$values,
	$elm$json$Json$Decode$list(
		$elm$json$Json$Decode$maybe($author$project$Page$QuoteBuilderV3$Quote$productDecoder)));
var $author$project$Page$QuoteBuilderV3$Quote$farmDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Page$QuoteBuilderV3$Quote$Farm,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'products', $author$project$Page$QuoteBuilderV3$Quote$productListDecoder, _List_Nil),
	A3(
		$author$project$Route$Quote$Utils$fieldDefault,
		'customProducts',
		$elm$json$Json$Decode$list($author$project$Page$QuoteBuilderV3$Quote$customProductDecoder),
		_List_Nil));
var $author$project$Page$QuoteBuilderV3$Quote$Greenhouse = F4(
	function (name, basePlan, products, customProducts) {
		return {g9: basePlan, hn: customProducts, fk: name, ig: products};
	});
var $author$project$Page$QuoteBuilderV3$Quote$Core = 2;
var $author$project$Page$QuoteBuilderV3$Quote$Custom = 3;
var $author$project$Page$QuoteBuilderV3$Quote$basePlanDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Seed':
				return $elm$json$Json$Decode$succeed(1);
			case 'Core':
				return $elm$json$Json$Decode$succeed(2);
			case 'Custom':
				return $elm$json$Json$Decode$succeed(3);
			default:
				var x = str;
				return $elm$json$Json$Decode$fail('BasePlan error: ' + x);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Page$QuoteBuilderV3$Quote$greenhouseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Page$QuoteBuilderV3$Quote$Greenhouse,
	A3($author$project$Route$Quote$Utils$fieldDefault, 'name', $elm$json$Json$Decode$string, 'Serre'),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'basePlan', $author$project$Page$QuoteBuilderV3$Quote$basePlanDecoder, 1),
	A3($author$project$Route$Quote$Utils$fieldDefault, 'products', $author$project$Page$QuoteBuilderV3$Quote$productListDecoder, _List_Nil),
	A3(
		$author$project$Route$Quote$Utils$fieldDefault,
		'customProducts',
		$elm$json$Json$Decode$list($author$project$Page$QuoteBuilderV3$Quote$customProductDecoder),
		_List_Nil));
var $author$project$Page$QuoteBuilderV3$Quote$decoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Page$QuoteBuilderV3$Quote$Quote,
	A2($elm$json$Json$Decode$field, 'client', $author$project$Route$Quote$Client$decoder),
	A2(
		$elm$json$Json$Decode$field,
		'greenhouses',
		$elm$json$Json$Decode$list($author$project$Page$QuoteBuilderV3$Quote$greenhouseDecoder)),
	A2($elm$json$Json$Decode$field, 'farm', $author$project$Page$QuoteBuilderV3$Quote$farmDecoder),
	A2($elm$json$Json$Decode$field, 'plan', $author$project$Route$Quote$Entry$planDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'discounts',
		$elm$json$Json$Decode$list($author$project$Page$QuoteBuilderV3$Quote$discountDecoder)));
var $author$project$Page$QuoteBuilderV3$Update$param = 'quoteV3';
var $author$project$Page$QuoteBuilderV3$Update$quoteFromUrl = function (url) {
	return A3($author$project$Route$QueryParameters$parseQueryParam, url, $author$project$Page$QuoteBuilderV3$Update$param, $author$project$Page$QuoteBuilderV3$Quote$decoder);
};
var $author$project$Update$initialModel = F4(
	function (navigationKey, url, urlLanguage, user) {
		var quoteV3FromUrl = $author$project$Page$QuoteBuilderV3$Update$quoteFromUrl(url);
		var quoteFromUrl = $author$project$Route$Quote$Entry$parseQuote(url);
		var isOrisha = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Maybe$map, $author$project$Domain$User$isOrisha, user));
		var page = isOrisha ? 1 : 0;
		return {
			bp: false,
			hR: urlLanguage,
			df: $author$project$Login$Page$initialModel,
			fl: navigationKey,
			fn: $elm$core$Maybe$Nothing,
			dC: $author$project$Page$PackingSlip$initialModel,
			dD: $author$project$Page$PackingSlipV3$initialModel,
			a2: page,
			aC: A2($author$project$Page$QuoteBuilder$Update$initialModel, user, quoteFromUrl),
			aD: A2($author$project$Page$QuoteBuilderV3$Update$initialModel, user, quoteV3FromUrl),
			fw: $elm$core$Maybe$Nothing,
			eO: url,
			aJ: user
		};
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Route$QueryParameters$decodeLanguage = function (str) {
	if ((!str.$) && (str.a === 'EN')) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Route$QueryParameters$languageParser = A2(
	$elm$url$Url$Parser$Query$map,
	$author$project$Route$QueryParameters$decodeLanguage,
	$elm$url$Url$Parser$Query$string('language'));
var $author$project$Route$QueryParameters$parseLanguage = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		1,
		A2(
			$elm$url$Url$Parser$parse,
			A2($elm$url$Url$Parser$questionMark, $elm$url$Url$Parser$top, $author$project$Route$QueryParameters$languageParser),
			url));
};
var $author$project$Update$init = F3(
	function (flags, url, navigationKey) {
		var user = $author$project$Domain$User$decode(flags.iB);
		var languageParameters = $author$project$Route$QueryParameters$parseLanguage(url);
		return _Utils_Tuple2(
			A4($author$project$Update$initialModel, navigationKey, url, languageParameters, user),
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A2($elm$core$Task$perform, $author$project$Update$Now, $elm$time$Time$now),
						A2($elm$core$Task$perform, $author$project$Update$Timezone, $elm$time$Time$here)
					])));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$cons = _String_cons;
var $robinheghan$murmur3$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {aP: charsProcessed, aY: hash, ef: seed, a3: shift};
	});
var $robinheghan$murmur3$Murmur3$c1 = 3432918353;
var $robinheghan$murmur3$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $robinheghan$murmur3$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Bitwise$or = _Bitwise_or;
var $robinheghan$murmur3$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $robinheghan$murmur3$Murmur3$finalize = function (data) {
	var acc = (!(!data.aY)) ? (data.ef ^ A2(
		$robinheghan$murmur3$Murmur3$multiplyBy,
		$robinheghan$murmur3$Murmur3$c2,
		A2(
			$robinheghan$murmur3$Murmur3$rotlBy,
			15,
			A2($robinheghan$murmur3$Murmur3$multiplyBy, $robinheghan$murmur3$Murmur3$c1, data.aY)))) : data.ef;
	var h0 = acc ^ data.aP;
	var h1 = A2($robinheghan$murmur3$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($robinheghan$murmur3$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $robinheghan$murmur3$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$robinheghan$murmur3$Murmur3$multiplyBy,
			5,
			A2(
				$robinheghan$murmur3$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$robinheghan$murmur3$Murmur3$multiplyBy,
					$robinheghan$murmur3$Murmur3$c2,
					A2(
						$robinheghan$murmur3$Murmur3$rotlBy,
						15,
						A2($robinheghan$murmur3$Murmur3$multiplyBy, $robinheghan$murmur3$Murmur3$c1, k1))))) + 3864292196;
	});
var $robinheghan$murmur3$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.aY | ((255 & $elm$core$Char$toCode(c)) << data.a3);
		var _v0 = data.a3;
		if (_v0 === 24) {
			return {
				aP: data.aP + 1,
				aY: 0,
				ef: A2($robinheghan$murmur3$Murmur3$mix, data.ef, res),
				a3: 0
			};
		} else {
			return {aP: data.aP + 1, aY: res, ef: data.ef, a3: data.a3 + 8};
		}
	});
var $robinheghan$murmur3$Murmur3$hashString = F2(
	function (seed, str) {
		return $robinheghan$murmur3$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$robinheghan$murmur3$Murmur3$hashFold,
				A4($robinheghan$murmur3$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$initialSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($robinheghan$murmur3$Murmur3$hashString, $rtfeldman$elm_css$Hash$initialSeed, str)));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				return styles;
			} else {
				return A3(
					$elm$core$Dict$insert,
					cssTemplate,
					$rtfeldman$elm_css$Hash$fromString(cssTemplate),
					styles);
			}
		} else {
			return styles;
		}
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlJson(value));
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = F2(
	function (styles, _v0) {
		var val = _v0.a;
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				var classname = _v1.a;
				return A2(
					$elm$virtual_dom$VirtualDom$property,
					'className',
					$elm$json$Json$Encode$string(classname));
			} else {
				return A2(
					$elm$virtual_dom$VirtualDom$property,
					'className',
					$elm$json$Json$Encode$string('_unstyled'));
			}
		} else {
			return val;
		}
	});
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS = F2(
	function (styles, _v0) {
		var val = _v0.a;
		var isCssStyles = _v0.b;
		var cssTemplate = _v0.c;
		if (isCssStyles) {
			var _v1 = A2($elm$core$Dict$get, cssTemplate, styles);
			if (!_v1.$) {
				var classname = _v1.a;
				return A2($elm$virtual_dom$VirtualDom$attribute, 'class', classname);
			} else {
				return A2($elm$virtual_dom$VirtualDom$attribute, 'class', '_unstyled');
			}
		} else {
			return val;
		}
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_nodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(finalStyles),
						properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin = '\u0007';
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$styleToDeclaration = F3(
	function (template, classname, declaration) {
		return declaration + ('\n' + A3($elm$core$String$replace, $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin, classname, template));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return A3($elm$core$Dict$foldl, $rtfeldman$elm_css$VirtualDom$Styled$styleToDeclaration, '', dict);
};
var $rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration = F2(
	function (scopingPrefix, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (template, classname, declaration) {
					return declaration + ('\n' + A3($elm$core$String$replace, '.' + $rtfeldman$elm_css$VirtualDom$Styled$classnameStandin, '#' + (scopingPrefix + ('.' + classname)), template));
				}),
			'',
			dict);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = F2(
	function (maybeNonce, accumulatedStyles) {
		var cssText = function () {
			if (!accumulatedStyles.$) {
				var allStyles = accumulatedStyles.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(allStyles);
			} else {
				var scope = accumulatedStyles.a;
				var rootStyles = accumulatedStyles.b;
				var descendantStyles = accumulatedStyles.c;
				return A2($rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration, scope, rootStyles) + ('\n' + A2($rtfeldman$elm_css$VirtualDom$Styled$toScopedDeclaration, scope + ' ', descendantStyles));
			}
		}();
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			'span',
			_List_fromArray(
				[
					A2($elm$virtual_dom$VirtualDom$attribute, 'style', 'display: none;'),
					A2($elm$virtual_dom$VirtualDom$attribute, 'class', 'elm-css-style-wrapper')
				]),
			_List_fromArray(
				[
					A3(
					$elm$virtual_dom$VirtualDom$node,
					'style',
					function () {
						if (!maybeNonce.$) {
							var nonce = maybeNonce.a;
							return _List_fromArray(
								[
									A2($elm$virtual_dom$VirtualDom$attribute, 'nonce', nonce)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					$elm$core$List$singleton(
						$elm$virtual_dom$VirtualDom$text(cssText)))
				]));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F4(
	function (maybeNonce, elemType, properties, children) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = A2(
			$rtfeldman$elm_css$VirtualDom$Styled$toStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles),
			properties);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F3(
	function (maybeNonce, accumulatedStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toStyleNode, maybeNonce, accumulatedStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F4(
	function (maybeNonce, elemType, properties, keyedChildren) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A3(
			$rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles),
			keyedChildNodes);
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute(styles),
			properties);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F5(
	function (maybeNonce, ns, elemType, properties, keyedChildren) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A3(
			$rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles),
			keyedChildNodes);
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles),
			properties);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F5(
	function (maybeNonce, ns, elemType, properties, children) {
		var initialStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, $elm$core$Dict$empty, properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = A2(
			$rtfeldman$elm_css$VirtualDom$Styled$toStyleNode,
			maybeNonce,
			$rtfeldman$elm_css$VirtualDom$Styled$UnscopedStyles(styles));
		var unstyledProperties = A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttributeNS(styles),
			properties);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyle, $elm$core$Maybe$Nothing, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A5($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, $elm$core$Maybe$Nothing, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, $elm$core$Maybe$Nothing, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A5($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, $elm$core$Maybe$Nothing, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $author$project$Theme$ImgPath$base = '/';
var $author$project$Theme$ImgPath$background = $author$project$Theme$ImgPath$base + 'bg-1366.jpg';
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$Property = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.gU);
	});
var $rtfeldman$elm_css$Css$backgroundImage = $rtfeldman$elm_css$Css$prop1('background-image');
var $rtfeldman$elm_css$Css$backgroundSize = $rtfeldman$elm_css$Css$prop1('background-size');
var $author$project$Update$PackingSlipMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Update$PackingSlipV3Msg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Update$Print = {$: 9};
var $author$project$Update$QuoteBuilderMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Update$QuoteBuilderV3Msg = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			fy: 0,
			fK: 0,
			aV: 0,
			B: 0,
			r: 0,
			aZ: 0,
			ao: 0,
			a_: 0,
			a$: 0,
			az: 0,
			aA: 0,
			ah: 0,
			a0: 0,
			ar: numericValue,
			a6: 0,
			a8: unitLabel,
			bE: units,
			gU: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.gU);
};
var $author$project$Style$Bulma$box = 'box';
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			false,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.ho) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.fk, record.ho, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{ho: decl, fk: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactDeclarations = function (declarations) {
	var _v0 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v0.a;
	var compactedDeclarations = _v0.b;
	return A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
};
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.fM;
	var imports = _v0.f6;
	var namespaces = _v0.gk;
	var declarations = _v0.hp;
	return {
		fM: charset,
		hp: $rtfeldman$elm_css$Css$Structure$compactDeclarations(declarations),
		f6: imports,
		gk: namespaces
	};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$String$mapJoinHelp = F4(
	function (map, sep, strs, result) {
		mapJoinHelp:
		while (true) {
			if (!strs.b) {
				return result;
			} else {
				if (!strs.b.b) {
					var first = strs.a;
					return result + (map(first) + '');
				} else {
					var first = strs.a;
					var rest = strs.b;
					var $temp$map = map,
						$temp$sep = sep,
						$temp$strs = rest,
						$temp$result = result + (map(first) + (sep + ''));
					map = $temp$map;
					sep = $temp$sep;
					strs = $temp$strs;
					result = $temp$result;
					continue mapJoinHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$Css$String$mapJoin = F3(
	function (map, sep, strs) {
		return A4($rtfeldman$elm_css$Css$String$mapJoinHelp, map, sep, strs, '');
	});
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.fX + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.gU)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, ' and ', expressions);
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
		'\n',
		mediaQueries);
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		function (_v0) {
			var prop = _v0;
			return prop + ';';
		},
		'',
		properties);
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return _Utils_ap(
				str,
				A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors);
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return _Utils_ap(
				str,
				A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, '', repeatableSimpleSelectors));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return $rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator) + (' ' + $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement));
	return A2(
		$elm$core$String$append,
		A2($elm$core$String$join, ' ', segments),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = function (_v0) {
	var firstSelector = _v0.a;
	var otherSelectors = _v0.b;
	var properties = _v0.c;
	var selectorStr = A3(
		$rtfeldman$elm_css$Css$String$mapJoin,
		$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
		',',
		A2($elm$core$List$cons, firstSelector, otherSelectors));
	return selectorStr + ('{' + ($rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties) + '}'));
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock(styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, ', ', mediaQueries);
			var blocks = A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, '\n', styleBlocks);
			return '@media ' + (query + ('{' + (blocks + '}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.fk;
			var declaration = decl.a.ho;
			return '@keyframes ' + (name + ('{' + (declaration + '}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.fM;
	var imports = _v0.f6;
	var namespaces = _v0.gk;
	var declarations = _v0.hp;
	return $rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$importToString, '\n', imports) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, '\n', namespaces) + (A3($rtfeldman$elm_css$Css$String$mapJoin, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, '\n', declarations) + '')));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$PageRule(properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{ho: str, fk: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$PageRule(properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.fM;
	var imports = _v0.f6;
	var namespaces = _v0.gk;
	var snippets = _v0.gJ;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {fM: charset, hp: declarations, f6: imports, gk: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {fM: $elm$core$Maybe$Nothing, f6: _List_Nil, gk: _List_Nil, gJ: snippets};
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$templateSelector = $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$ClassSelector($rtfeldman$elm_css$VirtualDom$Styled$classnameStandin)
		]));
var $rtfeldman$elm_css$VirtualDom$Styled$getCssTemplate = function (styles) {
	if (!styles.b) {
		return '';
	} else {
		var otherwise = styles;
		return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$VirtualDom$Styled$makeSnippet, styles, $rtfeldman$elm_css$VirtualDom$Styled$templateSelector)
					])));
	}
};
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var cssTemplate = $rtfeldman$elm_css$VirtualDom$Styled$getCssTemplate(styles);
	var classProperty = A2($elm$virtual_dom$VirtualDom$attribute, '', '');
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, true, cssTemplate);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$Css$displayFlex = A2($rtfeldman$elm_css$Css$property, 'display', 'flex');
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $author$project$S$empty = $rtfeldman$elm_css$Html$Styled$text('');
var $author$project$View$en = {bw: 'Print'};
var $rtfeldman$elm_css$Css$fixed = {bi: 0, bv: 0, et: 0, gU: 'fixed'};
var $author$project$View$fr = {bw: 'Imprimer'};
var $author$project$Language$getTranslation = F2(
	function (language, translations) {
		if (!language) {
			return translations.hw;
		} else {
			return translations.hE;
		}
	});
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		aw: 0,
		c$: 0,
		aA: 0,
		ah: 0,
		bu: 0,
		dw: 0,
		ar: val,
		a8: '',
		bE: 0,
		gU: $elm$core$String$fromInt(val)
	};
};
var $author$project$Update$SetLanguage = function (a) {
	return {$: 10, a: a};
};
var $rtfeldman$elm_css$Css$boxShadow = $rtfeldman$elm_css$Css$prop1('box-shadow');
var $elm$core$String$endsWith = _String_endsWith;
var $elm$core$String$toLower = _String_toLower;
var $rtfeldman$elm_css$Css$makeImportant = function (_v0) {
	var str = _v0;
	return A2(
		$elm$core$String$endsWith,
		' !important',
		$elm$core$String$toLower(str)) ? str : (str + ' !important');
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Preprocess$mapAllProperties = F2(
	function (update, styles) {
		if (!styles.b) {
			return styles;
		} else {
			if (!styles.b.b) {
				var only = styles.a;
				return _List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$Preprocess$mapProperties, update, only)
					]);
			} else {
				var first = styles.a;
				var rest = styles.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllProperties, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$mapProperties = F2(
	function (update, style) {
		switch (style.$) {
			case 0:
				var property = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(
					update(property));
			case 1:
				var selector = style.a;
				var styles = style.b;
				return A2(
					$rtfeldman$elm_css$Css$Preprocess$ExtendSelector,
					selector,
					A2($rtfeldman$elm_css$Css$Preprocess$mapAllProperties, update, styles));
			case 2:
				return style;
			case 3:
				return style;
			case 4:
				return style;
			case 5:
				return style;
			default:
				var otherStyles = style.a;
				return $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$mapProperties(update),
						otherStyles));
		}
	});
var $rtfeldman$elm_css$Css$important = $rtfeldman$elm_css$Css$Preprocess$mapProperties($rtfeldman$elm_css$Css$makeImportant);
var $rtfeldman$elm_css$Css$initial = {fB: 0, u: 0, bi: 0, fE: 0, aN: 0, fF: 0, aO: 0, ak: 0, H: 0, bY: 0, ag: 0, d: 0, o: 0, aV: 0, fe: 0, bl: 0, cD: 0, av: 0, B: 0, bm: 0, j: 0, aw: 0, c$: 0, gd: 0, fi: 0, r: 0, aZ: 0, ao: 0, a_: 0, a$: 0, az: 0, aA: 0, ah: 0, a0: 0, de: 0, f: 0, e: 0, du: 0, bu: 0, ar: 0, dB: 0, a1: 0, ac: 0, et: 0, a5: 0, aG: 0, a6: 0, a7: 0, aH: 0, T: 0, a8: '', bE: 0, gU: 'initial', bF: 0, aL: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{gU: 'inherit'});
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$none = {aN: 0, fH: 0, H: 0, d: 0, o: 0, hJ: 0, f8: 0, fi: 0, a$: 0, az: 0, ah: 0, f: 0, e: 0, du: 0, dB: 0, ic: 0, ac: 0, d1: 0, ir: 0, a5: 0, aH: 0, T: 0, l: 0, iE: 0, gU: 'none'};
var $rtfeldman$elm_css$Css$pointer = {d: 0, gU: 'pointer'};
var $rtfeldman$elm_css$Css$transparent = {ag: 0, gU: 'transparent'};
var $rtfeldman$elm_css$Html$Styled$Attributes$type_ = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('type');
var $author$project$Style$Buttons$cleanButton = F2(
	function (attrs, body) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$append,
				attrs,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
								$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]))
					])),
			body);
	});
var $author$project$S$cleanButton = $author$project$Style$Buttons$cleanButton;
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.gU);
};
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $rtfeldman$elm_css$Css$flexEnd = $rtfeldman$elm_css$Css$prop1('flex-end');
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		bg: 1,
		ha: 0,
		ag: 0,
		hF: 0,
		ij: 0,
		gU: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				bg: alpha / 255,
				ha: blue,
				ag: 0,
				hF: green,
				ij: red,
				gU: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $author$project$Theme$Color$linkBlue = $rtfeldman$elm_css$Css$hex('#3273dc');
var $rtfeldman$elm_css$Css$marginBottom = $rtfeldman$elm_css$Css$prop1('margin-bottom');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			false,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $author$project$View$languageSelectionView = F2(
	function (language, msg) {
		var _v0 = function () {
			if (!language) {
				return _Utils_Tuple2('Français', 1);
			} else {
				return _Utils_Tuple2('English', 0);
			}
		}();
		var languageOption = _v0.a;
		var selectedLanguage = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$em(-3)),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$em(2))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$cleanButton,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$em(1))),
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$linkBlue),
									$rtfeldman$elm_css$Css$zIndex(
									$rtfeldman$elm_css$Css$int(10))
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							msg(selectedLanguage))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(languageOption)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$maxWidth = $rtfeldman$elm_css$Css$prop1('max-width');
var $rtfeldman$elm_css$Css$Structure$OnlyQuery = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$only = $rtfeldman$elm_css$Css$Structure$OnlyQuery;
var $rtfeldman$elm_css$Css$Structure$Print = 0;
var $rtfeldman$elm_css$Css$Media$print = 0;
var $rtfeldman$elm_css$Css$Preprocess$WithMedia = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$withMedia = $rtfeldman$elm_css$Css$Preprocess$WithMedia;
var $author$project$Style$Media$onPrint = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Css$Media$only, $rtfeldman$elm_css$Css$Media$print, _List_Nil)
		]));
var $author$project$S$onPrint = $author$project$Style$Media$onPrint;
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2($rtfeldman$elm_css$Css$property, key, argA.gU + (' ' + argB.gU));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$RemUnits = 0;
var $rtfeldman$elm_css$Css$rem = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var $rtfeldman$elm_css$Css$VwUnits = 0;
var $rtfeldman$elm_css$Css$vw = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vw');
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $author$project$View$mainBox = F2(
	function (language, html) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$box),
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$vw(51)),
							$rtfeldman$elm_css$Css$maxWidth(
							$rtfeldman$elm_css$Css$px(1000)),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(1.5)),
							$rtfeldman$elm_css$Css$important(
							A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(6),
								$rtfeldman$elm_css$Css$rem(6))),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit),
									$rtfeldman$elm_css$Css$maxWidth($rtfeldman$elm_css$Css$inherit),
									$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none),
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$inherit))
								]))
						]))
				]),
			_List_fromArray(
				[
					A2($author$project$View$languageSelectionView, language, $author$project$Update$SetLanguage),
					html
				]));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var isCssStyle = _v0.b;
		var cssTemplate = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			isCssStyle,
			cssTemplate);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$Structure$NotQuery = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Media$not = $rtfeldman$elm_css$Css$Structure$NotQuery;
var $author$project$Style$Media$notOnPrint = $rtfeldman$elm_css$Css$Media$withMedia(
	_List_fromArray(
		[
			A2($rtfeldman$elm_css$Css$Media$not, $rtfeldman$elm_css$Css$Media$print, _List_Nil)
		]));
var $author$project$S$notOnPrint = $author$project$Style$Media$notOnPrint;
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		aA: 0,
		ah: 0,
		a0: 0,
		bu: 0,
		dw: 0,
		ar: val,
		a8: '',
		bE: 0,
		gU: $elm$core$String$fromFloat(val)
	};
};
var $rtfeldman$elm_css$Css$opacity = $rtfeldman$elm_css$Css$prop1('opacity');
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $author$project$Style$Buttons$button = F2(
	function (attrs, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('button-hover'),
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('button'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(1.5)),
								$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]))
					]),
				attrs),
			children);
	});
var $author$project$Theme$Color$darkWhite = $rtfeldman$elm_css$Css$hex('#cccccc');
var $rtfeldman$elm_css$Html$Styled$i = $rtfeldman$elm_css$Html$Styled$node('i');
var $rtfeldman$elm_css$Css$marginRight = $rtfeldman$elm_css$Css$prop1('margin-right');
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $elm_community$maybe_extra$Maybe$Extra$toList = function (m) {
	if (m.$ === 1) {
		return _List_Nil;
	} else {
		var x = m.a;
		return _List_fromArray(
			[x]);
	}
};
var $author$project$Style$Icon$faIcon = F2(
	function (fontAwesomeClass, color) {
		var iconStyles = _List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginLeft(
				$rtfeldman$elm_css$Css$em(0.3)),
				$rtfeldman$elm_css$Css$marginRight(
				$rtfeldman$elm_css$Css$em(0.3))
			]);
		var styleWithColor = A2(
			$elm$core$List$append,
			iconStyles,
			$elm_community$maybe_extra$Maybe$Extra$toList(
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$color, color)));
		return A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(styleWithColor)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$i,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('fa fa-' + fontAwesomeClass)
						]),
					_List_Nil)
				]));
	});
var $author$project$Style$Buttons$faButton = F4(
	function (fontAwesomeClass, color, str, attrs) {
		return A2(
			$author$project$Style$Buttons$button,
			attrs,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$darkWhite)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(str)
						])),
					A2($author$project$Style$Icon$faIcon, fontAwesomeClass, color)
				]));
	});
var $author$project$Theme$Color$green = $rtfeldman$elm_css$Css$hex('#149614');
var $author$project$Style$Buttons$printButton = A2(
	$author$project$Style$Buttons$faButton,
	'print',
	$elm$core$Maybe$Just($author$project$Theme$Color$green));
var $author$project$S$printButton = $author$project$Style$Buttons$printButton;
var $rtfeldman$elm_css$Css$right = $rtfeldman$elm_css$Css$prop1('right');
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $author$project$View$printLayout = F2(
	function (model, body) {
		var translation = A2(
			$author$project$Language$getTranslation,
			model.hR,
			{hw: $author$project$View$en, hE: $author$project$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					body,
					A2(
					$author$project$S$printButton,
					translation.bw,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
									$rtfeldman$elm_css$Css$top(
									$rtfeldman$elm_css$Css$rem(4)),
									$rtfeldman$elm_css$Css$right(
									$rtfeldman$elm_css$Css$rem(4)),
									$author$project$S$onPrint(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
										]))
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Update$Print)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Discount$Update$build = function (model) {
	return {hm: model.hm, ie: model.ie};
};
var $author$project$Page$QuoteBuilder$Client$toQuote = function (model) {
	return model;
};
var $author$project$Domain$Quote$Mode$isFarmProductIncluded = F2(
	function (mode, product) {
		var onlyInPro = !mode;
		switch (product) {
			case 0:
				return true;
			case 1:
				return onlyInPro;
			case 2:
				return onlyInPro;
			case 3:
				return onlyInPro;
			case 4:
				return onlyInPro;
			default:
				return onlyInPro;
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Page$QuoteBuilder$Farm$Update$toQuote = F2(
	function (mode, model) {
		var products = A2(
			$elm$core$List$filter,
			$author$project$Domain$Quote$Mode$isFarmProductIncluded(mode),
			model.ig);
		var member = function (product) {
			return A2($elm$core$List$member, product, products);
		};
		return {
			hk: member(0),
			hn: model.aR.hn,
			ib: member(1),
			ii: member(2),
			bz: member(3),
			iG: member(5),
			bG: member(4)
		};
	});
var $elm_community$list_extra$List$Extra$count = function (predicate) {
	return A2(
		$elm$core$List$foldl,
		F2(
			function (x, acc) {
				return predicate(x) ? (acc + 1) : acc;
			}),
		0);
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$en = {cJ: 'Greenhouse '};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$fr = {cJ: 'Serre '};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$defaultName = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Greenhouse$Update$en, hE: $author$project$Page$QuoteBuilder$Greenhouse$Update$fr});
		return _Utils_ap(
			translation.cJ,
			$elm$core$String$fromInt(model.cY + 1));
	});
var $author$project$Domain$Quote$Mode$isGreenhouseProductIncluded = F2(
	function (mode, product) {
		var onlyInPro = !mode;
		switch (product) {
			case 0:
				return true;
			case 1:
				return true;
			case 2:
				return onlyInPro;
			case 3:
				return onlyInPro;
			case 4:
				return onlyInPro;
			case 5:
				return true;
			case 6:
				return true;
			case 7:
				return true;
			case 8:
				return true;
			case 9:
				return true;
			case 10:
				return true;
			case 11:
				return true;
			case 12:
				return true;
			case 13:
				return onlyInPro;
			case 14:
				return onlyInPro;
			case 15:
				return onlyInPro;
			case 16:
				return true;
			case 17:
				return onlyInPro;
			default:
				return onlyInPro;
		}
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$toQuote = function (model) {
	return A2(
		$elm$core$List$map,
		function (p) {
			return {hr: p.hr, fk: p.fk, $8: p.$8, ih: p.ih, il: p.il};
		},
		model.hn);
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$toQuote = function ($) {
	return $.ae;
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$toQuote = F3(
	function (language, mode, model) {
		var products = A2(
			$elm$core$List$filter,
			$author$project$Domain$Quote$Mode$isGreenhouseProductIncluded(mode),
			model.ig);
		var name = function () {
			var _v0 = model.fk;
			switch (_v0.$) {
				case 0:
					var initial = _v0.a.bo;
					return initial;
				case 1:
					var n = _v0.a;
					return n;
				default:
					return A2($author$project$Page$QuoteBuilder$Greenhouse$Update$defaultName, language, model);
			}
		}();
		var count = function (product) {
			return A2(
				$elm_community$list_extra$List$Extra$count,
				$elm$core$Basics$eq(product),
				products);
		};
		return {
			ae: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$toQuote(model.aM),
			g6: count(5),
			b$: count(13),
			hh: count(0),
			hi: count(3),
			hn: $author$project$Page$QuoteBuilder$CustomField$Update$toQuote(model.aR),
			aS: count(1),
			cA: count(11),
			hC: count(12),
			cN: count(18),
			cO: count(6),
			hH: count(7),
			dk: count(15),
			dl: count(16),
			fk: name,
			h9: count(10),
			iq: {
				hO: model.d8,
				ih: count(8)
			},
			gH: count(9),
			iu: count(2),
			iz: count(4),
			eC: count(17),
			eR: count(14)
		};
	});
var $author$project$Page$QuoteBuilder$Update$toQuote = function (model) {
	return {
		ae: model.ae,
		hg: $author$project$Page$QuoteBuilder$Client$toQuote(model.hg),
		fR: $author$project$Page$QuoteBuilder$Discount$Update$build(model.I),
		ht: model.ht,
		fd: A2($author$project$Page$QuoteBuilder$Farm$Update$toQuote, model.hX, model.fd),
		aX: A2(
			$elm$core$List$map,
			A2($author$project$Page$QuoteBuilder$Greenhouse$Update$toQuote, model.hg.hR, model.hX),
			model.aX),
		hX: model.hX,
		ab: model.ab
	};
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$toQuote = function (model) {
	return {hn: model.aR.hn, ig: model.ig};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$en = {cJ: 'Greenhouse '};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$fr = {cJ: 'Serre '};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$defaultName = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$en, hE: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$fr});
		return _Utils_ap(
			translation.cJ,
			$elm$core$String$fromInt(model.cY + 1));
	});
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$toQuote = F2(
	function (language, g) {
		return {
			g9: g.g9,
			hn: g.aR.hn,
			fk: function () {
				var _v0 = g.fk;
				switch (_v0.$) {
					case 0:
						var initial = _v0.a.bo;
						return initial;
					case 1:
						var n = _v0.a;
						return n;
					default:
						return A2($author$project$Page$QuoteBuilderV3$Greenhouse$Update$defaultName, language, g);
				}
			}(),
			ig: g.ig
		};
	});
var $author$project$Page$QuoteBuilderV3$Update$toQuote = function (model) {
	return {
		hg: model.hg,
		fR: model.I.hm,
		fd: $author$project$Page$QuoteBuilderV3$Farm$Update$toQuote(model.fd),
		aX: A2(
			$elm$core$List$map,
			$author$project$Page$QuoteBuilderV3$Greenhouse$Update$toQuote(model.hg.hR),
			model.aX),
		ab: model.ab
	};
};
var $author$project$Update$ExtendTotal = {$: 13};
var $rtfeldman$elm_css$Css$row = {fe: 0, bl: 0, gU: 'row'};
var $rtfeldman$elm_css$Css$column = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{gU: 'column'});
var $rtfeldman$elm_css$Css$flexDirection = $rtfeldman$elm_css$Css$prop1('flex-direction');
var $rtfeldman$elm_css$Css$VhUnits = 0;
var $rtfeldman$elm_css$Css$vh = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'vh');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.gU;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Html$Styled$td = $rtfeldman$elm_css$Html$Styled$node('td');
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Html$Styled$tr = $rtfeldman$elm_css$Html$Styled$node('tr');
var $author$project$Page$QuoteBuilder$Total$actuatorPrice = F2(
	function (translation, price) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cq)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(price))
						]))
				]));
	});
var $rtfeldman$elm_css$Css$bold = {aw: 0, gU: 'bold'};
var $author$project$Domain$Total$Amount$inCurrency = F2(
	function (currency, _v0) {
		var amount = _v0;
		if (!currency) {
			return amount.cQ;
		} else {
			return $elm$core$Basics$round(
				function (inCAD) {
					return inCAD * $author$project$Domain$Total$Amount$cadToUsdRate;
				}(amount.cQ));
		}
	});
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $author$project$Formatter$Number$formatAmount = F2(
	function (currency, amount) {
		var n = A2($author$project$Domain$Total$Amount$inCurrency, currency, amount);
		return (n > 999) ? ($elm$core$String$fromInt((n / 1000) | 0) + (' ' + A3(
			$elm$core$String$padLeft,
			3,
			'0',
			$elm$core$String$fromInt(n % 1000)))) : ((n < 0) ? function (x) {
			return '- ' + x;
		}(
			$elm$core$String$fromInt(-n)) : $elm$core$String$fromInt(n));
	});
var $author$project$Domain$Total$Amount$toCAD = function (_v0) {
	var amount = _v0;
	return amount.cQ;
};
var $author$project$Domain$Total$Amount$negate = function (a) {
	return $author$project$Domain$Total$Amount$fromCAD(
		-$author$project$Domain$Total$Amount$toCAD(a));
};
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $author$project$Theme$Color$red = $rtfeldman$elm_css$Css$hex('#b42828');
var $author$project$Domain$Total$Amount$multiplyByFloat = F2(
	function (n, amount) {
		return $author$project$Domain$Total$Amount$fromCAD(
			$elm$core$Basics$ceiling(
				function (a) {
					return a * n;
				}(
					$author$project$Domain$Total$Amount$toCAD(amount))));
	});
var $author$project$Domain$Total$Amount$substract = F2(
	function (a, b) {
		return $author$project$Domain$Total$Amount$fromCAD(
			$author$project$Domain$Total$Amount$toCAD(a) - $author$project$Domain$Total$Amount$toCAD(b));
	});
var $author$project$Domain$Total$Discount$applyDiscount = F2(
	function (discount, initialAmount) {
		var _v0 = discount.fb;
		if (!_v0.$) {
			var percentage = _v0.a;
			var amount = A2($author$project$Domain$Total$Amount$multiplyByFloat, percentage / 100, initialAmount);
			return A2($author$project$Domain$Total$Amount$substract, initialAmount, amount);
		} else {
			var amount = _v0.a;
			return A2($author$project$Domain$Total$Amount$substract, initialAmount, amount);
		}
	});
var $author$project$Domain$Price$Listing$Entry$farmProductPurchasePrice = function (product) {
	switch (product) {
		case 0:
			return $author$project$Domain$Total$Amount$fromCAD(1500);
		case 1:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 2:
			return $author$project$Domain$Total$Amount$fromCAD(1060);
		case 3:
			return $author$project$Domain$Total$Amount$fromCAD(2760);
		case 4:
			return $author$project$Domain$Total$Amount$fromCAD(1380);
		default:
			return $author$project$Domain$Total$Amount$free;
	}
};
var $author$project$Domain$Total$Amount$divideByFloat = F2(
	function (n, amount) {
		return A2($author$project$Domain$Total$Amount$multiplyByFloat, 1 / n, amount);
	});
var $author$project$Domain$Price$Listing$Entry$toSubscriptionPrice = function (amount) {
	var numberOfMonthsForRepay = 38;
	return A2($author$project$Domain$Total$Amount$divideByFloat, numberOfMonthsForRepay, amount);
};
var $author$project$Domain$Price$Listing$Entry$farmProductPrice = F2(
	function (plan, product) {
		var purchasePrice = $author$project$Domain$Price$Listing$Entry$farmProductPurchasePrice(product);
		var subscriptionPrice = $author$project$Domain$Price$Listing$Entry$toSubscriptionPrice(purchasePrice);
		if (!plan) {
			return purchasePrice;
		} else {
			return subscriptionPrice;
		}
	});
var $author$project$Domain$Total$Amount$fromUSD = $author$project$Domain$Total$Amount$fromCurrency(1);
var $author$project$Domain$Price$Listing$Entry$greenhouseProductPurchasePrice = function (product) {
	switch (product) {
		case 0:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 1:
			return $author$project$Domain$Total$Amount$fromCAD(1500);
		case 2:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 3:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 4:
			return $author$project$Domain$Total$Amount$fromCAD(1120);
		case 5:
			return $author$project$Domain$Total$Amount$fromCAD(80);
		case 6:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 7:
			return $author$project$Domain$Total$Amount$fromCAD(450);
		case 8:
			return $author$project$Domain$Total$Amount$fromCAD(2010);
		case 9:
			return $author$project$Domain$Total$Amount$fromCAD(1280);
		case 10:
			return $author$project$Domain$Total$Amount$fromCAD(2010);
		case 11:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 12:
			return $author$project$Domain$Total$Amount$fromCAD(1380);
		case 13:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 14:
			return $author$project$Domain$Total$Amount$fromCAD(300);
		case 15:
			return $author$project$Domain$Total$Amount$fromCAD(910);
		case 16:
			return $author$project$Domain$Total$Amount$fromUSD(350);
		case 17:
			return $author$project$Domain$Total$Amount$fromCAD(1500);
		default:
			return $author$project$Domain$Total$Amount$fromCAD(1500);
	}
};
var $author$project$Domain$Price$Listing$Entry$greenhouseProductPrice = F2(
	function (plan, product) {
		var purchasePrice = $author$project$Domain$Price$Listing$Entry$greenhouseProductPurchasePrice(product);
		var subscriptionPrice = $author$project$Domain$Price$Listing$Entry$toSubscriptionPrice(purchasePrice);
		var _v0 = _Utils_Tuple2(plan, product);
		if (!_v0.a) {
			var _v1 = _v0.a;
			return purchasePrice;
		} else {
			if (_v0.b === 16) {
				var _v2 = _v0.a;
				var _v3 = _v0.b;
				return $author$project$Domain$Total$Amount$fromCAD(35);
			} else {
				var _v4 = _v0.a;
				return subscriptionPrice;
			}
		}
	});
var $author$project$Domain$Total$Amount$multiplyBy = F2(
	function (n, amount) {
		return $author$project$Domain$Total$Amount$fromCAD(
			function (a) {
				return a * n;
			}(
				$author$project$Domain$Total$Amount$toCAD(amount)));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct = F2(
	function (quote, product) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (g) {
					return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, g, product);
				},
				quote.aX));
	});
var $author$project$Domain$Total$Amount$add = F2(
	function (a, b) {
		return $author$project$Domain$Total$Amount$fromCAD(
			$author$project$Domain$Total$Amount$toCAD(a) + $author$project$Domain$Total$Amount$toCAD(b));
	});
var $author$project$Domain$Total$Amount$sum = function (list) {
	return A3($elm$core$List$foldl, $author$project$Domain$Total$Amount$add, $author$project$Domain$Total$Amount$free, list);
};
var $author$project$Domain$Total$Gross$fromQuote = function (quote) {
	var greenhouseProductsPrice = A2(
		$elm$core$List$map,
		function (p) {
			return A2(
				$author$project$Domain$Total$Amount$multiplyBy,
				A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, p),
				A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, p));
		},
		$author$project$Domain$Product$allGreenhouseProducts);
	var greenhouseCustomProductsPrice = A2(
		$elm$core$List$map,
		function (c) {
			return A2($author$project$Domain$Total$Amount$multiplyBy, c.ih, c.$8);
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.hn;
			},
			quote.aX));
	var farmProductsPrice = A2(
		$elm$core$List$map,
		function (p) {
			return A2(
				$author$project$Domain$Total$Amount$multiplyBy,
				A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, p),
				A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, quote.ab, p));
		},
		$author$project$Domain$Product$allFarmProducts);
	var farmCustomProductsPrice = A2(
		$elm$core$List$map,
		function (c) {
			return A2($author$project$Domain$Total$Amount$multiplyBy, c.ih, c.$8);
		},
		quote.fd.hn);
	return $author$project$Domain$Total$Amount$sum(
		_Utils_ap(
			greenhouseProductsPrice,
			_Utils_ap(
				farmProductsPrice,
				_Utils_ap(greenhouseCustomProductsPrice, farmCustomProductsPrice))));
};
var $author$project$Domain$Discount$Predefined$allPredefinedDiscounts = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6]);
var $author$project$Domain$Total$Amount$compare = F2(
	function (a, b) {
		return A2(
			$elm$core$Basics$compare,
			$author$project$Domain$Total$Amount$toCAD(a),
			$author$project$Domain$Total$Amount$toCAD(b));
	});
var $author$project$Domain$Discount$Custom$compareType = F2(
	function (a, b) {
		var _v0 = _Utils_Tuple2(a, b);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				var aa = _v0.a.a;
				var bb = _v0.b.a;
				return A2($elm$core$Basics$compare, aa, bb);
			} else {
				return 2;
			}
		} else {
			if (!_v0.b.$) {
				return 0;
			} else {
				var aa = _v0.a.a;
				var bb = _v0.b.a;
				return A2($author$project$Domain$Total$Amount$compare, aa, bb);
			}
		}
	});
var $author$project$Domain$Discount$Custom$compare = F2(
	function (a, b) {
		var typeComparison = A2($author$project$Domain$Discount$Custom$compareType, a.fb, b.fb);
		if (typeComparison === 1) {
			return A2($elm$core$Basics$compare, a.fk, b.fk);
		} else {
			return typeComparison;
		}
	});
var $author$project$Domain$Discount$Listing$isEligible = F2(
	function (quote, predefined) {
		switch (predefined) {
			case 0:
				return quote.fd.bz;
			case 1:
				return quote.fd.bG;
			case 2:
				return !$elm$core$List$isEmpty(
					A2(
						$elm$core$List$filter,
						function (x) {
							return x > 0;
						},
						A2(
							$elm$core$List$map,
							function ($) {
								return $.aS;
							},
							quote.aX)));
			case 3:
				return true;
			case 4:
				return true;
			case 5:
				return quote.hX === 1;
			default:
				var numberOfRoofs = $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function (g) {
							return g.iq.ih;
						},
						quote.aX));
				var noInverterSelected = A2(
					$elm$core$List$all,
					function (g) {
						return !g.iq.hO;
					},
					quote.aX);
				return (numberOfRoofs > 0) && noInverterSelected;
		}
	});
var $author$project$Domain$Discount$Listing$isEnabled = F2(
	function (quote, predefined) {
		return A2($elm$core$List$member, predefined, quote.fR.ie);
	});
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Domain$Product$isAGreenhouseDevice = function (product) {
	switch (product) {
		case 0:
			return false;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		case 5:
			return false;
		case 6:
			return true;
		case 7:
			return true;
		case 8:
			return true;
		case 9:
			return true;
		case 10:
			return true;
		case 11:
			return true;
		case 12:
			return true;
		case 13:
			return true;
		case 14:
			return true;
		case 15:
			return true;
		case 16:
			return true;
		case 17:
			return true;
		default:
			return true;
	}
};
var $author$project$Domain$Total$Amount$max = F2(
	function (a, b) {
		return $author$project$Domain$Total$Amount$fromCAD(
			A2(
				$elm$core$Basics$max,
				$author$project$Domain$Total$Amount$toCAD(a),
				$author$project$Domain$Total$Amount$toCAD(b)));
	});
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $author$project$Domain$Discount$Listing$basicModeDiscountValue = function (quote) {
	var twoMotorsPrice = A2(
		$author$project$Domain$Total$Amount$multiplyBy,
		2,
		A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, 16));
	var priceOfSideCurtain = A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, 9);
	var priceOfController = A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, quote.ab, 0);
	var priceOfClimateSensor = A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, 0);
	var numberOfSideCurtains = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 9);
	var numberOfGreenhouses = $elm$core$List$length(quote.aX);
	var numberOfSideCurtainsCovered = A2($elm$core$Basics$min, 2 * numberOfGreenhouses, numberOfSideCurtains);
	var numberOfSideCurtainsNotCovered = A2($elm$core$Basics$max, 0, numberOfSideCurtains - numberOfSideCurtainsCovered);
	var numberOfControllers = A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 0);
	var numberOfClimateSensors = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 0);
	var numberOfClimateSensorsCovered = A2($elm$core$Basics$min, numberOfGreenhouses, numberOfClimateSensors);
	var halfPrice = function (product) {
		return A2(
			$author$project$Domain$Total$Amount$divideByFloat,
			2,
			A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, product));
	};
	var fromOtherSideCurtains = A2(
		$author$project$Domain$Total$Amount$multiplyBy,
		numberOfSideCurtainsNotCovered,
		halfPrice(9));
	var fromOtherDevices = $author$project$Domain$Total$Amount$sum(
		A2(
			$elm$core$List$map,
			function (p) {
				return A2(
					$author$project$Domain$Total$Amount$multiplyBy,
					A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, p),
					halfPrice(p));
			},
			A2(
				$elm$core$List$filter,
				function (p) {
					return p !== 16;
				},
				A2(
					$elm$core$List$filter,
					function (p) {
						return p !== 9;
					},
					A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseDevice, $author$project$Domain$Product$allGreenhouseProducts)))));
	var fromController = A2($author$project$Domain$Total$Amount$multiplyBy, numberOfControllers, priceOfController);
	var fromClimateSensor = A2($author$project$Domain$Total$Amount$multiplyBy, numberOfClimateSensorsCovered, priceOfClimateSensor);
	var fromBaseSideCurtains = A2($author$project$Domain$Total$Amount$multiplyBy, numberOfSideCurtainsCovered, priceOfSideCurtain);
	var maxValue = $author$project$Domain$Total$Amount$sum(
		_List_fromArray(
			[fromController, fromClimateSensor, fromBaseSideCurtains, fromOtherSideCurtains]));
	var basePricePerGreenhouse = function () {
		var _v0 = _Utils_Tuple2(quote.hg.hl, quote.ab);
		if (!_v0.a) {
			if (!_v0.b) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return $author$project$Domain$Total$Amount$fromCAD(3700);
			} else {
				var _v3 = _v0.a;
				var _v4 = _v0.b;
				return $author$project$Domain$Total$Amount$fromCAD(168);
			}
		} else {
			if (!_v0.b) {
				var _v5 = _v0.a;
				var _v6 = _v0.b;
				return $author$project$Domain$Total$Amount$fromUSD(2600);
			} else {
				var _v7 = _v0.a;
				var _v8 = _v0.b;
				return $author$project$Domain$Total$Amount$fromUSD(125);
			}
		}
	}();
	var basePrice = A2(
		$author$project$Domain$Total$Amount$multiplyBy,
		numberOfGreenhouses,
		A2($author$project$Domain$Total$Amount$substract, basePricePerGreenhouse, twoMotorsPrice));
	return A2(
		$author$project$Domain$Total$Amount$add,
		fromOtherDevices,
		A2(
			$author$project$Domain$Total$Amount$max,
			$author$project$Domain$Total$Amount$free,
			A2($author$project$Domain$Total$Amount$substract, maxValue, basePrice)));
};
var $author$project$Domain$Discount$Listing$discountFromPurchase = F2(
	function (plan, purchaseDiscount) {
		if (!plan) {
			return purchaseDiscount;
		} else {
			return $author$project$Domain$Price$Listing$Entry$toSubscriptionPrice(purchaseDiscount);
		}
	});
var $author$project$Domain$Discount$Listing$en = {bW: 'Basic mode', aS: 'Dry wet sensor discount', cp: '% - EFAO member', di: '% - Student of the Market Gardener Institute', d8: ' Roof inverter(s) already provided', bz: '% - Solar sensor discount', bG: 'Wind sensor discount'};
var $author$project$Domain$Discount$Listing$fr = {bW: 'Mode de base', aS: 'Rabais capteur bulbe sec bulbe humide', cp: '% - EFAO member', di: '% - Étudiant de l\'Institut du jardinier-maraîcher', d8: ' Inverseur(s) de toit déjà fournis', bz: '% - Rabais capteur solaire', bG: 'Rabais capteur de vent'};
var $author$project$Domain$Discount$Listing$toCustomDiscount = F3(
	function (language, quote, predefined) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Domain$Discount$Listing$en, hE: $author$project$Domain$Discount$Listing$fr});
		switch (predefined) {
			case 0:
				var percentage = 40;
				return {
					fb: $author$project$Domain$Discount$Custom$Amount(
						A2(
							$author$project$Domain$Total$Amount$multiplyByFloat,
							percentage / 100,
							A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, quote.ab, 3))),
					fk: _Utils_ap(
						$elm$core$String$fromInt(percentage),
						translation.bz),
					id: $elm$core$Maybe$Nothing
				};
			case 1:
				return {
					fb: $author$project$Domain$Discount$Custom$Amount(
						A2(
							$author$project$Domain$Discount$Listing$discountFromPurchase,
							quote.ab,
							$author$project$Domain$Total$Amount$fromCAD(460))),
					fk: translation.bG,
					id: $elm$core$Maybe$Nothing
				};
			case 2:
				var unitDiscount = A2(
					$author$project$Domain$Discount$Listing$discountFromPurchase,
					quote.ab,
					$author$project$Domain$Total$Amount$fromCAD(350));
				var numberOfDryWet = $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function ($) {
							return $.aS;
						},
						quote.aX));
				var totalDiscount = A2($author$project$Domain$Total$Amount$multiplyBy, numberOfDryWet, unitDiscount);
				return {
					fb: $author$project$Domain$Discount$Custom$Amount(totalDiscount),
					fk: translation.aS,
					id: $elm$core$Maybe$Nothing
				};
			case 3:
				var percentage = 10;
				return {
					fb: $author$project$Domain$Discount$Custom$Percentage(percentage),
					fk: _Utils_ap(
						$elm$core$String$fromInt(percentage),
						translation.di),
					id: $elm$core$Maybe$Nothing
				};
			case 4:
				var percentage = 10;
				return {
					fb: $author$project$Domain$Discount$Custom$Percentage(percentage),
					fk: _Utils_ap(
						$elm$core$String$fromInt(percentage),
						translation.cp),
					id: $elm$core$Maybe$Nothing
				};
			case 5:
				return {
					fb: $author$project$Domain$Discount$Custom$Amount(
						$author$project$Domain$Discount$Listing$basicModeDiscountValue(quote)),
					fk: translation.bW,
					id: $elm$core$Maybe$Nothing
				};
			default:
				var unitDiscount = A2(
					$author$project$Domain$Discount$Listing$discountFromPurchase,
					quote.ab,
					$author$project$Domain$Total$Amount$fromCAD(400));
				var numberOfRoofs = $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.iq;
							},
							function ($) {
								return $.ih;
							}),
						quote.aX));
				var totalDiscount = A2($author$project$Domain$Total$Amount$multiplyBy, numberOfRoofs, unitDiscount);
				return {
					fb: $author$project$Domain$Discount$Custom$Amount(totalDiscount),
					fk: _Utils_ap(
						$elm$core$String$fromInt(numberOfRoofs),
						translation.d8),
					id: $elm$core$Maybe$Nothing
				};
		}
	});
var $author$project$Domain$Discount$Listing$toDiscountList = F2(
	function (language, quote) {
		var predefined = A2(
			$elm$core$List$map,
			A2($author$project$Domain$Discount$Listing$toCustomDiscount, language, quote),
			A2(
				$elm$core$List$filter,
				$author$project$Domain$Discount$Listing$isEligible(quote),
				A2(
					$elm$core$List$filter,
					$author$project$Domain$Discount$Listing$isEnabled(quote),
					$author$project$Domain$Discount$Predefined$allPredefinedDiscounts)));
		var custom = quote.fR.hm;
		return A2(
			$elm$core$List$sortWith,
			$author$project$Domain$Discount$Custom$compare,
			_Utils_ap(predefined, custom));
	});
var $author$project$Domain$Total$Discount$fromQuote = function (quote) {
	var grossTotal = $author$project$Domain$Total$Gross$fromQuote(quote);
	var dummy = 1;
	var discountList = A2($author$project$Domain$Discount$Listing$toDiscountList, dummy, quote);
	return A3($elm$core$List$foldl, $author$project$Domain$Total$Discount$applyDiscount, grossTotal, discountList);
};
var $author$project$Domain$Total$Discount$totalDiscount = function (quote) {
	var total = $author$project$Domain$Total$Discount$fromQuote(quote);
	var grossTotal = $author$project$Domain$Total$Gross$fromQuote(quote);
	return A2($author$project$Domain$Total$Amount$substract, grossTotal, total);
};
var $author$project$Page$QuoteBuilder$Total$discountTotal = F2(
	function (translation, quote) {
		var amount = $author$project$Domain$Total$Discount$totalDiscount(quote);
		return _Utils_eq(amount, $author$project$Domain$Total$Amount$free) ? $author$project$S$empty : A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.I)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(6)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right),
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2(
								$author$project$Formatter$Number$formatAmount,
								quote.hg.hl,
								$author$project$Domain$Total$Amount$negate(amount)))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Total$en = {I: 'Discount', cq: 'Actuator costs (CAD)', dF: 'Pieces of equipments', es: 'System price'};
var $author$project$Page$QuoteBuilder$Total$equipment = F2(
	function (translation, total) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(300))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dF)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(total))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Total$fr = {I: 'Rabais', cq: 'Coût des actuateurs (CAD)', dF: 'Nombre d\'équipements', es: 'Prix du système'};
var $author$project$Domain$Total$Amount$addMany = F3(
	function (numberOfEquipment, unitPrice, amount) {
		return A2(
			$author$project$Domain$Total$Amount$add,
			amount,
			A2($author$project$Domain$Total$Amount$multiplyBy, numberOfEquipment, unitPrice));
	});
var $author$project$Domain$Price$Listing$Equipment$cost = {
	g$: $author$project$Domain$Total$Amount$fromCAD(254),
	hY: $author$project$Domain$Total$Amount$fromCAD(438),
	gj: $author$project$Domain$Total$Amount$fromCAD(174),
	it: $author$project$Domain$Total$Amount$fromCAD(133),
	eH: $author$project$Domain$Total$Amount$fromCAD(25),
	eI: $author$project$Domain$Total$Amount$fromCAD(25)
};
var $author$project$Domain$Common$numberOfRelaysPerGreenhouseProduct = function (product) {
	switch (product) {
		case 0:
			return 0;
		case 1:
			return 0;
		case 2:
			return 0;
		case 3:
			return 0;
		case 4:
			return 0;
		case 5:
			return 0;
		case 6:
			return 1;
		case 7:
			return 1;
		case 8:
			return 2;
		case 9:
			return 0;
		case 10:
			return 2;
		case 11:
			return 1;
		case 12:
			return 1;
		case 13:
			return 1;
		case 14:
			return 1;
		case 15:
			return 1;
		case 16:
			return 0;
		case 17:
			return 2;
		default:
			return 2;
	}
};
var $author$project$Domain$Common$numberOfRelaysInGreenhouse = function (greenhouse) {
	var numberOfRelaysInGreenhouseProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function (p) {
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, greenhouse, p) * $author$project$Domain$Common$numberOfRelaysPerGreenhouseProduct(p);
			},
			$author$project$Domain$Product$allGreenhouseProducts));
	var numberOfRelaysInCustomProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function (cp) {
				return cp.ih * cp.il;
			},
			greenhouse.hn));
	return numberOfRelaysInGreenhouseProducts + numberOfRelaysInCustomProducts;
};
var $author$project$Domain$Common$numberOfActivationUnits = F2(
	function (actuator, greenhouse) {
		var _default = function () {
			if (!actuator) {
				return 0;
			} else {
				var numberOfRelaysPerUnit = 4;
				return $elm$core$Basics$ceiling(
					function (n) {
						return n / numberOfRelaysPerUnit;
					}(
						A2(
							$elm$core$Basics$max,
							0,
							$author$project$Domain$Common$numberOfRelaysInGreenhouse(greenhouse))));
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.g$;
				},
				greenhouse.ae));
	});
var $author$project$Domain$Common$totalOfActivationUnits = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Common$numberOfActivationUnits(quote.ae),
			quote.aX));
};
var $author$project$Domain$Common$numberMotorActuatorInGreenhouse = function (greenhouse) {
	return (greenhouse.gH > 0) ? $elm$core$Basics$ceiling(
		function (n) {
			return n / 2;
		}(greenhouse.gH)) : 0;
};
var $author$project$Domain$Common$numberOfMultiWithMotorDrivers = F2(
	function (actuator, greenhouse) {
		var _default = function () {
			if (!actuator) {
				return $author$project$Domain$Common$numberMotorActuatorInGreenhouse(greenhouse);
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hY;
				},
				greenhouse.ae));
	});
var $author$project$Domain$Common$totalOfMultiWithMotorDrivers = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Common$numberOfMultiWithMotorDrivers(quote.ae),
			quote.aX));
};
var $author$project$Domain$Common$numberOfMultiboxes = F2(
	function (actuator, greenhouse) {
		var _default = function () {
			var numberOfRelaysPerMultibox = 10;
			var multiWithMotorDriverRelays = A2($author$project$Domain$Common$numberOfMultiWithMotorDrivers, actuator, greenhouse) * 10;
			if (!actuator) {
				return $elm$core$Basics$ceiling(
					function (n) {
						return n / numberOfRelaysPerMultibox;
					}(
						A2(
							$elm$core$Basics$max,
							0,
							$author$project$Domain$Common$numberOfRelaysInGreenhouse(greenhouse) - multiWithMotorDriverRelays)));
			} else {
				return 0;
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.hZ;
				},
				greenhouse.ae));
	});
var $author$project$Domain$Common$totalOfMultiboxes = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Common$numberOfMultiboxes(quote.ae),
			quote.aX));
};
var $author$project$Domain$Common$numberOfSideCurtainUnits = F2(
	function (actuator, greenhouse) {
		var _default = function () {
			if (!actuator) {
				return 0;
			} else {
				return $author$project$Domain$Common$numberMotorActuatorInGreenhouse(greenhouse);
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			_default,
			A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.it;
				},
				greenhouse.ae));
	});
var $author$project$Domain$Common$totalOfSideCurtainUnits = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Common$numberOfSideCurtainUnits(quote.ae),
			quote.aX));
};
var $author$project$Domain$Common$numberOfTransformer24V = function (greenhouse) {
	return greenhouse.iq.hO ? 0 : A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, greenhouse, 8);
};
var $author$project$Domain$Common$totalOfTransformer24V = function (quote) {
	return $elm$core$List$sum(
		A2($elm$core$List$map, $author$project$Domain$Common$numberOfTransformer24V, quote.aX));
};
var $author$project$Domain$Common$numberOfTransformer24VWithWago = F2(
	function (actuator, greenhouse) {
		var numberOfValves = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, greenhouse, 14);
		var numberOfMisters = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, greenhouse, 15);
		var numberOfValvesAndMisters = numberOfValves + numberOfMisters;
		if (!actuator) {
			return $elm$core$Basics$ceiling(
				function (n) {
					return n / 4;
				}(numberOfValvesAndMisters));
		} else {
			return 0;
		}
	});
var $author$project$Domain$Common$totalOfTransformer24VWithWago = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Common$numberOfTransformer24VWithWago(quote.ae),
			quote.aX));
};
var $author$project$Domain$Total$Equipment$fromQuote = function (quote) {
	var transformer24VWithWago = $author$project$Domain$Common$totalOfTransformer24VWithWago(quote);
	var transformer24V = $author$project$Domain$Common$totalOfTransformer24V(quote);
	var sideCurtainUnits = $author$project$Domain$Common$totalOfSideCurtainUnits(quote);
	var multiboxes = $author$project$Domain$Common$totalOfMultiboxes(quote);
	var multiWithMotorDrivers = $author$project$Domain$Common$totalOfMultiWithMotorDrivers(quote);
	var activationUnits = $author$project$Domain$Common$totalOfActivationUnits(quote);
	return A3(
		$author$project$Domain$Total$Amount$addMany,
		transformer24VWithWago,
		$author$project$Domain$Price$Listing$Equipment$cost.eI,
		A3(
			$author$project$Domain$Total$Amount$addMany,
			transformer24V,
			$author$project$Domain$Price$Listing$Equipment$cost.eH,
			A3(
				$author$project$Domain$Total$Amount$addMany,
				activationUnits,
				$author$project$Domain$Price$Listing$Equipment$cost.g$,
				A3(
					$author$project$Domain$Total$Amount$addMany,
					sideCurtainUnits,
					$author$project$Domain$Price$Listing$Equipment$cost.it,
					A3(
						$author$project$Domain$Total$Amount$addMany,
						multiWithMotorDrivers,
						$author$project$Domain$Price$Listing$Equipment$cost.hY,
						A3($author$project$Domain$Total$Amount$addMany, multiboxes, $author$project$Domain$Price$Listing$Equipment$cost.gj, $author$project$Domain$Total$Amount$free))))));
};
var $author$project$Domain$Quote$Equipment$numberOfEquipments = function (quote) {
	var greenhouseProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct(quote),
			$author$project$Domain$Product$allGreenhouseProducts));
	var farmProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Domain$Quote$Equipment$numberOfFarmProduct(quote.fd),
			$author$project$Domain$Product$allFarmProducts));
	var customGreenhouseProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.ih;
			},
			A2(
				$elm$core$List$concatMap,
				function ($) {
					return $.hn;
				},
				quote.aX)));
	var customFarmProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			function ($) {
				return $.ih;
			},
			quote.fd.hn));
	return ((greenhouseProducts + farmProducts) + customGreenhouseProducts) + customFarmProducts;
};
var $author$project$Utils$Style$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2($elm$core$String$join, ' ', classes));
};
var $author$project$Style$Bulma$isStriped = 'is-striped';
var $rtfeldman$elm_css$Html$Styled$table = $rtfeldman$elm_css$Html$Styled$node('table');
var $author$project$Style$Bulma$table = 'table';
var $author$project$Style$Table$table = F2(
	function (attrs, children) {
		var styles = _List_fromArray(
			[
				$author$project$Utils$Style$classList(
				_List_fromArray(
					[$author$project$Style$Bulma$table, $author$project$Style$Bulma$isStriped, 'is-fullwidth'])),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$px(0)))
					]))
			]);
		return A2(
			$rtfeldman$elm_css$Html$Styled$table,
			_Utils_ap(styles, attrs),
			children);
	});
var $author$project$S$table = $author$project$Style$Table$table;
var $author$project$Page$QuoteBuilder$Total$totalView = F3(
	function (currency, str, total) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(str)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Formatter$Number$formatAmount, currency, total))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Total$view = F4(
	function (language, user, isActuatorPriceShown, quote) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Total$en, hE: $author$project$Page$QuoteBuilder$Total$fr});
		var totalWithoutDiscount = $author$project$Domain$Total$Gross$fromQuote(quote);
		var numberOfEquipments = $author$project$Domain$Quote$Equipment$numberOfEquipments(quote);
		var equipmentPrice = A2(
			$author$project$Domain$Total$Amount$inCurrency,
			0,
			$author$project$Domain$Total$Equipment$fromQuote(quote));
		return A2(
			$author$project$S$table,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Page$QuoteBuilder$Total$equipment, translation, numberOfEquipments),
					A3($author$project$Page$QuoteBuilder$Total$totalView, quote.hg.hl, translation.es, totalWithoutDiscount),
					A2($author$project$Page$QuoteBuilder$Total$discountTotal, translation, quote),
					A3(
					$author$project$Page$QuoteBuilder$Total$totalView,
					quote.hg.hl,
					'Total',
					$author$project$Domain$Total$Discount$fromQuote(quote)),
					(isActuatorPriceShown && $author$project$Domain$User$isOrisha(user)) ? A2($author$project$Page$QuoteBuilder$Total$actuatorPrice, translation, equipmentPrice) : $author$project$S$empty
				]));
	});
var $author$project$View$total = F4(
	function (language, user, isActuatorPriceShown, quote) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$box),
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
							$rtfeldman$elm_css$Css$top(
							$rtfeldman$elm_css$Css$vh(2.5)),
							$rtfeldman$elm_css$Css$right(
							$rtfeldman$elm_css$Css$px(0)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$vw(24)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
								]))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Update$ExtendTotal)
				]),
			_List_fromArray(
				[
					A4($author$project$Page$QuoteBuilder$Total$view, language, user, isActuatorPriceShown, quote)
				]));
	});
var $author$project$Update$Logout = {$: 12};
var $rtfeldman$elm_css$Css$block = {o: 0, gU: 'block'};
var $author$project$Menu$en = {cG: 'Form', cH: 'Form V3', dC: 'Packing Slip', dD: 'Packing Slip V3', dW: 'Quote', en: 'Sign Out'};
var $rtfeldman$elm_css$Css$flexStart = $rtfeldman$elm_css$Css$prop1('flex-start');
var $author$project$Menu$fr = {cG: 'Formulaire', cH: 'Formulaire V3', dC: 'Bon de livraison', dD: 'Bon de livraison V3', dW: 'Devis', en: 'Se déconnecter'};
var $author$project$Update$ResetUrl = {$: 14};
var $rtfeldman$elm_css$Html$Styled$a = $rtfeldman$elm_css$Html$Styled$node('a');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Html$Styled$img = $rtfeldman$elm_css$Html$Styled$node('img');
var $author$project$Theme$ImgPath$logo = $author$project$Theme$ImgPath$base + 'Orisha_logo.svg';
var $rtfeldman$elm_css$Html$Styled$Attributes$src = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'src', url);
};
var $author$project$Theme$Color$white = $rtfeldman$elm_css$Css$hex('#eeeeee');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ',', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			bg: alpha,
			ha: b,
			ag: 0,
			hF: g,
			ij: r,
			gU: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $author$project$Theme$Color$withOpacity = F2(
	function (opacity, color) {
		return A4($rtfeldman$elm_css$Css$rgba, color.ij, color.hF, color.ha, opacity);
	});
var $author$project$Menu$layout = function (content) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
						$rtfeldman$elm_css$Css$top(
						$rtfeldman$elm_css$Css$vh(2.5)),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
						$rtfeldman$elm_css$Css$backgroundColor(
						A2($author$project$Theme$Color$withOpacity, 0.3, $author$project$Theme$Color$white)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$vw(24)),
						$rtfeldman$elm_css$Css$maxWidth(
						$rtfeldman$elm_css$Css$px(400)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$vh(95)),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$rem(3)),
						$author$project$S$onPrint(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
							]))
					]))
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$px(200)),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(2))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Update$ResetUrl)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$img,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo)
									]),
								_List_Nil)
							]))
					])),
			content));
};
var $author$project$Update$GoToPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Update$PackingSlip = 3;
var $author$project$Update$PackingSlipV3 = 4;
var $author$project$Update$QuotePreview = 2;
var $author$project$Theme$Color$darkGrey = $rtfeldman$elm_css$Css$hex('#363636');
var $rtfeldman$elm_css$Css$flex = $rtfeldman$elm_css$Css$prop1('flex');
var $rtfeldman$elm_css$Html$Styled$hr = $rtfeldman$elm_css$Html$Styled$node('hr');
var $rtfeldman$elm_css$Html$Styled$nav = $rtfeldman$elm_css$Html$Styled$node('nav');
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $author$project$Style$Grid$rowGap = function (length) {
	return A2($rtfeldman$elm_css$Css$property, 'row-gap', length.gU);
};
var $author$project$S$showIf = F2(
	function (predicate, content) {
		return predicate ? content : $author$project$S$empty;
	});
var $rtfeldman$elm_css$Css$spaceAround = $rtfeldman$elm_css$Css$prop1('space-around');
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $author$project$Utils$Style$noStyle = $rtfeldman$elm_css$Css$batch(_List_Nil);
var $author$project$Utils$Style$styleIf = F2(
	function (condition, style) {
		return condition ? $rtfeldman$elm_css$Css$batch(style) : $author$project$Utils$Style$noStyle;
	});
var $author$project$Menu$links = F3(
	function (user, translation, currentPage) {
		var separator = A2(
			$rtfeldman$elm_css$Html$Styled$hr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(100))
						]))
				]),
			_List_Nil);
		var link = F2(
			function (page, label) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									A2(
									$author$project$Utils$Style$styleIf,
									_Utils_eq(currentPage, page),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($author$project$Theme$Color$darkGrey)
										]))
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Update$GoToPage(page))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label)
						]));
			});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$nav,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexStart),
									$author$project$Style$Grid$rowGap(
									$rtfeldman$elm_css$Css$rem(1.25)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1.2))
								]))
						]),
					_List_fromArray(
						[
							A2(link, 0, translation.cG),
							A2(
							$author$project$S$showIf,
							$author$project$Domain$User$isOrisha(user),
							A2(link, 1, translation.cH)),
							separator,
							A2(link, 2, translation.dW),
							A2(
							$author$project$S$showIf,
							$author$project$Domain$User$isOrisha(user),
							A2(link, 3, translation.dC)),
							A2(
							$author$project$S$showIf,
							$author$project$Domain$User$isOrisha(user),
							A2(link, 4, translation.dD))
						]))
				]));
	});
var $author$project$Menu$view = F3(
	function (language, user, currentPage) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Menu$en, hE: $author$project$Menu$fr});
		return $author$project$Menu$layout(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexStart),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$author$project$S$cleanButton,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(-0.1)),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.7))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Update$Logout)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$color($author$project$Theme$Color$linkBlue)
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.en)
										]))
								]))
						])),
					A3($author$project$Menu$links, user, translation, currentPage)
				]));
	});
var $author$project$Page$PackingSlip$CopyToClipboard = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$alignSelf = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignSelf',
		'align-self',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $author$project$Domain$Hardware$ActivationUnit = 2;
var $author$project$Domain$Hardware$BackUpThermostat = 21;
var $author$project$Domain$Hardware$ClimateSensor = 17;
var $author$project$Domain$Hardware$Co2Sensor = 19;
var $author$project$Domain$Hardware$Controller = 0;
var $author$project$Domain$Hardware$DryWetSensor = 20;
var $author$project$Domain$Hardware$ExtensionKitForMotor24VDC = 8;
var $author$project$Domain$Hardware$FanAndLouversBox = 12;
var $author$project$Domain$Hardware$Inverter24VDC = 14;
var $author$project$Domain$Hardware$IrrometerTensiometersEmitter = 16;
var $author$project$Domain$Hardware$Motor24VDC = 7;
var $author$project$Domain$Hardware$MultiBoxR = 3;
var $author$project$Domain$Hardware$MultiBoxRMVT2 = 4;
var $author$project$Domain$Hardware$PowerBarKit = 9;
var $author$project$Domain$Hardware$RW2401Phase5WiresRoofMotorInverter = 13;
var $author$project$Domain$Hardware$RainSensor = 23;
var $author$project$Domain$Hardware$RelayBox110VAC = 11;
var $author$project$Domain$Hardware$SideCurtainsUnit = 1;
var $author$project$Domain$Hardware$SoilTemperatureSensor = 18;
var $author$project$Domain$Hardware$SolarSensor = 24;
var $author$project$Domain$Hardware$Tensiometer12 = 15;
var $author$project$Domain$Hardware$Transformer24V = 5;
var $author$project$Domain$Hardware$Transformer24VWithWago = 6;
var $author$project$Domain$Hardware$WeatherBox = 10;
var $author$project$Domain$Hardware$WindSensor = 22;
var $author$project$Domain$Hardware$allHardwares = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]);
var $author$project$Domain$Common$numberOfActuators = F2(
	function (actuator, greenhouse) {
		var sideCurtainUnits = A2($author$project$Domain$Common$numberOfSideCurtainUnits, actuator, greenhouse);
		var multiboxes = A2($author$project$Domain$Common$numberOfMultiboxes, actuator, greenhouse);
		var multiWithMotorDrivers = A2($author$project$Domain$Common$numberOfMultiWithMotorDrivers, actuator, greenhouse);
		var activationUnits = A2($author$project$Domain$Common$numberOfActivationUnits, actuator, greenhouse);
		return ((multiWithMotorDrivers + multiboxes) + activationUnits) + sideCurtainUnits;
	});
var $author$project$Domain$Hardware$count = F2(
	function (quote, hardware) {
		switch (hardware) {
			case 0:
				return A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 0);
			case 1:
				return $author$project$Domain$Common$totalOfSideCurtainUnits(quote);
			case 2:
				return $author$project$Domain$Common$totalOfActivationUnits(quote);
			case 3:
				return $author$project$Domain$Common$totalOfMultiboxes(quote);
			case 4:
				return $author$project$Domain$Common$totalOfMultiWithMotorDrivers(quote);
			case 5:
				return $author$project$Domain$Common$totalOfTransformer24V(quote);
			case 6:
				return $author$project$Domain$Common$totalOfTransformer24VWithWago(quote);
			case 7:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 16);
			case 8:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 16);
			case 9:
				return $elm$core$List$length(
					A2(
						$elm$core$List$filter,
						function (g) {
							return A2($author$project$Domain$Common$numberOfActuators, quote.ae, g) > 0;
						},
						quote.aX));
			case 10:
				return A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 5);
			case 11:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 11) + A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 13);
			case 12:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 12);
			case 13:
				return $elm$core$List$sum(
					A2(
						$elm$core$List$map,
						function (g) {
							return g.iq.hO ? g.iq.ih : 0;
						},
						quote.aX));
			case 14:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 10);
			case 15:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 4);
			case 16:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 4);
			case 17:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 0) + A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 1);
			case 18:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 2);
			case 19:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 3);
			case 20:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 1);
			case 21:
				return A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 5);
			case 22:
				return A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 4);
			case 23:
				return A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 2);
			default:
				return A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 3);
		}
	});
var $author$project$Domain$Hardware$sku = function (hardware) {
	switch (hardware) {
		case 0:
			return 1005;
		case 1:
			return 1008;
		case 2:
			return 1028;
		case 3:
			return 1012;
		case 4:
			return 1015;
		case 5:
			return 1317;
		case 6:
			return 1436;
		case 7:
			return 1433;
		case 8:
			return 1422;
		case 9:
			return 1045;
		case 10:
			return 1006;
		case 11:
			return 1034;
		case 12:
			return 1022;
		case 13:
			return 1019;
		case 14:
			return 1413;
		case 15:
			return 1287;
		case 16:
			return 1003;
		case 17:
			return 1391;
		case 18:
			return 1029;
		case 19:
			return 1021;
		case 20:
			return 1388;
		case 21:
			return 1088;
		case 22:
			return 1038;
		case 23:
			return 1027;
		default:
			return 1023;
	}
};
var $author$project$Page$PackingSlip$autoPopulateSnippet = function (quote) {
	var part = function (hardware) {
		var count = A2($author$project$Domain$Hardware$count, quote, hardware);
		return (count > 0) ? $elm$core$Maybe$Just(
			$elm$core$String$fromInt(
				$author$project$Domain$Hardware$sku(hardware)) + (' ' + $elm$core$String$fromInt(count))) : $elm$core$Maybe$Nothing;
	};
	return A2(
		$elm$core$String$join,
		' ',
		$elm_community$maybe_extra$Maybe$Extra$values(
			A2($elm$core$List$map, part, $author$project$Domain$Hardware$allHardwares)));
};
var $author$project$Domain$Quote$AutomationDevice$CirculationFan = 0;
var $author$project$Domain$Quote$AutomationDevice$Fan = 1;
var $author$project$Domain$Quote$AutomationDevice$HeatPipe = 3;
var $author$project$Domain$Quote$AutomationDevice$Heater = 2;
var $author$project$Domain$Quote$AutomationDevice$Mister = 4;
var $author$project$Domain$Quote$AutomationDevice$Opening = 5;
var $author$project$Domain$Quote$AutomationDevice$Tensiometer = 6;
var $author$project$Domain$Quote$AutomationDevice$ThermalScreen = 7;
var $author$project$Domain$Quote$AutomationDevice$Valve = 8;
var $author$project$Domain$Quote$AutomationDevice$allDevices = _List_fromArray(
	[0, 3, 2, 4, 5, 6, 7, 8, 1]);
var $author$project$Page$PackingSlip$customProducts = function (quote) {
	var greenhouseCustomProducts = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.hn;
		},
		quote.aX);
	var farmCustomProducts = quote.fd.hn;
	return A2($elm$core$List$append, greenhouseCustomProducts, farmCustomProducts);
};
var $author$project$Theme$Icon$deviceIcon = function (path) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$em(1.7)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$em(1.7))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src(path)
					]),
				_List_Nil)
			]));
};
var $author$project$Theme$Icon$circulationFan = $author$project$Theme$Icon$deviceIcon('icons/haf.png');
var $author$project$Theme$Icon$fan = $author$project$Theme$Icon$deviceIcon('icons/fan.svg');
var $author$project$Theme$Icon$heatPipe = $author$project$Theme$Icon$deviceIcon('icons/heat-pipe.svg');
var $author$project$Theme$Icon$heater = $author$project$Theme$Icon$deviceIcon('icons/heater.svg');
var $author$project$Theme$Icon$mister = $author$project$Theme$Icon$deviceIcon('icons/mister.svg');
var $author$project$Theme$Icon$roof = $author$project$Theme$Icon$deviceIcon('icons/roof.svg');
var $author$project$Theme$Icon$tensiometer = $author$project$Theme$Icon$deviceIcon('icons/tensiometer.svg');
var $author$project$Theme$Icon$thermalScreen = $author$project$Theme$Icon$deviceIcon('icons/thermal-screen.svg');
var $author$project$Theme$Icon$valve = $author$project$Theme$Icon$deviceIcon('icons/valve.png');
var $author$project$Domain$Quote$AutomationDevice$deviceIcon = function (device) {
	switch (device) {
		case 0:
			return $author$project$Theme$Icon$circulationFan;
		case 1:
			return $author$project$Theme$Icon$fan;
		case 2:
			return $author$project$Theme$Icon$heater;
		case 3:
			return $author$project$Theme$Icon$heatPipe;
		case 4:
			return $author$project$Theme$Icon$mister;
		case 5:
			return $author$project$Theme$Icon$roof;
		case 6:
			return $author$project$Theme$Icon$tensiometer;
		case 7:
			return $author$project$Theme$Icon$thermalScreen;
		default:
			return $author$project$Theme$Icon$valve;
	}
};
var $author$project$Domain$Quote$AutomationDevice$en = {b$: 'Circulation fan (HAF)', cA: 'Ventilation fan', cN: 'Heat pipe', cO: 'Heater', dk: 'Mister', iq: 'Roof', ey: 'Tensiometer', eC: 'Thermal screen', eR: 'Valve'};
var $author$project$Domain$Quote$AutomationDevice$fr = {b$: 'HAF', cA: 'Pression +/-', cN: 'Tuyau chauffant', cO: 'Fournaise', dk: 'Brumisateur', iq: 'Ouvrant', ey: 'Tensiomètre', eC: 'Toile thermique', eR: 'Valve'};
var $author$project$Domain$Quote$AutomationDevice$deviceName = F2(
	function (language, device) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Domain$Quote$AutomationDevice$en, hE: $author$project$Domain$Quote$AutomationDevice$fr});
		switch (device) {
			case 0:
				return translation.b$;
			case 1:
				return translation.cA;
			case 2:
				return translation.cO;
			case 3:
				return translation.cN;
			case 4:
				return translation.dk;
			case 5:
				return translation.iq;
			case 6:
				return translation.ey;
			case 7:
				return translation.eC;
			default:
				return translation.eR;
		}
	});
var $author$project$Domain$Quote$AutomationDevice$fromGreenhouseProduct = function (product) {
	switch (product) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Just(6);
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just(2);
		case 7:
			return $elm$core$Maybe$Just(2);
		case 8:
			return $elm$core$Maybe$Just(5);
		case 9:
			return $elm$core$Maybe$Just(5);
		case 10:
			return $elm$core$Maybe$Just(5);
		case 11:
			return $elm$core$Maybe$Just(1);
		case 12:
			return $elm$core$Maybe$Just(1);
		case 13:
			return $elm$core$Maybe$Just(0);
		case 14:
			return $elm$core$Maybe$Just(8);
		case 15:
			return $elm$core$Maybe$Just(4);
		case 16:
			return $elm$core$Maybe$Nothing;
		case 17:
			return $elm$core$Maybe$Just(7);
		default:
			return $elm$core$Maybe$Just(3);
	}
};
var $author$project$Page$PackingSlip$filteredGreenhouseProducts = function (device) {
	return A2(
		$elm$core$List$filter,
		function (greenhouseProduct) {
			return _Utils_eq(
				$author$project$Domain$Quote$AutomationDevice$fromGreenhouseProduct(greenhouseProduct),
				$elm$core$Maybe$Just(device));
		},
		$author$project$Domain$Product$allGreenhouseProducts);
};
var $author$project$Domain$Quote$AutomationDevice$fromCustomProduct = function (customProductDevice) {
	switch (customProductDevice) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(6);
		case 2:
			return $elm$core$Maybe$Just(2);
		case 3:
			return $elm$core$Maybe$Just(5);
		case 4:
			return $elm$core$Maybe$Just(1);
		case 5:
			return $elm$core$Maybe$Just(0);
		case 6:
			return $elm$core$Maybe$Just(8);
		case 7:
			return $elm$core$Maybe$Just(4);
		case 8:
			return $elm$core$Maybe$Just(7);
		default:
			return $elm$core$Maybe$Just(3);
	}
};
var $author$project$Page$PackingSlip$deviceEntry = F4(
	function (language, quote, device, isCustomerVersion) {
		var name = isCustomerVersion ? A2($author$project$Domain$Quote$AutomationDevice$deviceName, language, device) : A2($author$project$Domain$Quote$AutomationDevice$deviceName, 0, device);
		var icon = $author$project$Domain$Quote$AutomationDevice$deviceIcon(device);
		var greenhouseProductQuantity = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct(quote),
				$author$project$Page$PackingSlip$filteredGreenhouseProducts(device)));
		var customProductQuantity = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.ih;
				},
				A2(
					$elm$core$List$filter,
					function (customProduct) {
						return _Utils_eq(
							$author$project$Domain$Quote$AutomationDevice$fromCustomProduct(customProduct.hr),
							$elm$core$Maybe$Just(device));
					},
					$author$project$Page$PackingSlip$customProducts(quote))));
		var quantity = greenhouseProductQuantity + customProductQuantity;
		var centered = function (str) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(str)
					]));
		};
		return (quantity > 0) ? A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[icon])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					centered(
					$elm$core$String$fromInt(quantity))
				])) : $author$project$S$empty;
	});
var $author$project$S$exactPrint = A2($rtfeldman$elm_css$Css$property, '-webkit-print-color-adjust', 'exact');
var $rtfeldman$elm_css$Css$margin = $rtfeldman$elm_css$Css$prop1('margin');
var $author$project$Theme$Color$blue = $rtfeldman$elm_css$Css$hex('#00a2ea');
var $author$project$Theme$Color$sectionBlue = A2($author$project$Theme$Color$withOpacity, 0.2, $author$project$Theme$Color$blue);
var $rtfeldman$elm_css$Html$Styled$th = $rtfeldman$elm_css$Html$Styled$node('th');
var $rtfeldman$elm_css$Html$Styled$thead = $rtfeldman$elm_css$Html$Styled$node('thead');
var $author$project$Page$PackingSlip$devicesView = F4(
	function (language, translation, quote, isCustomerVersion) {
		var alignCenter = $rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center))
				]));
		return A2(
			$author$project$S$table,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$marginRight(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$inherit)
								]))
						]))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$thead,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
											$author$project$S$exactPrint
										]))
								]),
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$th, _List_Nil, _List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.bV)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[alignCenter]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.ih)
										]))
								]))
						]),
						A2(
						$elm$core$List$map,
						function (device) {
							return A4($author$project$Page$PackingSlip$deviceEntry, language, quote, device, isCustomerVersion);
						},
						$author$project$Domain$Quote$AutomationDevice$allDevices)
					])));
	});
var $author$project$Page$PackingSlip$en = {bU: 'Auto populate snippet: ', bV: 'Automating devices', b8: 'Core Documentation', ca: 'Custom Basic Documentation', cd: 'Custom Pro Documentation', ce: 'CUSTOMER COPY', cR: 'In the box', hX: 'Mode', dA: 'ORISHA COPY', dC: 'Packing Slip', dQ: 'Production manager:', ih: 'Quantity', d0: 'Replacement instructions', ef: 'Seed Documentation', eA: 'Tested', e0: 'Will be sent soon'};
var $author$project$S$faIcon = $author$project$Style$Icon$faIcon;
var $author$project$Domain$Quote$Client$fullName = function (client) {
	return client.fZ + (' ' + client.gh);
};
var $rtfeldman$elm_css$Css$spaceBetween = $rtfeldman$elm_css$Css$prop1('space-between');
var $author$project$Page$PackingSlip$fileHeader = F3(
	function (translation, quote, isCustomerVersion) {
		var languageLabel = function () {
			var _v0 = quote.hg.hR;
			if (!_v0) {
				return ' (EN)';
			} else {
				return ' (FR)';
			}
		}();
		var copyLabel = isCustomerVersion ? translation.ce : translation.dA;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									_Utils_ap(copyLabel, languageLabel))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Domain$Quote$Client$fullName(quote.hg))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(quote.hg.hD)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(quote.hg.g1)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$maxWidth(
									$rtfeldman$elm_css$Css$px(150)),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$img,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$PackingSlip$fr = {bU: 'Auto populate snippet: ', bV: 'Appareils à automatiser', b8: 'Documentation pour Contrôle', ca: 'Documentation pour Sur mesure (Base)', cd: 'Documentation pour Sur mesure (Pro)', ce: 'COPIE CLIENT', cR: 'Dans la boîte', hX: 'Mode', dA: 'COPIE ORISHA', dC: 'Bon de livraison', dQ: 'Responsable de la production :', ih: 'Quantité', d0: 'Instructions de remplacement', ef: 'Documentation pour Vision', eA: 'Testé', e0: 'Sera envoyé prochainement'};
var $rtfeldman$elm_css$Html$Styled$h2 = $rtfeldman$elm_css$Html$Styled$node('h2');
var $author$project$Style$Title$h2 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h2(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-2')
				]),
			attrs));
};
var $author$project$S$h2 = $author$project$Style$Title$h2;
var $author$project$Theme$Table$centeredTh = F2(
	function (attrs, content) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$th,
			attrs,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
								]))
						]),
					content)
				]));
	});
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $author$project$Theme$Table$headerStyle = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
			$author$project$S$exactPrint,
			$rtfeldman$elm_css$Css$important(
			$rtfeldman$elm_css$Css$paddingTop(
				$rtfeldman$elm_css$Css$rem(1))),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$rem(3))
		]));
var $rtfeldman$elm_css$Html$Styled$styled = F4(
	function (fn, styles, attrs, children) {
		return A2(
			fn,
			A2(
				$elm$core$List$cons,
				$rtfeldman$elm_css$Html$Styled$Internal$css(styles),
				attrs),
			children);
	});
var $author$project$Theme$Table$header = A2(
	$rtfeldman$elm_css$Html$Styled$styled,
	$rtfeldman$elm_css$Html$Styled$thead,
	_List_fromArray(
		[$author$project$Theme$Table$headerStyle]));
var $author$project$Page$PackingSlip$headerRow = function (translation) {
	var checkboxColumn = function (str) {
		return A2(
			$author$project$Theme$Table$centeredTh,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$rem(10)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$lineHeight(
								$rtfeldman$elm_css$Css$rem(1.5))),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$rem(5))
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	};
	return A2(
		$author$project$Theme$Table$header,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$rem(10))
							]))
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Description')
					])),
				checkboxColumn(translation.ih),
				checkboxColumn(translation.eA),
				checkboxColumn(translation.cR),
				checkboxColumn(translation.e0)
			]));
};
var $author$project$Domain$Quote$Mode$toString = function (mode) {
	if (!mode) {
		return 'Pro';
	} else {
		return 'Basic';
	}
};
var $author$project$Page$PackingSlip$modeView = F3(
	function (translation, quote, isCustomerVersion) {
		return isCustomerVersion ? $author$project$S$empty : A2(
			$author$project$S$table,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$marginRight(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$inherit)
								]))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$thead,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
									$author$project$S$exactPrint
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.hX)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Domain$Quote$Mode$toString(quote.hX))
								]))
						]))
				]));
	});
var $author$project$Theme$Table$centered = F2(
	function (attrs, content) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$td,
			attrs,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
								]))
						]),
					content)
				]));
	});
var $author$project$Style$Bulma$checkbox = 'checkbox';
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $rtfeldman$elm_css$Html$Styled$label = $rtfeldman$elm_css$Html$Styled$node('label');
var $author$project$Style$Form$checkbox = F2(
	function (attrs, body) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$label,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$checkbox),
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$lineHeight($rtfeldman$elm_css$Css$inherit)
						]))
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$rtfeldman$elm_css$Html$Styled$input,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox')
							]),
						attrs),
					_List_Nil),
				body));
	});
var $author$project$S$checkbox = $author$project$Style$Form$checkbox;
var $author$project$Page$PackingSlip$productPhoto = function (path) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src(path),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$rem(4)),
								$author$project$S$onPrint(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$maxWidth(
										$rtfeldman$elm_css$Css$rem(3))
									]))
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Page$PackingSlip$entry = F5(
	function (photoPath, description, quantity, isCustomerVersion, orishacheckboxes) {
		var checkboxColumn = A2(
			$author$project$Theme$Table$centered,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$S$checkbox, _List_Nil, _List_Nil)
				]));
		return (quantity > 0) ? A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Page$PackingSlip$productPhoto(photoPath)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
										]))
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(description)
										])),
								isCustomerVersion ? _List_fromArray(
									[$author$project$S$empty]) : orishacheckboxes))
						])),
					A2(
					$author$project$Theme$Table$centered,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity))
						])),
					checkboxColumn,
					checkboxColumn,
					checkboxColumn
				])) : $author$project$S$empty;
	});
var $author$project$Domain$Hardware$image = function (hardware) {
	switch (hardware) {
		case 0:
			return 'packing-slip/controller.png';
		case 1:
			return 'packing-slip/side-curtains-unit.png';
		case 2:
			return 'packing-slip/activation-unit.png';
		case 3:
			return 'packing-slip/multi-box-no-switches.jpg';
		case 4:
			return 'packing-slip/multi-box-with-switches.png';
		case 5:
			return 'packing-slip/transfo-24v.jpg';
		case 6:
			return 'packing-slip/transfo-24v-with-wago.jpg';
		case 7:
			return 'packing-slip/motor-24v-dc.png';
		case 8:
			return 'packing-slip/extension-kit-for-motor-24v-dc.png';
		case 9:
			return 'packing-slip/power-bar-kit.png';
		case 10:
			return 'packing-slip/weather-box.png';
		case 11:
			return 'packing-slip/relay-box-110V-AC.png';
		case 12:
			return 'packing-slip/multi-box-no-switches.jpg';
		case 13:
			return 'packing-slip/rw240-1phase-5wires-roof-motor-inverter.jpeg';
		case 14:
			return 'packing-slip/inverter-24V-DC.jpg';
		case 15:
			return 'packing-slip/tensiometer.png';
		case 16:
			return 'packing-slip/weather-box.png';
		case 17:
			return 'packing-slip/temperature-humidity-sensor.png';
		case 18:
			return 'packing-slip/weather-box.png';
		case 19:
			return 'packing-slip/weather-box.png';
		case 20:
			return 'packing-slip/dry-bulb-wet-bulb-sensor.png';
		case 21:
			return 'packing-slip/thermostat.png';
		case 22:
			return 'packing-slip/anemometer.jpeg';
		case 23:
			return 'packing-slip/rain-sensor.png';
		default:
			return 'packing-slip/solar-sensor.png';
	}
};
var $author$project$Domain$Hardware$en = {g$: 'Activation Unit', cy: 'Extension kit for a 24V DC motor', cB: 'Fan and Louvers Box', c1: '24V DC Inverter', c2: 'Emitter for Irrometer Tensiometers', dl: '24V DC Motor', dm: 'Multifunction Box R---', dn: 'Multifunction Box RMVT2', $7: 'Multifunction box with side curtains control', dp: 'Multifunction box without side curtains control', dG: 'Power Bar Kit', dY: 'RW240 1 Phase 5 Wires Roof Motor Inverter', d_: '110V Relay Box AC Version', el: 'Side Curtains Unit', eH: '24V 1A AC/AC Transformer', eI: '24V 1A AC/AC Transformer with wago'};
var $author$project$Page$Product$en = {g6: 'Back up thermostat', b$: 'HAF', hh: 'Temperature and humidity sensor', hi: 'CO₂ sensor', hk: 'Central controller', aS: 'Dry bulb wet bulb sensor', cA: 'Positive/negative pressure vent', hC: 'Positive pressure and louvers', cN: 'Heat pipe', cO: 'Heater ON/OFF type', hH: 'Heating stage', dk: 'Mister', dl: 'Motor 24V DC', h9: 'Opening with 24V inverter', ib: 'Outdoor temperature and humidity sensor', ii: 'Rain sensor', iq: 'Roof 240V', gH: 'Side curtain', iu: 'Soil temperature sensor', bz: 'Solar sensor', iz: 'Tensiometer 12in', eC: 'Thermal screen', eR: '24V AC electric valve', iG: 'Weather box', bG: 'Wind sensor'};
var $author$project$Page$Product$fr = {g6: 'Thermostat de secours', b$: 'HAF', hh: 'Capteur de température et d\'humidité', hi: 'Capteur de CO₂', hk: 'Contrôleur central', aS: 'Capteur bulbe sec bulbe humide', cA: 'Pression positive/négative', hC: 'Pression positive et louvres', cN: 'Tuyau chauffant', cO: 'Fournaise de type On/Off', hH: 'Stade de chauffage', dk: 'Brumisateur', dl: 'Moteur 24V DC', h9: 'Ouvrant avec inverseur 24V', ib: 'Capteur de température et d\'humidité extérieur', ii: 'Capteur de pluie', iq: 'Toit ouvrant 240V', gH: 'Côté ouvrant', iu: 'Capteur de température de sol', bz: 'Capteur solaire', iz: 'Tensiomètre 12po', eC: 'Toile thermique', eR: 'Valve électrique 24V AC', iG: 'Boîtier météo', bG: 'Capteur de vent'};
var $author$project$Page$Product$farmProductName = F2(
	function (language, product) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$Product$en, hE: $author$project$Page$Product$fr});
		switch (product) {
			case 0:
				return translation.hk;
			case 1:
				return translation.ib;
			case 2:
				return translation.ii;
			case 3:
				return translation.bz;
			case 4:
				return translation.bG;
			default:
				return translation.iG;
		}
	});
var $author$project$Domain$Hardware$fr = {g$: 'Module d\'activation', cy: 'Rallonge pour un moteur 24V DC', cB: 'Boîtier de ventilateur et louvres', c1: 'Inverseur pour moteur 24V DC', c2: 'Émetteur pour tensiomètres Irrometer', dl: 'Moteur 24V DC', dm: 'Boîtier multifonction R---', dn: 'Boîtier multifonction RMVT2', $7: 'Boîtier multifonction avec contrôle des côtés ouvrants', dp: 'Boîtier multifonction sans contrôle des côtés ouvrants', dG: 'Kit de multiprise', dY: 'Inverseur pour moteur de toit Ridder RW240 1 phase 5 fils', d_: 'Boîtier de relais 110V version AC', el: 'Module de côtés ouvrants', eH: 'Transformateur AC/AC 24V 1A', eI: 'Transformateur AC/AC 24V 1A avec wago'};
var $author$project$Page$Product$greenhouseProductName = F2(
	function (language, product) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$Product$en, hE: $author$project$Page$Product$fr});
		switch (product) {
			case 0:
				return translation.hh;
			case 1:
				return translation.aS;
			case 2:
				return translation.iu;
			case 3:
				return translation.hi;
			case 4:
				return translation.iz;
			case 5:
				return translation.g6;
			case 6:
				return translation.cO;
			case 7:
				return translation.hH;
			case 8:
				return translation.iq;
			case 9:
				return translation.gH;
			case 10:
				return translation.h9;
			case 11:
				return translation.cA;
			case 12:
				return translation.hC;
			case 13:
				return translation.b$;
			case 14:
				return translation.eR;
			case 15:
				return translation.dk;
			case 16:
				return translation.dl;
			case 17:
				return translation.eC;
			default:
				return translation.cN;
		}
	});
var $author$project$Domain$Hardware$name = F3(
	function (language, hardware, isForCustomerDisplay) {
		var withSku = function (nameWithoutSku) {
			return nameWithoutSku + (' (' + ($elm$core$String$fromInt(
				$author$project$Domain$Hardware$sku(hardware)) + ')'));
		};
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Domain$Hardware$en, hE: $author$project$Domain$Hardware$fr});
		var hardwareName = function () {
			switch (hardware) {
				case 0:
					return A2($author$project$Page$Product$farmProductName, language, 0);
				case 1:
					return translation.el;
				case 2:
					return translation.g$;
				case 3:
					return isForCustomerDisplay ? translation.dp : translation.dm;
				case 4:
					return isForCustomerDisplay ? translation.$7 : translation.dn;
				case 5:
					return translation.eH;
				case 6:
					return translation.eI;
				case 7:
					return translation.dl;
				case 8:
					return translation.cy;
				case 9:
					return translation.dG;
				case 10:
					return A2($author$project$Page$Product$farmProductName, language, 5);
				case 11:
					return translation.d_;
				case 12:
					return translation.cB;
				case 13:
					return translation.dY;
				case 14:
					return translation.c1;
				case 15:
					return A2($author$project$Page$Product$greenhouseProductName, language, 4);
				case 16:
					return translation.c2;
				case 17:
					return A2($author$project$Page$Product$greenhouseProductName, language, 0);
				case 18:
					return A2($author$project$Page$Product$greenhouseProductName, language, 2);
				case 19:
					return A2($author$project$Page$Product$greenhouseProductName, language, 3);
				case 20:
					return A2($author$project$Page$Product$greenhouseProductName, language, 1);
				case 21:
					return A2($author$project$Page$Product$greenhouseProductName, language, 5);
				case 22:
					return A2($author$project$Page$Product$farmProductName, language, 4);
				case 23:
					return A2($author$project$Page$Product$farmProductName, language, 2);
				default:
					return A2($author$project$Page$Product$farmProductName, language, 3);
			}
		}();
		return isForCustomerDisplay ? hardwareName : withSku(hardwareName);
	});
var $author$project$Page$PackingSlip$orishaTeamcheckboxes = F2(
	function (quote, hardware) {
		var numberOfOutdoorClimateSensors = A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 1);
		var numberOfIndoorClimateSensors = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 0);
		var numberOfFans = $elm$core$String$fromInt(
			$elm$core$List$sum(
				A2(
					$elm$core$List$map,
					$author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct(quote),
					$author$project$Page$PackingSlip$filteredGreenhouseProducts(1))));
		var numberOfClimateSensorsBatteries = $elm$core$String$fromInt(
			function (sensors) {
				return 3 * sensors;
			}(numberOfIndoorClimateSensors + numberOfOutdoorClimateSensors));
		var customerLanguage = function () {
			var _v2 = quote.hg.hR;
			if (!_v2) {
				return 'EN';
			} else {
				return 'FR';
			}
		}();
		var checkbox = function (info) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(0.7)),
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$S$checkbox,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$em(1)),
										$rtfeldman$elm_css$Css$marginRight(
										$rtfeldman$elm_css$Css$em(0.2))
									]))
							]),
						_List_Nil),
						$rtfeldman$elm_css$Html$Styled$text(info)
					]));
		};
		var documentation = function () {
			var _v1 = quote.ht;
			switch (_v1) {
				case 0:
					return $author$project$S$empty;
				case 1:
					return checkbox('Cartable Core ' + customerLanguage);
				case 2:
					return checkbox('Cartable Basic ' + customerLanguage);
				case 3:
					return checkbox('Cartable complet ' + customerLanguage);
				case 4:
					return checkbox('Instructions de remplacement ' + customerLanguage);
				default:
					return $author$project$S$empty;
			}
		}();
		switch (hardware) {
			case 0:
				return _List_fromArray(
					[
						checkbox('JWT Config'),
						checkbox('One pager ' + customerLanguage),
						documentation
					]);
			case 1:
				return _List_fromArray(
					[
						checkbox('Transfo 24V DC')
					]);
			case 2:
				return _List_Nil;
			case 3:
				return _List_fromArray(
					[
						checkbox(numberOfFans + ' jumpers')
					]);
			case 4:
				return _List_fromArray(
					[
						checkbox(numberOfFans + ' jumpers'),
						checkbox('Transfo 24V DC')
					]);
			case 5:
				return _List_Nil;
			case 6:
				return _List_Nil;
			case 7:
				return _List_Nil;
			case 8:
				return _List_Nil;
			case 9:
				return _List_Nil;
			case 10:
				return _List_Nil;
			case 11:
				return _List_Nil;
			case 12:
				return _List_Nil;
			case 13:
				return _List_fromArray(
					[
						checkbox('Transfo 24V AC')
					]);
			case 14:
				return _List_fromArray(
					[
						checkbox('Transfo 24V DC')
					]);
			case 15:
				return _List_Nil;
			case 16:
				return _List_fromArray(
					[
						checkbox('One pager ' + customerLanguage)
					]);
			case 17:
				return _List_fromArray(
					[
						checkbox(numberOfClimateSensorsBatteries + ' piles')
					]);
			case 18:
				return _List_Nil;
			case 19:
				return _List_Nil;
			case 20:
				return _List_Nil;
			case 21:
				return _List_Nil;
			case 22:
				return _List_fromArray(
					[
						checkbox('Boîte de carton')
					]);
			case 23:
				return _List_Nil;
			default:
				return _List_fromArray(
					[
						checkbox('Feuille de calibration'),
						checkbox('Livre d\'instructions'),
						checkbox('Support')
					]);
		}
	});
var $author$project$Page$PackingSlip$products = F4(
	function (language, translation, quote, isCustomerVersion) {
		var noOrishaTeamcheckboxes = _List_Nil;
		var entry_ = function (hardware) {
			return A5(
				$author$project$Page$PackingSlip$entry,
				$author$project$Domain$Hardware$image(hardware),
				A3($author$project$Domain$Hardware$name, language, hardware, isCustomerVersion),
				A2($author$project$Domain$Hardware$count, quote, hardware),
				isCustomerVersion,
				A2($author$project$Page$PackingSlip$orishaTeamcheckboxes, quote, hardware));
		};
		var documentationLabel = function () {
			var _v0 = quote.ht;
			switch (_v0) {
				case 0:
					return $elm$core$Maybe$Just(translation.ef);
				case 1:
					return $elm$core$Maybe$Just(translation.b8);
				case 2:
					return $elm$core$Maybe$Just(translation.ca);
				case 3:
					return $elm$core$Maybe$Just(translation.cd);
				case 4:
					return $elm$core$Maybe$Just(translation.d0);
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		var documentation = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (label) {
					return _List_fromArray(
						[
							A5($author$project$Page$PackingSlip$entry, 'packing-slip/favicon.ico', label, 1, isCustomerVersion, noOrishaTeamcheckboxes)
						]);
				},
				documentationLabel));
		var custom = A2(
			$elm$core$List$map,
			function (c) {
				return A5($author$project$Page$PackingSlip$entry, 'packing-slip/custom.png', c.fk, c.ih, isCustomerVersion, noOrishaTeamcheckboxes);
			},
			$author$project$Page$PackingSlip$customProducts(quote));
		return function (hardwares) {
			return _Utils_ap(
				hardwares,
				_Utils_ap(custom, documentation));
		}(
			A2($elm$core$List$map, entry_, $author$project$Domain$Hardware$allHardwares));
	});
var $rtfeldman$elm_css$Css$borderBottom2 = $rtfeldman$elm_css$Css$prop2('border-bottom');
var $rtfeldman$elm_css$Css$solid = {H: 0, aG: 0, gU: 'solid'};
var $author$project$Page$PackingSlip$signature = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'align-self', 'flex-end'),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$rem(4))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.dQ)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$rem(1.5)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$rem(25)),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$rem(2)),
								A2(
								$rtfeldman$elm_css$Css$borderBottom2,
								$rtfeldman$elm_css$Css$px(1),
								$rtfeldman$elm_css$Css$solid)
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Page$PackingSlip$view = F3(
	function (quote, isCustomerVersion, model) {
		var orishaTeamLanguage = 1;
		var language = isCustomerVersion ? quote.hg.hR : orishaTeamLanguage;
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$PackingSlip$en, hE: $author$project$Page$PackingSlip$fr});
		var autoPopulateSnippet_ = $author$project$Page$PackingSlip$autoPopulateSnippet(quote);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(2)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$px(25)),
									$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none)
								]))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$Page$PackingSlip$fileHeader, translation, quote, isCustomerVersion),
									A2(
									$author$project$S$h2,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$marginBottom(
														$rtfeldman$elm_css$Css$rem(3))),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.3)))
														]))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dC)
										])),
									A2(
									$author$project$S$table,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginLeft(
													$rtfeldman$elm_css$Css$rem(-5)),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(-5)),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit)),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$inherit)
														]))
												]))
										]),
									A2(
										$elm$core$List$cons,
										$author$project$Page$PackingSlip$headerRow(translation),
										A4($author$project$Page$PackingSlip$products, language, translation, quote, isCustomerVersion)))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(3)),
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
											A2(
											$rtfeldman$elm_css$Css$Media$withMedia,
											_List_fromArray(
												[
													A2($rtfeldman$elm_css$Css$Media$only, $rtfeldman$elm_css$Css$Media$print, _List_Nil)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none))
												]))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(0.5))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.bU)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(autoPopulateSnippet_)
										]),
									_List_fromArray(
										[
											A2($author$project$S$faIcon, 'clipboard', $elm$core$Maybe$Nothing)
										])),
									model.b5 ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$S$faIcon,
											'check',
											$elm$core$Maybe$Just($author$project$Theme$Color$green))
										])) : $author$project$S$empty
								])),
							A4($author$project$Page$PackingSlip$devicesView, language, translation, quote, isCustomerVersion),
							A3($author$project$Page$PackingSlip$modeView, translation, quote, isCustomerVersion),
							$author$project$Page$PackingSlip$signature(translation)
						]))
				]));
	});
var $author$project$Page$PackingSlipV3$CopyToClipboard = $elm$core$Basics$identity;
var $author$project$Page$PackingSlipV3$autoPopulateSnippet = function (quote) {
	var part = function (hardware) {
		var count = A2($author$project$Domain$Hardware$count, quote, hardware);
		return (count > 0) ? $elm$core$Maybe$Just(
			$elm$core$String$fromInt(
				$author$project$Domain$Hardware$sku(hardware)) + (' ' + $elm$core$String$fromInt(count))) : $elm$core$Maybe$Nothing;
	};
	return A2(
		$elm$core$String$join,
		' ',
		$elm_community$maybe_extra$Maybe$Extra$values(
			A2($elm$core$List$map, part, $author$project$Domain$Hardware$allHardwares)));
};
var $author$project$Page$PackingSlipV3$AdvancedVentilation = 3;
var $author$project$Page$PackingSlipV3$DiseasePrevention = 1;
var $author$project$Page$PackingSlipV3$HeatPipe = 8;
var $author$project$Page$PackingSlipV3$Heating = 4;
var $author$project$Page$PackingSlipV3$HumidityConservation = 5;
var $author$project$Page$PackingSlipV3$Irrigation = 2;
var $author$project$Page$PackingSlipV3$RollupVentilation = 0;
var $author$project$Page$PackingSlipV3$Tensiometer = 6;
var $author$project$Page$PackingSlipV3$ThermalScreen = 7;
var $author$project$Page$PackingSlipV3$allConfigs = _List_fromArray(
	[8, 6, 7, 0, 1, 2, 3, 4, 5]);
var $rtfeldman$elm_css$Html$Styled$col = $rtfeldman$elm_css$Html$Styled$node('col');
var $rtfeldman$elm_css$Html$Styled$colgroup = $rtfeldman$elm_css$Html$Styled$node('colgroup');
var $author$project$Theme$Icon$faIcon = F2(
	function (fontAwesomeClass, color) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$em(1.7)),
							$rtfeldman$elm_css$Css$color(color)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$i,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('fa fa-' + fontAwesomeClass)
						]),
					_List_Nil)
				]));
	});
var $author$project$Theme$Icon$humidity = A2($author$project$Theme$Icon$faIcon, 'tint', $author$project$Theme$Color$blue);
var $author$project$Page$PackingSlipV3$configIcon = function (config) {
	switch (config) {
		case 0:
			return $author$project$Theme$Icon$roof;
		case 1:
			return $author$project$Theme$Icon$humidity;
		case 2:
			return $author$project$Theme$Icon$valve;
		case 3:
			return $author$project$Theme$Icon$fan;
		case 4:
			return $author$project$Theme$Icon$heater;
		case 5:
			return $author$project$Theme$Icon$mister;
		case 6:
			return $author$project$Theme$Icon$tensiometer;
		case 7:
			return $author$project$Theme$Icon$thermalScreen;
		default:
			return $author$project$Theme$Icon$heatPipe;
	}
};
var $author$project$Page$PackingSlipV3$configName = function (config) {
	switch (config) {
		case 0:
			return 'Rollup Ventilation';
		case 1:
			return 'Disease Prevention';
		case 2:
			return 'Irrigation';
		case 3:
			return 'Advanced Ventilation';
		case 4:
			return 'Heating';
		case 5:
			return 'Humidity Conservation';
		case 6:
			return 'Tensiometer';
		case 7:
			return 'Thermal Screen';
		default:
			return 'Heat Pipe';
	}
};
var $author$project$Page$PackingSlipV3$configEntry = F2(
	function (configs, config) {
		var quantity = A2(
			$elm_community$list_extra$List$Extra$count,
			$elm$core$Basics$eq(config),
			configs);
		return (quantity > 0) ? A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$author$project$Page$PackingSlipV3$configIcon(config)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Page$PackingSlipV3$configName(config))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity))
						]))
				])) : $author$project$S$empty;
	});
var $author$project$Page$PackingSlipV3$configFromBasePlan = function (basePlan) {
	switch (basePlan) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Just(0);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$PackingSlipV3$configFromProduct = function (product) {
	switch (product) {
		case 0:
			return $elm$core$Maybe$Just(1);
		case 1:
			return $elm$core$Maybe$Just(2);
		case 2:
			return $elm$core$Maybe$Just(3);
		case 3:
			return $elm$core$Maybe$Just(4);
		case 4:
			return $elm$core$Maybe$Just(5);
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Nothing;
		case 7:
			return $elm$core$Maybe$Just(6);
		case 8:
			return $elm$core$Maybe$Just(7);
		case 9:
			return $elm$core$Maybe$Just(8);
		case 10:
			return $elm$core$Maybe$Nothing;
		case 11:
			return $elm$core$Maybe$Nothing;
		case 12:
			return $elm$core$Maybe$Nothing;
		case 13:
			return $elm$core$Maybe$Nothing;
		case 14:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$PackingSlipV3$configView = F2(
	function (translation, quoteV3) {
		var configsFromGreenhouse = function (g) {
			return $elm_community$maybe_extra$Maybe$Extra$values(
				A2(
					$elm$core$List$cons,
					$author$project$Page$PackingSlipV3$configFromBasePlan(g.g9),
					A2($elm$core$List$map, $author$project$Page$PackingSlipV3$configFromProduct, g.ig)));
		};
		var configs = A2($elm$core$List$concatMap, configsFromGreenhouse, quoteV3.aX);
		var colgroup = A2(
			$rtfeldman$elm_css$Html$Styled$colgroup,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$col,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$pct(5))
								]))
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$col,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$pct(70))
								]))
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$col,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$pct(25))
								]))
						]),
					_List_Nil)
				]));
		var alignCenter = $rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center))
				]));
		var header = A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
							$author$project$S$exactPrint
						]))
				]),
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Html$Styled$th, _List_Nil, _List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.b2)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[alignCenter]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.ih)
						]))
				]));
		return A2(
			$author$project$S$table,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$marginRight(
							$rtfeldman$elm_css$Css$rem(-5)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$inherit)
								]))
						]))
				]),
			A2(
				$elm$core$List$cons,
				header,
				A2(
					$elm$core$List$cons,
					colgroup,
					A2(
						$elm$core$List$map,
						$author$project$Page$PackingSlipV3$configEntry(configs),
						$author$project$Page$PackingSlipV3$allConfigs))));
	});
var $author$project$Page$PackingSlipV3$en = {bU: 'Auto populate snippet: ', b2: 'Configuration', b8: 'Core Documentation', ca: 'Custom Basic Documentation', cd: 'Custom Pro Documentation', ce: 'CUSTOMER COPY', cR: 'In the box', dA: 'ORISHA COPY', dC: 'Packing Slip', dQ: 'Production manager:', ih: 'Quantity', d0: 'Replacement instructions', ef: 'Seed Documentation', eA: 'Tested', e0: 'Will be sent soon'};
var $author$project$Page$PackingSlipV3$fileHeader = F3(
	function (translation, quoteV3, isCustomerVersion) {
		var languageLabel = function () {
			var _v0 = quoteV3.hg.hR;
			if (!_v0) {
				return ' (EN)';
			} else {
				return ' (FR)';
			}
		}();
		var copyLabel = isCustomerVersion ? translation.ce : translation.dA;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									_Utils_ap(copyLabel, languageLabel))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Domain$Quote$Client$fullName(quoteV3.hg))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(quoteV3.hg.hD)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(quoteV3.hg.g1)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$maxWidth(
									$rtfeldman$elm_css$Css$px(150)),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$img,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$PackingSlipV3$fr = {bU: 'Auto populate snippet: ', b2: 'Configuration', b8: 'Documentation pour Contrôle', ca: 'Documentation pour Sur mesure (Base)', cd: 'Documentation pour Sur mesure (Pro)', ce: 'COPIE CLIENT', cR: 'Dans la boîte', dA: 'COPIE ORISHA', dC: 'Bon de livraison', dQ: 'Responsable de la production :', ih: 'Quantité', d0: 'Instructions pour remplacement', ef: 'Documentation pour Vision', eA: 'Testé', e0: 'Sera envoyé prochainement'};
var $author$project$Page$PackingSlipV3$headerRow = function (translation) {
	var checkboxColumn = function (str) {
		return A2(
			$author$project$Theme$Table$centeredTh,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$rem(10)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$lineHeight(
								$rtfeldman$elm_css$Css$rem(1.5))),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$rem(5))
								]))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	};
	return A2(
		$author$project$Theme$Table$header,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$rem(10))
							]))
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Description')
					])),
				checkboxColumn(translation.ih),
				checkboxColumn(translation.eA),
				checkboxColumn(translation.cR),
				checkboxColumn(translation.e0)
			]));
};
var $author$project$Page$PackingSlipV3$customProducts = function (quote) {
	var greenhouseCustomProducts = A2(
		$elm$core$List$concatMap,
		function ($) {
			return $.hn;
		},
		quote.aX);
	var farmCustomProducts = quote.fd.hn;
	return A2($elm$core$List$append, greenhouseCustomProducts, farmCustomProducts);
};
var $author$project$Page$PackingSlipV3$productPhoto = function (path) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src(path),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$rem(4)),
								$author$project$S$onPrint(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$maxWidth(
										$rtfeldman$elm_css$Css$rem(3))
									]))
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Page$PackingSlipV3$entry = F5(
	function (photoPath, description, quantity, isCustomerVersion, orishacheckboxes) {
		var checkboxColumn = A2(
			$author$project$Theme$Table$centered,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$S$checkbox, _List_Nil, _List_Nil)
				]));
		return (quantity > 0) ? A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Page$PackingSlipV3$productPhoto(photoPath)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
										]))
								]),
							A2(
								$elm$core$List$cons,
								A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(description)
										])),
								isCustomerVersion ? _List_fromArray(
									[$author$project$S$empty]) : orishacheckboxes))
						])),
					A2(
					$author$project$Theme$Table$centered,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity))
						])),
					checkboxColumn,
					checkboxColumn,
					checkboxColumn
				])) : $author$project$S$empty;
	});
var $author$project$Page$PackingSlipV3$filteredGreenhouseProducts = function (device) {
	return A2(
		$elm$core$List$filter,
		function (greenhouseProduct) {
			return _Utils_eq(
				$author$project$Domain$Quote$AutomationDevice$fromGreenhouseProduct(greenhouseProduct),
				$elm$core$Maybe$Just(device));
		},
		$author$project$Domain$Product$allGreenhouseProducts);
};
var $author$project$Page$PackingSlipV3$orishaTeamCheckboxes = F3(
	function (quote, quoteV3, hardware) {
		var numberOfOutdoorClimateSensors = A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, 1);
		var numberOfIndoorClimateSensors = A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, 0);
		var numberOfFans = $elm$core$String$fromInt(
			$elm$core$List$sum(
				A2(
					$elm$core$List$map,
					$author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct(quote),
					$author$project$Page$PackingSlipV3$filteredGreenhouseProducts(1))));
		var numberOfClimateSensorsBatteries = $elm$core$String$fromInt(
			function (sensors) {
				return 3 * sensors;
			}(numberOfIndoorClimateSensors + numberOfOutdoorClimateSensors));
		var customerLanguage = function () {
			var _v2 = quoteV3.hg.hR;
			if (!_v2) {
				return 'EN';
			} else {
				return 'FR';
			}
		}();
		var checkbox = function (info) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(0.7)),
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$S$checkbox,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$em(1)),
										$rtfeldman$elm_css$Css$marginRight(
										$rtfeldman$elm_css$Css$em(0.2))
									]))
							]),
						_List_Nil),
						$rtfeldman$elm_css$Html$Styled$text(info)
					]));
		};
		var documentation = function () {
			var _v1 = quote.ht;
			switch (_v1) {
				case 0:
					return $author$project$S$empty;
				case 1:
					return checkbox('Cartable Core ' + customerLanguage);
				case 2:
					return checkbox('Cartable Basic ' + customerLanguage);
				case 3:
					return checkbox('Cartable complet ' + customerLanguage);
				case 4:
					return checkbox('Instructions de remplacement ' + customerLanguage);
				default:
					return $author$project$S$empty;
			}
		}();
		switch (hardware) {
			case 0:
				return _List_fromArray(
					[
						checkbox('JWT Config'),
						checkbox('One pager ' + customerLanguage),
						documentation
					]);
			case 1:
				return _List_fromArray(
					[
						checkbox('Transfo 24V DC')
					]);
			case 2:
				return _List_Nil;
			case 3:
				return _List_fromArray(
					[
						checkbox(numberOfFans + ' jumpers')
					]);
			case 4:
				return _List_fromArray(
					[
						checkbox(numberOfFans + ' jumpers'),
						checkbox('Transfo 24V DC')
					]);
			case 5:
				return _List_Nil;
			case 6:
				return _List_Nil;
			case 7:
				return _List_Nil;
			case 8:
				return _List_Nil;
			case 9:
				return _List_Nil;
			case 10:
				return _List_Nil;
			case 11:
				return _List_Nil;
			case 12:
				return _List_Nil;
			case 13:
				return _List_fromArray(
					[
						checkbox('Transfo 24V AC')
					]);
			case 14:
				return _List_fromArray(
					[
						checkbox('Transfo 24V DC')
					]);
			case 15:
				return _List_Nil;
			case 16:
				return _List_fromArray(
					[
						checkbox('One pager ' + customerLanguage)
					]);
			case 17:
				return _List_fromArray(
					[
						checkbox(numberOfClimateSensorsBatteries + ' piles')
					]);
			case 18:
				return _List_Nil;
			case 19:
				return _List_Nil;
			case 20:
				return _List_Nil;
			case 21:
				return _List_Nil;
			case 22:
				return _List_fromArray(
					[
						checkbox('Boîte de carton')
					]);
			case 23:
				return _List_Nil;
			default:
				return _List_fromArray(
					[
						checkbox('Feuille de calibration'),
						checkbox('Livre d\'instructions'),
						checkbox('Support')
					]);
		}
	});
var $author$project$Page$PackingSlipV3$products = F5(
	function (language, translation, quote, quoteV3, isCustomerVersion) {
		var noOrishaTeamcheckboxes = _List_Nil;
		var entry_ = function (hardware) {
			return A5(
				$author$project$Page$PackingSlipV3$entry,
				$author$project$Domain$Hardware$image(hardware),
				A3($author$project$Domain$Hardware$name, language, hardware, isCustomerVersion),
				A2($author$project$Domain$Hardware$count, quote, hardware),
				isCustomerVersion,
				A3($author$project$Page$PackingSlipV3$orishaTeamCheckboxes, quote, quoteV3, hardware));
		};
		var documentationLabel = function () {
			var _v0 = quote.ht;
			switch (_v0) {
				case 0:
					return $elm$core$Maybe$Just(translation.ef);
				case 1:
					return $elm$core$Maybe$Just(translation.b8);
				case 2:
					return $elm$core$Maybe$Just(translation.ca);
				case 3:
					return $elm$core$Maybe$Just(translation.cd);
				case 4:
					return $elm$core$Maybe$Just(translation.d0);
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		var documentation = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				function (label) {
					return _List_fromArray(
						[
							A5($author$project$Page$PackingSlipV3$entry, 'packing-slip/favicon.ico', label, 1, isCustomerVersion, noOrishaTeamcheckboxes)
						]);
				},
				documentationLabel));
		var custom = A2(
			$elm$core$List$map,
			function (c) {
				return A5($author$project$Page$PackingSlipV3$entry, 'packing-slip/custom.png', c.fk, c.ih, isCustomerVersion, noOrishaTeamcheckboxes);
			},
			$author$project$Page$PackingSlipV3$customProducts(quote));
		return function (hardwares) {
			return _Utils_ap(
				hardwares,
				_Utils_ap(custom, documentation));
		}(
			A2($elm$core$List$map, entry_, $author$project$Domain$Hardware$allHardwares));
	});
var $author$project$Page$PackingSlipV3$signature = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						A2($rtfeldman$elm_css$Css$property, 'align-self', 'flex-end'),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$rem(4))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.dQ)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$rem(1.5)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$rem(25)),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$rem(2)),
								A2(
								$rtfeldman$elm_css$Css$borderBottom2,
								$rtfeldman$elm_css$Css$px(1),
								$rtfeldman$elm_css$Css$solid)
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Page$PackingSlipV3$view = F4(
	function (quote, quoteV3, isCustomerVersion, model) {
		var orishaTeamLanguage = 1;
		var language = isCustomerVersion ? quoteV3.hg.hR : orishaTeamLanguage;
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$PackingSlipV3$en, hE: $author$project$Page$PackingSlipV3$fr});
		var autoPopulateSnippet_ = $author$project$Page$PackingSlipV3$autoPopulateSnippet(quote);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(2)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$px(25)),
									$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none)
								]))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$Page$PackingSlipV3$fileHeader, translation, quoteV3, isCustomerVersion),
									A2(
									$author$project$S$h2,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$marginBottom(
														$rtfeldman$elm_css$Css$rem(3))),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.3)))
														]))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dC)
										])),
									A2(
									$author$project$S$table,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginLeft(
													$rtfeldman$elm_css$Css$rem(-5)),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(-5)),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$inherit)),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$inherit)
														]))
												]))
										]),
									A2(
										$elm$core$List$cons,
										$author$project$Page$PackingSlipV3$headerRow(translation),
										A5($author$project$Page$PackingSlipV3$products, language, translation, quote, quoteV3, isCustomerVersion)))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(3)),
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
											A2(
											$rtfeldman$elm_css$Css$Media$withMedia,
											_List_fromArray(
												[
													A2($rtfeldman$elm_css$Css$Media$only, $rtfeldman$elm_css$Css$Media$print, _List_Nil)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none))
												]))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(0.5))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.bU)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(autoPopulateSnippet_)
										]),
									_List_fromArray(
										[
											A2($author$project$S$faIcon, 'clipboard', $elm$core$Maybe$Nothing)
										])),
									model.b5 ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$S$faIcon,
											'check',
											$elm$core$Maybe$Just($author$project$Theme$Color$green))
										])) : $author$project$S$empty
								])),
							(!isCustomerVersion) ? A2($author$project$Page$PackingSlipV3$configView, translation, quoteV3) : $author$project$S$empty,
							$author$project$Page$PackingSlipV3$signature(translation)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Update$ClientMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilder$Update$DiscountMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilder$Update$SetDocumentation = function (a) {
	return {$: 9, a: a};
};
var $author$project$Domain$Quote$Documentation$allDocumentations = _List_fromArray(
	[0, 1, 2, 3, 4, 5]);
var $author$project$Style$Form$labeled = F2(
	function (labelText, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('field')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('label'),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(0.9)),
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(300))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(labelText)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('control')
						]),
					children)
				]));
	});
var $author$project$S$labeled = $author$project$Style$Form$labeled;
var $author$project$Style$Bulma$control = 'control';
var $elm_community$list_extra$List$Extra$findIndexHelp = F3(
	function (index, predicate, list) {
		findIndexHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					return $elm$core$Maybe$Just(index);
				} else {
					var $temp$index = index + 1,
						$temp$predicate = predicate,
						$temp$list = xs;
					index = $temp$index;
					predicate = $temp$predicate;
					list = $temp$list;
					continue findIndexHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$findIndex = $elm_community$list_extra$List$Extra$findIndexHelp(0);
var $elm_community$list_extra$List$Extra$elemIndex = function (x) {
	return $elm_community$list_extra$List$Extra$findIndex(
		$elm$core$Basics$eq(x));
};
var $elm_community$json_extra$Json$Decode$Extra$fromMaybe = F2(
	function (error, val) {
		if (!val.$) {
			var v = val.a;
			return $elm$json$Json$Decode$succeed(v);
		} else {
			return $elm$json$Json$Decode$fail(error);
		}
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $rtfeldman$elm_css$Html$Styled$option = $rtfeldman$elm_css$Html$Styled$node('option');
var $rtfeldman$elm_css$Html$Styled$select = $rtfeldman$elm_css$Html$Styled$node('select');
var $author$project$Style$Bulma$select = 'select';
var $elm$json$Json$Encode$bool = _Json_wrap;
var $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$selected = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('selected');
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $author$project$Style$Form$select = F5(
	function (initial, options, toLabel, toMsg, attrs) {
		var onInput = A2(
			$elm$json$Json$Decode$andThen,
			function (index) {
				return A2(
					$elm_community$json_extra$Json$Decode$Extra$fromMaybe,
					'Index not found',
					A2(
						$elm$core$Maybe$map,
						toMsg,
						A2(
							$elm$core$Maybe$andThen,
							function (i) {
								return A2($elm_community$list_extra$List$Extra$getAt, i, options);
							},
							$elm$core$String$toInt(index))));
			},
			$rtfeldman$elm_css$Html$Styled$Events$targetValue);
		var initialIndex = A2($elm_community$list_extra$List$Extra$elemIndex, initial, options);
		var toOption = F2(
			function (index, elem) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$option,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$selected(
							_Utils_eq(
								$elm$core$Maybe$Just(index),
								initialIndex)),
							$rtfeldman$elm_css$Html$Styled$Attributes$value(
							$elm$core$String$fromInt(index))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							toLabel(elem))
						]));
			});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$control)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$select)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							A2(
								$elm$core$List$cons,
								A2($rtfeldman$elm_css$Html$Styled$Events$on, 'input', onInput),
								attrs),
							A2($elm$core$List$indexedMap, toOption, options))
						]))
				]));
	});
var $author$project$S$select = $author$project$Style$Form$select;
var $author$project$Page$QuoteBuilder$View$documentationView = F3(
	function (translation, initialDocumentation, user) {
		var toLabel = function (documentation) {
			switch (documentation) {
				case 0:
					return translation.ef;
				case 1:
					return translation.b8;
				case 2:
					return translation.ca;
				case 3:
					return translation.cd;
				case 4:
					return translation.d0;
				default:
					return translation.du;
			}
		};
		return $author$project$Domain$User$isOrisha(user) ? A2(
			$author$project$S$labeled,
			translation.ht,
			_List_fromArray(
				[
					A5($author$project$S$select, initialDocumentation, $author$project$Domain$Quote$Documentation$allDocumentations, toLabel, $author$project$Page$QuoteBuilder$Update$SetDocumentation, _List_Nil)
				])) : $author$project$S$empty;
	});
var $author$project$Page$QuoteBuilder$View$en = {bL: 'Activation and Side Curtains Units', bM: 'Actuators', bW: 'Basic', bX: 'Beware, the link contains the necessary numerical values!', bZ: 'Build your own quote!', b3: 'For any questions, please contact us!', b7: 'You only have to copy and paste the following link in an email.', b8: 'Core', ca: 'Custom Basic', cd: 'Custom Pro', ht: 'Documentation', hX: 'Mode', gj: 'Multifunction boxes', dr: 'New greenhouse', du: 'None', ab: 'Plan', $8: 'Amount ($)', dO: 'Pro', ig: 'Products', dS: 'Purchase', ih: 'Quantity', d0: 'Replacement instructions', ef: 'Seed', ek: 'Share the Return On Investment tool with your customer!', er: 'Subscription', e3: 'Therefore, you have to complete filling the above informations in order for your client to see the right numerical values in the ROI tool.'};
var $author$project$Page$QuoteBuilder$Update$SetActuator = function (a) {
	return {$: 6, a: a};
};
var $author$project$Domain$Quote$Actuator$allActuatorTypes = _List_fromArray(
	[1, 0]);
var $author$project$Page$QuoteBuilder$View$equipmentView = F3(
	function (translation, initialActuatorType, user) {
		var toLabel = function (actuatorType) {
			if (!actuatorType) {
				return translation.gj;
			} else {
				return translation.bL;
			}
		};
		return $author$project$Domain$User$isOrisha(user) ? A2(
			$author$project$S$labeled,
			translation.bM,
			_List_fromArray(
				[
					A5($author$project$S$select, initialActuatorType, $author$project$Domain$Quote$Actuator$allActuatorTypes, toLabel, $author$project$Page$QuoteBuilder$Update$SetActuator, _List_Nil)
				])) : $author$project$S$empty;
	});
var $author$project$Page$QuoteBuilder$View$fr = {bL: 'Modules d\'activation et de côtés ouvrants', bM: 'Actuateurs', bW: 'Basic', bX: 'Attention, le lien contient les valeurs numériques nécessaires!', bZ: 'Construisez votre devis!', b3: 'Pour toutes questions, communiquez avec nous!', b7: 'Il suffit de copier et coller le lien suivant dans un courriel.', b8: 'Contrôle (Core)', ca: 'Sur mesure (Custom) Basic', cd: 'Sur mesure (Custom) Pro', ht: 'Documentation', hX: 'Mode', gj: 'Boîtier multifonctions', dr: 'Nouvelle serre', du: 'Aucune', ab: 'Plan d\'achat', $8: 'Prix ($)', dO: 'Pro', ig: 'Produit', dS: 'Achat', ih: 'Quantité', d0: 'Instructions de remplacement', ef: 'Vision (Seed)', ek: 'Partagez l\'outil de retour sur investissement avec vos clients!', er: 'Location', e3: 'Il est donc important d\'avoir complété la saisie des informations pour le devis pour que les bonnes valeurs numériques s\'affichent pour le client.'};
var $author$project$Utils$Style$gap = function (length) {
	return A2($rtfeldman$elm_css$Css$property, 'gap', length.gU);
};
var $author$project$Page$QuoteBuilder$Update$SetMode = function (a) {
	return {$: 8, a: a};
};
var $author$project$Domain$Quote$Mode$allModes = _List_fromArray(
	[0, 1]);
var $author$project$Page$QuoteBuilder$View$modeView = F2(
	function (translation, initialMode) {
		var toLabel = function (mode) {
			if (!mode) {
				return translation.dO;
			} else {
				return translation.bW;
			}
		};
		return A2(
			$author$project$S$labeled,
			translation.hX,
			_List_fromArray(
				[
					A5($author$project$S$select, initialMode, $author$project$Domain$Quote$Mode$allModes, toLabel, $author$project$Page$QuoteBuilder$Update$SetMode, _List_Nil)
				]));
	});
var $author$project$Info$phoneNumber = '1-888-267-4742';
var $author$project$Page$QuoteBuilder$Update$SetPlan = function (a) {
	return {$: 7, a: a};
};
var $author$project$Domain$Quote$Plan$allPlans = _List_fromArray(
	[0, 1]);
var $author$project$Page$QuoteBuilder$View$planView = F2(
	function (translation, initialPlan) {
		var toLabel = function (plan) {
			if (!plan) {
				return translation.dS;
			} else {
				return translation.er;
			}
		};
		return A2(
			$author$project$S$labeled,
			translation.ab,
			_List_fromArray(
				[
					A5($author$project$S$select, initialPlan, $author$project$Domain$Quote$Plan$allPlans, toLabel, $author$project$Page$QuoteBuilder$Update$SetPlan, _List_Nil)
				]));
	});
var $author$project$Page$QuoteBuilder$Update$ROIMsg = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $author$project$Page$ROI$CopyLink = $elm$core$Basics$identity;
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $author$project$Route$ROI$formatLanguage = function (language) {
	if (!language) {
		return 'EN';
	} else {
		return 'FR';
	}
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Route$ROI$buildUrl = function (quote) {
	var systemPrice = A2(
		$author$project$Domain$Total$Amount$inCurrency,
		quote.hg.hl,
		$author$project$Domain$Total$Discount$fromQuote(quote));
	var numberOfGreenhouses = $elm$core$List$length(quote.aX);
	var numberOfEquipments = $author$project$Domain$Quote$Equipment$numberOfEquipments(quote);
	var formattedLanguage = $author$project$Route$ROI$formatLanguage(quote.hg.hR);
	return A3(
		$elm$url$Url$Builder$crossOrigin,
		'https://roi.orisha.ca',
		_List_Nil,
		_List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'language', formattedLanguage),
				A2($elm$url$Url$Builder$int, 'numberOfGreenhouses', numberOfGreenhouses),
				A2($elm$url$Url$Builder$int, 'systemPrice', systemPrice),
				A2($elm$url$Url$Builder$int, 'monthlySubscription', 0),
				A2($elm$url$Url$Builder$int, 'numberOfEquipments', numberOfEquipments)
			]));
};
var $author$project$Page$ROI$en = {b6: 'Copy link', dd: 'Link'};
var $author$project$Page$ROI$fr = {b6: 'Copier le lien', dd: 'Lien'};
var $rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$target = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var $author$project$Page$ROI$roiLink = F2(
	function (language, quote) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$ROI$en, hE: $author$project$Page$ROI$fr});
		var roiUrl = $author$project$Route$ROI$buildUrl(quote);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(1))
								])),
							$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
							$rtfeldman$elm_css$Html$Styled$Attributes$href(roiUrl)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dd)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$em(2)),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$rem(2)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1)),
									$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
									$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
									$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(roiUrl)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$padding(
											$rtfeldman$elm_css$Css$em(0.3))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.b6)
								]))
						]))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$h4 = $rtfeldman$elm_css$Html$Styled$node('h4');
var $author$project$Style$Text$talk1 = function (str) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$h4,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-4'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$important(
						$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$rem(3))),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(200))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(str)
			]));
};
var $author$project$S$talk1 = $author$project$Style$Text$talk1;
var $rtfeldman$elm_css$Html$Styled$em = $rtfeldman$elm_css$Html$Styled$node('em');
var $author$project$Theme$Color$orange = $rtfeldman$elm_css$Css$hex('#ff7000');
var $author$project$Style$Status$warningLine = function (warningMsg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$em,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$em(0.8)),
						$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orange)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(warningMsg)
			]));
};
var $author$project$S$warningLine = $author$project$Style$Status$warningLine;
var $author$project$Page$QuoteBuilder$View$roi = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$View$en, hE: $author$project$Page$QuoteBuilder$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('rendement')
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(8)),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(6))
								]))
						]),
					_List_fromArray(
						[
							$author$project$S$talk1(translation.ek)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.b7)
						])),
					$author$project$S$warningLine(translation.bX),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$S$warningLine(translation.e3)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Page$QuoteBuilder$Update$ROIMsg,
							A2(
								$author$project$Page$ROI$roiLink,
								language,
								$author$project$Page$QuoteBuilder$Update$toQuote(model)))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Update$FarmMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilder$Update$GreenhouseMsg = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Page$QuoteBuilder$Farm$Update$SetProduct = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$checked = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('checked');
var $author$project$Page$QuoteBuilder$Entry$centered = $rtfeldman$elm_css$Html$Styled$td(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
				]))
		]));
var $author$project$Page$QuoteBuilder$Entry$iconCell = function (icon) {
	return $author$project$Page$QuoteBuilder$Entry$centered(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[icon]))
			]));
};
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Html$Styled$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $rtfeldman$elm_css$Html$Styled$Events$onCheck = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetChecked));
};
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $author$project$Page$QuoteBuilder$Entry$bareCheckView = F6(
	function (currency, icon, description, unitPrice, isAdded, onToggle) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$Entry$iconCell(icon),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[description])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$checkbox,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$checked(isAdded),
											$rtfeldman$elm_css$Html$Styled$Events$onCheck(onToggle)
										]),
									_List_Nil)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2(
										$author$project$Formatter$Number$formatAmount,
										currency,
										isAdded ? unitPrice : $author$project$Domain$Total$Amount$free))
								]))
						]))
				]));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			false,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$colspan = function (n) {
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$attribute,
		'colspan',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$QuoteBuilder$Farm$View$en = {b9: 'Core system', cF: 'For the farm', dq: 'A new client must purchase one'};
var $author$project$Theme$Icon$controller = $author$project$Theme$Icon$deviceIcon('icons/controller.png');
var $author$project$Theme$Icon$rainSensor = $author$project$Theme$Icon$deviceIcon('icons/rain.png');
var $author$project$Theme$Icon$solarSensor = $author$project$Theme$Icon$deviceIcon('icons/sun2.png');
var $author$project$Theme$Icon$thermometer = $author$project$Theme$Icon$deviceIcon('icons/thermometer.png');
var $author$project$Theme$Icon$weatherBox = $author$project$Theme$Icon$deviceIcon('packing-slip/weather-box.png');
var $author$project$Theme$Icon$windSensor = $author$project$Theme$Icon$deviceIcon('icons/wind.png');
var $author$project$Page$Product$farmProductIcon = function (product) {
	switch (product) {
		case 0:
			return $author$project$Theme$Icon$controller;
		case 1:
			return $author$project$Theme$Icon$thermometer;
		case 2:
			return $author$project$Theme$Icon$rainSensor;
		case 3:
			return $author$project$Theme$Icon$solarSensor;
		case 4:
			return $author$project$Theme$Icon$windSensor;
		default:
			return $author$project$Theme$Icon$weatherBox;
	}
};
var $author$project$Page$QuoteBuilder$Farm$View$fr = {b9: 'Système de base', cF: 'Pour toute la ferme', dq: 'Un nouveau client en a obligatoirement besoin'};
var $author$project$Page$QuoteBuilder$Farm$View$base = F4(
	function (language, client, plan, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Farm$View$en, hE: $author$project$Page$QuoteBuilder$Farm$View$fr});
		var controllerLabel = A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Page$Product$farmProductName, language, 0))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.8))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dq)
						]))
				]));
		return _List_fromArray(
			[
				A2(
				$author$project$Theme$Table$header,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.b9)
							]))
					])),
				A6(
				$author$project$Page$QuoteBuilder$Entry$bareCheckView,
				client.hl,
				$author$project$Page$Product$farmProductIcon(0),
				controllerLabel,
				A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, plan, 0),
				A2($elm$core$List$member, 0, model.ig),
				$author$project$Page$QuoteBuilder$Farm$Update$SetProduct(0))
			]);
	});
var $author$project$Page$QuoteBuilder$Update$AddGreenhouse = {$: 0};
var $author$project$Style$Buttons$addButton = A2(
	$author$project$Style$Buttons$faButton,
	'plus',
	$elm$core$Maybe$Just($author$project$Theme$Color$green));
var $author$project$S$addButton = $author$project$Style$Buttons$addButton;
var $author$project$Page$QuoteBuilder$View$greenhouseAddition = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Update$AddGreenhouse)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.dr),
								A2($author$project$S$addButton, '', _List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$QuoteBuilder$View$tableHeader = function (translation) {
	var centeredTitle = F2(
		function (widthInEm, str) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$em(widthInEm)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(200)))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(str)
					]));
		});
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(1.1))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(200)))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.ig)
					])),
				A2(centeredTitle, 7, translation.ih),
				A2(centeredTitle, 7, translation.$8)
			]));
};
var $author$project$Page$QuoteBuilder$Farm$Update$CustomFieldMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Utils$Style$inactiveIf = function (condition) {
	return A2(
		$author$project$Utils$Style$styleIf,
		condition,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$opacity(
				$rtfeldman$elm_css$Css$num(0.6)),
				A2($rtfeldman$elm_css$Css$property, 'filter', 'grayscale(100)')
			]));
};
var $author$project$Page$QuoteBuilder$Entry$toggleView = F7(
	function (currency, icon, productName, unitPrice, isAdded, onToggle, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			attrs,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$Entry$iconCell(icon),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(productName)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$checkbox,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$checked(isAdded),
											$rtfeldman$elm_css$Html$Styled$Events$onCheck(onToggle)
										]),
									_List_Nil)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2(
										$author$project$Formatter$Number$formatAmount,
										currency,
										isAdded ? unitPrice : $author$project$Domain$Total$Amount$free))
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$FormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilder$CustomField$Update$Remove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilder$CustomField$Update$StartEdition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Theme$Icon$custom = $author$project$Theme$Icon$deviceIcon('icons/custom.png');
var $author$project$Domain$Quote$CustomProduct$en = {b$: 'HAF', cA: 'Ventilation fan', cN: 'Heat pipe', cO: 'Heater', dk: 'Mister', ds: 'None', dy: 'Opening', ey: 'Tensiometer', eC: 'Thermal screen', eR: 'Valve'};
var $author$project$Domain$Quote$CustomProduct$fr = {b$: 'HAF', cA: 'Pression +/-', cN: 'Tuyau chauffant', cO: 'Fournaise', dk: 'Brumisateur', ds: 'Aucun', dy: 'Ouvrant', ey: 'Tensiomètre', eC: 'Toile thermique', eR: 'Valve'};
var $author$project$Domain$Quote$CustomProduct$deviceName = F2(
	function (language, device) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Domain$Quote$CustomProduct$en, hE: $author$project$Domain$Quote$CustomProduct$fr});
		switch (device) {
			case 0:
				return translation.ds;
			case 1:
				return translation.ey;
			case 2:
				return translation.cO;
			case 3:
				return translation.dy;
			case 4:
				return translation.cA;
			case 5:
				return translation.b$;
			case 6:
				return translation.eR;
			case 7:
				return translation.dk;
			case 8:
				return translation.eC;
			default:
				return translation.cN;
		}
	});
var $author$project$Theme$Color$yellow = $rtfeldman$elm_css$Css$hex('#ffa319');
var $author$project$Style$Buttons$editButton = A2(
	$author$project$Style$Buttons$faButton,
	'pencil',
	$elm$core$Maybe$Just($author$project$Theme$Color$yellow));
var $author$project$S$editButton = $author$project$Style$Buttons$editButton;
var $author$project$Style$Buttons$removeButton = A2(
	$author$project$Style$Buttons$faButton,
	'trash-o',
	$elm$core$Maybe$Just($author$project$Theme$Color$red));
var $author$project$S$removeButton = $author$project$Style$Buttons$removeButton;
var $author$project$Page$QuoteBuilder$CustomField$View$editedView = F7(
	function (currency, language, index, name, device, price, quantity) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$marginLeft(
												$rtfeldman$elm_css$Css$em(-0.15)))
										]))
								]),
							_List_fromArray(
								[$author$project$Theme$Icon$custom]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(name)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$em(0.8))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2($author$project$Domain$Quote$CustomProduct$deviceName, language, device))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$editButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilder$CustomField$Update$StartEdition(index))
										])),
									A2(
									$author$project$S$removeButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilder$CustomField$Update$Remove(index))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2(
										$author$project$Formatter$Number$formatAmount,
										currency,
										A2($author$project$Domain$Total$Amount$multiplyBy, quantity, price)))
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$CancelEdition = {$: 2};
var $author$project$Page$QuoteBuilder$CustomField$Update$ConfirmEdition = {$: 1};
var $author$project$Style$Buttons$cancelButton = A2(
	$author$project$Style$Buttons$faButton,
	'times',
	$elm$core$Maybe$Just($author$project$Theme$Color$red));
var $author$project$S$cancelButton = $author$project$Style$Buttons$cancelButton;
var $author$project$Style$Buttons$okButton = A2(
	$author$project$Style$Buttons$faButton,
	'check',
	$elm$core$Maybe$Just($author$project$Theme$Color$green));
var $author$project$S$okButton = $author$project$Style$Buttons$okButton;
var $author$project$Page$QuoteBuilder$CustomField$View$editionButtons = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
				]))
		]),
	_List_fromArray(
		[
			A2(
			$author$project$S$okButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1))),
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$em(1))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$CustomField$Update$ConfirmEdition)
				])),
			A2(
			$author$project$S$cancelButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$CustomField$Update$CancelEdition)
				]))
		]));
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$Amount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$Name = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$Quantity = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$attribute = $rtfeldman$elm_css$VirtualDom$Styled$attribute;
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$DeviceSelection = function (a) {
	return {$: 3, a: a};
};
var $author$project$Domain$Quote$CustomProduct$CirculationFan = 5;
var $author$project$Domain$Quote$CustomProduct$HeatPipe = 9;
var $author$project$Domain$Quote$CustomProduct$ThermalScreen = 8;
var $author$project$Domain$Quote$CustomProduct$allDevices = _List_fromArray(
	[0, 1, 2, 9, 3, 8, 4, 5, 6, 7]);
var $author$project$Page$QuoteBuilder$CustomField$Form$View$deviceSelectionView = F3(
	function (language, translation, initialDevice) {
		return A2(
			$author$project$S$labeled,
			translation.hr,
			_List_fromArray(
				[
					A5(
					$author$project$S$select,
					initialDevice,
					$author$project$Domain$Quote$CustomProduct$allDevices,
					$author$project$Domain$Quote$CustomProduct$deviceName(language),
					$author$project$Page$QuoteBuilder$CustomField$Form$Update$DeviceSelection,
					_List_Nil)
				]));
	});
var $author$project$Style$Grid$displayGrid = A2($rtfeldman$elm_css$Css$property, 'display', 'grid');
var $author$project$Page$QuoteBuilder$CustomField$Form$View$en = {hr: 'App Device', $8: 'Price ($)', ih: 'Quantity', d$: 'Relays needed'};
var $author$project$Page$QuoteBuilder$CustomField$Form$View$fr = {hr: 'Appareil dans l\'app', $8: 'Prix ($)', ih: 'Quantité', d$: 'Relais nécessaires'};
var $author$project$Style$Grid$gridTemplateColumns = $rtfeldman$elm_css$Css$property('grid-template-columns');
var $author$project$Style$Form$control = $rtfeldman$elm_css$Html$Styled$div(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$control),
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit)
				]))
		]));
var $author$project$Style$Bulma$input = 'input';
var $author$project$Style$Form$input = F2(
	function (attrs, children) {
		return $author$project$Style$Form$control(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$input,
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$input),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit)
										]))
								]),
								attrs
							])),
					children)
				]));
	});
var $author$project$S$input = $author$project$Style$Form$input;
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$Relay = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$QuoteBuilder$CustomField$Form$View$relaySelectionView = F3(
	function (customProduct, title, isDisplayed) {
		return isDisplayed ? A2(
			$author$project$S$labeled,
			title,
			_List_fromArray(
				[
					A2(
					$author$project$S$input,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$vw(2.5))
								])),
							$rtfeldman$elm_css$Html$Styled$Attributes$value(
							$elm$core$String$fromInt(customProduct.il)),
							$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$CustomField$Form$Update$Relay)
						]),
					_List_Nil)
				])) : $author$project$S$empty;
	});
var $author$project$Page$QuoteBuilder$CustomField$Form$View$view = F4(
	function (language, isInAGreenhouse, currency, customProduct) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$CustomField$Form$View$en, hE: $author$project$Page$QuoteBuilder$CustomField$Form$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridTemplateColumns('2fr 1fr'),
							$author$project$Utils$Style$gap(
							$rtfeldman$elm_css$Css$rem(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					'Description',
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(20))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(customProduct.fk),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'data-autofocus', ''),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$CustomField$Form$Update$Name)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.ih,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(7.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$elm$core$String$fromInt(customProduct.ih)),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$CustomField$Form$Update$Quantity)
								]),
							_List_Nil)
						])),
					A3($author$project$Page$QuoteBuilder$CustomField$Form$View$deviceSelectionView, language, translation, customProduct.hr),
					A3($author$project$Page$QuoteBuilder$CustomField$Form$View$relaySelectionView, customProduct, translation.d$, isInAGreenhouse),
					A2(
					$author$project$S$labeled,
					translation.$8,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(7.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									A2($author$project$Formatter$Number$formatAmount, currency, customProduct.$8)),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$CustomField$Form$Update$Amount)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$CustomField$View$editionView = F4(
	function (language, isInAGreenhouse, currency, model) {
		var _v0 = model.K;
		if (!_v0.$) {
			var customProduct = _v0.b;
			return _List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$displayFlex,
													$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
													$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
													$rtfeldman$elm_css$Css$padding(
													$rtfeldman$elm_css$Css$em(0.5))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Page$QuoteBuilder$CustomField$Update$FormMsg,
											A4($author$project$Page$QuoteBuilder$CustomField$Form$View$view, language, isInAGreenhouse, currency, customProduct)),
											$author$project$Page$QuoteBuilder$CustomField$View$editionButtons
										]))
								]))
						]))
				]);
		} else {
			return A2(
				$elm$core$List$indexedMap,
				function (index) {
					return function (c) {
						return A7($author$project$Page$QuoteBuilder$CustomField$View$editedView, currency, language, index, c.fk, c.hr, c.$8, c.ih);
					};
				},
				model.hn);
		}
	});
var $author$project$Page$QuoteBuilder$CustomField$View$en = {hm: 'Custom Devices'};
var $author$project$Page$QuoteBuilder$CustomField$View$fr = {hm: 'Équipement sur mesure'};
var $author$project$Page$QuoteBuilder$CustomField$Update$Add = {$: 4};
var $rtfeldman$elm_css$Css$paddingLeft = $rtfeldman$elm_css$Css$prop1('padding-left');
var $author$project$Theme$Color$sectionGreen = A2(
	$author$project$Theme$Color$withOpacity,
	0.2,
	$rtfeldman$elm_css$Css$hex('#25b14e'));
var $author$project$Theme$Table$subHeaderStyle = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionGreen),
			$author$project$S$exactPrint
		]));
var $author$project$Theme$Table$subHeader = function (content) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$author$project$Theme$Table$subHeaderStyle]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$paddingLeft(
									$rtfeldman$elm_css$Css$em(1.2))),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(200))
							]))
					]),
				content)
			]));
};
var $author$project$Page$QuoteBuilder$CustomField$View$section = function (translation) {
	return _List_fromArray(
		[
			$author$project$Theme$Table$subHeader(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.hm)
								])),
							A2(
							$author$project$S$addButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(
												$rtfeldman$elm_css$Css$em(0.9))),
											$rtfeldman$elm_css$Css$marginRight(
											$rtfeldman$elm_css$Css$rem(0.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$CustomField$Update$Add)
								]))
						]))
				]))
		]);
};
var $author$project$Page$QuoteBuilder$CustomField$View$view = F5(
	function (language, user, isInAGreenhouse, currency, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$CustomField$View$en, hE: $author$project$Page$QuoteBuilder$CustomField$View$fr});
		return $author$project$Domain$User$isOrisha(user) ? $elm$core$List$concat(
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$CustomField$View$section(translation),
					A4($author$project$Page$QuoteBuilder$CustomField$View$editionView, language, isInAGreenhouse, currency, model)
				])) : _List_fromArray(
			[$author$project$S$empty]);
	});
var $author$project$Page$QuoteBuilder$Farm$View$view = F6(
	function (language, user, client, plan, mode, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Farm$View$en, hE: $author$project$Page$QuoteBuilder$Farm$View$fr});
		var toggleView = function (product) {
			return A7(
				$author$project$Page$QuoteBuilder$Entry$toggleView,
				client.hl,
				$author$project$Page$Product$farmProductIcon(product),
				A2($author$project$Page$Product$farmProductName, language, product),
				A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, plan, product),
				A2($elm$core$List$member, product, model.ig),
				$author$project$Page$QuoteBuilder$Farm$Update$SetProduct(product),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$author$project$Utils$Style$inactiveIf(
								!A2($author$project$Domain$Quote$Mode$isFarmProductIncluded, mode, product))
							]))
					]));
		};
		var isInAGreenhouse = false;
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$author$project$Theme$Table$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.cF)
									]))
							])),
						toggleView(1),
						toggleView(2),
						toggleView(4),
						toggleView(3),
						$author$project$Domain$User$isOrisha(user) ? toggleView(5) : $author$project$S$empty
					]),
					A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Farm$Update$CustomFieldMsg),
					A5($author$project$Page$QuoteBuilder$CustomField$View$view, language, user, isInAGreenhouse, client.hl, model.aR))
				]));
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Update$ActuatorMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$AddProduct = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$CustomFieldMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$RemoveProduct = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$ToggleRoofInverter = {$: 3};
var $author$project$DocumentLinks$compatibleDevices = function (language) {
	if (!language) {
		return 'https://docs.google.com/document/d/1LTpszRhORX5-YYQ63yspOr7c7eFGG84yzU2DJM7datk';
	} else {
		return 'https://docs.google.com/document/d/1lan_ZAc8qDR7ZbVR_7IaSuOqL6amGBGEN3lah4sEW0M';
	}
};
var $author$project$Page$QuoteBuilder$Greenhouse$View$en = {cl: 'Details on devices compatibility', cm: 'Devices', eh: 'Sensors'};
var $author$project$Page$QuoteBuilder$Greenhouse$View$fr = {cl: 'Précisions sur les appareils compatibles', cm: 'Appareils à automatiser', eh: 'Capteurs'};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$Clone = {$: 9};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$Remove = function (a) {
	return {$: 8, a: a};
};
var $author$project$Style$Buttons$cloneButton = A2(
	$author$project$Style$Buttons$faButton,
	'clone',
	$elm$core$Maybe$Just($author$project$Theme$Color$green));
var $author$project$S$cloneButton = $author$project$Style$Buttons$cloneButton;
var $author$project$Page$QuoteBuilder$Greenhouse$Update$CancelNameEdition = {$: 7};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$ConfirmNameEdition = {$: 6};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$Name = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Utils$Events$onEnter = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2(
			$elm$json$Json$Decode$andThen,
			function (code) {
				return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('keyDown');
			},
			$rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $author$project$Page$QuoteBuilder$Greenhouse$View$nameEditionView = function (initial) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$author$project$S$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$value(initial),
						$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Greenhouse$Update$Name),
						$author$project$Utils$Events$onEnter($author$project$Page$QuoteBuilder$Greenhouse$Update$ConfirmNameEdition)
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$S$okButton,
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$em(1))),
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$em(1))
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Greenhouse$Update$ConfirmNameEdition)
							])),
						A2(
						$author$project$S$cancelButton,
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$em(1)))
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Greenhouse$Update$CancelNameEdition)
							]))
					]))
			]));
};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$StartNameEdition = {$: 4};
var $author$project$Page$QuoteBuilder$Greenhouse$View$nameView = function (greenhouseName) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(greenhouseName),
				A2(
				$author$project$S$editButton,
				'',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(0.8))),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(1))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Greenhouse$Update$StartNameEdition)
					]))
			]));
};
var $author$project$Page$QuoteBuilder$Greenhouse$View$greenhouseHeader = F2(
	function (language, model) {
		var nameDisplay = function () {
			var _v0 = model.fk;
			switch (_v0.$) {
				case 0:
					var input = _v0.a.fh;
					return $author$project$Page$QuoteBuilder$Greenhouse$View$nameEditionView(input);
				case 1:
					var greenhouseName = _v0.a;
					return $author$project$Page$QuoteBuilder$Greenhouse$View$nameView(greenhouseName);
				default:
					return $author$project$Page$QuoteBuilder$Greenhouse$View$nameView(
						A2($author$project$Page$QuoteBuilder$Greenhouse$Update$defaultName, language, model));
			}
		}();
		return A2(
			$author$project$Theme$Table$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
										]))
								]),
							_List_fromArray(
								[
									nameDisplay,
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$S$cloneButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.1))),
															$rtfeldman$elm_css$Css$marginRight(
															$rtfeldman$elm_css$Css$rem(1))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Greenhouse$Update$Clone)
												])),
											A2(
											$author$project$S$removeButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.1)))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick(
													$author$project$Page$QuoteBuilder$Greenhouse$Update$Remove(model.cY))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Theme$Icon$backUpThermostat = $author$project$Theme$Icon$deviceIcon('icons/backup-thermostat.svg');
var $author$project$Theme$Icon$co2Sensor = $author$project$Theme$Icon$deviceIcon('icons/co2level.png');
var $author$project$Theme$Icon$dryWetSensor = $author$project$Theme$Icon$deviceIcon('icons/dry-wet.svg');
var $author$project$Theme$Icon$fanWithLouvers = $author$project$Theme$Icon$deviceIcon('icons/louvers.svg');
var $author$project$Theme$Icon$motor = $author$project$Theme$Icon$deviceIcon('icons/motor.png');
var $author$project$Theme$Icon$sideCurtain = $author$project$Theme$Icon$deviceIcon('icons/side-curtain.png');
var $author$project$Page$Product$greenhouseProductIcon = function (product) {
	switch (product) {
		case 0:
			return $author$project$Theme$Icon$thermometer;
		case 1:
			return $author$project$Theme$Icon$dryWetSensor;
		case 2:
			return $author$project$Theme$Icon$thermometer;
		case 3:
			return $author$project$Theme$Icon$co2Sensor;
		case 4:
			return $author$project$Theme$Icon$tensiometer;
		case 5:
			return $author$project$Theme$Icon$backUpThermostat;
		case 6:
			return $author$project$Theme$Icon$heater;
		case 7:
			return $author$project$Theme$Icon$heater;
		case 8:
			return $author$project$Theme$Icon$roof;
		case 9:
			return $author$project$Theme$Icon$sideCurtain;
		case 10:
			return $author$project$Theme$Icon$roof;
		case 11:
			return $author$project$Theme$Icon$fan;
		case 12:
			return $author$project$Theme$Icon$fanWithLouvers;
		case 13:
			return $author$project$Theme$Icon$circulationFan;
		case 14:
			return $author$project$Theme$Icon$valve;
		case 15:
			return $author$project$Theme$Icon$mister;
		case 16:
			return $author$project$Theme$Icon$motor;
		case 17:
			return $author$project$Theme$Icon$thermalScreen;
		default:
			return $author$project$Theme$Icon$heatPipe;
	}
};
var $author$project$Domain$Product$isAGreenhouseSensor = function (product) {
	switch (product) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		case 3:
			return true;
		case 4:
			return true;
		case 5:
			return true;
		case 6:
			return false;
		case 7:
			return false;
		case 8:
			return false;
		case 9:
			return false;
		case 10:
			return false;
		case 11:
			return false;
		case 12:
			return false;
		case 13:
			return false;
		case 14:
			return false;
		case 15:
			return false;
		case 16:
			return false;
		case 17:
			return false;
		default:
			return false;
	}
};
var $author$project$Domain$User$isUserSellingOutsideOfCanada = function (user) {
	var _v0 = user.hG;
	switch (_v0) {
		case 0:
			return true;
		case 4:
			return true;
		case 7:
			return true;
		default:
			return false;
	}
};
var $rtfeldman$elm_css$Css$margin2 = $rtfeldman$elm_css$Css$prop2('margin');
var $author$project$Style$Buttons$minusButton = A2(
	$author$project$Style$Buttons$faButton,
	'minus',
	$elm$core$Maybe$Just($author$project$Theme$Color$red));
var $author$project$S$minusButton = $author$project$Style$Buttons$minusButton;
var $author$project$S$noStyle = A2($rtfeldman$elm_css$Css$property, '', '');
var $author$project$Page$QuoteBuilder$Entry$view = F8(
	function (currency, icon, productName, price, quantity, onRemove, onAdd, attrs) {
		var buttonSize = $rtfeldman$elm_css$Css$em(0.9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			attrs,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$Entry$iconCell(icon),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(productName)
						])),
					$author$project$Page$QuoteBuilder$Entry$centered(
					_List_fromArray(
						[
							A2(
							$author$project$S$minusButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize)),
											(!quantity) ? $rtfeldman$elm_css$Css$batch(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$opacity(
														$rtfeldman$elm_css$Css$num(0)))
												])) : $author$project$S$noStyle
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onRemove)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$margin2,
											$rtfeldman$elm_css$Css$px(0),
											$rtfeldman$elm_css$Css$em(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$elm$core$String$fromInt(quantity))
								])),
							A2(
							$author$project$S$addButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onAdd)
								]))
						])),
					$author$project$Page$QuoteBuilder$Entry$centered(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Formatter$Number$formatAmount, currency, price))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Entry$normalView = F8(
	function (currency, icon, productName, unitPrice, quantity, onRemove, onAdd, attrs) {
		return A8(
			$author$project$Page$QuoteBuilder$Entry$view,
			currency,
			icon,
			productName,
			A2($author$project$Domain$Total$Amount$multiplyBy, quantity, unitPrice),
			quantity,
			onRemove,
			onAdd,
			attrs);
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $author$project$Page$QuoteBuilder$Entry$en = {hO: 'Inverter'};
var $author$project$Page$QuoteBuilder$Entry$fr = {hO: 'Inverseur'};
var $author$project$Page$QuoteBuilder$Entry$roofView = function (user) {
	return function (currency) {
		return function (icon) {
			return function (productName) {
				return function (unitPrice) {
					return function (quantity) {
						return function (onRemove) {
							return function (onAdd) {
								return function (isAdded) {
									return function (onToggle) {
										var translation = A2(
											$author$project$Language$getTranslation,
											user.hR,
											{hw: $author$project$Page$QuoteBuilder$Entry$en, hE: $author$project$Page$QuoteBuilder$Entry$fr});
										var buttonSize = $rtfeldman$elm_css$Css$em(0.9);
										return A2(
											$rtfeldman$elm_css$Html$Styled$tr,
											_List_Nil,
											_List_fromArray(
												[
													$author$project$Page$QuoteBuilder$Entry$iconCell(icon),
													A2(
													$rtfeldman$elm_css$Html$Styled$td,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(productName)
														])),
													$author$project$Page$QuoteBuilder$Entry$centered(
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$css(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Css$displayFlex,
																			$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
																		]))
																]),
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$div,
																	_List_Nil,
																	_List_fromArray(
																		[
																			A2(
																			$author$project$S$minusButton,
																			'',
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$important(
																							$rtfeldman$elm_css$Css$fontSize(buttonSize)),
																							(!quantity) ? $rtfeldman$elm_css$Css$batch(
																							_List_fromArray(
																								[
																									$rtfeldman$elm_css$Css$important(
																									$rtfeldman$elm_css$Css$opacity(
																										$rtfeldman$elm_css$Css$num(0)))
																								])) : $author$project$S$noStyle
																						])),
																					$rtfeldman$elm_css$Html$Styled$Events$onClick(onRemove)
																				])),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$span,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							A2(
																							$rtfeldman$elm_css$Css$margin2,
																							$rtfeldman$elm_css$Css$px(0),
																							$rtfeldman$elm_css$Css$em(1))
																						]))
																				]),
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text(
																					$elm$core$String$fromInt(quantity))
																				])),
																			A2(
																			$author$project$S$addButton,
																			'',
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$important(
																							$rtfeldman$elm_css$Css$fontSize(buttonSize))
																						])),
																					$rtfeldman$elm_css$Html$Styled$Events$onClick(onAdd)
																				]))
																		])),
																	$author$project$Domain$User$isOrisha(user) ? A2(
																	$rtfeldman$elm_css$Html$Styled$div,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$css(
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Css$displayFlex,
																					$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
																					$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
																				]))
																		]),
																	_List_fromArray(
																		[
																			A2(
																			$rtfeldman$elm_css$Html$Styled$div,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$fontSize(
																							$rtfeldman$elm_css$Css$em(0.7)),
																							$rtfeldman$elm_css$Css$marginRight(
																							$rtfeldman$elm_css$Css$em(0.5))
																						]))
																				]),
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$text(translation.hO)
																				])),
																			A2(
																			$rtfeldman$elm_css$Html$Styled$div,
																			_List_fromArray(
																				[
																					$rtfeldman$elm_css$Html$Styled$Attributes$css(
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Css$displayFlex,
																							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
																						]))
																				]),
																			_List_fromArray(
																				[
																					A2(
																					$author$project$S$checkbox,
																					_List_fromArray(
																						[
																							$rtfeldman$elm_css$Html$Styled$Attributes$checked(isAdded),
																							$rtfeldman$elm_css$Html$Styled$Events$onCheck(
																							$elm$core$Basics$always(onToggle))
																						]),
																					_List_Nil)
																				]))
																		])) : $author$project$S$empty
																]))
														])),
													$author$project$Page$QuoteBuilder$Entry$centered(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															A2(
																$author$project$Formatter$Number$formatAmount,
																currency,
																A2($author$project$Domain$Total$Amount$multiplyBy, quantity, unitPrice)))
														]))
												]));
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$FormMsg = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Css$hidden = {H: 0, a1: 0, gU: 'hidden', bF: 0};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editedView = F5(
	function (translation, sideCurtainQuantity, activationUnit, multibox, multiboxWithMotorDriver) {
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.it)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(sideCurtainQuantity))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$hidden))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.g$)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(activationUnit))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$hidden))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.hZ)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(multibox))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$hidden))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.hY)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(multiboxWithMotorDriver))
							]))
					]))
			]);
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$CancelEdition = {$: 1};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$ConfirmEdition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editionButtons = function (actuator) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$author$project$S$okButton,
				'',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(1))),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(1))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						$author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$ConfirmEdition(actuator))
					])),
				A2(
				$author$project$S$cancelButton,
				'',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(1)))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$CancelEdition)
					]))
			]));
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$AddActivationUnit = 0;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$AddMultiWithMotorDriver = 6;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$AddMultibox = 4;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$AddSideCurtainUnit = 2;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$RemoveActivationUnit = 1;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$RemoveMultiWithMotorDriver = 7;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$RemoveMultibox = 5;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$RemoveSideCurtain = 3;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$en = {g$: 'Activation unit', hY: 'Multibox with side curtain', hZ: 'Multibox', it: 'Side curtain unit'};
var $rtfeldman$elm_css$Css$borderWidth = $rtfeldman$elm_css$Css$prop1('border-width');
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$centered = $rtfeldman$elm_css$Html$Styled$td(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
				]))
		]));
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$productPhoto = function (path) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$img,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$src(path),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$maxWidth(
								$rtfeldman$elm_css$Css$em(2))
							]))
					]),
				_List_Nil)
			]));
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$entryView = F5(
	function (imagePath, productName, quantity, onRemove, onAdd) {
		var buttonSize = $rtfeldman$elm_css$Css$em(0.9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$borderWidth(
								$rtfeldman$elm_css$Css$px(1))),
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$hidden))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
						]),
					_List_fromArray(
						[
							$author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$productPhoto(imagePath)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(productName)
						])),
					$author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$centered(
					_List_fromArray(
						[
							A2(
							$author$project$S$minusButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize)),
											(!quantity) ? $rtfeldman$elm_css$Css$batch(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$opacity(
														$rtfeldman$elm_css$Css$num(0)))
												])) : $author$project$S$noStyle
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onRemove)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$margin2,
											$rtfeldman$elm_css$Css$px(0),
											$rtfeldman$elm_css$Css$em(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$elm$core$String$fromInt(quantity))
								])),
							A2(
							$author$project$S$addButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onAdd)
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$fr = {g$: 'Module d\'activation', hY: 'Boîtier multifonction avec côtés ouvrants', hZ: 'Boîtier multifonction', it: 'Module de côtés ouvrants'};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$view = F2(
	function (language, actuators) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$en, hE: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$fr});
		return _List_fromArray(
			[
				A5($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$entryView, 'packing-slip/multi-box-with-switches.png', translation.it, actuators.it, 3, 2),
				A5($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$entryView, 'packing-slip/multi-box-no-switches.jpg', translation.g$, actuators.g$, 1, 0),
				A5($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$entryView, 'packing-slip/multi-box-with-switches.png', translation.hY, actuators.hY, 7, 6),
				A5($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$entryView, 'packing-slip/multi-box-no-switches.jpg', translation.hZ, actuators.hZ, 5, 4)
			]);
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editionView = F3(
	function (language, translation, model) {
		var _v0 = model.K;
		switch (_v0) {
			case 0:
				return _List_Nil;
			case 1:
				return _List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$displayFlex,
														$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
														$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
													]))
											]),
										A2(
											$elm$core$Maybe$withDefault,
											_List_fromArray(
												[$author$project$S$empty]),
											A2(
												$elm$core$Maybe$map,
												function (m) {
													return _List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$css(
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
																		]))
																]),
															A2(
																$elm$core$List$map,
																$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$FormMsg),
																A2($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$View$view, language, m))),
															$author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editionButtons(m)
														]);
												},
												model.ae)))
									]))
							]))
					]);
			default:
				var _v1 = model.ae;
				if (!_v1.$) {
					var m = _v1.a;
					return A5($author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editedView, translation, m.it, m.g$, m.hZ, m.hY);
				} else {
					return _List_Nil;
				}
		}
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$en = {g$: 'Activation Unit', b4: 'Actuators', hY: 'Multibox with side curtain', hZ: 'Multibox', it: 'Side curtain unit'};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$fr = {g$: 'Module d\'activation', b4: 'Actuateurs', hY: 'Boîtier multifonction avec côtés ouvrants', hZ: 'Boîtier multifonction', it: 'Module de côtés ouvrants'};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$ManualSelection = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$section = F3(
	function (translation, actuator, greenhouse) {
		var defaultSimpleMulti = A2($author$project$Domain$Common$numberOfMultiboxes, actuator, greenhouse);
		var defaultSideCurtainUnit = A2($author$project$Domain$Common$numberOfSideCurtainUnits, actuator, greenhouse);
		var defaultMultiWithMotorDriver = A2($author$project$Domain$Common$numberOfMultiWithMotorDrivers, actuator, greenhouse);
		var defaultActivationUnit = A2($author$project$Domain$Common$numberOfActivationUnits, actuator, greenhouse);
		return _List_fromArray(
			[
				$author$project$Theme$Table$subHeader(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.b4)
									])),
								A2(
								$author$project$S$editButton,
								'',
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$important(
												$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$em(0.95))),
												$rtfeldman$elm_css$Css$marginRight(
												$rtfeldman$elm_css$Css$rem(0.5))
											])),
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										A4($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$ManualSelection, defaultActivationUnit, defaultSideCurtainUnit, defaultSimpleMulti, defaultMultiWithMotorDriver))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$view = F5(
	function (language, user, model, actuator, greenhouse) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$en, hE: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$fr});
		return $author$project$Domain$User$isOrisha(user) ? $elm$core$List$concat(
			_List_fromArray(
				[
					A3($author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$section, translation, actuator, greenhouse),
					A3($author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$editionView, language, translation, model)
				])) : _List_fromArray(
			[$author$project$S$empty]);
	});
var $author$project$Page$QuoteBuilder$Greenhouse$View$view = F7(
	function (language, user, client, plan, mode, actuator, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Greenhouse$View$en, hE: $author$project$Page$QuoteBuilder$Greenhouse$View$fr});
		var isInAGreenhouse = true;
		var currency = client.hl;
		var normalView = function (product) {
			return A8(
				$author$project$Page$QuoteBuilder$Entry$normalView,
				currency,
				$author$project$Page$Product$greenhouseProductIcon(product),
				A2($author$project$Page$Product$greenhouseProductName, language, product),
				A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, plan, product),
				A2(
					$elm_community$list_extra$List$Extra$count,
					$elm$core$Basics$eq(product),
					model.ig),
				$author$project$Page$QuoteBuilder$Greenhouse$Update$RemoveProduct(product),
				$author$project$Page$QuoteBuilder$Greenhouse$Update$AddProduct(product),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$author$project$Utils$Style$inactiveIf(
								!A2($author$project$Domain$Quote$Mode$isGreenhouseProductIncluded, mode, product))
							]))
					]));
		};
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2($author$project$Page$QuoteBuilder$Greenhouse$View$greenhouseHeader, client.hR, model),
						$author$project$Theme$Table$subHeader(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.eh)
							]))
					]),
					A2(
					$elm$core$List$map,
					normalView,
					A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseSensor, $author$project$Domain$Product$allGreenhouseProducts)),
					_List_fromArray(
					[
						$author$project$Theme$Table$subHeader(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.cm),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$rem(0.9)),
														$rtfeldman$elm_css$Css$fontWeight(
														$rtfeldman$elm_css$Css$int(400))
													])),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
												$rtfeldman$elm_css$Html$Styled$Attributes$href(
												$author$project$DocumentLinks$compatibleDevices(language))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.cl)
											]))
									]))
							])),
						normalView(6),
						A2($elm$core$List$member, 6, model.ig) ? normalView(7) : $author$project$S$empty,
						$author$project$Page$QuoteBuilder$Entry$roofView(user)(currency)(
						$author$project$Page$Product$greenhouseProductIcon(8))(
						A2($author$project$Page$Product$greenhouseProductName, language, 8))(
						A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, plan, 8))(
						A2(
							$elm_community$list_extra$List$Extra$count,
							$elm$core$Basics$eq(8),
							model.ig))(
						$author$project$Page$QuoteBuilder$Greenhouse$Update$RemoveProduct(8))(
						$author$project$Page$QuoteBuilder$Greenhouse$Update$AddProduct(8))(model.d8)($author$project$Page$QuoteBuilder$Greenhouse$Update$ToggleRoofInverter),
						normalView(9),
						$author$project$Domain$User$isUserSellingOutsideOfCanada(user) ? normalView(10) : $author$project$S$empty,
						$author$project$Domain$User$isOrisha(user) ? normalView(16) : $author$project$S$empty,
						normalView(11),
						normalView(12),
						normalView(13),
						normalView(14),
						normalView(15),
						normalView(17),
						normalView(18)
					]),
					A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Greenhouse$Update$CustomFieldMsg),
					A5($author$project$Page$QuoteBuilder$CustomField$View$view, language, user, isInAGreenhouse, currency, model.aR)),
					A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Greenhouse$Update$ActuatorMsg),
					A5(
						$author$project$Page$QuoteBuilder$Greenhouse$Actuator$View$view,
						language,
						user,
						model.aM,
						actuator,
						A3($author$project$Page$QuoteBuilder$Greenhouse$Update$toQuote, client.hR, mode, model)))
				]));
	});
var $author$project$Page$QuoteBuilder$View$tableView = F4(
	function (language, translation, model, user) {
		return A2(
			$author$project$S$table,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(5))
						]))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							$author$project$Page$QuoteBuilder$View$tableHeader(translation)
						]),
						A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Update$FarmMsg),
						A4($author$project$Page$QuoteBuilder$Farm$View$base, language, model.hg, model.ab, model.fd)),
						$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (index) {
								return $elm$core$List$map(
									$rtfeldman$elm_css$Html$Styled$map(
										$author$project$Page$QuoteBuilder$Update$GreenhouseMsg(index)));
							},
							A2(
								$elm$core$List$map,
								A6($author$project$Page$QuoteBuilder$Greenhouse$View$view, language, user, model.hg, model.ab, model.hX, model.ae),
								model.aX))),
						_List_fromArray(
						[
							$author$project$Page$QuoteBuilder$View$greenhouseAddition(translation)
						]),
						A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilder$Update$FarmMsg),
						A6($author$project$Page$QuoteBuilder$Farm$View$view, language, user, model.hg, model.ab, model.hX, model.fd))
					])));
	});
var $author$project$Page$QuoteBuilder$Client$Address = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilder$Client$FarmName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilder$Client$FirstName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilder$Client$LastName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Style$Grid$columnGap = function (length) {
	return A2($rtfeldman$elm_css$Css$property, 'column-gap', length.gU);
};
var $author$project$Page$QuoteBuilder$Client$CurrencySelection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Domain$Total$Amount$currencyToString = function (currency) {
	if (!currency) {
		return 'CAD';
	} else {
		return 'USD';
	}
};
var $author$project$Page$QuoteBuilder$Client$currencyView = F2(
	function (translation, model) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(50))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.dT,
					_List_fromArray(
						[
							A5(
							$author$project$S$select,
							model.hl,
							_List_fromArray(
								[0, 1]),
							$author$project$Domain$Total$Amount$currencyToString,
							$author$project$Page$QuoteBuilder$Client$CurrencySelection,
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Client$en = {g1: 'Address', hw: 'English', hD: 'Farm name', fZ: 'First name', hE: 'French', gh: 'Last name', dT: 'Quote Currency', dV: 'Quote language'};
var $author$project$Page$QuoteBuilder$Client$fr = {g1: 'Adresse', hw: 'Anglais', hD: 'Nom de la ferme', fZ: 'Prénom', hE: 'Français', gh: 'Nom de famille', dT: 'Devise du devis', dV: 'Langue du devis'};
var $author$project$Page$QuoteBuilder$Client$LanguageSelection = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$QuoteBuilder$Client$languageToString = F2(
	function (translation, language) {
		if (!language) {
			return translation.hw;
		} else {
			return translation.hE;
		}
	});
var $author$project$Page$QuoteBuilder$Client$languageSelectionView = F2(
	function (translation, model) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(50))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.dV,
					_List_fromArray(
						[
							A5(
							$author$project$S$select,
							model.hR,
							_List_fromArray(
								[1, 0]),
							$author$project$Page$QuoteBuilder$Client$languageToString(translation),
							$author$project$Page$QuoteBuilder$Client$LanguageSelection,
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Client$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Client$en, hE: $author$project$Page$QuoteBuilder$Client$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridTemplateColumns('1fr 1fr'),
							$author$project$Style$Grid$columnGap(
							$rtfeldman$elm_css$Css$rem(3)),
							$author$project$Style$Grid$rowGap(
							$rtfeldman$elm_css$Css$rem(2)),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(4))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.fZ,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.fZ),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Client$FirstName)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.gh,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.gh),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Client$LastName)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.hD,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hD),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Client$FarmName)
								]),
							_List_Nil)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$Page$QuoteBuilder$Client$languageSelectionView, translation, model),
							A2($author$project$Page$QuoteBuilder$Client$currencyView, translation, model)
						])),
					A2(
					$author$project$S$labeled,
					translation.g1,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.g1),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Client$Address)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Discount$Update$Add = {$: 4};
var $author$project$Page$QuoteBuilder$Discount$Update$FormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilder$Discount$Update$Remove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilder$Discount$Update$StartEdition = function (a) {
	return {$: 0, a: a};
};
var $elm_community$list_extra$List$Extra$takeWhile = function (predicate) {
	var takeWhileMemo = F2(
		function (memo, list) {
			takeWhileMemo:
			while (true) {
				if (!list.b) {
					return $elm$core$List$reverse(memo);
				} else {
					var x = list.a;
					var xs = list.b;
					if (predicate(x)) {
						var $temp$memo = A2($elm$core$List$cons, x, memo),
							$temp$list = xs;
						memo = $temp$memo;
						list = $temp$list;
						continue takeWhileMemo;
					} else {
						return $elm$core$List$reverse(memo);
					}
				}
			}
		});
	return takeWhileMemo(_List_Nil);
};
var $author$project$Domain$Total$Discount$discountAmount = F2(
	function (quote, discount) {
		var grossTotal = $author$project$Domain$Total$Gross$fromQuote(quote);
		var dummy = 1;
		var discountList = A2($author$project$Domain$Discount$Listing$toDiscountList, dummy, quote);
		var totalBeforeDiscount = A3(
			$elm$core$List$foldl,
			$author$project$Domain$Total$Discount$applyDiscount,
			grossTotal,
			A2(
				$elm_community$list_extra$List$Extra$takeWhile,
				function (d) {
					return !A2($author$project$Domain$Discount$Custom$compare, d, discount);
				},
				discountList));
		var totalAfterDiscount = A2($author$project$Domain$Total$Discount$applyDiscount, discount, totalBeforeDiscount);
		return A2($author$project$Domain$Total$Amount$substract, totalBeforeDiscount, totalAfterDiscount);
	});
var $author$project$Page$QuoteBuilder$Discount$View$discountAmountText = F2(
	function (quote, discount) {
		return $rtfeldman$elm_css$Html$Styled$text(
			A2(
				$author$project$Formatter$Number$formatAmount,
				quote.hg.hl,
				$author$project$Domain$Total$Amount$negate(
					A2($author$project$Domain$Total$Discount$discountAmount, quote, discount))));
	});
var $author$project$Page$QuoteBuilder$Discount$View$amountData = F2(
	function (quote, discount) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$td,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$Page$QuoteBuilder$Discount$View$discountAmountText, quote, discount)
						]))
				]));
	});
var $author$project$Theme$Icon$discountTag = A2($author$project$Theme$Icon$faIcon, 'tag', $author$project$Theme$Color$red);
var $author$project$Page$QuoteBuilder$Discount$View$iconData = A2(
	$rtfeldman$elm_css$Html$Styled$td,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(4)),
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
				]))
		]),
	_List_fromArray(
		[$author$project$Theme$Icon$discountTag]));
var $author$project$Page$QuoteBuilder$Discount$View$editedView = F3(
	function (quote, index, discount) {
		var name = function () {
			var _v0 = discount.fb;
			if (!_v0.$) {
				var p = _v0.a;
				return $elm$core$String$fromInt(p) + ('% - ' + discount.fk);
			} else {
				return discount.fk;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$Discount$View$iconData,
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$editButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilder$Discount$Update$StartEdition(index))
										])),
									A2(
									$author$project$S$removeButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilder$Discount$Update$Remove(index))
										]))
								]))
						])),
					A2($author$project$Page$QuoteBuilder$Discount$View$amountData, quote, discount)
				]));
	});
var $author$project$Page$QuoteBuilder$Discount$Update$CancelEdition = {$: 2};
var $author$project$Page$QuoteBuilder$Discount$Update$ConfirmEdition = {$: 1};
var $author$project$Page$QuoteBuilder$Discount$View$editionButtons = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$author$project$Utils$Style$gap(
					$rtfeldman$elm_css$Css$rem(1))
				]))
		]),
	_List_fromArray(
		[
			A2(
			$author$project$S$okButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Discount$Update$ConfirmEdition)
				])),
			A2(
			$author$project$S$cancelButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Discount$Update$CancelEdition)
				]))
		]));
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $author$project$Page$QuoteBuilder$Discount$Form$Update$Amount = 0;
var $author$project$Page$QuoteBuilder$Discount$Form$Update$AmountOrPercentage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilder$Discount$Form$Update$Name = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilder$Discount$Form$Update$Percentage = 1;
var $author$project$Page$QuoteBuilder$Discount$Form$Update$SetDiscountType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilder$Discount$Form$View$discountTypeToString = function (discountType) {
	if (!discountType) {
		return '$';
	} else {
		return '%';
	}
};
var $author$project$Page$QuoteBuilder$Discount$Form$View$en = {co: 'Discount'};
var $rtfeldman$elm_css$Html$Styled$form = $rtfeldman$elm_css$Html$Styled$node('form');
var $author$project$Page$QuoteBuilder$Discount$Form$View$fr = {co: 'Rabais'};
var $author$project$Page$QuoteBuilder$Discount$Form$View$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Discount$Form$View$en, hE: $author$project$Page$QuoteBuilder$Discount$Form$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
							$author$project$Utils$Style$gap(
							$rtfeldman$elm_css$Css$rem(2))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					'Description',
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(20))
										])),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'data-autofocus', ''),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.fk),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Discount$Form$Update$Name)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.co,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[$rtfeldman$elm_css$Css$displayFlex]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$input,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$vw(5))
												])),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bh),
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilder$Discount$Form$Update$AmountOrPercentage)
										]),
									_List_Nil),
									A5(
									$author$project$S$select,
									model.fb,
									_List_fromArray(
										[1, 0]),
									$author$project$Page$QuoteBuilder$Discount$Form$View$discountTypeToString,
									$author$project$Page$QuoteBuilder$Discount$Form$Update$SetDiscountType,
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilder$Discount$View$customDiscountsView = F3(
	function (language, quote, model) {
		var editionRow = function (discount) {
			return _List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Page$QuoteBuilder$Discount$Update$FormMsg,
							A2($author$project$Page$QuoteBuilder$Discount$Form$View$view, language, discount))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[$author$project$Page$QuoteBuilder$Discount$View$editionButtons]))
				]);
		};
		var _v0 = model.K;
		if (!_v0.$) {
			var index = _v0.a;
			var discount = _v0.b;
			return function (rs) {
				return _Utils_ap(
					rs,
					editionRow(discount));
			}(
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$QuoteBuilder$Discount$View$editedView(quote),
					A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm)));
		} else {
			return A2(
				$elm$core$List$indexedMap,
				$author$project$Page$QuoteBuilder$Discount$View$editedView(quote),
				model.hm);
		}
	});
var $author$project$Page$QuoteBuilder$Discount$Update$SetPredefined = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Page$QuoteBuilder$Discount$View$predefinedDiscount = F3(
	function (language, quote, discount) {
		var noDiscount = {
			fb: $author$project$Domain$Discount$Custom$Amount($author$project$Domain$Total$Amount$free),
			fk: '',
			id: $elm$core$Maybe$Nothing
		};
		var isEnabled = A2($author$project$Domain$Discount$Listing$isEnabled, quote, discount);
		var isEligible = A2($author$project$Domain$Discount$Listing$isEligible, quote, discount);
		var custom = A3($author$project$Domain$Discount$Listing$toCustomDiscount, language, quote, discount);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Utils$Style$inactiveIf(!isEligible)
						]))
				]),
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilder$Discount$View$iconData,
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(custom.fk)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$checkbox,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$checked(isEnabled),
											$rtfeldman$elm_css$Html$Styled$Events$onCheck(
											$author$project$Page$QuoteBuilder$Discount$Update$SetPredefined(discount))
										]),
									_List_Nil)
								]))
						])),
					(isEnabled && isEligible) ? A2($author$project$Page$QuoteBuilder$Discount$View$amountData, quote, custom) : A2($author$project$Page$QuoteBuilder$Discount$View$amountData, quote, noDiscount)
				]));
	});
var $rtfeldman$elm_css$Html$Styled$tbody = $rtfeldman$elm_css$Html$Styled$node('tbody');
var $author$project$Page$QuoteBuilder$Discount$View$discounts = F4(
	function (language, user, quote, model) {
		var predefined = A2(
			$elm$core$List$map,
			A2($author$project$Page$QuoteBuilder$Discount$View$predefinedDiscount, language, quote),
			A2(
				$elm$core$List$filter,
				$author$project$Domain$Discount$Listing$isAllowed(
					$elm$core$Maybe$Just(user)),
				$author$project$Domain$Discount$Predefined$allPredefinedDiscounts));
		var edition = A3($author$project$Page$QuoteBuilder$Discount$View$customDiscountsView, quote.hg.hR, quote, model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tbody,
			_List_Nil,
			_Utils_ap(predefined, edition));
	});
var $author$project$Page$QuoteBuilder$Discount$View$en = {cn: 'Discount', dN: '% discounts are applied after $ discount'};
var $author$project$Page$QuoteBuilder$Discount$View$fr = {cn: 'Rabais', dN: 'Les rabais en % sont appliqués après les rabais en $'};
var $author$project$Theme$Table$priceColumnWidth = $rtfeldman$elm_css$Css$width(
	$rtfeldman$elm_css$Css$rem(8));
var $author$project$Page$QuoteBuilder$Discount$View$view = F4(
	function (language, quote, model, user) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$Discount$View$en, hE: $author$project$Page$QuoteBuilder$Discount$View$fr});
		return A2(
			$author$project$S$table,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$thead,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$author$project$Theme$Table$headerStyle]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cn)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$em(0.85)),
													$rtfeldman$elm_css$Css$fontWeight(
													$rtfeldman$elm_css$Css$int(300)),
													$rtfeldman$elm_css$Css$marginTop(
													$rtfeldman$elm_css$Css$px(-20)),
													$rtfeldman$elm_css$Css$marginBottom(
													$rtfeldman$elm_css$Css$px(-10))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dN)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[$author$project$Theme$Table$priceColumnWidth]))
								]),
							_List_fromArray(
								[
									$author$project$Domain$User$isOrisha(user) ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$displayFlex,
													$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(0.5))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$author$project$S$addButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$em(1.1)))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilder$Discount$Update$Add)
												]))
										])) : $author$project$S$empty
								]))
						])),
					A4($author$project$Page$QuoteBuilder$Discount$View$discounts, language, user, quote, model)
				]));
	});
var $author$project$Page$QuoteBuilder$View$view = F3(
	function (language, model, user) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilder$View$en, hE: $author$project$Page$QuoteBuilder$View$fr});
		var quote = $author$project$Page$QuoteBuilder$Update$toQuote(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$S$talk1(translation.bZ),
					$author$project$S$talk1(translation.b3),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-1.5)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text($author$project$Info$phoneNumber)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
									$author$project$Utils$Style$gap(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Page$QuoteBuilder$Update$ClientMsg,
							A2($author$project$Page$QuoteBuilder$Client$view, language, model.hg)),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$marginBottom(
															$rtfeldman$elm_css$Css$rem(1))
														]))
												]),
											_List_fromArray(
												[
													A2($author$project$Page$QuoteBuilder$View$planView, translation, model.ab)
												])),
											A2($author$project$Page$QuoteBuilder$View$modeView, translation, model.hX)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$marginBottom(
															$rtfeldman$elm_css$Css$rem(1))
														]))
												]),
											_List_fromArray(
												[
													A3($author$project$Page$QuoteBuilder$View$documentationView, translation, model.ht, user)
												])),
											A3($author$project$Page$QuoteBuilder$View$equipmentView, translation, model.ae, user)
										]))
								]))
						])),
					A4($author$project$Page$QuoteBuilder$View$tableView, language, translation, model, user),
					A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Page$QuoteBuilder$Update$DiscountMsg,
					A4($author$project$Page$QuoteBuilder$Discount$View$view, language, quote, model.I, user)),
					A2($author$project$Page$QuoteBuilder$View$roi, language, model)
				]));
	});
var $author$project$Page$QuoteBuilderV3$Preview$View$en = {cg: 'Date:', dJ: 'Prepared for:', dU: 'Quote for:', eQ: 'Valid for 30 days'};
var $author$project$Page$QuoteBuilderV3$Preview$View$fr = {cg: 'Date :', dJ: 'Préparé pour :', dU: 'Devis pour :', eQ: 'Valide 30 jours'};
var $author$project$Style$Grid$justifyItems = $rtfeldman$elm_css$Css$property('justify-items');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $rtfeldman$elm_css$Css$absolute = {bv: 0, gU: 'absolute'};
var $rtfeldman$elm_css$Css$InchUnits = 0;
var $rtfeldman$elm_css$Css$inches = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'in');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$minHeight = $rtfeldman$elm_css$Css$prop1('min-height');
var $rtfeldman$elm_css$Css$minWidth = $rtfeldman$elm_css$Css$prop1('min-width');
var $rtfeldman$elm_css$Css$pointerEvents = $rtfeldman$elm_css$Css$prop1('pointer-events');
var $rtfeldman$elm_css$Css$relative = {bv: 0, gU: 'relative'};
var $author$project$Page$QuoteBuilderV3$Preview$Common$page_ = F2(
	function (withImageBackground, content) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$inches(8.5)),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$inches(11)),
							A2(
							$author$project$Utils$Style$styleIf,
							!withImageBackground,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$white)
								])),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
							A2($rtfeldman$elm_css$Css$property, 'break-after', 'auto'),
							A2($rtfeldman$elm_css$Css$property, 'break-inside', 'avoid')
						]))
				]),
			_List_fromArray(
				[
					withImageBackground ? A2(
					$rtfeldman$elm_css$Html$Styled$img,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$src('quote/cover.jpg'),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$pointerEvents($rtfeldman$elm_css$Css$none),
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
									$rtfeldman$elm_css$Css$zIndex(
									$rtfeldman$elm_css$Css$int(-1)),
									$rtfeldman$elm_css$Css$minHeight(
									$rtfeldman$elm_css$Css$pct(100)),
									$rtfeldman$elm_css$Css$maxHeight(
									$rtfeldman$elm_css$Css$pct(100)),
									$rtfeldman$elm_css$Css$minWidth(
									$rtfeldman$elm_css$Css$pct(100)),
									$rtfeldman$elm_css$Css$maxWidth(
									$rtfeldman$elm_css$Css$pct(100))
								]))
						]),
					_List_Nil) : $author$project$S$empty,
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$pct(100)),
									A2(
									$rtfeldman$elm_css$Css$padding2,
									$rtfeldman$elm_css$Css$inches(0.9),
									$rtfeldman$elm_css$Css$inches(0.75))
								]))
						]),
					content)
				]));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Common$coverPage = $author$project$Page$QuoteBuilderV3$Preview$Common$page_(true);
var $author$project$Formatter$Date$en = {bS: 'April', bT: 'August', ci: 'December', cC: 'February', c3: 'January', c8: 'July', c9: 'June', dg: 'March', dj: 'May', dv: 'November', dx: 'October', ej: 'September'};
var $author$project$Formatter$Date$fr = {bS: 'avril', bT: 'août', ci: 'décembre', cC: 'février', c3: 'janvier', c8: 'juillet', c9: 'juin', dg: 'mars', dj: 'mai', dv: 'novembre', dx: 'octobre', ej: 'septembre'};
var $author$project$Formatter$Date$month = F2(
	function (language, m) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Formatter$Date$en, hE: $author$project$Formatter$Date$fr});
		switch (m) {
			case 0:
				return translation.c3;
			case 1:
				return translation.cC;
			case 2:
				return translation.dg;
			case 3:
				return translation.bS;
			case 4:
				return translation.dj;
			case 5:
				return translation.c9;
			case 6:
				return translation.c8;
			case 7:
				return translation.bT;
			case 8:
				return translation.ej;
			case 9:
				return translation.dx;
			case 10:
				return translation.dv;
			default:
				return translation.ci;
		}
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fr, posixMinutes) < 0) {
					return posixMinutes + era.c;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		fP: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		gi: month,
		g_: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).fP;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gi;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).g_;
	});
var $author$project$Formatter$Date$dayMonthYear = F3(
	function (language, zone, posix) {
		var formattedYear = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, posix));
		var formattedMonth = A2(
			$author$project$Formatter$Date$month,
			language,
			A2($elm$time$Time$toMonth, zone, posix));
		var formattedDay = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, posix));
		return (!language) ? (formattedMonth + (' ' + (formattedDay + (', ' + formattedYear)))) : (formattedDay + (' ' + (formattedMonth + (' ' + formattedYear))));
	});
var $rtfeldman$elm_css$Html$Styled$strong = $rtfeldman$elm_css$Html$Styled$node('strong');
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Page$QuoteBuilderV3$Quote$discountTypeEncoder = function (discountType) {
	if (!discountType.$) {
		var p = discountType.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('Percentage')),
					_Utils_Tuple2(
					'percentage',
					$elm$json$Json$Encode$int(p))
				]));
	} else {
		var amount = discountType.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'tag',
					$elm$json$Json$Encode$string('Amount')),
					_Utils_Tuple2(
					'amount',
					$elm$json$Json$Encode$int(amount))
				]));
	}
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm_community$json_extra$Json$Encode$Extra$maybe = function (encoder) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(encoder),
		$elm$core$Maybe$withDefault($elm$json$Json$Encode$null));
};
var $author$project$Page$QuoteBuilderV3$Quote$discountEncoder = function (d) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(d.fk)),
				_Utils_Tuple2(
				'discountType',
				$author$project$Page$QuoteBuilderV3$Quote$discountTypeEncoder(d.fb)),
				_Utils_Tuple2(
				'precision',
				A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, d.id))
			]));
};
var $author$project$Route$Quote$Client$currencyEncoder = function (c) {
	if (!c) {
		return $elm$json$Json$Encode$string('CAD');
	} else {
		return $elm$json$Json$Encode$string('USD');
	}
};
var $author$project$Route$Quote$Client$languageEncoder = function (language) {
	var str = function () {
		if (!language) {
			return 'EN';
		} else {
			return 'FR';
		}
	}();
	return $elm$json$Json$Encode$string(str);
};
var $author$project$Route$Quote$Client$encoder = function (c) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(c.fZ)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$string(c.gh)),
				_Utils_Tuple2(
				'farmName',
				$elm$json$Json$Encode$string(c.hD)),
				_Utils_Tuple2(
				'address',
				$elm$json$Json$Encode$string(c.g1)),
				_Utils_Tuple2(
				'language',
				$author$project$Route$Quote$Client$languageEncoder(c.hR)),
				_Utils_Tuple2(
				'currency',
				$author$project$Route$Quote$Client$currencyEncoder(c.hl))
			]));
};
var $author$project$Page$QuoteBuilderV3$Quote$customProductEncoder = function (p) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(p.fk)),
				_Utils_Tuple2(
				'price',
				$elm$json$Json$Encode$int(p.$8)),
				_Utils_Tuple2(
				'quantity',
				$elm$json$Json$Encode$int(p.ih))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Page$QuoteBuilderV3$Quote$productEncoder = function (product) {
	switch (product) {
		case 0:
			return $elm$json$Json$Encode$string('DiseasePrevention');
		case 1:
			return $elm$json$Json$Encode$string('Irrigation');
		case 2:
			return $elm$json$Json$Encode$string('AdvancedVentilation');
		case 3:
			return $elm$json$Json$Encode$string('Heating');
		case 4:
			return $elm$json$Json$Encode$string('HumidityConservation');
		case 5:
			return $elm$json$Json$Encode$string('ClimateSensor');
		case 6:
			return $elm$json$Json$Encode$string('AdvancedClimateSensor');
		case 7:
			return $elm$json$Json$Encode$string('Tensiometer');
		case 8:
			return $elm$json$Json$Encode$string('ThermalScreen');
		case 9:
			return $elm$json$Json$Encode$string('HeatPipe');
		case 10:
			return $elm$json$Json$Encode$string('RainProtection');
		case 11:
			return $elm$json$Json$Encode$string('WindProtection');
		case 12:
			return $elm$json$Json$Encode$string('SolarSensor');
		case 13:
			return $elm$json$Json$Encode$string('RangeExtensionKit');
		case 14:
			return $elm$json$Json$Encode$string('AdditionalBrain');
		default:
			return $elm$json$Json$Encode$string('OutdoorClimateSensor');
	}
};
var $author$project$Page$QuoteBuilderV3$Quote$farmEncoder = function (farm) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'products',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$productEncoder, farm.ig)),
				_Utils_Tuple2(
				'customProducts',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$customProductEncoder, farm.hn))
			]));
};
var $author$project$Page$QuoteBuilderV3$Quote$basePlanEncoder = function (basePlan) {
	switch (basePlan) {
		case 0:
			return $elm$json$Json$Encode$string('None');
		case 1:
			return $elm$json$Json$Encode$string('Seed');
		case 2:
			return $elm$json$Json$Encode$string('Core');
		default:
			return $elm$json$Json$Encode$string('Custom');
	}
};
var $author$project$Page$QuoteBuilderV3$Quote$greenhouseEncoder = function (greenhouse) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(greenhouse.fk)),
				_Utils_Tuple2(
				'basePlan',
				$author$project$Page$QuoteBuilderV3$Quote$basePlanEncoder(greenhouse.g9)),
				_Utils_Tuple2(
				'products',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$productEncoder, greenhouse.ig)),
				_Utils_Tuple2(
				'customProducts',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$customProductEncoder, greenhouse.hn))
			]));
};
var $author$project$Route$Quote$Entry$planEncoder = function (plan) {
	if (!plan) {
		return $elm$json$Json$Encode$string('Purchase');
	} else {
		return $elm$json$Json$Encode$string('Subscription');
	}
};
var $author$project$Page$QuoteBuilderV3$Quote$encoder = function (q) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'client',
				$author$project$Route$Quote$Client$encoder(q.hg)),
				_Utils_Tuple2(
				'greenhouses',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$greenhouseEncoder, q.aX)),
				_Utils_Tuple2(
				'farm',
				$author$project$Page$QuoteBuilderV3$Quote$farmEncoder(q.fd)),
				_Utils_Tuple2(
				'discounts',
				A2($elm$json$Json$Encode$list, $author$project$Page$QuoteBuilderV3$Quote$discountEncoder, q.fR)),
				_Utils_Tuple2(
				'plan',
				$author$project$Route$Quote$Entry$planEncoder(q.ab))
			]));
};
var $author$project$Page$QuoteBuilderV3$Update$queryParam = function (quote) {
	return A2(
		$elm$url$Url$Builder$string,
		$author$project$Page$QuoteBuilderV3$Update$param,
		$elm$url$Url$percentEncode(
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Page$QuoteBuilderV3$Quote$encoder(quote))));
};
var $author$project$Page$QuoteBuilderV3$Update$urlFromQuote = function (quote) {
	return A2(
		$elm$url$Url$Builder$absolute,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$QuoteBuilderV3$Update$queryParam(quote)
			]));
};
var $author$project$Page$QuoteBuilderV3$Preview$View$page1 = F5(
	function (language, translation, now, here, quote) {
		var client = quote.hg;
		return $author$project$Page$QuoteBuilderV3$Preview$Common$coverPage(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href(
									$author$project$Page$QuoteBuilderV3$Update$urlFromQuote(quote)),
									$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo),
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$rem(12))
												]))
										]),
									_List_Nil)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(8)),
											$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$rem(1)),
											$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$rem(2))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dU)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$rem(3))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(client.hD)
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$author$project$Style$Grid$displayGrid,
									$author$project$Style$Grid$rowGap(
									$rtfeldman$elm_css$Css$rem(3)),
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(8)),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$rem(26)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1.2))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dJ)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$author$project$Domain$Quote$Client$fullName(client))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(client.hD)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginTop(
													$rtfeldman$elm_css$Css$rem(1))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(client.g1)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cg)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											A3($author$project$Formatter$Date$dayMonthYear, language, here, now))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$flex(
									$rtfeldman$elm_css$Css$int(1)),
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexEnd),
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.eQ)
								]))
						]))
				]));
	});
var $rtfeldman$elm_css$Html$Styled$li = $rtfeldman$elm_css$Html$Styled$node('li');
var $author$project$Style$List$li = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$li(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$em(0.75))
					])),
			attrs));
};
var $author$project$S$li = $author$project$Style$List$li;
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$en = {
	bJ: 'More than 700 automated greenhouses!',
	bR: 'Orisha helped me achieved greenhouse yields 25% above my goal in my first year.',
	ck: 'Let\'s help hardworking market gardeners feed their community',
	c4: 'With my lieutenant Orisha, I can go to the riverside and enjoy ice cream with the team while keeping an eye on my phone to make sure everything is fine in the greenhouses!',
	c5: _List_fromArray(
		['Both at the Ferme des Quatre-Temps and at the Jardins de la Grelinette, we have equiped our greenhouses with Orisha systems.', 'For us, it\'s an investment to use their services.']),
	c6: 'Thanks to the precise climate control of Orisha’s technology, we saved ourselves from disease-related problems.',
	c7: 'What surprised me most with using Orisha’s products is the simplicity!',
	eB: 'The Market Gardener',
	eK: 'Greenhouse growers trust us to:',
	eL: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Facilitate their operations')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Secure their production')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Increase their yield')
				]))
		])
};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$fr = {
	bJ: 'Plus de 700 serres automatisées!',
	bR: 'Orisha m\'a permis d\'atteindre des rendements 25% plus élevés que mon objectif dès ma première année.',
	ck: 'Aidons nos maraîchers à mieux nourrir leur communauté',
	c4: 'Avec ma lieutenante Orisha, je peux aller sur le bord de l\'eau pour une crème glacée avec l\'équipe.',
	c5: _List_fromArray(
		['Tant à la Ferme des Quatre-Temps qu’aux Jardins de la Grelinette, on a équipé nos serres de systèmes Orisha.', 'Pour nous, c\'est un investissement d\'utiliser leurs services!']),
	c6: 'Grâce au contrôle climatique, j’ai remarqué une diminution des maladies.',
	c7: 'Ce qui m’a le plus surprise avec Orisha, c’est sa simplicité!',
	eB: 'Le Jardinier Maraîcher',
	eK: 'Les producteurs nous font confiance pour :',
	eL: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Faciliter leurs opérations')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Sécuriser leur production')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Augmenter leur rendement')
				]))
		])
};
var $rtfeldman$elm_css$Html$Styled$h3 = $rtfeldman$elm_css$Html$Styled$node('h3');
var $author$project$Style$Title$h3 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h3(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-3')
				]),
			attrs));
};
var $author$project$S$h3 = $author$project$Style$Title$h3;
var $rtfeldman$elm_css$Html$Styled$h6 = $rtfeldman$elm_css$Html$Styled$node('h6');
var $author$project$Style$Title$h6 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h6(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-6')
				]),
			attrs));
};
var $author$project$S$h6 = $author$project$Style$Title$h6;
var $author$project$Page$QuoteBuilderV3$Preview$Common$page = $author$project$Page$QuoteBuilderV3$Preview$Common$page_(false);
var $author$project$Theme$Color$orishaGreen = $rtfeldman$elm_css$Css$hex('#25b14e');
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$oneLiner = function (str) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(1.2)),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(400)),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$rem(0.5)),
						$rtfeldman$elm_css$Css$lineHeight(
						$rtfeldman$elm_css$Css$pct(200)),
						$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(str)
			]));
};
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2($rtfeldman$elm_css$Css$property, key, argA.gU + (' ' + (argB.gU + (' ' + (argC.gU + (' ' + argD.gU))))));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $author$project$Theme$Color$lightGrey = $rtfeldman$elm_css$Css$hex('#999999');
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$cardStyle = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$author$project$S$exactPrint,
			$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
			$rtfeldman$elm_css$Css$padding(
			$rtfeldman$elm_css$Css$rem(1)),
			$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$white),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(10)),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(10),
			$author$project$Theme$Color$lightGrey),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$pct(150))
		]));
var $rtfeldman$elm_css$Css$stringsToValue = function (list) {
	return $elm$core$List$isEmpty(list) ? {gU: 'none'} : {
		gU: A2($elm$core$String$join, ', ', list)
	};
};
var $rtfeldman$elm_css$Css$fontFamilies = A2(
	$elm$core$Basics$composeL,
	$rtfeldman$elm_css$Css$prop1('font-family'),
	$rtfeldman$elm_css$Css$stringsToValue);
var $author$project$S$cursiveFont2 = $rtfeldman$elm_css$Css$fontFamilies(
	_List_fromArray(
		['Nothing You Could Do', 'cursive']));
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$photoCredit = F2(
	function (name, farmName) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$S$cursiveFont2,
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(0.8)),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(0.5))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-0.2))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(farmName)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteImage = function (photoPath) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$img,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$rem(7)),
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(3))
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$src(photoPath)
			]),
		_List_Nil);
};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$testimonialView = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		function (t) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(t)
					]));
		}),
	$rtfeldman$elm_css$Html$Styled$div(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$rowGap(
						$rtfeldman$elm_css$Css$rem(0.5))
					]))
			])));
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout = F5(
	function (photoPath, testimonial, name, farmName, cardWidth) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
						]))
				]),
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteImage(photoPath),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$cardStyle,
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-4)),
									$rtfeldman$elm_css$Css$paddingTop(
									$rtfeldman$elm_css$Css$rem(4)),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(cardWidth))
								]))
						]),
					_List_fromArray(
						[
							$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$testimonialView(testimonial),
							A2($author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$photoCredit, name, farmName)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$threeTestimonialsView = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$rtfeldman$elm_css$Css$displayFlex]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout,
						'quote/testimonials/antoine.png',
						_List_fromArray(
							[translation.bR]),
						'Antoine',
						'Les Jardins d\'Inverness',
						200)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5($author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout, 'quote/testimonials/jean-martin.png', translation.c5, 'Jean-Martin Fortier', translation.eB, 200)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout,
						'quote/testimonials/jimo-close.png',
						_List_fromArray(
							[translation.c6]),
						'Jean-Michel',
						'La Gauloise Ferme Maraîchère',
						200)
					]))
			]));
};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$testimonials = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(0.7)),
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$rem(-2))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$rem(0),
										$rtfeldman$elm_css$Css$rem(1))
									]))
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout,
								'quote/testimonials/jaymie.jpg',
								_List_fromArray(
									[translation.c4]),
								'Jaymie',
								'Rutabaga Ranch',
								300)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$rem(0),
										$rtfeldman$elm_css$Css$rem(1))
									]))
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$quoteLayout,
								'quote/testimonials/julia2.png',
								_List_fromArray(
									[translation.c7]),
								'Julia',
								'Ferme des Quatre-Temps',
								300)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(2))
							]))
					]),
				_List_fromArray(
					[
						$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$oneLiner(translation.ck)
					])),
				$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$threeTestimonialsView(translation)
			]));
};
var $rtfeldman$elm_css$Css$disc = {f: 0, e: 0, gU: 'disc'};
var $rtfeldman$elm_css$Css$listStyle = $rtfeldman$elm_css$Css$prop1('list-style');
var $rtfeldman$elm_css$Html$Styled$ul = $rtfeldman$elm_css$Html$Styled$node('ul');
var $author$project$Style$List$ul = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$ul(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$rowGap(
						$rtfeldman$elm_css$Css$em(0.75)),
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$em(2)),
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$em(1)),
						$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$disc)
					])),
			attrs));
};
var $author$project$S$ul = $author$project$Style$List$ul;
var $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$view = function (language) {
	var translation = A2(
		$author$project$Language$getTranslation,
		language,
		{hw: $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$en, hE: $author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$fr});
	var title = function (str) {
		return A2(
			$author$project$S$h3,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$px(0)))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	};
	return $author$project$Page$QuoteBuilderV3$Preview$Common$page(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$author$project$Style$Grid$displayGrid,
								$author$project$Style$Grid$rowGap(
								$rtfeldman$elm_css$Css$rem(2)),
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						title(translation.bJ),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$img,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$src('quote/map.png'),
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$width(
												$rtfeldman$elm_css$Css$rem(25))
											]))
									]),
								_List_Nil),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginLeft(
												$rtfeldman$elm_css$Css$rem(2))
											]))
									]),
								_List_fromArray(
									[
										A2(
										$author$project$S$h6,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.eK)
											])),
										A2($author$project$S$ul, _List_Nil, translation.eL)
									]))
							])),
						$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$testimonials(translation)
					]))
			]));
};
var $author$project$Page$QuoteBuilderV3$Quote$allProducts = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
var $author$project$Page$QuoteBuilderV3$Quote$en = {E: 'Additional Brain', F: 'Advanced Climate Sensor', G: 'Advanced Ventilation', hh: 'Climate Sensor', b8: 'Core', hm: 'Custom', J: 'Disease Prevention', cN: 'Heat Pipe', N: 'Heating', O: 'Humidity Conservation', P: 'Irrigation', du: 'None', ib: 'Outdoor Climate Sensor', R: 'Rain Protection', S: 'Range Extension Kit', ef: 'Seed', bz: 'Solar Sensor', ey: 'Tensiometer', eC: 'Thermal Screen', V: 'Wind Protection'};
var $author$project$Page$QuoteBuilderV3$Quote$fr = {E: 'Cerveau additionnel', F: 'Capteur de climat avancé', G: 'Ventilation avancée', hh: 'Capteur de climat', b8: 'Contrôle', hm: 'Sur mesure', J: 'Prévention des maladies', cN: 'Tuyaux chauffant', N: 'Chauffage', O: 'Conservation de l\'humidité', P: 'Irrigation', du: 'Aucun', ib: 'Capteur de climat extérieur', R: 'Protection contre la pluie', S: 'Kit de prolongation de la portée', ef: 'Vision', bz: 'Capteur solaire', ey: 'Tensiomètre', eC: 'Toile thermique', V: 'Protection contre le vent'};
var $author$project$Page$QuoteBuilderV3$Quote$purchaseCAD = {E: 1800, F: 1300, G: 1400, hh: 900, b8: 4900, hm: 2300, J: 1400, cN: 1700, N: 1000, O: 1000, P: 1000, du: 0, ib: 900, R: 1400, S: 50, ef: 1800, bz: 1600, ey: 1300, eC: 1500, V: 1400};
var $author$project$Page$QuoteBuilderV3$Quote$purchaseUSD = {E: 1400, F: 1000, G: 1000, hh: 800, b8: 4000, hm: 1700, J: 1000, cN: 1400, N: 800, O: 800, P: 800, du: 0, ib: 800, R: 800, S: 50, ef: 1300, bz: 1400, ey: 1000, eC: 1200, V: 800};
var $author$project$Page$QuoteBuilderV3$Quote$subscriptionCAD = {E: 45, F: 35, G: 35, hh: 25, b8: 155, hm: 60, J: 35, cN: 45, N: 25, O: 25, P: 25, du: 0, ib: 25, R: 25, S: 5, ef: 45, bz: 45, ey: 35, eC: 40, V: 25};
var $author$project$Page$QuoteBuilderV3$Quote$subscriptionUSD = {E: 35, F: 25, G: 25, hh: 20, b8: 125, hm: 45, J: 25, cN: 35, N: 20, O: 20, P: 20, du: 0, ib: 20, R: 20, S: 5, ef: 35, bz: 40, ey: 25, eC: 30, V: 20};
var $author$project$Page$QuoteBuilderV3$Quote$getPricing = F2(
	function (currency, plan) {
		var _v0 = _Utils_Tuple2(currency, plan);
		if (!_v0.a) {
			if (!_v0.b) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return $author$project$Page$QuoteBuilderV3$Quote$purchaseCAD;
			} else {
				var _v3 = _v0.a;
				var _v4 = _v0.b;
				return $author$project$Page$QuoteBuilderV3$Quote$subscriptionCAD;
			}
		} else {
			if (!_v0.b) {
				var _v5 = _v0.a;
				var _v6 = _v0.b;
				return $author$project$Page$QuoteBuilderV3$Quote$purchaseUSD;
			} else {
				var _v7 = _v0.a;
				var _v8 = _v0.b;
				return $author$project$Page$QuoteBuilderV3$Quote$subscriptionUSD;
			}
		}
	});
var $author$project$Page$QuoteBuilderV3$Quote$precisionEn = {E: $elm$core$Maybe$Nothing, F: $elm$core$Maybe$Nothing, G: $elm$core$Maybe$Nothing, hh: $elm$core$Maybe$Nothing, b8: $elm$core$Maybe$Nothing, hm: $elm$core$Maybe$Nothing, J: $elm$core$Maybe$Nothing, cN: $elm$core$Maybe$Nothing, N: $elm$core$Maybe$Nothing, O: $elm$core$Maybe$Nothing, P: $elm$core$Maybe$Nothing, du: $elm$core$Maybe$Nothing, ib: $elm$core$Maybe$Nothing, R: $elm$core$Maybe$Nothing, S: $elm$core$Maybe$Nothing, ef: $elm$core$Maybe$Nothing, bz: $elm$core$Maybe$Nothing, ey: $elm$core$Maybe$Nothing, eC: $elm$core$Maybe$Nothing, V: $elm$core$Maybe$Nothing};
var $author$project$Page$QuoteBuilderV3$Quote$precisionFr = {E: $elm$core$Maybe$Nothing, F: $elm$core$Maybe$Nothing, G: $elm$core$Maybe$Nothing, hh: $elm$core$Maybe$Nothing, b8: $elm$core$Maybe$Nothing, hm: $elm$core$Maybe$Nothing, J: $elm$core$Maybe$Nothing, cN: $elm$core$Maybe$Nothing, N: $elm$core$Maybe$Nothing, O: $elm$core$Maybe$Nothing, P: $elm$core$Maybe$Nothing, du: $elm$core$Maybe$Nothing, ib: $elm$core$Maybe$Nothing, R: $elm$core$Maybe$Nothing, S: $elm$core$Maybe$Nothing, ef: $elm$core$Maybe$Nothing, bz: $elm$core$Maybe$Nothing, ey: $elm$core$Maybe$Nothing, eC: $elm$core$Maybe$Nothing, V: $elm$core$Maybe$Nothing};
var $author$project$Page$QuoteBuilderV3$Quote$basePlanDetails = F4(
	function (language, currency, plan, basePlan) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Quote$en, hE: $author$project$Page$QuoteBuilderV3$Quote$fr});
		var pricing = A2($author$project$Page$QuoteBuilderV3$Quote$getPricing, currency, plan);
		var precision = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Quote$precisionEn, hE: $author$project$Page$QuoteBuilderV3$Quote$precisionFr});
		switch (basePlan) {
			case 0:
				return {hK: $author$project$Theme$Icon$controller, fk: translation.du, id: precision.du, $8: pricing.du};
			case 1:
				return {hK: $author$project$Theme$Icon$controller, fk: translation.ef, id: precision.ef, $8: pricing.ef};
			case 2:
				return {hK: $author$project$Theme$Icon$controller, fk: translation.b8, id: precision.b8, $8: pricing.b8};
			default:
				return {hK: $author$project$Theme$Icon$controller, fk: translation.hm, id: precision.hm, $8: pricing.hm};
		}
	});
var $author$project$Page$QuoteBuilderV3$Quote$customProductDetails = function (customProduct) {
	return {hK: $author$project$Theme$Icon$custom, fk: customProduct.fk, id: $elm$core$Maybe$Nothing, $8: customProduct.$8};
};
var $author$project$Formatter$Number$formatInt = function (amount) {
	return (amount > 999) ? ($elm$core$String$fromInt((amount / 1000) | 0) + (' ' + A3(
		$elm$core$String$padLeft,
		3,
		'0',
		$elm$core$String$fromInt(amount % 1000)))) : ((amount < 0) ? function (x) {
		return '- ' + x;
	}(
		$elm$core$String$fromInt(-amount)) : $elm$core$String$fromInt(amount));
};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$entry = F2(
	function (details, quantity) {
		var total = details.$8 * quantity;
		var centered = function (str) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(str)
					]));
		};
		return (quantity > 0) ? _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
												$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[details.hK]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(details.fk)
							])),
						centered(
						$elm$core$String$fromInt(quantity)),
						centered(
						$author$project$Formatter$Number$formatInt(total))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$customProductEntry = function (c) {
	return A2(
		$author$project$Page$QuoteBuilderV3$Preview$Page$Price$entry,
		$author$project$Page$QuoteBuilderV3$Quote$customProductDetails(c),
		c.ih);
};
var $author$project$Page$QuoteBuilderV3$Quote$productDetails = F4(
	function (language, currency, plan, addOn) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Quote$en, hE: $author$project$Page$QuoteBuilderV3$Quote$fr});
		var pricing = A2($author$project$Page$QuoteBuilderV3$Quote$getPricing, currency, plan);
		var precision = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Quote$precisionEn, hE: $author$project$Page$QuoteBuilderV3$Quote$precisionFr});
		switch (addOn) {
			case 0:
				return {hK: $author$project$Theme$Icon$humidity, fk: translation.J, id: precision.J, $8: pricing.J};
			case 1:
				return {hK: $author$project$Theme$Icon$valve, fk: translation.P, id: precision.P, $8: pricing.P};
			case 2:
				return {hK: $author$project$Theme$Icon$fan, fk: translation.G, id: precision.G, $8: pricing.G};
			case 3:
				return {hK: $author$project$Theme$Icon$heater, fk: translation.N, id: precision.N, $8: pricing.N};
			case 4:
				return {hK: $author$project$Theme$Icon$mister, fk: translation.O, id: precision.O, $8: pricing.O};
			case 5:
				return {hK: $author$project$Theme$Icon$thermometer, fk: translation.hh, id: precision.hh, $8: pricing.hh};
			case 6:
				return {hK: $author$project$Theme$Icon$dryWetSensor, fk: translation.F, id: precision.F, $8: pricing.F};
			case 7:
				return {hK: $author$project$Theme$Icon$tensiometer, fk: translation.ey, id: precision.ey, $8: pricing.ey};
			case 8:
				return {hK: $author$project$Theme$Icon$thermalScreen, fk: translation.eC, id: precision.eC, $8: pricing.eC};
			case 9:
				return {hK: $author$project$Theme$Icon$heatPipe, fk: translation.cN, id: precision.cN, $8: pricing.cN};
			case 10:
				return {hK: $author$project$Theme$Icon$rainSensor, fk: translation.R, id: precision.R, $8: pricing.R};
			case 11:
				return {hK: $author$project$Theme$Icon$windSensor, fk: translation.V, id: precision.V, $8: pricing.V};
			case 12:
				return {hK: $author$project$Theme$Icon$solarSensor, fk: translation.bz, id: precision.bz, $8: pricing.bz};
			case 13:
				return {hK: $author$project$Theme$Icon$custom, fk: translation.S, id: precision.S, $8: pricing.S};
			case 14:
				return {hK: $author$project$Theme$Icon$controller, fk: translation.E, id: precision.E, $8: pricing.E};
			default:
				return {hK: $author$project$Theme$Icon$thermometer, fk: translation.ib, id: precision.ib, $8: pricing.ib};
		}
	});
var $author$project$Page$QuoteBuilderV3$Quote$grossTotal = function (quote) {
	var withQuote = function (fn) {
		return A3(fn, quote.hg.hR, quote.hg.hl, quote.ab);
	};
	var totalGreenhouse = function (g) {
		var products = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.$8;
				},
				A2(
					$elm$core$List$map,
					withQuote($author$project$Page$QuoteBuilderV3$Quote$productDetails),
					g.ig)));
		var customProducts = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (p) {
					return p.$8 * p.ih;
				},
				g.hn));
		var basePlan = A2(withQuote, $author$project$Page$QuoteBuilderV3$Quote$basePlanDetails, g.g9).$8;
		return (basePlan + products) + customProducts;
	};
	var totalGreenhouses = $elm$core$List$sum(
		A2($elm$core$List$map, totalGreenhouse, quote.aX));
	var totalFarm = function () {
		var products = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.$8;
				},
				A2(
					$elm$core$List$map,
					withQuote($author$project$Page$QuoteBuilderV3$Quote$productDetails),
					quote.fd.ig)));
		var customProducts = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				function (p) {
					return p.$8 * p.ih;
				},
				quote.fd.hn));
		return products + customProducts;
	}();
	return totalFarm + totalGreenhouses;
};
var $author$project$Page$QuoteBuilderV3$Quote$discountAmount = F2(
	function (quote, discount) {
		var _v0 = discount.fb;
		if (!_v0.$) {
			var percentage = _v0.a;
			return $elm$core$Basics$round(
				(percentage / 100) * $author$project$Page$QuoteBuilderV3$Quote$grossTotal(quote));
		} else {
			var amount = _v0.a;
			return amount;
		}
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$discountRow = F2(
	function (quote, discount) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
						]))
				]),
			_List_fromArray(
				[
					A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(discount.fk)
								])),
							A2(
							$elm$core$Maybe$withDefault,
							$author$project$S$empty,
							A2(
								$elm$core$Maybe$map,
								function (precision) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(0.8)),
														$rtfeldman$elm_css$Css$fontWeight(
														$rtfeldman$elm_css$Css$int(200))
													]))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(precision)
											]));
								},
								discount.id))
						])),
					A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							function (str) {
								return '-' + str;
							}(
								$author$project$Formatter$Number$formatInt(
									A2($author$project$Page$QuoteBuilderV3$Quote$discountAmount, quote, discount))))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$en = {cF: 'For the farm', $8: 'Price', dM: 'Price per month', dP: 'Product', ih: 'Quantity', ev: 'Tax not included', e4: 'Your total: '};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$fr = {cF: 'Pour la ferme', $8: 'Prix', dM: 'Prix par mois', dP: 'Produit', ih: 'Quantité', ev: 'Taxes applicables en sus', e4: 'Votre total: '};
var $author$project$Theme$Color$orishaGreenVeryLight = $rtfeldman$elm_css$Css$hex('#b3efc5');
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$tableHeader = F2(
	function (translation, quote) {
		var priceInfo = function () {
			var _v0 = quote.ab;
			if (!_v0) {
				return translation.$8;
			} else {
				return translation.dM;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
							$author$project$S$exactPrint
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dP)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white)),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(12)),
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.ih)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white)),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(12)),
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							priceInfo + (' (' + ($author$project$Domain$Total$Amount$currencyToString(quote.hg.hl) + ')')))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Quote$discountTotal = function (quote) {
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$author$project$Page$QuoteBuilderV3$Quote$discountAmount(quote),
			quote.fR));
};
var $author$project$Page$QuoteBuilderV3$Quote$total = function (quote) {
	return $author$project$Page$QuoteBuilderV3$Quote$grossTotal(quote) - $author$project$Page$QuoteBuilderV3$Quote$discountTotal(quote);
};
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$pages = F4(
	function (translation, quote, index, lines) {
		var totalStr = $author$project$Formatter$Number$formatInt(
			$author$project$Page$QuoteBuilderV3$Quote$total(quote)) + ('$' + $author$project$Domain$Total$Amount$currencyToString(quote.hg.hl));
		var page = function (l) {
			return $author$project$Page$QuoteBuilderV3$Preview$Common$page(
				_List_fromArray(
					[
						(!index) ? A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$author$project$Style$Grid$displayGrid,
										$author$project$Style$Grid$rowGap(
										$rtfeldman$elm_css$Css$em(1.5)),
										$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$em(0.9))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$S$h2,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.e4)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(totalStr)
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.ev)
									]))
							])) : $author$project$S$empty,
						A2(
						$author$project$S$table,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$rem(0.9))
									]))
							]),
						A2(
							$elm$core$List$cons,
							A2($author$project$Page$QuoteBuilderV3$Preview$Page$Price$tableHeader, translation, quote),
							l))
					]));
		};
		var maxNumberOfLines = (!index) ? 18 : 21;
		var _v0 = A2($elm_community$list_extra$List$Extra$splitAt, maxNumberOfLines, lines);
		if (!_v0.b.b) {
			var l = _v0.a;
			return _List_fromArray(
				[
					page(l)
				]);
		} else {
			var l = _v0.a;
			var xs = _v0.b;
			return A2(
				$elm$core$List$cons,
				page(l),
				A4($author$project$Page$QuoteBuilderV3$Preview$Page$Price$pages, translation, quote, index + 1, xs));
		}
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$productEntry = F3(
	function (quote, count, product) {
		var details = A4($author$project$Page$QuoteBuilderV3$Quote$productDetails, quote.hg.hR, quote.hg.hl, quote.ab, product);
		return A2(
			$author$project$Page$QuoteBuilderV3$Preview$Page$Price$entry,
			details,
			count(product));
	});
var $author$project$Page$QuoteBuilderV3$Preview$Page$Price$view = function (quote) {
	var sectionHeader = function (label) {
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4),
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreenVeryLight)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(label)
							]))
					]))
			]);
	};
	var plan = quote.ab;
	var language = quote.hg.hR;
	var translation = A2(
		$author$project$Language$getTranslation,
		language,
		{hw: $author$project$Page$QuoteBuilderV3$Preview$Page$Price$en, hE: $author$project$Page$QuoteBuilderV3$Preview$Page$Price$fr});
	var farmSection = $elm$core$List$concat(
		_List_fromArray(
			[
				($elm$core$List$isEmpty(quote.fd.ig) && $elm$core$List$isEmpty(quote.fd.hn)) ? _List_Nil : sectionHeader(translation.cF),
				A2(
				$elm$core$List$concatMap,
				A2(
					$author$project$Page$QuoteBuilderV3$Preview$Page$Price$productEntry,
					quote,
					function (p) {
						return A2(
							$elm_community$list_extra$List$Extra$count,
							$elm$core$Basics$eq(p),
							quote.fd.ig);
					}),
				A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isPerFarm, $author$project$Page$QuoteBuilderV3$Quote$allProducts)),
				A2($elm$core$List$concatMap, $author$project$Page$QuoteBuilderV3$Preview$Page$Price$customProductEntry, quote.fd.hn)
			]));
	var currency = quote.hg.hl;
	var greenhouseSection = function (g) {
		return $elm$core$List$concat(
			_List_fromArray(
				[
					sectionHeader(g.fk),
					A2(
					$author$project$Page$QuoteBuilderV3$Preview$Page$Price$entry,
					A4($author$project$Page$QuoteBuilderV3$Quote$basePlanDetails, language, currency, plan, g.g9),
					1),
					A2(
					$elm$core$List$concatMap,
					A2(
						$author$project$Page$QuoteBuilderV3$Preview$Page$Price$productEntry,
						quote,
						function (p) {
							return A2(
								$elm_community$list_extra$List$Extra$count,
								$elm$core$Basics$eq(p),
								g.ig);
						}),
					A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isPerGreenhouse, $author$project$Page$QuoteBuilderV3$Quote$allProducts)),
					A2($elm$core$List$concatMap, $author$project$Page$QuoteBuilderV3$Preview$Page$Price$customProductEntry, g.hn)
				]));
	};
	return A4(
		$author$project$Page$QuoteBuilderV3$Preview$Page$Price$pages,
		translation,
		quote,
		0,
		$elm$core$List$concat(
			_List_fromArray(
				[
					A2($elm$core$List$concatMap, greenhouseSection, quote.aX),
					farmSection,
					A2(
					$elm$core$List$map,
					$author$project$Page$QuoteBuilderV3$Preview$Page$Price$discountRow(quote),
					quote.fR),
					_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Total')
									])),
								A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
											])),
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Formatter$Number$formatInt(
											$author$project$Page$QuoteBuilderV3$Quote$total(quote)))
									]))
							]))
					])
				])));
};
var $author$project$Page$QuoteBuilderV3$Preview$View$view = F3(
	function (now, here, model) {
		var quote = $author$project$Page$QuoteBuilderV3$Update$toQuote(model);
		var language = model.hg.hR;
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Preview$View$en, hE: $author$project$Page$QuoteBuilderV3$Preview$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$justifyItems('center'),
							$author$project$Style$Grid$rowGap(
							$rtfeldman$elm_css$Css$rem(2)),
							$rtfeldman$elm_css$Css$paddingTop(
							$rtfeldman$elm_css$Css$rem(5)),
							$rtfeldman$elm_css$Css$paddingBottom(
							$rtfeldman$elm_css$Css$rem(5)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$px(0))
								]))
						]))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A5($author$project$Page$QuoteBuilderV3$Preview$View$page1, language, translation, now, here, quote)
						]),
						$author$project$Page$QuoteBuilderV3$Preview$Page$Price$view(quote),
						_List_fromArray(
						[
							$author$project$Page$QuoteBuilderV3$Preview$Page$Confidence$view(language)
						])
					])));
	});
var $author$project$Page$QuoteBuilderV3$Total$discountTotal = F2(
	function (translation, quote) {
		var amount = $author$project$Page$QuoteBuilderV3$Quote$discountTotal(quote);
		return (!amount) ? $author$project$S$empty : A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.I)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(6)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right),
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Formatter$Number$formatInt(amount * (-1)))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Total$en = {I: 'Discount', es: 'System price', eF: 'Total'};
var $author$project$Page$QuoteBuilderV3$Total$fr = {I: 'Rabais', es: 'Prix du système', eF: 'Total'};
var $author$project$Page$QuoteBuilderV3$Total$totalView = F2(
	function (str, total) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight(
							$rtfeldman$elm_css$Css$int(600))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(str)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$right)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(total))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Total$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Total$en, hE: $author$project$Page$QuoteBuilderV3$Total$fr});
		var quote = $author$project$Page$QuoteBuilderV3$Update$toQuote(model);
		return A2(
			$author$project$S$table,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$Page$QuoteBuilderV3$Total$totalView,
					translation.es,
					$author$project$Page$QuoteBuilderV3$Quote$grossTotal(quote)),
					A2($author$project$Page$QuoteBuilderV3$Total$discountTotal, translation, quote),
					A2(
					$author$project$Page$QuoteBuilderV3$Total$totalView,
					translation.eF,
					$author$project$Page$QuoteBuilderV3$Quote$total(quote))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Update$ClientMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Update$DiscountMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilderV3$View$en = {bZ: 'Build your own quote!', b3: 'For any questions, contact us!', dr: 'New greenhouse', ab: 'Plan', $8: 'Amount ($)', ig: 'Products', dS: 'Purchase', ih: 'Quantity', er: 'Subscription'};
var $author$project$Page$QuoteBuilderV3$View$fr = {bZ: 'Construisez votre devis!', b3: 'Pour toutes questions, communiquez avec nous!', dr: 'Nouvelle serre', ab: 'Plan d\'achat', $8: 'Prix ($)', ig: 'Produit', dS: 'Achat', ih: 'Quantité', er: 'Location'};
var $author$project$Page$QuoteBuilderV3$Update$SetPlan = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilderV3$View$planView = F2(
	function (translation, initialPlan) {
		var toLabel = function (plan) {
			if (!plan) {
				return translation.dS;
			} else {
				return translation.er;
			}
		};
		return A2(
			$author$project$S$labeled,
			translation.ab,
			_List_fromArray(
				[
					A5($author$project$S$select, initialPlan, $author$project$Domain$Quote$Plan$allPlans, toLabel, $author$project$Page$QuoteBuilderV3$Update$SetPlan, _List_Nil)
				]));
	});
var $author$project$Page$QuoteBuilderV3$Update$FarmMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$QuoteBuilderV3$Update$GreenhouseMsg = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Page$QuoteBuilderV3$Update$AddGreenhouse = {$: 0};
var $author$project$Page$QuoteBuilderV3$View$greenhouseAddition = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
										$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
										$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Update$AddGreenhouse)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.dr),
								A2($author$project$S$addButton, '', _List_Nil)
							]))
					]))
			]));
};
var $author$project$Page$QuoteBuilderV3$View$tableHeader = function (translation) {
	var centeredTitle = F2(
		function (widthInEm, str) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$em(widthInEm)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(200)))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(str)
					]));
		});
	return A2(
		$rtfeldman$elm_css$Html$Styled$thead,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(1.1))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(200)))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.ig)
					])),
				A2(centeredTitle, 7, translation.ih),
				A2(centeredTitle, 7, translation.$8)
			]));
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$AddProduct = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$CustomFieldMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilderV3$Farm$Update$RemoveProduct = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Farm$View$en = {cz: 'Extras', cF: 'For the farm'};
var $author$project$Page$QuoteBuilderV3$Farm$Update$ToggleShowExtras = {$: 2};
var $author$project$Page$QuoteBuilderV3$Farm$View$extrasHeader = F2(
	function (translation, model) {
		var chevron = model.by ? 'chevron-up' : 'chevron-down';
		return _List_fromArray(
			[
				$author$project$Theme$Table$subHeader(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
										$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Farm$Update$ToggleShowExtras)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.cz)
									])),
								A2($author$project$Style$Icon$faIcon, chevron, $elm$core$Maybe$Nothing)
							]))
					]))
			]);
	});
var $author$project$Page$QuoteBuilderV3$Farm$View$fr = {cz: 'Extras', cF: 'Pour toute la ferme'};
var $author$project$Page$QuoteBuilderV3$Quote$isUnique = function (product) {
	switch (product) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		case 3:
			return true;
		case 4:
			return true;
		case 5:
			return false;
		case 6:
			return false;
		case 7:
			return false;
		case 8:
			return false;
		case 9:
			return false;
		case 10:
			return true;
		case 11:
			return true;
		case 12:
			return false;
		case 13:
			return false;
		case 14:
			return false;
		default:
			return false;
	}
};
var $author$project$Page$QuoteBuilderV3$Entry$centered = $rtfeldman$elm_css$Html$Styled$td(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
				]))
		]));
var $author$project$Page$QuoteBuilderV3$Entry$iconCell = function (details) {
	return $author$project$Page$QuoteBuilderV3$Entry$centered(
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[details.hK]))
			]));
};
var $author$project$Page$QuoteBuilderV3$Entry$nameCell = function (details) {
	var subText = function (str) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$em(0.6)),
							$rtfeldman$elm_css$Css$opacity(
							$rtfeldman$elm_css$Css$num(0.8))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	};
	return A2(
		$rtfeldman$elm_css$Html$Styled$td,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(details.fk)
					])),
				A2(
				$elm$core$Maybe$withDefault,
				$author$project$S$empty,
				A2($elm$core$Maybe$map, subText, details.id))
			]));
};
var $author$project$Page$QuoteBuilderV3$Entry$quantityView = F5(
	function (details, quantity, onRemove, onAdd, attrs) {
		var buttonSize = $rtfeldman$elm_css$Css$em(0.9);
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			attrs,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$Entry$iconCell(details),
					$author$project$Page$QuoteBuilderV3$Entry$nameCell(details),
					$author$project$Page$QuoteBuilderV3$Entry$centered(
					_List_fromArray(
						[
							A2(
							$author$project$S$minusButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize)),
											(!quantity) ? $rtfeldman$elm_css$Css$batch(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$opacity(
														$rtfeldman$elm_css$Css$num(0)))
												])) : $author$project$S$noStyle
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onRemove)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$margin2,
											$rtfeldman$elm_css$Css$px(0),
											$rtfeldman$elm_css$Css$em(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$elm$core$String$fromInt(quantity))
								])),
							A2(
							$author$project$S$addButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(buttonSize))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(onAdd)
								]))
						])),
					$author$project$Page$QuoteBuilderV3$Entry$centered(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity * details.$8))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Entry$toggleView = F4(
	function (details, isAdded, onToggle, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			attrs,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$Entry$iconCell(details),
					$author$project$Page$QuoteBuilderV3$Entry$nameCell(details),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$checkbox,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$checked(isAdded),
											$rtfeldman$elm_css$Html$Styled$Events$onCheck(onToggle)
										]),
									_List_Nil)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									isAdded ? $elm$core$String$fromInt(details.$8) : '0')
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Update$FormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$Remove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$StartEdition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$View$editedView = F4(
	function (index, name, price, quantity) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$marginLeft(
												$rtfeldman$elm_css$Css$em(-0.15)))
										]))
								]),
							_List_fromArray(
								[$author$project$Theme$Icon$custom]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(name)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$editButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilderV3$CustomField$Update$StartEdition(index))
										])),
									A2(
									$author$project$S$removeButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilderV3$CustomField$Update$Remove(index))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(quantity))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Formatter$Number$formatInt(quantity * price))
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Update$CancelEdition = {$: 2};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$ConfirmEdition = {$: 1};
var $author$project$Page$QuoteBuilderV3$CustomField$View$editionButtons = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
				]))
		]),
	_List_fromArray(
		[
			A2(
			$author$project$S$okButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1))),
							$rtfeldman$elm_css$Css$marginLeft(
							$rtfeldman$elm_css$Css$em(1))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$CustomField$Update$ConfirmEdition)
				])),
			A2(
			$author$project$S$cancelButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$CustomField$Update$CancelEdition)
				]))
		]));
var $author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Amount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Name = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Quantity = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilderV3$CustomField$Form$View$en = {$8: 'Price ($)', ih: 'Quantity'};
var $author$project$Page$QuoteBuilderV3$CustomField$Form$View$fr = {$8: 'Prix ($)', ih: 'Quantité'};
var $author$project$Page$QuoteBuilderV3$CustomField$Form$View$view = F2(
	function (language, customProduct) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$CustomField$Form$View$en, hE: $author$project$Page$QuoteBuilderV3$CustomField$Form$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridTemplateColumns('2fr 1fr'),
							$author$project$Utils$Style$gap(
							$rtfeldman$elm_css$Css$rem(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					'Description',
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(20))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(customProduct.fk),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'data-autofocus', ''),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Name)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.ih,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(7.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$elm$core$String$fromInt(customProduct.ih)),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Quantity)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.$8,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(7.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(
									$author$project$Formatter$Number$formatInt(customProduct.$8)),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$CustomField$Form$Update$Amount)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$CustomField$View$editionView = F2(
	function (language, model) {
		var _v0 = model.K;
		if (!_v0.$) {
			var customProduct = _v0.b;
			return _List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$thead,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$td,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$displayFlex,
													$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
													$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
													$rtfeldman$elm_css$Css$padding(
													$rtfeldman$elm_css$Css$em(0.5))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Page$QuoteBuilderV3$CustomField$Update$FormMsg,
											A2($author$project$Page$QuoteBuilderV3$CustomField$Form$View$view, language, customProduct)),
											$author$project$Page$QuoteBuilderV3$CustomField$View$editionButtons
										]))
								]))
						]))
				]);
		} else {
			return A2(
				$elm$core$List$indexedMap,
				F2(
					function (index, c) {
						return A4($author$project$Page$QuoteBuilderV3$CustomField$View$editedView, index, c.fk, c.$8, c.ih);
					}),
				model.hn);
		}
	});
var $author$project$Page$QuoteBuilderV3$CustomField$View$en = {cb: 'Custom Device'};
var $author$project$Page$QuoteBuilderV3$CustomField$View$fr = {cb: 'Équipement sur mesure'};
var $author$project$Page$QuoteBuilderV3$CustomField$Update$Add = {$: 4};
var $author$project$Page$QuoteBuilderV3$CustomField$View$section = function (translation) {
	return _List_fromArray(
		[
			$author$project$Theme$Table$subHeader(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.cb)
								])),
							A2(
							$author$project$S$addButton,
							'',
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$important(
											$rtfeldman$elm_css$Css$fontSize(
												$rtfeldman$elm_css$Css$em(0.9))),
											$rtfeldman$elm_css$Css$marginRight(
											$rtfeldman$elm_css$Css$rem(0.5))
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$CustomField$Update$Add)
								]))
						]))
				]))
		]);
};
var $author$project$Page$QuoteBuilderV3$CustomField$View$view = F3(
	function (language, user, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$CustomField$View$en, hE: $author$project$Page$QuoteBuilderV3$CustomField$View$fr});
		return $author$project$Domain$User$isOrisha(user) ? $elm$core$List$concat(
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$CustomField$View$section(translation),
					A2($author$project$Page$QuoteBuilderV3$CustomField$View$editionView, language, model)
				])) : _List_fromArray(
			[$author$project$S$empty]);
	});
var $author$project$Page$QuoteBuilderV3$Farm$View$view = F5(
	function (language, user, client, plan, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Farm$View$en, hE: $author$project$Page$QuoteBuilderV3$Farm$View$fr});
		var productView = function (product) {
			var details = A4($author$project$Page$QuoteBuilderV3$Quote$productDetails, language, client.hl, plan, product);
			return $author$project$Page$QuoteBuilderV3$Quote$isUnique(product) ? A4(
				$author$project$Page$QuoteBuilderV3$Entry$toggleView,
				details,
				A2($elm$core$List$member, product, model.ig),
				function (checked) {
					return checked ? $author$project$Page$QuoteBuilderV3$Farm$Update$AddProduct(product) : $author$project$Page$QuoteBuilderV3$Farm$Update$RemoveProduct(product);
				},
				_List_Nil) : A5(
				$author$project$Page$QuoteBuilderV3$Entry$quantityView,
				details,
				A2(
					$elm_community$list_extra$List$Extra$count,
					$elm$core$Basics$eq(product),
					model.ig),
				$author$project$Page$QuoteBuilderV3$Farm$Update$RemoveProduct(product),
				$author$project$Page$QuoteBuilderV3$Farm$Update$AddProduct(product),
				_List_Nil);
		};
		var farmProducts = A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isPerFarm, $author$project$Page$QuoteBuilderV3$Quote$allProducts);
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$author$project$Theme$Table$header,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.cF)
									]))
							]))
					]),
					A2(
					$elm$core$List$map,
					productView,
					A2(
						$elm$core$List$filter,
						A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Page$QuoteBuilderV3$Quote$isExtra),
						farmProducts)),
					A2($author$project$Page$QuoteBuilderV3$Farm$View$extrasHeader, translation, model),
					model.by ? A2(
					$elm$core$List$map,
					productView,
					A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isExtra, farmProducts)) : _List_Nil,
					A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilderV3$Farm$Update$CustomFieldMsg),
					A3($author$project$Page$QuoteBuilderV3$CustomField$View$view, language, user, model.aR))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$AddProduct = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$CustomFieldMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$RemoveProduct = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$SetBasePlan = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Quote$None = 0;
var $author$project$Page$QuoteBuilderV3$Quote$allBasePlans = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$en = {cz: 'Extras'};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$ToggleShowExtras = {$: 3};
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$extrasHeader = F2(
	function (translation, model) {
		var chevron = model.by ? 'chevron-up' : 'chevron-down';
		return _List_fromArray(
			[
				$author$project$Theme$Table$subHeader(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$displayFlex,
										$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
										$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Greenhouse$Update$ToggleShowExtras)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.cz)
									])),
								A2($author$project$Style$Icon$faIcon, chevron, $elm$core$Maybe$Nothing)
							]))
					]))
			]);
	});
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$fr = {cz: 'Extras'};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Clone = {$: 10};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Remove = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$CancelNameEdition = {$: 8};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$ConfirmNameEdition = {$: 7};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Name = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$nameEditionView = function (initial) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$author$project$S$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$value(initial),
						$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Greenhouse$Update$Name),
						$author$project$Utils$Events$onEnter($author$project$Page$QuoteBuilderV3$Greenhouse$Update$ConfirmNameEdition)
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$S$okButton,
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$em(1))),
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$em(1))
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Greenhouse$Update$ConfirmNameEdition)
							])),
						A2(
						$author$project$S$cancelButton,
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$important(
										$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$em(1)))
									])),
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Greenhouse$Update$CancelNameEdition)
							]))
					]))
			]));
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$StartNameEdition = {$: 5};
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$nameView = function (greenhouseName) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
						$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(greenhouseName),
				A2(
				$author$project$S$editButton,
				'',
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$important(
								$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(0.8))),
								$rtfeldman$elm_css$Css$marginLeft(
								$rtfeldman$elm_css$Css$em(1))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Greenhouse$Update$StartNameEdition)
					]))
			]));
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$greenhouseHeader = F2(
	function (language, model) {
		var nameDisplay = function () {
			var _v0 = model.fk;
			switch (_v0.$) {
				case 0:
					var input = _v0.a.fh;
					return $author$project$Page$QuoteBuilderV3$Greenhouse$View$nameEditionView(input);
				case 1:
					var greenhouseName = _v0.a;
					return $author$project$Page$QuoteBuilderV3$Greenhouse$View$nameView(greenhouseName);
				default:
					return $author$project$Page$QuoteBuilderV3$Greenhouse$View$nameView(
						A2($author$project$Page$QuoteBuilderV3$Greenhouse$Update$defaultName, language, model));
			}
		}();
		return A2(
			$author$project$Theme$Table$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(4)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
										]))
								]),
							_List_fromArray(
								[
									nameDisplay,
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$author$project$S$cloneButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.1))),
															$rtfeldman$elm_css$Css$marginRight(
															$rtfeldman$elm_css$Css$rem(1))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Greenhouse$Update$Clone)
												])),
											A2(
											$author$project$S$removeButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$rem(1.1)))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick(
													$author$project$Page$QuoteBuilderV3$Greenhouse$Update$Remove(model.cY))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Entry$selectView = F6(
	function (details, initialChoice, choices, toLabel, onChoice, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			attrs,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$Entry$iconCell(details),
					$author$project$Page$QuoteBuilderV3$Entry$nameCell(details),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A5($author$project$S$select, initialChoice, choices, toLabel, onChoice, _List_Nil)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$important(
									$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$elm$core$String$fromInt(details.$8))
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Greenhouse$View$view = F5(
	function (language, client, plan, user, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Greenhouse$View$en, hE: $author$project$Page$QuoteBuilderV3$Greenhouse$View$fr});
		var productView = function (product) {
			var details = A4($author$project$Page$QuoteBuilderV3$Quote$productDetails, language, client.hl, plan, product);
			return $author$project$Page$QuoteBuilderV3$Quote$isUnique(product) ? A4(
				$author$project$Page$QuoteBuilderV3$Entry$toggleView,
				details,
				A2($elm$core$List$member, product, model.ig),
				function (checked) {
					return checked ? $author$project$Page$QuoteBuilderV3$Greenhouse$Update$AddProduct(product) : $author$project$Page$QuoteBuilderV3$Greenhouse$Update$RemoveProduct(product);
				},
				_List_Nil) : A5(
				$author$project$Page$QuoteBuilderV3$Entry$quantityView,
				details,
				A2(
					$elm_community$list_extra$List$Extra$count,
					$elm$core$Basics$eq(product),
					model.ig),
				$author$project$Page$QuoteBuilderV3$Greenhouse$Update$RemoveProduct(product),
				$author$project$Page$QuoteBuilderV3$Greenhouse$Update$AddProduct(product),
				_List_Nil);
		};
		var greenhouseProducts = A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isPerGreenhouse, $author$project$Page$QuoteBuilderV3$Quote$allProducts);
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2($author$project$Page$QuoteBuilderV3$Greenhouse$View$greenhouseHeader, client.hR, model),
						A6(
						$author$project$Page$QuoteBuilderV3$Entry$selectView,
						A4($author$project$Page$QuoteBuilderV3$Quote$basePlanDetails, language, client.hl, plan, model.g9),
						model.g9,
						$author$project$Page$QuoteBuilderV3$Quote$allBasePlans,
						A2(
							$elm$core$Basics$composeR,
							A3($author$project$Page$QuoteBuilderV3$Quote$basePlanDetails, language, client.hl, plan),
							function ($) {
								return $.fk;
							}),
						$author$project$Page$QuoteBuilderV3$Greenhouse$Update$SetBasePlan,
						_List_Nil)
					]),
					A2(
					$elm$core$List$map,
					productView,
					A2(
						$elm$core$List$filter,
						A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Page$QuoteBuilderV3$Quote$isExtra),
						greenhouseProducts)),
					A2($author$project$Page$QuoteBuilderV3$Greenhouse$View$extrasHeader, translation, model),
					model.by ? A2(
					$elm$core$List$map,
					productView,
					A2($elm$core$List$filter, $author$project$Page$QuoteBuilderV3$Quote$isExtra, greenhouseProducts)) : _List_Nil,
					A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilderV3$Greenhouse$Update$CustomFieldMsg),
					A3($author$project$Page$QuoteBuilderV3$CustomField$View$view, language, user, model.aR))
				]));
	});
var $author$project$Page$QuoteBuilderV3$View$tableView = F4(
	function (language, translation, user, model) {
		return A2(
			$author$project$S$table,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(5))
						]))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$colgroup,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$col,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$pct(5))
												]))
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$col,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$pct(65))
												]))
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$col,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$pct(15))
												]))
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$col,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$pct(15))
												]))
										]),
									_List_Nil)
								])),
							$author$project$Page$QuoteBuilderV3$View$tableHeader(translation)
						]),
						$elm$core$List$concat(
						A2(
							$elm$core$List$indexedMap,
							function (index) {
								return $elm$core$List$map(
									$rtfeldman$elm_css$Html$Styled$map(
										$author$project$Page$QuoteBuilderV3$Update$GreenhouseMsg(index)));
							},
							A2(
								$elm$core$List$map,
								A4($author$project$Page$QuoteBuilderV3$Greenhouse$View$view, language, model.hg, model.ab, user),
								model.aX))),
						_List_fromArray(
						[
							$author$project$Page$QuoteBuilderV3$View$greenhouseAddition(translation)
						]),
						A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Html$Styled$map($author$project$Page$QuoteBuilderV3$Update$FarmMsg),
						A5($author$project$Page$QuoteBuilderV3$Farm$View$view, language, user, model.hg, model.ab, model.fd))
					])));
	});
var $author$project$Page$QuoteBuilderV3$Client$Address = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$FarmName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$FirstName = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$LastName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$CurrencySelection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$currencyView = F2(
	function (translation, model) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(50))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.dT,
					_List_fromArray(
						[
							A5(
							$author$project$S$select,
							model.hl,
							_List_fromArray(
								[0, 1]),
							$author$project$Domain$Total$Amount$currencyToString,
							$author$project$Page$QuoteBuilderV3$Client$CurrencySelection,
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Client$en = {g1: 'Address', hw: 'English', hD: 'Farm name', fZ: 'First name', hE: 'French', gh: 'Last name', dT: 'Quote Currency', dV: 'Quote Language'};
var $author$project$Page$QuoteBuilderV3$Client$fr = {g1: 'Adresse', hw: 'Anglais', hD: 'Nom de la ferme', fZ: 'Prénom', hE: 'Français', gh: 'Nom de famille', dT: 'Devise du devis', dV: 'Langue du devis'};
var $author$project$Page$QuoteBuilderV3$Client$LanguageSelection = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$QuoteBuilderV3$Client$languageToString = F2(
	function (translation, language) {
		if (!language) {
			return translation.hw;
		} else {
			return translation.hE;
		}
	});
var $author$project$Page$QuoteBuilderV3$Client$languageSelectionView = F2(
	function (translation, model) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(50))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.dV,
					_List_fromArray(
						[
							A5(
							$author$project$S$select,
							model.hR,
							_List_fromArray(
								[1, 0]),
							$author$project$Page$QuoteBuilderV3$Client$languageToString(translation),
							$author$project$Page$QuoteBuilderV3$Client$LanguageSelection,
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Client$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Client$en, hE: $author$project$Page$QuoteBuilderV3$Client$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$gridTemplateColumns('1fr 1fr'),
							$author$project$Style$Grid$columnGap(
							$rtfeldman$elm_css$Css$rem(3)),
							$author$project$Style$Grid$rowGap(
							$rtfeldman$elm_css$Css$rem(2)),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(4))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					translation.fZ,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.fZ),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Client$FirstName)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.gh,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.gh),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Client$LastName)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.hD,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.hD),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Client$FarmName)
								]),
							_List_Nil)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$Page$QuoteBuilderV3$Client$languageSelectionView, translation, model),
							A2($author$project$Page$QuoteBuilderV3$Client$currencyView, translation, model)
						])),
					A2(
					$author$project$S$labeled,
					translation.g1,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.g1),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Client$Address)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Discount$Update$Add = {$: 4};
var $author$project$Page$QuoteBuilderV3$Discount$Update$FormMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$Update$Remove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$Update$StartEdition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$View$discountAmountText = F2(
	function (quote, discount) {
		return $rtfeldman$elm_css$Html$Styled$text(
			$author$project$Formatter$Number$formatInt(
				(-1) * A2($author$project$Page$QuoteBuilderV3$Quote$discountAmount, quote, discount)));
	});
var $author$project$Page$QuoteBuilderV3$Discount$View$amountData = F2(
	function (quote, discount) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$td,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$Page$QuoteBuilderV3$Discount$View$discountAmountText, quote, discount)
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Discount$View$iconData = A2(
	$rtfeldman$elm_css$Html$Styled$td,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(4)),
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle))
				]))
		]),
	_List_fromArray(
		[$author$project$Theme$Icon$discountTag]));
var $author$project$Page$QuoteBuilderV3$Discount$View$editedView = F3(
	function (quote, index, discount) {
		var name = function () {
			var _v0 = discount.fb;
			if (!_v0.$) {
				var p = _v0.a;
				return $elm$core$String$fromInt(p) + ('% - ' + discount.fk);
			} else {
				return discount.fk;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$QuoteBuilderV3$Discount$View$iconData,
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$editButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilderV3$Discount$Update$StartEdition(index))
										])),
									A2(
									$author$project$S$removeButton,
									'',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(1)))
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Page$QuoteBuilderV3$Discount$Update$Remove(index))
										]))
								]))
						])),
					A2($author$project$Page$QuoteBuilderV3$Discount$View$amountData, quote, discount)
				]));
	});
var $author$project$Page$QuoteBuilderV3$Discount$Update$CancelEdition = {$: 2};
var $author$project$Page$QuoteBuilderV3$Discount$Update$ConfirmEdition = {$: 1};
var $author$project$Page$QuoteBuilderV3$Discount$View$editionButtons = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$displayFlex,
					$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
					$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
					$author$project$Utils$Style$gap(
					$rtfeldman$elm_css$Css$rem(1))
				]))
		]),
	_List_fromArray(
		[
			A2(
			$author$project$S$okButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Discount$Update$ConfirmEdition)
				])),
			A2(
			$author$project$S$cancelButton,
			'',
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$em(1)))
						])),
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Discount$Update$CancelEdition)
				]))
		]));
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$Amount = 0;
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$AmountOrPercentage = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$Name = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$Percentage = 1;
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$SetDiscountType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$View$discountTypeToString = function (discountType) {
	if (!discountType) {
		return '$';
	} else {
		return '%';
	}
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$View$en = {co: 'Discount'};
var $author$project$Page$QuoteBuilderV3$Discount$Form$View$fr = {co: 'Rabais'};
var $author$project$Page$QuoteBuilderV3$Discount$Form$View$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Discount$Form$View$en, hE: $author$project$Page$QuoteBuilderV3$Discount$Form$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$form,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
							$author$project$Utils$Style$gap(
							$rtfeldman$elm_css$Css$rem(2))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$S$labeled,
					'Description',
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$vw(20))
										])),
									A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'data-autofocus', ''),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.fk),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Discount$Form$Update$Name)
								]),
							_List_Nil)
						])),
					A2(
					$author$project$S$labeled,
					translation.co,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[$rtfeldman$elm_css$Css$displayFlex]))
								]),
							_List_fromArray(
								[
									A2(
									$author$project$S$input,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$vw(5))
												])),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bh),
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Page$QuoteBuilderV3$Discount$Form$Update$AmountOrPercentage)
										]),
									_List_Nil),
									A5(
									$author$project$S$select,
									model.fb,
									_List_fromArray(
										[1, 0]),
									$author$project$Page$QuoteBuilderV3$Discount$Form$View$discountTypeToString,
									$author$project$Page$QuoteBuilderV3$Discount$Form$Update$SetDiscountType,
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Page$QuoteBuilderV3$Discount$View$customDiscountsView = F2(
	function (quote, model) {
		var editionRow = function (discount) {
			return _List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Page$QuoteBuilderV3$Discount$Update$FormMsg,
							A2($author$project$Page$QuoteBuilderV3$Discount$Form$View$view, quote.hg.hR, discount))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[$author$project$Page$QuoteBuilderV3$Discount$View$editionButtons]))
				]);
		};
		var _v0 = model.K;
		if (!_v0.$) {
			var index = _v0.a;
			var discount = _v0.b;
			return function (rows) {
				return _Utils_ap(
					rows,
					editionRow(discount));
			}(
				A2(
					$elm$core$List$indexedMap,
					$author$project$Page$QuoteBuilderV3$Discount$View$editedView(quote),
					A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm)));
		} else {
			return A2(
				$elm$core$List$indexedMap,
				$author$project$Page$QuoteBuilderV3$Discount$View$editedView(quote),
				model.hm);
		}
	});
var $author$project$Page$QuoteBuilderV3$Discount$View$en = {cn: 'Discount'};
var $author$project$Page$QuoteBuilderV3$Discount$View$fr = {cn: 'Rabais'};
var $author$project$Page$QuoteBuilderV3$Discount$View$view = F4(
	function (language, user, quote, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$Discount$View$en, hE: $author$project$Page$QuoteBuilderV3$Discount$View$fr});
		return A2(
			$author$project$S$table,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$thead,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$author$project$Theme$Table$headerStyle]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cn)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$th,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[$author$project$Theme$Table$priceColumnWidth]))
								]),
							_List_fromArray(
								[
									$author$project$Domain$User$isOrisha(user) ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$displayFlex,
													$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
													$rtfeldman$elm_css$Css$marginRight(
													$rtfeldman$elm_css$Css$rem(0.5))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$author$project$S$addButton,
											'',
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$fontSize(
																$rtfeldman$elm_css$Css$em(1.1)))
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Page$QuoteBuilderV3$Discount$Update$Add)
												]))
										])) : $author$project$S$empty
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$tbody,
					_List_Nil,
					A2($author$project$Page$QuoteBuilderV3$Discount$View$customDiscountsView, quote, model))
				]));
	});
var $author$project$Page$QuoteBuilderV3$View$view = F3(
	function (language, user, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuoteBuilderV3$View$en, hE: $author$project$Page$QuoteBuilderV3$View$fr});
		var quote = $author$project$Page$QuoteBuilderV3$Update$toQuote(model);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$S$talk1(translation.bZ),
					$author$project$S$talk1(translation.b3),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-1.5)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text($author$project$Info$phoneNumber)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
									$author$project$Utils$Style$gap(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Page$QuoteBuilderV3$Update$ClientMsg,
							A2($author$project$Page$QuoteBuilderV3$Client$view, language, model.hg)),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginBottom(
													$rtfeldman$elm_css$Css$rem(1))
												]))
										]),
									_List_fromArray(
										[
											A2($author$project$Page$QuoteBuilderV3$View$planView, translation, model.ab)
										]))
								]))
						])),
					A4($author$project$Page$QuoteBuilderV3$View$tableView, language, translation, user, model),
					A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Page$QuoteBuilderV3$Update$DiscountMsg,
					A4($author$project$Page$QuoteBuilderV3$Discount$View$view, language, user, quote, model.I))
				]));
	});
var $author$project$Page$QuotePreview$View$en = {cg: 'Date:', dJ: 'Prepared for:', dU: 'Quote for:', eQ: 'Valid for 30 days'};
var $author$project$Page$QuotePreview$View$fr = {cg: 'Date :', dJ: 'Préparé pour :', dU: 'Devis pour :', eQ: 'Valide 30 jours'};
var $rtfeldman$elm_css$Css$cover = {ao: 0, gU: 'cover'};
var $author$project$Page$QuotePreview$Common$page = F2(
	function (attrs, content) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$inches(8.5)),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$inches(11)),
							$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$white),
							A4(
							$rtfeldman$elm_css$Css$boxShadow4,
							$rtfeldman$elm_css$Css$px(1),
							$rtfeldman$elm_css$Css$px(2),
							$rtfeldman$elm_css$Css$px(8),
							$rtfeldman$elm_css$Css$px(3)),
							$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$boxShadow($rtfeldman$elm_css$Css$none),
									A2($rtfeldman$elm_css$Css$property, 'page-break-after', 'auto'),
									A2($rtfeldman$elm_css$Css$property, 'page-break-inside', 'avoid')
								]))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$height(
										$rtfeldman$elm_css$Css$pct(100)),
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$inches(0.9),
										$rtfeldman$elm_css$Css$inches(0.75))
									]))
							]),
						attrs),
					content)
				]));
	});
var $rtfeldman$elm_css$Css$url = function (urlValue) {
	return {aN: 0, gU: 'url(' + (urlValue + ')')};
};
var $author$project$Route$Quote$Entry$actuatorEncoder = function (actuator) {
	if (!actuator) {
		return $elm$json$Json$Encode$string('Multibox');
	} else {
		return $elm$json$Json$Encode$string('ActivationAndSideCurtainsUnits');
	}
};
var $author$project$Route$Quote$Entry$documentationEncoder = function (documentation) {
	switch (documentation) {
		case 0:
			return $elm$json$Json$Encode$string('Seed');
		case 1:
			return $elm$json$Json$Encode$string('Core');
		case 2:
			return $elm$json$Json$Encode$string('CustomBasic');
		case 3:
			return $elm$json$Json$Encode$string('CustomPro');
		case 4:
			return $elm$json$Json$Encode$string('Replacement');
		default:
			return $elm$json$Json$Encode$string('None');
	}
};
var $author$project$Route$Quote$Discount$discountTypeEncoder = F2(
	function (currency, discountType) {
		if (!discountType.$) {
			var p = discountType.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('Percentage')),
						_Utils_Tuple2(
						'percentage',
						$elm$json$Json$Encode$int(p))
					]));
		} else {
			var amount = discountType.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'tag',
						$elm$json$Json$Encode$string('Amount')),
						_Utils_Tuple2(
						'amount',
						$elm$json$Json$Encode$int(
							A2($author$project$Domain$Total$Amount$inCurrency, currency, amount)))
					]));
		}
	});
var $author$project$Route$Quote$Discount$customDiscountEncoder = F2(
	function (currency, d) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(d.fk)),
					_Utils_Tuple2(
					'discountType',
					A2($author$project$Route$Quote$Discount$discountTypeEncoder, currency, d.fb)),
					_Utils_Tuple2(
					'precision',
					A2($elm_community$json_extra$Json$Encode$Extra$maybe, $elm$json$Json$Encode$string, d.id))
				]));
	});
var $author$project$Route$Quote$Discount$predefinedDiscountEncoder = function (predefined) {
	switch (predefined) {
		case 0:
			return $elm$json$Json$Encode$string('SolarSensor');
		case 1:
			return $elm$json$Json$Encode$string('WindSensor');
		case 2:
			return $elm$json$Json$Encode$string('DryWetSensor');
		case 3:
			return $elm$json$Json$Encode$string('MarketGardenerStudent');
		case 4:
			return $elm$json$Json$Encode$string('EfaoMember');
		case 5:
			return $elm$json$Json$Encode$string('BasicMode');
		default:
			return $elm$json$Json$Encode$string('RoofInverter');
	}
};
var $author$project$Route$Quote$Discount$encoder = F2(
	function (currency, discounts) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'custom',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Route$Quote$Discount$customDiscountEncoder(currency),
						discounts.hm)),
					_Utils_Tuple2(
					'predefined',
					A2($elm$json$Json$Encode$list, $author$project$Route$Quote$Discount$predefinedDiscountEncoder, discounts.ie))
				]));
	});
var $author$project$Route$Quote$CustomProduct$encoder = F2(
	function (currency, p) {
		var deviceEncoder = function (d) {
			switch (d) {
				case 0:
					return $elm$json$Json$Encode$string('NoDevice');
				case 1:
					return $elm$json$Json$Encode$string('Tensiometer');
				case 2:
					return $elm$json$Json$Encode$string('Furnace');
				case 3:
					return $elm$json$Json$Encode$string('Opening');
				case 4:
					return $elm$json$Json$Encode$string('Fan');
				case 5:
					return $elm$json$Json$Encode$string('HAF');
				case 6:
					return $elm$json$Json$Encode$string('Valve');
				case 7:
					return $elm$json$Json$Encode$string('Mister');
				case 8:
					return $elm$json$Json$Encode$string('ThermalScreen');
				default:
					return $elm$json$Json$Encode$string('HeatPipe');
			}
		};
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(p.fk)),
					_Utils_Tuple2(
					'price',
					$elm$json$Json$Encode$int(
						A2($author$project$Domain$Total$Amount$inCurrency, currency, p.$8))),
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(p.ih)),
					_Utils_Tuple2(
					'device',
					deviceEncoder(p.hr)),
					_Utils_Tuple2(
					'relay',
					$elm$json$Json$Encode$int(p.il))
				]));
	});
var $author$project$Route$Quote$Farm$encoder = F2(
	function (currency, f) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'controller',
					$elm$json$Json$Encode$bool(f.hk)),
					_Utils_Tuple2(
					'windSensor',
					$elm$json$Json$Encode$bool(f.bG)),
					_Utils_Tuple2(
					'rainSensor',
					$elm$json$Json$Encode$bool(f.ii)),
					_Utils_Tuple2(
					'solarSensor',
					$elm$json$Json$Encode$bool(f.bz)),
					_Utils_Tuple2(
					'weatherBox',
					$elm$json$Json$Encode$bool(f.iG)),
					_Utils_Tuple2(
					'outdoorSensor',
					$elm$json$Json$Encode$bool(f.ib)),
					_Utils_Tuple2(
					'customProducts',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Route$Quote$CustomProduct$encoder(currency),
						f.hn))
				]));
	});
var $author$project$Route$Quote$Greenhouse$actuatorEncoder = function (a) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'activationUnit',
				$elm$json$Json$Encode$int(a.g$)),
				_Utils_Tuple2(
				'sideCurtainUnit',
				$elm$json$Json$Encode$int(a.it)),
				_Utils_Tuple2(
				'multibox',
				$elm$json$Json$Encode$int(a.hZ)),
				_Utils_Tuple2(
				'multiWithMotorDriver',
				$elm$json$Json$Encode$int(a.hY))
			]));
};
var $author$project$Route$Quote$Greenhouse$fanEncoder = function (quantity) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quantity',
				$elm$json$Json$Encode$int(quantity))
			]));
};
var $author$project$Route$Quote$Greenhouse$heaterEncoder = F2(
	function (heater, heatingStage) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'quantity',
					$elm$json$Json$Encode$int(heater)),
					_Utils_Tuple2(
					'heatingStage',
					$elm$json$Json$Encode$int(heatingStage))
				]));
	});
var $author$project$Route$Quote$Greenhouse$roofEncoder = function (r) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'quantity',
				$elm$json$Json$Encode$int(r.ih)),
				_Utils_Tuple2(
				'inverter',
				$elm$json$Json$Encode$bool(r.hO))
			]));
};
var $author$project$Route$Quote$Greenhouse$encoder = F2(
	function (currency, g) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'actuator',
					A2($elm_community$json_extra$Json$Encode$Extra$maybe, $author$project$Route$Quote$Greenhouse$actuatorEncoder, g.ae)),
					_Utils_Tuple2(
					'backUpThermostat',
					$elm$json$Json$Encode$int(g.g6)),
					_Utils_Tuple2(
					'circulationFan',
					$author$project$Route$Quote$Greenhouse$fanEncoder(g.b$)),
					_Utils_Tuple2(
					'climateSensor',
					$elm$json$Json$Encode$int(g.hh)),
					_Utils_Tuple2(
					'co2Sensor',
					$elm$json$Json$Encode$int(g.hi)),
					_Utils_Tuple2(
					'customProducts',
					A2(
						$elm$json$Json$Encode$list,
						$author$project$Route$Quote$CustomProduct$encoder(currency),
						g.hn)),
					_Utils_Tuple2(
					'dryBulbWetBulbSensor',
					$elm$json$Json$Encode$int(g.aS)),
					_Utils_Tuple2(
					'fan',
					$author$project$Route$Quote$Greenhouse$fanEncoder(g.cA)),
					_Utils_Tuple2(
					'fanWithLouvers',
					$author$project$Route$Quote$Greenhouse$fanEncoder(g.hC)),
					_Utils_Tuple2(
					'heater',
					A2($author$project$Route$Quote$Greenhouse$heaterEncoder, g.cO, g.hH)),
					_Utils_Tuple2(
					'heatPipe',
					$elm$json$Json$Encode$int(g.cN)),
					_Utils_Tuple2(
					'mister',
					$elm$json$Json$Encode$int(g.dk)),
					_Utils_Tuple2(
					'motor24VDC',
					$elm$json$Json$Encode$int(g.dl)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(g.fk)),
					_Utils_Tuple2(
					'opening24VWithInverter',
					$elm$json$Json$Encode$int(g.h9)),
					_Utils_Tuple2(
					'roof',
					$author$project$Route$Quote$Greenhouse$roofEncoder(g.iq)),
					_Utils_Tuple2(
					'sideCurtain',
					$elm$json$Json$Encode$int(g.gH)),
					_Utils_Tuple2(
					'soilTemperatureSensor',
					$elm$json$Json$Encode$int(g.iu)),
					_Utils_Tuple2(
					'tensiometer',
					$elm$json$Json$Encode$int(g.iz)),
					_Utils_Tuple2(
					'thermalScreen',
					$elm$json$Json$Encode$int(g.eC)),
					_Utils_Tuple2(
					'valve',
					$elm$json$Json$Encode$int(g.eR))
				]));
	});
var $author$project$Route$Quote$Entry$modeEncoder = function (mode) {
	if (!mode) {
		return $elm$json$Json$Encode$string('Pro');
	} else {
		return $elm$json$Json$Encode$string('Basic');
	}
};
var $author$project$Route$Quote$Entry$quoteEncoder = function (quote) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'client',
				$author$project$Route$Quote$Client$encoder(quote.hg)),
				_Utils_Tuple2(
				'greenhouses',
				A2(
					$elm$json$Json$Encode$list,
					$author$project$Route$Quote$Greenhouse$encoder($author$project$Route$Quote$Entry$communicationCurrency),
					quote.aX)),
				_Utils_Tuple2(
				'farm',
				A2($author$project$Route$Quote$Farm$encoder, $author$project$Route$Quote$Entry$communicationCurrency, quote.fd)),
				_Utils_Tuple2(
				'discount',
				A2($author$project$Route$Quote$Discount$encoder, $author$project$Route$Quote$Entry$communicationCurrency, quote.fR)),
				_Utils_Tuple2(
				'plan',
				$author$project$Route$Quote$Entry$planEncoder(quote.ab)),
				_Utils_Tuple2(
				'mode',
				$author$project$Route$Quote$Entry$modeEncoder(quote.hX)),
				_Utils_Tuple2(
				'documentation',
				$author$project$Route$Quote$Entry$documentationEncoder(quote.ht)),
				_Utils_Tuple2(
				'actuator',
				$author$project$Route$Quote$Entry$actuatorEncoder(quote.ae))
			]));
};
var $author$project$Route$Quote$Entry$queryParam = function (quote) {
	return A2(
		$elm$url$Url$Builder$string,
		$author$project$Route$Quote$Entry$param,
		$elm$url$Url$percentEncode(
			A2(
				$elm$json$Json$Encode$encode,
				0,
				$author$project$Route$Quote$Entry$quoteEncoder(quote))));
};
var $author$project$Route$Quote$Entry$urlWithQuote = function (quote) {
	return A2(
		$elm$url$Url$Builder$absolute,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Route$Quote$Entry$queryParam(quote)
			]));
};
var $author$project$Page$QuotePreview$View$page1 = F5(
	function (language, translation, now, here, quote) {
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$backgroundSize($rtfeldman$elm_css$Css$cover),
							$rtfeldman$elm_css$Css$backgroundImage(
							$rtfeldman$elm_css$Css$url('quote/cover.jpg')),
							$author$project$S$exactPrint
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href(
									$author$project$Route$Quote$Entry$urlWithQuote(quote)),
									$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo),
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$rem(12))
												]))
										]),
									_List_Nil)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(8)),
											$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$rem(1)),
											$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$rem(2))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dU)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$rem(3))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(quote.hg.hD)
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$author$project$Style$Grid$displayGrid,
									$author$project$Style$Grid$rowGap(
									$rtfeldman$elm_css$Css$rem(3)),
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(8)),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$rem(26)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1.2))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dJ)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											$author$project$Domain$Quote$Client$fullName(quote.hg))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(quote.hg.hD)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$marginTop(
													$rtfeldman$elm_css$Css$rem(1))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(quote.hg.g1)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$strong,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cg)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											A3($author$project$Formatter$Date$dayMonthYear, language, here, now))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$flex(
									$rtfeldman$elm_css$Css$int(1)),
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexEnd),
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$strong,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.eQ)
								]))
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Confidence$en = {
	bJ: 'More than 500 automated greenhouses!',
	bR: 'Orisha helped me achieved greenhouse yields 25% above my goal in my first year.',
	ck: 'Let\'s help hardworking market gardeners feed their community',
	c4: 'With my lieutenant Orisha, I can go to the riverside and enjoy ice cream with the team while keeping an eye on my phone to make sure everything is fine in the greenhouses!',
	c5: _List_fromArray(
		['Both at the Ferme des Quatre-Temps and at the Jardins de la Grelinette, we have equiped our greenhouses with Orisha systems.', 'For us, it\'s an investment to use their services.']),
	c6: 'Thanks to the precise climate control of Orisha’s technology, we saved ourselves from disease-related problems.',
	c7: 'What surprised me most with using Orisha’s products is the simplicity!',
	eB: 'The Market Gardener',
	eK: 'Greenhouse growers trust us to:',
	eL: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Facilitate their operations')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Secure their production')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Increase their yield')
				]))
		])
};
var $author$project$Page$QuotePreview$Page$Confidence$fr = {
	bJ: 'Plus de 500 serres automatisées!',
	bR: 'Orisha m\'a permis d\'atteindre des rendements 25% plus élevés que mon objectif dès ma première année.',
	ck: 'Aidons nos maraîchers à mieux nourrir leur communauté',
	c4: 'Avec ma lieutenante Orisha, je peux aller sur le bord de l\'eau pour une crème glacée avec l\'équipe.',
	c5: _List_fromArray(
		['Tant à la Ferme des Quatre-Temps qu’aux Jardins de la Grelinette, on a équipé nos serres de systèmes Orisha.', 'Pour nous, c\'est un investissement d\'utiliser leurs services!']),
	c6: 'Grâce au contrôle climatique, j’ai remarqué une diminution des maladies.',
	c7: 'Ce qui m’a le plus surprise avec Orisha, c’est sa simplicité!',
	eB: 'Le Jardinier Maraîcher',
	eK: 'Les producteurs nous font confiance pour :',
	eL: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Faciliter leurs opérations')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Sécuriser leur production')
				])),
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Augmenter leur rendement')
				]))
		])
};
var $author$project$Page$QuotePreview$Page$Confidence$oneLiner = function (str) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$p,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(1.2)),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(400)),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$rem(0.5)),
						$rtfeldman$elm_css$Css$lineHeight(
						$rtfeldman$elm_css$Css$pct(200)),
						$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(str)
			]));
};
var $author$project$Page$QuotePreview$Page$Confidence$cardStyle = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$displayFlex,
			$author$project$S$exactPrint,
			$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
			$rtfeldman$elm_css$Css$padding(
			$rtfeldman$elm_css$Css$rem(1)),
			$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$white),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(10)),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(10),
			$author$project$Theme$Color$lightGrey),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$pct(150))
		]));
var $author$project$Page$QuotePreview$Page$Confidence$photoCredit = F2(
	function (name, farmName) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$S$cursiveFont2,
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$rem(0.8)),
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(0.5))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-0.2))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(farmName)
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Confidence$quoteImage = function (photoPath) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$img,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$rem(7)),
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(3))
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$src(photoPath)
			]),
		_List_Nil);
};
var $author$project$Page$QuotePreview$Page$Confidence$testimonialView = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		function (t) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(t)
					]));
		}),
	$rtfeldman$elm_css$Html$Styled$div(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$rowGap(
						$rtfeldman$elm_css$Css$rem(0.5))
					]))
			])));
var $author$project$Page$QuotePreview$Page$Confidence$quoteLayout = F5(
	function (photoPath, testimonial, name, farmName, cardWidth) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
						]))
				]),
			_List_fromArray(
				[
					$author$project$Page$QuotePreview$Page$Confidence$quoteImage(photoPath),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$author$project$Page$QuotePreview$Page$Confidence$cardStyle,
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(-4)),
									$rtfeldman$elm_css$Css$paddingTop(
									$rtfeldman$elm_css$Css$rem(4)),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(cardWidth))
								]))
						]),
					_List_fromArray(
						[
							$author$project$Page$QuotePreview$Page$Confidence$testimonialView(testimonial),
							A2($author$project$Page$QuotePreview$Page$Confidence$photoCredit, name, farmName)
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Confidence$threeTestimonialsView = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$rtfeldman$elm_css$Css$displayFlex]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Page$QuotePreview$Page$Confidence$quoteLayout,
						'quote/testimonials/antoine.png',
						_List_fromArray(
							[translation.bR]),
						'Antoine',
						'Les Jardins d\'Inverness',
						200)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5($author$project$Page$QuotePreview$Page$Confidence$quoteLayout, 'quote/testimonials/jean-martin.png', translation.c5, 'Jean-Martin Fortier', translation.eB, 200)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$padding2,
								$rtfeldman$elm_css$Css$rem(0),
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A5(
						$author$project$Page$QuotePreview$Page$Confidence$quoteLayout,
						'quote/testimonials/jimo-close.png',
						_List_fromArray(
							[translation.c6]),
						'Jean-Michel',
						'La Gauloise Ferme Maraîchère',
						200)
					]))
			]));
};
var $author$project$Page$QuotePreview$Page$Confidence$testimonials = function (translation) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(0.7)),
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$rem(-2))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$rem(0),
										$rtfeldman$elm_css$Css$rem(1))
									]))
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Page$QuotePreview$Page$Confidence$quoteLayout,
								'quote/testimonials/jaymie.jpg',
								_List_fromArray(
									[translation.c4]),
								'Jaymie',
								'Rutabaga Ranch',
								300)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$rem(0),
										$rtfeldman$elm_css$Css$rem(1))
									]))
							]),
						_List_fromArray(
							[
								A5(
								$author$project$Page$QuotePreview$Page$Confidence$quoteLayout,
								'quote/testimonials/julia2.png',
								_List_fromArray(
									[translation.c7]),
								'Julia',
								'Ferme des Quatre-Temps',
								300)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(2))
							]))
					]),
				_List_fromArray(
					[
						$author$project$Page$QuotePreview$Page$Confidence$oneLiner(translation.ck)
					])),
				$author$project$Page$QuotePreview$Page$Confidence$threeTestimonialsView(translation)
			]));
};
var $author$project$Page$QuotePreview$Page$Confidence$view = function (language) {
	var translation = A2(
		$author$project$Language$getTranslation,
		language,
		{hw: $author$project$Page$QuotePreview$Page$Confidence$en, hE: $author$project$Page$QuotePreview$Page$Confidence$fr});
	var title = function (str) {
		return A2(
			$author$project$S$h3,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$important(
							$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$px(0)))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(str)
				]));
	};
	return A2(
		$author$project$Page$QuotePreview$Common$page,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$rowGap(
						$rtfeldman$elm_css$Css$rem(2)),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				title(translation.bJ),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$src('quote/map.png'),
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$rem(25))
									]))
							]),
						_List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$rem(2))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$author$project$S$h6,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.eK)
									])),
								A2($author$project$S$ul, _List_Nil, translation.eL)
							]))
					])),
				$author$project$Page$QuotePreview$Page$Confidence$testimonials(translation)
			]));
};
var $author$project$Page$QuotePreview$Page$EquipmentNeed$en = {cm: 'Automating devices', cr: 'Equipment for your farm', bs: 'Please note we will be sending the equipment on the basis that everything in one greenhouse will be connected at the same place', eh: 'Sensors', e$: 'For the whole farm', bH: 'Please, make the proper verifications to ensure we correctly understand your needs', bI: 'YOUR QUOTE'};
var $author$project$Page$QuotePreview$Page$EquipmentNeed$fr = {cm: 'Appareils à automatiser', cr: 'Équipements pour votre ferme', bs: 'Notez que nous envoyons le matériel en considérant que tout ce qui est dans une serre peut être connecté au même endroit', eh: 'Capteurs', e$: 'Pour toute la ferme', bH: 'SVP, vérifiez que nous avons bien compris vos besoins', bI: 'VOTRE DEVIS'};
var $author$project$Page$QuotePreview$Page$EquipmentNeed$device = F3(
	function (icon, name, quantity) {
		return (quantity > 0) ? _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$em(3))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
												$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[icon]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(name)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$em(6))
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(quantity))
							]))
					]))
			]) : _List_Nil;
	});
var $author$project$Page$QuotePreview$Page$EquipmentNeed$customProductView = function (customProduct) {
	return A3($author$project$Page$QuotePreview$Page$EquipmentNeed$device, $author$project$Theme$Icon$custom, customProduct.fk, customProduct.ih);
};
var $author$project$Style$Title$h4 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$h4(
		A2(
			$elm$core$List$append,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('title is-4')
				]),
			attrs));
};
var $author$project$S$h4 = $author$project$Style$Title$h4;
var $author$project$Domain$Product$isAFarmSensor = function (product) {
	switch (product) {
		case 0:
			return false;
		case 1:
			return true;
		case 2:
			return true;
		case 3:
			return true;
		case 4:
			return true;
		default:
			return false;
	}
};
var $author$project$Page$QuotePreview$Page$EquipmentNeed$farmStuff = F3(
	function (language, translation, f) {
		var isThereCustomProducts = !$elm$core$List$isEmpty(f.hn);
		var device_ = function (product) {
			return A3(
				$author$project$Page$QuotePreview$Page$EquipmentNeed$device,
				$author$project$Page$Product$farmProductIcon(product),
				A2($author$project$Page$Product$farmProductName, language, product),
				A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, f, product));
		};
		return (f.bG || (f.ii || (f.bz || (f.ib || isThereCustomProducts)))) ? A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h4,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cr)
						])),
					A2(
					$author$project$S$table,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$thead,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
													$author$project$S$exactPrint
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$th,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3),
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
														]))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(translation.e$)
												]))
										]))
								]),
								A2(
								$elm$core$List$concatMap,
								device_,
								A2($elm$core$List$filter, $author$project$Domain$Product$isAFarmSensor, $author$project$Domain$Product$allFarmProducts)),
								A2($elm$core$List$concatMap, $author$project$Page$QuotePreview$Page$EquipmentNeed$customProductView, f.hn)
							])))
				])) : $author$project$S$empty;
	});
var $author$project$Page$QuotePreview$Page$EquipmentNeed$greenhouseStuff = F3(
	function (language, translation, g) {
		var device_ = function (product) {
			return A3(
				$author$project$Page$QuotePreview$Page$EquipmentNeed$device,
				$author$project$Page$Product$greenhouseProductIcon(product),
				A2($author$project$Page$Product$greenhouseProductName, language, product),
				A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, g, product));
		};
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h4,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(g.fk)
						])),
					A2(
					$author$project$S$table,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					$elm$core$List$concat(
						_List_fromArray(
							[
								_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$thead,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
													$author$project$S$exactPrint,
													$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$th,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3),
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
														]))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(translation.eh)
												]))
										]))
								]),
								A2(
								$elm$core$List$concatMap,
								device_,
								A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseSensor, $author$project$Domain$Product$allGreenhouseProducts)),
								_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$thead,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
													$author$project$S$exactPrint,
													$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white)
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$th,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$css(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$important(
															$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
														])),
													$rtfeldman$elm_css$Html$Styled$Attributes$colspan(3)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(translation.cm)
												]))
										]))
								]),
								A2(
								$elm$core$List$concatMap,
								device_,
								A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseDevice, $author$project$Domain$Product$allGreenhouseProducts)),
								A2($elm$core$List$concatMap, $author$project$Page$QuotePreview$Page$EquipmentNeed$customProductView, g.hn)
							])))
				]));
	});
var $author$project$Page$QuotePreview$Page$EquipmentNeed$manyComponentsView = F3(
	function (language, translation, quote) {
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$author$project$Page$QuotePreview$Common$page,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$S$h2,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.bI)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$rem(2)),
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$em,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.bH)
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$rem(2)),
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$em,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.bs)
											]))
									])),
								A3($author$project$Page$QuotePreview$Page$EquipmentNeed$farmStuff, language, translation, quote.fd)
							]))
					]),
					A2(
					$elm$core$List$map,
					function (g) {
						return A2(
							$author$project$Page$QuotePreview$Common$page,
							_List_Nil,
							_List_fromArray(
								[
									A3($author$project$Page$QuotePreview$Page$EquipmentNeed$greenhouseStuff, language, translation, g)
								]));
					},
					quote.aX)
				]));
	});
var $author$project$Domain$Product$isAClientFacingFarmProduct = function (product) {
	switch (product) {
		case 0:
			return true;
		case 1:
			return true;
		case 2:
			return true;
		case 3:
			return true;
		case 4:
			return true;
		default:
			return false;
	}
};
var $author$project$Page$QuotePreview$Common$numberOfFarmComponents = function (f) {
	var isThereA = function (product) {
		return (A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, f, product) >= 1) ? 1 : 0;
	};
	return $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			isThereA,
			A2($elm$core$List$filter, $author$project$Domain$Product$isAClientFacingFarmProduct, $author$project$Domain$Product$allFarmProducts))) + $elm$core$List$length(f.hn);
};
var $author$project$Page$QuotePreview$Common$numberOfGreenhouseComponents = function (g) {
	var isThereA = function (product) {
		return (A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProductForGreenhouse, g, product) >= 1) ? 1 : 0;
	};
	return $elm$core$List$sum(
		A2($elm$core$List$map, isThereA, $author$project$Domain$Product$allGreenhouseProducts)) + $elm$core$List$length(g.hn);
};
var $author$project$Page$QuotePreview$Page$EquipmentNeed$regularView = F3(
	function (language, translation, quote) {
		return A2(
			$elm$core$List$indexedMap,
			F2(
				function (index, g) {
					return A2(
						$author$project$Page$QuotePreview$Common$page,
						_List_Nil,
						(!index) ? _List_fromArray(
							[
								A2(
								$author$project$S$h2,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.bI)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$rem(2)),
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$em,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.bH)
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$marginBottom(
												$rtfeldman$elm_css$Css$rem(2)),
												$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$em,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(translation.bs)
											]))
									])),
								A3($author$project$Page$QuotePreview$Page$EquipmentNeed$farmStuff, language, translation, quote.fd),
								A3($author$project$Page$QuotePreview$Page$EquipmentNeed$greenhouseStuff, language, translation, g)
							]) : _List_fromArray(
							[
								A3($author$project$Page$QuotePreview$Page$EquipmentNeed$greenhouseStuff, language, translation, g)
							]));
				}),
			quote.aX);
	});
var $author$project$Page$QuotePreview$Page$EquipmentNeed$view = F2(
	function (language, quote) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$EquipmentNeed$en, hE: $author$project$Page$QuotePreview$Page$EquipmentNeed$fr});
		var componentsInGreenhouse1 = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$QuotePreview$Common$numberOfGreenhouseComponents,
				$elm$core$List$head(quote.aX)));
		var componentsInFarm = $author$project$Page$QuotePreview$Common$numberOfFarmComponents(quote.fd);
		var componentsOnFirstPage = componentsInGreenhouse1 + componentsInFarm;
		return (componentsOnFirstPage < 14) ? A3($author$project$Page$QuotePreview$Page$EquipmentNeed$regularView, language, translation, quote) : A3($author$project$Page$QuotePreview$Page$EquipmentNeed$manyComponentsView, language, translation, quote);
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$en = {bV: 'Automating devices', b9: 'Core system', cc: 'Custom Devices', cl: 'Details on devices compatibility', $8: 'Price', dK: 'PRICE DETAILS', dL: 'PRICE DETAILS', ig: 'Products', ih: 'Quantity', eh: 'Sensors', eM: 'Unit price'};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$deviceToAutomateTitle = F3(
	function (name, compatibleDevicesLink, detailsOnDevices) {
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$thead,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
								$author$project$S$exactPrint
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$colspan(5)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(name),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$rem(0.9)),
														$rtfeldman$elm_css$Css$fontWeight(
														$rtfeldman$elm_css$Css$int(400))
													])),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
												$rtfeldman$elm_css$Html$Styled$Attributes$href(compatibleDevicesLink)
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(detailsOnDevices)
											]))
									]))
							]))
					]))
			]);
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$entry = F5(
	function (currency, icon, name, unitPrice, quantity) {
		var total = A2($author$project$Domain$Total$Amount$multiplyBy, quantity, unitPrice);
		var centered = function (str) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(str)
					]));
		};
		return (quantity > 0) ? _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$width(
										$rtfeldman$elm_css$Css$em(3))
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
												$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
											]))
									]),
								_List_fromArray(
									[icon]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$td,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(name)
							])),
						centered(
						A2($author$project$Formatter$Number$formatAmount, currency, unitPrice)),
						centered(
						$elm$core$String$fromInt(quantity)),
						centered(
						A2($author$project$Formatter$Number$formatAmount, currency, total))
					]))
			]) : _List_Nil;
	});
var $author$project$Domain$Product$isACoreSystemFarmProduct = function (product) {
	switch (product) {
		case 0:
			return true;
		case 1:
			return false;
		case 2:
			return false;
		case 3:
			return false;
		case 4:
			return false;
		default:
			return false;
	}
};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$sectionTitle = function (name) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$thead,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$sectionBlue),
							$author$project$S$exactPrint
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$th,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(5)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						]))
				]))
		]);
};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$regularEntriesView = F3(
	function (language, translation, quote) {
		var greenhouseEntry_ = function (product) {
			return A5(
				$author$project$Page$QuotePreview$Page$EquipmentPrice$entry,
				quote.hg.hl,
				$author$project$Page$Product$greenhouseProductIcon(product),
				A2($author$project$Page$Product$greenhouseProductName, language, product),
				A2($author$project$Domain$Price$Listing$Entry$greenhouseProductPrice, quote.ab, product),
				A2($author$project$Domain$Quote$Equipment$numberOfGreenhouseProduct, quote, product));
		};
		var farmEntry_ = function (product) {
			return A5(
				$author$project$Page$QuotePreview$Page$EquipmentPrice$entry,
				quote.hg.hl,
				$author$project$Page$Product$farmProductIcon(product),
				A2($author$project$Page$Product$farmProductName, language, product),
				A2($author$project$Domain$Price$Listing$Entry$farmProductPrice, quote.ab, product),
				A2($author$project$Domain$Quote$Equipment$numberOfFarmProduct, quote.fd, product));
		};
		var currency = quote.hg.hl;
		var quoteCurrency = function () {
			if (!currency) {
				return ' (CAD)';
			} else {
				return ' (USD)';
			}
		}();
		var alignCenter = $rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)),
					$rtfeldman$elm_css$Css$important(
					$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
				]));
		return $elm$core$List$concat(
			_List_fromArray(
				[
					_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$thead,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
										$author$project$S$exactPrint
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$colspan(2),
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$important(
												$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.ig)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[alignCenter]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.eM)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[alignCenter]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.ih)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_fromArray(
									[alignCenter]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										_Utils_ap(translation.$8, quoteCurrency))
									]))
							]))
					]),
					$author$project$Page$QuotePreview$Page$EquipmentPrice$sectionTitle(translation.b9),
					A2(
					$elm$core$List$concatMap,
					farmEntry_,
					A2($elm$core$List$filter, $author$project$Domain$Product$isACoreSystemFarmProduct, $author$project$Domain$Product$allFarmProducts)),
					$author$project$Page$QuotePreview$Page$EquipmentPrice$sectionTitle(translation.eh),
					A2(
					$elm$core$List$concatMap,
					greenhouseEntry_,
					A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseSensor, $author$project$Domain$Product$allGreenhouseProducts)),
					A2(
					$elm$core$List$concatMap,
					farmEntry_,
					A2($elm$core$List$filter, $author$project$Domain$Product$isAFarmSensor, $author$project$Domain$Product$allFarmProducts)),
					A3(
					$author$project$Page$QuotePreview$Page$EquipmentPrice$deviceToAutomateTitle,
					translation.bV,
					$author$project$DocumentLinks$compatibleDevices(language),
					translation.cl),
					A2(
					$elm$core$List$concatMap,
					greenhouseEntry_,
					A2($elm$core$List$filter, $author$project$Domain$Product$isAGreenhouseDevice, $author$project$Domain$Product$allGreenhouseProducts))
				]));
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$firstPage = F3(
	function (language, translation, quote) {
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dK)
						])),
					A2(
					$author$project$S$table,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					A3($author$project$Page$QuotePreview$Page$EquipmentPrice$regularEntriesView, language, translation, quote))
				]));
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$fr = {bV: 'Appareils à automatiser', b9: 'Système de base', cc: 'Équipements sur mesure', cl: 'Précisions sur les appareils compatibles', $8: 'Prix', dK: 'DÉTAILS DES PRIX', dL: 'DÉTAILS DES PRIX', ig: 'Produits', ih: 'Quantité', eh: 'Capteurs', eM: 'Prix unitaire'};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$customProductEntry = function (quote) {
	return A2(
		$elm$core$List$concatMap,
		function (c) {
			return A5($author$project$Page$QuotePreview$Page$EquipmentPrice$entry, quote.hg.hl, $author$project$Theme$Icon$custom, c.fk, c.$8, c.ih);
		},
		A2(
			$elm$core$List$concatMap,
			function ($) {
				return $.hn;
			},
			quote.aX));
};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$customEntriesView = F2(
	function (translation, quote) {
		var farmCustomProductEntry = A2(
			$elm$core$List$concatMap,
			function (c) {
				return A5($author$project$Page$QuotePreview$Page$EquipmentPrice$entry, quote.hg.hl, $author$project$Theme$Icon$custom, c.fk, c.$8, c.ih);
			},
			quote.fd.hn);
		return $elm$core$List$concat(
			_List_fromArray(
				[
					$author$project$Page$QuotePreview$Page$EquipmentPrice$sectionTitle(translation.cc),
					$author$project$Page$QuotePreview$Page$EquipmentPrice$customProductEntry(quote),
					farmCustomProductEntry
				]));
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$secondPage = F2(
	function (translation, quote) {
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.dL)
						])),
					A2(
					$author$project$S$table,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					A2($author$project$Page$QuotePreview$Page$EquipmentPrice$customEntriesView, translation, quote))
				]));
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$Regular = 0;
var $author$project$Page$QuotePreview$Page$EquipmentPrice$TwoPages = 1;
var $author$project$Page$QuotePreview$Common$numberOfCustomProducts = function (quote) {
	var greenhouseCustomProducts = $elm$core$List$sum(
		A2(
			$elm$core$List$map,
			$elm$core$List$length,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.hn;
				},
				quote.aX)));
	var farmCustomProducts = $elm$core$List$length(quote.fd.hn);
	return greenhouseCustomProducts + farmCustomProducts;
};
var $author$project$Page$QuotePreview$Page$EquipmentPrice$viewSetter = F2(
	function (quote, user) {
		var numberOfCustomEntries = $author$project$Domain$User$isOrisha(user) ? $author$project$Page$QuotePreview$Common$numberOfCustomProducts(quote) : 0;
		return (!numberOfCustomEntries) ? 0 : 1;
	});
var $author$project$Page$QuotePreview$Page$EquipmentPrice$view = F3(
	function (language, quote, user) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$EquipmentPrice$en, hE: $author$project$Page$QuotePreview$Page$EquipmentPrice$fr});
		var setView = A2($author$project$Page$QuotePreview$Page$EquipmentPrice$viewSetter, quote, user);
		if (!setView) {
			return _List_fromArray(
				[
					A3($author$project$Page$QuotePreview$Page$EquipmentPrice$firstPage, language, translation, quote)
				]);
		} else {
			return _List_fromArray(
				[
					A3($author$project$Page$QuotePreview$Page$EquipmentPrice$firstPage, language, translation, quote),
					A2($author$project$Page$QuotePreview$Page$EquipmentPrice$secondPage, translation, quote)
				]);
		}
	});
var $author$project$Theme$Color$black = $rtfeldman$elm_css$Css$hex('#000000');
var $author$project$Theme$Color$brightRed = $rtfeldman$elm_css$Css$hex('#ff0000');
var $author$project$Page$QuotePreview$Page$Exclusion$en = {cf: 'CUSTOMS FEES FOR COSTUMERS OUTSIDE CANADA', cm: 'DEVICES TO CONTROL (VALVES, HEATERS, MOTORS, FANS)', cw: 'EXCLUSIONS', cZ: 'INSTALLATION', ei: 'THE BRACKETS FOR THE WIND SENSOR, THE RAIN SENSOR AND THE SUNLIGHT SENSOR', ep: 'OTHER COMPONENTS SPECIFIC FOR YOUR INSTALLATION', eG: 'THE TOUCH SCREEN YOU NEED TO USE THE SOFTWARE. THE SOFTWARE MUST BE OPERATE WITH A PHONE, A TABLET OR A COMPUTER THAT IS NOT PROVIDED WITH ORISHA CONTROL SYSTEM', eS: 'VALVES ARE NOT INCLUDED', e1: 'WIRING (CALL US FOR ADVICES)'};
var $author$project$Page$QuotePreview$Page$Exclusion$fr = {cf: 'FRAIS DE DOUANES POUR LES CLIENTS HORS-CANADA', cm: 'LES ÉQUIPEMENTS À AUTOMATISER (VALVES, FOURNAISES, MOTEURS, VENTILATEURS)', cw: 'EXCLUSIONS', cZ: 'L’INSTALLATION', ei: 'LES SUPPORTS POUR L’ANÉMOMÈTRE, LE CAPTEUR DE PLUIE ET LE CAPTEUR DE LUMINOSITÉ', ep: 'AUTRES PIÈCES SPÉCIFIQUES À VOTRE INSTALLATION', eG: 'L’ÉCRAN TACTILE POUR L’UTILISATION DU LOGICIEL. LE LOGICIEL DOIT ÊTRE OPÉRÉ AVEC UN TÉLÉPHONE, UNE TABLETTE OU UN ORDINATEUR QUI NE SONT PAS FOURNIS AVEC LE SYSTÈME D’AUTOMATISATION ORISHA', eS: 'VALVES NON INCLUSES', e1: 'LE FILAGE (APPELEZ-NOUS POUR DES CONSEILS UTILES)'};
var $rtfeldman$elm_css$Html$Styled$u = $rtfeldman$elm_css$Html$Styled$node('u');
var $author$project$Page$QuotePreview$Page$Exclusion$view = function (language) {
	var translation = A2(
		$author$project$Language$getTranslation,
		language,
		{hw: $author$project$Page$QuotePreview$Page$Exclusion$en, hE: $author$project$Page$QuotePreview$Page$Exclusion$fr});
	return A2(
		$author$project$Page$QuotePreview$Common$page,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$S$h2,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.cw)
					])),
				A2(
				$author$project$S$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(2)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(1.2)),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(400))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.cm)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.cZ)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.e1)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.ep)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.ei)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.eG)
							])),
						A2(
						$author$project$S$li,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(translation.cf)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
								$author$project$S$exactPrint
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$em,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$padding(
										$rtfeldman$elm_css$Css$rem(1)),
										$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$rem(3)),
										$rtfeldman$elm_css$Css$fontWeight(
										$rtfeldman$elm_css$Css$int(700)),
										$rtfeldman$elm_css$Css$color($author$project$Theme$Color$black)
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$u,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$brightRed)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.eS)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(1))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$img,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$src('quote/valves_not_included.png'),
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$height(
										$rtfeldman$elm_css$Css$rem(15))
									]))
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Page$QuotePreview$Page$Inclusion$en = {cV: 'INCLUSIONS', dH: 'POWER BOXES FOR 100W 24V DC MOTORS IF YOU CHOOSE TO AUTOMATIZE SIDE CURTAINS EXCEPT IN CASE OF CONTRARY INDICATION FOR THE SUBSCRIPTION', eh: 'SENSORS MENTIONED IN THE SENSORS PART OF THE QUOTE', eq: 'THE WARRANTY IS EXTENDED AT THE LENGTH OF THE SUBSCRIPTION EXCEPT FOR TENSIOMETERS AND THE PROBE OF THE TEMPERATURE AND HUMIDITY SENSOR WHICH ARE GUARANTEED 1 YEAR', eJ: '24V AC TRANSFORMERS IF YOU CHOOSE TO AUTOMATIZE VALVES', eU: 'A 5 YEARS WARRANTY ON THE SYSTEM COMPONENTS EXCEPT FOR TENSIOMETERS AND THE PROBE OF THE TEMPERATURE AND HUMIDITY SENSOR WHICH ARE GUARANTEED 1 YEAR'};
var $author$project$Page$QuotePreview$Page$Inclusion$fr = {cV: 'INCLUSIONS', dH: 'BOÎTIER DE PUISSANCE POUR DES MOTEURS DE 100W 24V DC SI VOUS OPTEZ POUR L’AUTOMATISATION DES CÔTÉS OUVRANTS À MOINS D’UNE MENTION CONTRAIRE DANS LE DEVIS', eh: 'LES CAPTEURS MENTIONNÉS DANS LA SECTION CAPTEUR', eq: 'LA GARANTIE EST ÉTENDUE À LA DURÉE DE L’ABONNEMENT À L’EXCEPTION DES TENSIOMÈTRES ET DES SONDES DE CAPTEURS DE TEMPÉRATURE ET D\'HUMIDITÉ QUI SONT GARANTIS 1 AN', eJ: 'DES TRANSFORMATEURS 24V AC SI VOUS OPTEZ POUR L’AUTOMATISATION DE VALVES', eU: 'UNE GARANTIE DE 5 ANS SUR LES APPAREILS À L’EXCEPTION DES TENSIOMÈTRES ET DES SONDES DE CAPTEURS DE TEMPÉRATURE ET D\'HUMIDITÉ QUI SONT GARANTIS 1 AN'};
var $author$project$Page$QuotePreview$Page$Inclusion$view = F2(
	function (language, plan) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$Inclusion$en, hE: $author$project$Page$QuotePreview$Page$Inclusion$fr});
		var warranty = function () {
			if (!plan) {
				return translation.eU;
			} else {
				return translation.eq;
			}
		}();
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cV)
						])),
					A2(
					$author$project$S$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(3)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(1.2)),
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(400))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$author$project$S$li,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.eh)
								])),
							A2(
							$author$project$S$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.eJ)
								])),
							A2(
							$author$project$S$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(warranty)
								])),
							A2(
							$author$project$S$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.dH)
								]))
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Total$discountRow = F2(
	function (quote, discount) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(discount.fk)
								])),
							A2(
							$elm$core$Maybe$withDefault,
							$author$project$S$empty,
							A2(
								$elm$core$Maybe$map,
								function (precision) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$fontSize(
														$rtfeldman$elm_css$Css$em(0.8)),
														$rtfeldman$elm_css$Css$fontWeight(
														$rtfeldman$elm_css$Css$int(200))
													]))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(precision)
											]));
								},
								discount.id))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$red)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							function (str) {
								return '-' + str;
							}(
								A2(
									$author$project$Formatter$Number$formatAmount,
									quote.hg.hl,
									A2($author$project$Domain$Total$Discount$discountAmount, quote, discount))))
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Total$en = {b_: 'Category', $8: 'Price', dM: 'Price per month', ev: 'Tax not included', eF: 'TOTAL'};
var $author$project$Page$QuotePreview$Page$Total$fr = {b_: 'Catégorie', $8: 'Prix', dM: 'Prix par mois', ev: 'Taxes applicables en sus', eF: 'TOTAL'};
var $author$project$Page$QuotePreview$Page$Total$modalities = $rtfeldman$elm_css$Html$Styled$div(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$author$project$Style$Grid$displayGrid,
					$author$project$Style$Grid$rowGap(
					$rtfeldman$elm_css$Css$em(1.5)),
					$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$em(0.9)),
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$rem(3))
				]))
		]));
var $author$project$Theme$Table$totalRow = F3(
	function (numberOfColumn, name, amount) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$tr,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$colspan(numberOfColumn - 1),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$td,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								])),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$bold)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$author$project$Formatter$Number$formatInt(amount))
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Total$view = F2(
	function (language, quote) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$Total$en, hE: $author$project$Page$QuotePreview$Page$Total$fr});
		var priceInfo = function () {
			var _v0 = quote.ab;
			if (!_v0) {
				return translation.$8;
			} else {
				return translation.dM;
			}
		}();
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.eF)
						])),
					A2(
					$author$project$S$table,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					A2(
						$elm$core$List$cons,
						A2(
							$rtfeldman$elm_css$Html$Styled$thead,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$orishaGreen),
											$author$project$S$exactPrint
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.b_)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$color($author$project$Theme$Color$white)),
													$rtfeldman$elm_css$Css$width(
													$rtfeldman$elm_css$Css$em(12)),
													$rtfeldman$elm_css$Css$important(
													$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											priceInfo + (' (' + ($author$project$Domain$Total$Amount$currencyToString(quote.hg.hl) + ')')))
										]))
								])),
						_Utils_ap(
							A2(
								$elm$core$List$map,
								$author$project$Page$QuotePreview$Page$Total$discountRow(quote),
								A2($author$project$Domain$Discount$Listing$toDiscountList, language, quote)),
							_List_fromArray(
								[
									A3(
									$author$project$Theme$Table$totalRow,
									2,
									'Total',
									A2(
										$author$project$Domain$Total$Amount$inCurrency,
										quote.hg.hl,
										$author$project$Domain$Total$Discount$fromQuote(quote)))
								])))),
					$author$project$Page$QuotePreview$Page$Total$modalities(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.ev)
								]))
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Warning$en = {bc: ' above.', bK: 'To have access to the Internet, the central controller must be in a ', bN: 'additional fees may apply.', bO: 'By agreeing to this subscription, you accept that : ', bP: 'All the Orisha equipments must be at ', b0: 'checked the compatibility of your devices', b1: '(Compatibility guide)', ch: 'in a maximum of 6 months', cE: ', following the signature of the quote.', cI: 'zone with a good Wi-Fi coverage', cK: 'guaranteed for 1 year', cW: 'inclusions and exclusions', cX: 'In case of incompatibility, ', c_: 'Installation is not included', c0: 'The client at Orisha has access to their data, and Orisha possesses the data of its clients. We reserve the right to use them anonymously to continue advancing small-scale agriculture.', dc: 'less than 250 feet of the central controller', dE: 'The equiments should be fully paid, ', bx: 'You have read and understood the ', dZ: ' and to refer to it to use your Orisha system.', eg: 'The temperature and humidity sensor probes and the tensiometers are ', eu: 'You commit to take knowledge of the ', ew: 'You have read the technical sheet for the Orisha products.', ex: ' (Technical sheet)', eP: 'user guide', eT: 'WARNINGS', eV: 'warranty and responsabilities', e2: 'You have '};
var $author$project$Page$QuotePreview$Page$Warning$fr = {bc: ' ci-haut.', bK: 'Pour avoir accès à Internet, le contrôleur central doit se trouver dans une ', bN: 'des frais supplémentaires peuvent s\'appliquer.', bO: 'En acceptant ce devis, vous acceptez que : ', bP: 'Tous les équipements Orisha doivent se trouver à ', b0: 'vérifié la compatibilité de vos appareils', b1: '(Guide de compatibilité)', ch: 'dans un maximum de 6 mois', cE: ', suivant l\'acceptation de ce devis.', cI: 'zone avec une bonne couverture Wi-Fi', cK: 'garantis pour une durée de 1 an', cW: 'inclusions et exclusions', cX: 'Dans le cas d\'appareils non-compatibles, ', c_: 'L\'installation n\'est pas incluse', c0: 'Le client chez Orisha a accès à ses données et Orisha possède les données de ses clients.\nNous nous réservons le droit de les utiliser de manière anonyme pour continuer de faire avancer l\'agriculture de petite surface.', dc: 'moins de 250 pieds du contrôleur central', dE: 'L\'équipement doit être payé en totalité, ', bx: 'Vous avez lu et compris les ', dZ: ' et à vous y référer pour utiliser votre système Orisha.', eg: 'Les sondes des capteurs de température et d\'humidité et les tensiomètres sont ', eu: 'Vous vous engagez à prendre connaissance du ', ew: 'J\'ai pris connaissance de la fiche technique des produits Orisha.', ex: ' (Fiche technique)', eP: 'guide d\'utilisateur', eT: 'AVERTISSEMENTS', eV: 'termes de garanties et responsabilités', e2: 'Vous avez '};
var $author$project$Page$QuotePreview$Page$Warning$item = function (content) {
	return A2(
		$author$project$S$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(1.5)),
								$rtfeldman$elm_css$Css$marginRight(
								$rtfeldman$elm_css$Css$rem(1.5))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('□')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$alignSelf($rtfeldman$elm_css$Css$center)
							]))
					]),
				content)
			]));
};
var $author$project$Page$QuotePreview$Page$Warning$signature = function () {
	var line = A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$marginLeft(
						$rtfeldman$elm_css$Css$rem(0.8)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$rem(1.5)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$rem(15)),
						A2(
						$rtfeldman$elm_css$Css$borderBottom2,
						$rtfeldman$elm_css$Css$px(1),
						$rtfeldman$elm_css$Css$solid)
					]))
			]),
		_List_Nil);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$rem(1.1)),
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$flexEnd),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$flexEnd),
						$rtfeldman$elm_css$Css$marginTop(
						$rtfeldman$elm_css$Css$rem(1)),
						$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1.5))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Signature')
							])),
						line
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row)
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Date')
							])),
						line
					]))
			]));
}();
var $author$project$DocumentLinks$technicalList = function (language) {
	if (!language) {
		return 'https://docs.google.com/spreadsheets/d/1GLWIsP4Cl1D9NCNt2_EiNR9QbQnO_cFek6lP5Y9TFQQ#gid=417902250';
	} else {
		return 'https://docs.google.com/spreadsheets/d/1GLWIsP4Cl1D9NCNt2_EiNR9QbQnO_cFek6lP5Y9TFQQ#gid=1635297855';
	}
};
var $author$project$Style$List$ul2 = function (attrs) {
	return $rtfeldman$elm_css$Html$Styled$ul(
		A2(
			$elm$core$List$cons,
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$author$project$Style$Grid$displayGrid,
						$author$project$Style$Grid$rowGap(
						$rtfeldman$elm_css$Css$em(0.75)),
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$em(1)),
						$rtfeldman$elm_css$Css$listStyle($rtfeldman$elm_css$Css$none)
					])),
			attrs));
};
var $author$project$S$ul2 = $author$project$Style$List$ul2;
var $author$project$Page$QuotePreview$Page$Warning$view = F2(
	function (language, plan) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$Warning$en, hE: $author$project$Page$QuotePreview$Page$Warning$fr});
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.eT)
						])),
					A2(
					$author$project$S$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.bO)
						])),
					A2(
					$author$project$S$ul2,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(2)),
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(400))
								]))
						]),
					_List_fromArray(
						[
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.bx),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cW)
										])),
									$rtfeldman$elm_css$Html$Styled$text(translation.bc)
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.bx),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.eV)
										])),
									$rtfeldman$elm_css$Html$Styled$text(translation.bc)
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.eu),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.eP)
										])),
									$rtfeldman$elm_css$Html$Styled$text(translation.dZ)
								])),
							function () {
							if (!plan) {
								return $author$project$Page$QuotePreview$Page$Warning$item(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.eg),
											A2(
											$rtfeldman$elm_css$Html$Styled$u,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(translation.cK)
												])),
											$rtfeldman$elm_css$Html$Styled$text('.')
										]));
							} else {
								return $author$project$S$empty;
							}
						}(),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.c_)
										])),
									$rtfeldman$elm_css$Html$Styled$text('.')
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.bP),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dc)
										])),
									$rtfeldman$elm_css$Html$Styled$text('.')
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.bK),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cI)
										])),
									$rtfeldman$elm_css$Html$Styled$text('.')
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.e2),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.b0)
										])),
									$rtfeldman$elm_css$Html$Styled$text('. '),
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
											$rtfeldman$elm_css$Html$Styled$Attributes$href(
											$author$project$DocumentLinks$compatibleDevices(language))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.b1)
										]))
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.c0)
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.cX),
									A2(
									$rtfeldman$elm_css$Html$Styled$u,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.bN)
										]))
								])),
							$author$project$Page$QuotePreview$Page$Warning$item(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.ew),
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
											$rtfeldman$elm_css$Html$Styled$Attributes$href(
											$author$project$DocumentLinks$technicalList(language))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.ex)
										]))
								])),
							function () {
							if (!plan) {
								return $author$project$Page$QuotePreview$Page$Warning$item(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.dE),
											A2(
											$rtfeldman$elm_css$Html$Styled$u,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(translation.ch)
												])),
											$rtfeldman$elm_css$Html$Styled$text(translation.cE)
										]));
							} else {
								return $author$project$S$empty;
							}
						}()
						])),
					$author$project$Page$QuotePreview$Page$Warning$signature
				]));
	});
var $author$project$Page$QuotePreview$Page$Warranty$en = {
	b3: 'Contact us at ',
	cs: 'Equipments ownership',
	ct: 'The equipment provided is leased and remains the property of Automatisation Orisha Inc. This equipment must be returned at your expense if you decide to unsuscribe.',
	cu: 'Example :',
	cv: 'excepted for the tensiometers and the temperature and humidity sensor which have a 1 year warranty.',
	cL: 'Garantees',
	cM: 'GUARANTEES AND LIABILITIES',
	bn: 'GUARANTEES AND LIABILITIES (NEXT)',
	cP: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Bad connection')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Water damage')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Broken tensiometer')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Box left open and exposed to moisture')
				]))
		]),
	cS: 'Systems purchased do not include feature development and update service. These systems have a frozen version which will be kept running on our servers. Orisha uses the latest technologies available as well as the latest upgrades. The choice of technologies by Orisha is made considering the future availability of these technologies. In spite of that, Orisha cannot guarantee the availability of these technologies in the future, which is beyond its control.',
	cT: 'In all cases, the system runs directly on the farm, locally on your Orisha controller. You can connect directly to it without going through our servers.',
	cU: 'Subscription systems are kept up to date with the latest features developed by Orisha Automation Inc. With the subscription, we are committed to assuming for you the risks associated with changes in the world of technology.',
	da: 'When replacing equipment, labor costs (e.g. disconnection and reconnection of wires) are the responsibility of the customer.',
	db: 'The labor costs for the replacement are the responsibility of the customer.',
	dI: 'The warranty also does not cover breakage resulting from a power surge. ',
	dR: 'Make sure you protect your devices with a surge-limiting device.',
	d3: 'Orisha Automation Inc. can in no way be held responsible for a loss of agricultural yield, a loss of harvest or a loss of any other nature even if this or these losses result directly from a manufacturing or operating defect of a device sold by Orisha.',
	d4: 'Limitation of responsability',
	d5: 'Return logistics',
	d6: 'In the event of a defect resulting from a manufacturing defect, a replacement device will be sent by mail. ',
	d7: ' to obtain a return merchandise number.',
	ec: '30 days satisfaction',
	ed: 'If within 30 days of purchase you are not completely satisfied with the products you have purchased, return the item to us and we will exchange or refund it to you. Shipping and handling costs are your responsibility.',
	eo: 'Software maintenance',
	eD: 'Times may vary depending on the speed of the postal service and the availability of replacement parts. The cost of returning defective equipment under warranty is the responsibility of Orisha Automation Inc. The customer must first obtain a return number.\n',
	eW: 'However, the warranty does not cover breakage resulting from improper use of the devices.',
	eX: 'The length of the warranty for every sold product is of 5 years ',
	eY: 'The warranty for every sold product is extended at the length of the subscription ',
	eZ: 'Warranty limitations',
	e_: 'All equipment is guaranteed against any manufacturing defect. If a device ceases to function normally during the warranty period, Orisha Automation Inc. will replace it for you free of charge.'
};
var $rtfeldman$elm_css$Html$Styled$b = $rtfeldman$elm_css$Html$Styled$node('b');
var $rtfeldman$elm_css$Css$justify = $rtfeldman$elm_css$Css$prop1('justify');
var $author$project$Page$QuotePreview$Page$Warranty$firstPage = F2(
	function (translation, plan) {
		var warrantyDuration = function () {
			if (!plan) {
				return translation.eX;
			} else {
				return translation.eY;
			}
		}();
		var softwareUpgrades = function () {
			if (!plan) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(0.5)),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.9))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.cS)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(0.5)),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.9))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.cT)
								]))
						]));
			} else {
				return A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$rem(0.5)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cU)
						]));
			}
		}();
		var infoEmail = 'info@orisha.io';
		return A2(
			$author$project$Page$QuotePreview$Common$page,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$author$project$S$h3,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cM)
						])),
					A2(
					$author$project$S$h4,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(1000)),
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.eo)
						])),
					softwareUpgrades,
					A2(
					$author$project$S$h4,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(1000)),
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(3))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.cL)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginTop(
									$rtfeldman$elm_css$Css$rem(0.5)),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(warrantyDuration),
							A2(
							$rtfeldman$elm_css$Html$Styled$u,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$b,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$rtfeldman$elm_css$Css$fontSize(
													$rtfeldman$elm_css$Css$rem(0.9))
												]))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(translation.cv)
										]))
								]))
						])),
					function () {
					if (!plan) {
						return A2(
							$author$project$S$h4,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(1000)),
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(3))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.ec)
								]));
					} else {
						return $author$project$S$empty;
					}
				}(),
					function () {
					if (!plan) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(0.5)),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.9))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.ed)
								]));
					} else {
						return $author$project$S$empty;
					}
				}(),
					function () {
					if (!plan) {
						return $author$project$S$empty;
					} else {
						return A2(
							$author$project$S$h4,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(1000)),
											$rtfeldman$elm_css$Css$marginTop(
											$rtfeldman$elm_css$Css$rem(3))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.cs)
								]));
					}
				}(),
					function () {
					if (!plan) {
						return $author$project$S$empty;
					} else {
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
											$rtfeldman$elm_css$Css$marginBottom(
											$rtfeldman$elm_css$Css$rem(0.5)),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$rem(0.9))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.ct)
								]));
					}
				}(),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$rem(0.9))
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(translation.b3),
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href(infoEmail)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(infoEmail)
								])),
							$rtfeldman$elm_css$Html$Styled$text(translation.d7)
						]))
				]));
	});
var $author$project$Page$QuotePreview$Page$Warranty$fr = {
	b3: 'Écrivez-nous à ',
	cs: 'Propriété des équipements',
	ct: 'Le matériel fourni est loué et demeure la propriété d\'Automatisation Orisha Inc. Ce matériel doit être retourné à vos frais si vous décidez de vous désinscrire.',
	cu: 'Exemple :',
	cv: 'excepté pour les tensiomètres et les sondes de capteurs de température et d\'humidité dont la garantie est de 1 an.',
	cL: 'Garanties',
	cM: 'GARANTIES ET RESPONSABILITÉS',
	bn: 'GARANTIES ET RESPONSABILITÉS (SUITE)',
	cP: _List_fromArray(
		[
			A2(
			$author$project$S$li,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Mauvais branchement')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Dégât d\'eau')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Tensiomètre cassé')
				])),
			A2(
			$author$project$S$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$marginTop(
							$rtfeldman$elm_css$Css$rem(-0.5))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Boîtier laissé ouvert et exposé à l’humidité')
				]))
		]),
	cS: 'Les systèmes achetés ne comprennent pas le service de développement et de mise à jour des fonctionnalités. Ces systèmes ont une version figée qui sera maintenue en fonction sur nos serveurs. Ceci dit, Orisha utilise au moment de la vente les plus récentes technologies disponibles et les dernières mises à jour. Le choix de ces technologies par Orisha est notamment fait en fonction de la disponibilité future de ces technologies. Malgré cela, Orisha ne peut pas et ne garantie pas la disponibilité de ces technologies dans le futur, laquelle est hors de son contrôle.',
	cT: 'Dans tous les cas, le système roule directement à la ferme, localement sur votre contrôleur Orisha. Vous pouvez vous connecter directement dessus sans passer par nos serveurs.',
	cU: 'Les systèmes en abonnement sont maintenus à jour avec les dernières fonctionnalités développées par Automatisation Orisha. Avec l’abonnement, nous nous engageons à assumer pour vous les risques reliés aux changements dans le monde des technologies.',
	da: 'Lors d\'un remplacement d\'équipement, les frais de main-d\'œuvre (ex. débranchement et rebranchement des fils) sont à la charge du client.',
	db: 'Les frais de main-d\'œuvre pour le remplacement sont à la charge du client.',
	dI: 'La garantie ne couvre pas non plus des bris résultants d’une surtension. ',
	dR: 'Assurez-vous de bien protéger vos appareils avec un dispositif limitant la surtension.',
	d3: 'Automatisation Orisha inc. ne peut en aucun cas être tenue responsable d’une perte de rendement agricole, d’une perte de récolte ou d’une perte de quelconque autre nature même si cette ou ces pertes résultent directement d’un défaut de fabrication ou de fonctionnement d’un appareil vendu par Orisha.',
	d4: 'Limitations de la responsabilité',
	d5: 'Logistique de retour',
	d6: 'En cas de défectuosité résultant d’un défaut de fabrication, un appareil de remplacement sera envoyé par la poste. ',
	d7: ' pour obtenir un numéro de retour de marchandise.',
	ec: 'Satisfaction 30 jours',
	ed: 'Si dans les 30 jours suivant l’achat, vous n’êtes pas entièrement satisfait par les produits que vous avez achetés, retournez-nous l’article et nous vous l’échangerons ou le rembourserons. Les frais d’expéditions et de manutention sont à votre charge.',
	eo: 'Maintien du logiciel',
	eD: 'Les délais peuvent varier en fonction de la rapidité du service postal et de la disponibilité des pièces de remplacement. Les frais de retour des équipements défectueux sous garantie sont à la charge d’Automatisation Orisha inc. Le client doit obtenir au préalable un numéro de retour.',
	eW: 'La garantie ne couvre cependant pas les bris résultant d’une mauvaise utilisation des appareils.',
	eX: 'La durée de la garantie pour chacun des produits vendus est de 5 ans ',
	eY: 'La garantie pour chacun des produits vendus s\'étend à la durée de l\'abonnement ',
	eZ: 'Limitations de garantie',
	e_: 'Tous les équipements sont garantis contre tout défaut de fabrication. Si un appareil cessait de fonctionner normalement au cours de la période couverte par la garantie, Automatisation Orisha inc. vous le remplacera gratuitement.'
};
var $author$project$Page$QuotePreview$Page$Warranty$secondPage = function (translation) {
	return A2(
		$author$project$Page$QuotePreview$Common$page,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$S$h3,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.bn)
					])),
				A2(
				$author$project$S$h4,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(1000)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(3))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.eZ)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.e_)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9)),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(800))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.eW)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(0.5)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.cu)
					])),
				A2(
				$author$project$S$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				translation.cP),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.dI)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9)),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(800))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.dR)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginBottom(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9)),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(800))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.da)
					])),
				A2(
				$author$project$S$h4,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(1000)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(3))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.d4)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.d3)
					]))
			]));
};
var $author$project$Page$QuotePreview$Page$Warranty$thirdPage = function (translation) {
	return A2(
		$author$project$Page$QuotePreview$Common$page,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$author$project$S$h3,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$color($author$project$Theme$Color$orishaGreen)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.bn)
					])),
				A2(
				$author$project$S$h4,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(1000)),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(3))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.d5)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(0.5)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.d6),
						A2(
						$rtfeldman$elm_css$Html$Styled$u,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$b,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$fontSize(
												$rtfeldman$elm_css$Css$rem(0.9))
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(translation.db)
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$p,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$justify),
								$rtfeldman$elm_css$Css$marginTop(
								$rtfeldman$elm_css$Css$rem(1)),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$rem(0.9))
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(translation.eD)
					]))
			]));
};
var $author$project$Page$QuotePreview$Page$Warranty$view = F2(
	function (language, plan) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$Page$Warranty$en, hE: $author$project$Page$QuotePreview$Page$Warranty$fr});
		return _List_fromArray(
			[
				A2($author$project$Page$QuotePreview$Page$Warranty$firstPage, translation, plan),
				$author$project$Page$QuotePreview$Page$Warranty$secondPage(translation),
				$author$project$Page$QuotePreview$Page$Warranty$thirdPage(translation)
			]);
	});
var $author$project$Page$QuotePreview$View$view = F5(
	function (language, now, here, quote, user) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Page$QuotePreview$View$en, hE: $author$project$Page$QuotePreview$View$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$author$project$Style$Grid$displayGrid,
							$author$project$Style$Grid$justifyItems('center'),
							$author$project$Style$Grid$rowGap(
							$rtfeldman$elm_css$Css$rem(2)),
							$rtfeldman$elm_css$Css$paddingTop(
							$rtfeldman$elm_css$Css$rem(5)),
							$rtfeldman$elm_css$Css$paddingBottom(
							$rtfeldman$elm_css$Css$rem(5)),
							$author$project$S$onPrint(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
									$rtfeldman$elm_css$Css$padding(
									$rtfeldman$elm_css$Css$px(0))
								]))
						]))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						_List_fromArray(
						[
							A5($author$project$Page$QuotePreview$View$page1, language, translation, now, here, quote)
						]),
						A2($author$project$Page$QuotePreview$Page$EquipmentNeed$view, language, quote),
						function () {
						var _v0 = quote.ab;
						if (!_v0) {
							return A3($author$project$Page$QuotePreview$Page$EquipmentPrice$view, language, quote, user);
						} else {
							return _List_Nil;
						}
					}(),
						_List_fromArray(
						[
							A2($author$project$Page$QuotePreview$Page$Total$view, language, quote),
							$author$project$Page$QuotePreview$Page$Confidence$view(language),
							A2($author$project$Page$QuotePreview$Page$Inclusion$view, language, quote.ab),
							$author$project$Page$QuotePreview$Page$Exclusion$view(language)
						]),
						A2($author$project$Page$QuotePreview$Page$Warranty$view, language, quote.ab),
						_List_fromArray(
						[
							A2($author$project$Page$QuotePreview$Page$Warning$view, language, quote.ab)
						])
					])));
	});
var $author$project$View$content = F2(
	function (user, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			model.hR,
			{hw: $author$project$View$en, hE: $author$project$View$fr});
		var quoteV3 = $author$project$Page$QuoteBuilderV3$Update$toQuote(model.aD);
		var quoteLanguage = model.aC.hg.hR;
		var quote = $author$project$Page$QuoteBuilder$Update$toQuote(model.aC);
		var body = function () {
			var _v0 = model.a2;
			switch (_v0) {
				case 0:
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$View$mainBox,
								model.hR,
								A2(
									$rtfeldman$elm_css$Html$Styled$map,
									$author$project$Update$QuoteBuilderMsg,
									A3($author$project$Page$QuoteBuilder$View$view, model.hR, model.aC, user))),
								A4($author$project$View$total, model.hR, user, model.bp, quote)
							]));
				case 1:
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$author$project$S$onPrint(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
													]))
											]))
									]),
								_List_fromArray(
									[
										A2(
										$author$project$View$mainBox,
										model.hR,
										A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Update$QuoteBuilderV3Msg,
											A3($author$project$Page$QuoteBuilderV3$View$view, model.hR, user, model.aD))),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
														$rtfeldman$elm_css$Css$top(
														$rtfeldman$elm_css$Css$vh(2.5)),
														$rtfeldman$elm_css$Css$right(
														$rtfeldman$elm_css$Css$vw(3))
													]))
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$box),
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$width(
																$rtfeldman$elm_css$Css$vw(20))
															]))
													]),
												_List_fromArray(
													[
														A2($author$project$Page$QuoteBuilderV3$Total$view, model.hR, model.aD)
													])),
												A2(
												$author$project$S$printButton,
												translation.bw,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Style$Bulma$box),
														$rtfeldman$elm_css$Html$Styled$Attributes$css(
														_List_fromArray(
															[
																$rtfeldman$elm_css$Css$padding(
																$rtfeldman$elm_css$Css$rem(0.5)),
																$rtfeldman$elm_css$Css$opacity(
																$rtfeldman$elm_css$Css$num(1)),
																$rtfeldman$elm_css$Css$zIndex(
																$rtfeldman$elm_css$Css$int(10)),
																$rtfeldman$elm_css$Css$backgroundColor($author$project$Theme$Color$white)
															])),
														$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Update$Print)
													]))
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$author$project$S$notOnPrint(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
													]))
											]))
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$author$project$S$empty,
										A3(
											$elm$core$Maybe$map2,
											F2(
												function (now, timezone) {
													return A3($author$project$Page$QuoteBuilderV3$Preview$View$view, now, timezone, model.aD);
												}),
											model.fn,
											model.fw))
									]))
							]));
				case 2:
					return A2(
						$author$project$View$printLayout,
						model,
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$S$empty,
							A3(
								$elm$core$Maybe$map2,
								F2(
									function (now, timezone) {
										return A5($author$project$Page$QuotePreview$View$view, quoteLanguage, now, timezone, quote, user);
									}),
								model.fn,
								model.fw)));
				case 3:
					return A2(
						$author$project$View$printLayout,
						model,
						A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$View$mainBox,
									model.hR,
									A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Update$PackingSlipMsg,
										A3($author$project$Page$PackingSlip$view, quote, true, model.dC))),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$author$project$S$notOnPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
														])),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Css$property, 'break-after', 'auto'),
															A2($rtfeldman$elm_css$Css$property, 'break-inside', 'avoid')
														]))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Update$PackingSlipMsg,
											A3($author$project$Page$PackingSlip$view, quote, false, model.dC))
										]))
								])));
				default:
					return A2(
						$author$project$View$printLayout,
						model,
						A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$author$project$View$mainBox,
									model.hR,
									A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Update$PackingSlipV3Msg,
										A4($author$project$Page$PackingSlipV3$view, quote, quoteV3, true, model.dD))),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$css(
											_List_fromArray(
												[
													$author$project$S$notOnPrint(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none)
														])),
													$author$project$S$onPrint(
													_List_fromArray(
														[
															A2($rtfeldman$elm_css$Css$property, 'break-before', 'always'),
															A2($rtfeldman$elm_css$Css$property, 'break-inside', 'avoid')
														]))
												]))
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Update$PackingSlipV3Msg,
											A4($author$project$Page$PackingSlipV3$view, quote, quoteV3, false, model.dD))
										]))
								])));
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$Menu$view, model.hR, user, model.a2),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$vw(24)),
									$author$project$S$onPrint(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$marginLeft(
											$rtfeldman$elm_css$Css$px(0))
										]))
								]))
						]),
					_List_fromArray(
						[body]))
				]));
	});
var $author$project$Update$LoginMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Login$Page$ComponentsMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Login$Page$Submit = {$: 1};
var $author$project$Login$Page$en = {dt: 'No user with these informations', em: 'Sign in'};
var $author$project$Login$Page$fr = {dt: 'Aucun collaborateur avec ces informations', em: 'Se connecter'};
var $rtfeldman$elm_css$Html$Styled$Attributes$alt = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('alt');
var $rtfeldman$elm_css$Css$auto = {g2: 0, d: 0, aV: 0, c$: 0, hQ: 0, aZ: 0, ao: 0, ah: 0, a1: 0, ac: 0, et: 0, a7: 0, T: 0, gU: 'auto'};
var $author$project$Login$Components$en = {Q: 'Password', dX: 'Quoting tool', U: 'Username'};
var $author$project$Login$Components$fr = {Q: 'Mot de passe', dX: 'Outil de devis', U: 'Nom d\'utilisateur'};
var $rtfeldman$elm_css$Css$overflowX = $rtfeldman$elm_css$Css$prop1('overflow-x');
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $rtfeldman$elm_css$Css$padding4 = $rtfeldman$elm_css$Css$prop4('padding');
var $author$project$Login$Components$layout = F2(
	function (language, body) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Login$Components$en, hE: $author$project$Login$Components$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$flex(
							$rtfeldman$elm_css$Css$int(1)),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$backgroundColor(
									A2($author$project$Theme$Color$withOpacity, 0.7, $author$project$Theme$Color$white)),
									$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$auto),
									$rtfeldman$elm_css$Css$overflowX($rtfeldman$elm_css$Css$hidden),
									$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$auto),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$vh(100)),
									A4(
									$rtfeldman$elm_css$Css$padding4,
									$rtfeldman$elm_css$Css$rem(4),
									$rtfeldman$elm_css$Css$rem(18),
									$rtfeldman$elm_css$Css$rem(6),
									$rtfeldman$elm_css$Css$rem(18))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$px(300)),
											$rtfeldman$elm_css$Css$paddingBottom(
											$rtfeldman$elm_css$Css$rem(2.5))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$img,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$src($author$project$Theme$ImgPath$logo),
											$rtfeldman$elm_css$Html$Styled$Attributes$alt('Orisha\'s logo')
										]),
									_List_Nil)
								])),
							A2(
							$author$project$S$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.dX)
								])),
							body
						]))
				]));
	});
var $author$project$Style$Status$errorLine = function (errorMsg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$em,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$em(0.8)),
						$rtfeldman$elm_css$Css$color($author$project$Theme$Color$brightRed)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(errorMsg)
			]));
};
var $author$project$Style$Status$maybeErrorLine = function (errorMsg) {
	return A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2($elm$core$Maybe$map, $author$project$Style$Status$errorLine, errorMsg));
};
var $author$project$S$maybeErrorLine = $author$project$Style$Status$maybeErrorLine;
var $rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onSubmit = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $author$project$Login$Components$Password = function (a) {
	return {$: 1, a: a};
};
var $author$project$Login$Components$passwordField = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Login$Components$en, hE: $author$project$Login$Components$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginRight(
									$rtfeldman$elm_css$Css$em(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('label')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.Q)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$S$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.Q),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('password'),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$width(
											$rtfeldman$elm_css$Css$em(10)),
											$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Login$Components$Password)
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Style$Buttons$blueButton = $rtfeldman$elm_css$Css$color($author$project$Theme$Color$linkBlue);
var $rtfeldman$elm_css$Css$textDecoration = $rtfeldman$elm_css$Css$prop1('text-decoration');
var $author$project$Style$Buttons$submitButton = F2(
	function (buttonText, attrs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			A2(
				$elm$core$List$append,
				attrs,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
								$rtfeldman$elm_css$Css$backgroundColor($rtfeldman$elm_css$Css$transparent),
								$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer)
							]))
					])),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$em(2)),
									$author$project$Style$Buttons$blueButton,
									$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
									$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
								]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(buttonText)
						]))
				]));
	});
var $author$project$S$submitButton = $author$project$Style$Buttons$submitButton;
var $author$project$Login$Page$translateError = F2(
	function (language, _v0) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Login$Page$en, hE: $author$project$Login$Page$fr});
		return translation.dt;
	});
var $author$project$Login$Components$Username = function (a) {
	return {$: 0, a: a};
};
var $author$project$Login$Components$usernameField = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Login$Components$en, hE: $author$project$Login$Components$fr});
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceBetween),
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$marginBottom(
							$rtfeldman$elm_css$Css$em(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$p,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$marginRight(
									$rtfeldman$elm_css$Css$em(1))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('label')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(translation.U)
								]))
						])),
					A2(
					$author$project$S$input,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value(model.U),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$em(10)),
									$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
								])),
							$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Login$Components$Username)
						]),
					_List_Nil)
				]));
	});
var $author$project$Login$Page$view = F2(
	function (language, model) {
		var translation = A2(
			$author$project$Language$getTranslation,
			language,
			{hw: $author$project$Login$Page$en, hE: $author$project$Login$Page$fr});
		return A2(
			$author$project$Login$Components$layout,
			language,
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(300))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$form,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Login$Page$Submit)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
												$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
												$rtfeldman$elm_css$Css$marginTop(
												$rtfeldman$elm_css$Css$rem(6))
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Login$Page$ComponentsMsg,
										A2($author$project$Login$Components$usernameField, language, model.al)),
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Login$Page$ComponentsMsg,
										A2($author$project$Login$Components$passwordField, language, model.al))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$displayFlex,
												$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
												$rtfeldman$elm_css$Css$paddingTop(
												$rtfeldman$elm_css$Css$rem(2))
											]))
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
													]))
											]),
										_List_fromArray(
											[
												$author$project$S$maybeErrorLine(
												A2(
													$elm$core$Maybe$map,
													$author$project$Login$Page$translateError(language),
													model.bk))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$css(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Css$marginTop(
														$rtfeldman$elm_css$Css$rem(1)),
														$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center)
													]))
											]),
										_List_fromArray(
											[
												A2($author$project$S$submitButton, translation.em, _List_Nil)
											]))
									]))
							]))
					])));
	});
var $author$project$View$loginView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$map,
		$author$project$Update$LoginMsg,
		A2($author$project$Login$Page$view, model.hR, model.df));
};
var $author$project$Update$topAnchor = 'top';
var $author$project$View$view = function (model) {
	var page = function (f) {
		return f(model);
	}(
		A2(
			$elm$core$Maybe$withDefault,
			$author$project$View$loginView,
			A2($elm$core$Maybe$map, $author$project$View$content, model.aJ)));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id($author$project$Update$topAnchor)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
								$rtfeldman$elm_css$Css$backgroundImage(
								$rtfeldman$elm_css$Css$url($author$project$Theme$ImgPath$background)),
								$rtfeldman$elm_css$Css$backgroundSize($rtfeldman$elm_css$Css$cover),
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$zIndex(
								$rtfeldman$elm_css$Css$int(-10))
							]))
					]),
				_List_Nil),
				page
			]));
};
var $author$project$Main$mainView = function (model) {
	return {
		hb: _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$toUnstyled(
				$author$project$View$view(model))
			]),
		iA: 'Orisha - Soumission'
	};
};
var $author$project$Port$sessionSaved = _Platform_incomingPort('sessionSaved', $elm$json$Json$Decode$string);
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm_community$easing_functions$Ease$flip = F2(
	function (easing, time) {
		return 1 - easing(1 - time);
	});
var $elm_community$easing_functions$Ease$inQuint = function (time) {
	return A2($elm$core$Basics$pow, time, 5);
};
var $elm_community$easing_functions$Ease$outQuint = $elm_community$easing_functions$Ease$flip($elm_community$easing_functions$Ease$inQuint);
var $linuss$smooth_scroll$SmoothScroll$defaultConfig = {fc: $elm_community$easing_functions$Ease$outQuint, c: 12, iv: 200};
var $author$project$Port$deleteSession = _Platform_outgoingPort(
	'deleteSession',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Port$print = _Platform_outgoingPort(
	'print',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Update$resetForms = function (model) {
	return _Utils_update(
		model,
		{
			aC: A2($author$project$Page$QuoteBuilder$Update$initialModel, model.aJ, $elm$core$Maybe$Nothing),
			aD: A2($author$project$Page$QuoteBuilderV3$Update$initialModel, model.aJ, $elm$core$Maybe$Nothing)
		});
};
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $linuss$smooth_scroll$Internal$SmoothScroll$animationSteps = F4(
	function (speed, easing, start, stop) {
		var operator = (_Utils_cmp(start, stop) > 0) ? $elm$core$Basics$sub : $elm$core$Basics$add;
		var diff = $elm$core$Basics$abs(start - stop);
		var frames = A2(
			$elm$core$Basics$max,
			1,
			($elm$core$Basics$round(diff) / speed) | 0);
		var framesFloat = frames;
		var weights = A2(
			$elm$core$List$map,
			function (i) {
				return easing(i / framesFloat);
			},
			A2($elm$core$List$range, 0, frames));
		return ((speed <= 0) || _Utils_eq(start, stop)) ? _List_Nil : A2(
			$elm$core$List$map,
			function (weight) {
				return A2(operator, start, weight * diff);
			},
			weights);
	});
var $elm$browser$Browser$Dom$getElement = _Browser_getElement;
var $elm$browser$Browser$Dom$getViewport = _Browser_withWindow(_Browser_getViewport);
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $linuss$smooth_scroll$SmoothScroll$scrollToWithOptions = F2(
	function (config, id) {
		var tasks = F2(
			function (from, to) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						$elm$browser$Browser$Dom$setViewport(0),
						A4($linuss$smooth_scroll$Internal$SmoothScroll$animationSteps, config.iv, config.fc, from, to - config.c)));
			});
		return A2(
			$elm$core$Task$andThen,
			function (_v0) {
				var viewport = _v0.a.gV;
				var element = _v0.b.hv;
				return A2(tasks, viewport.gZ, element.gZ);
			},
			A3(
				$elm$core$Task$map2,
				$elm$core$Tuple$pair,
				$elm$browser$Browser$Dom$getViewport,
				$elm$browser$Browser$Dom$getElement(id)));
	});
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$Login$Page$UnaccreditedUser = 0;
var $author$project$Domain$User$encode = function (user) {
	return $elm$core$String$fromInt(user.y);
};
var $author$project$Login$Page$getUser = F2(
	function (username, password) {
		var compareUserCredentials = function (accreditedUser) {
			return _Utils_eq(
				_Utils_Tuple2(
					$elm$core$String$toLower(accreditedUser.U),
					$elm$core$String$toLower(accreditedUser.Q)),
				_Utils_Tuple2(
					$elm$core$String$toLower(username),
					$elm$core$String$toLower(password)));
		};
		return $elm$core$List$head(
			A2($elm$core$List$filter, compareUserCredentials, $author$project$Domain$User$accreditedUsers));
	});
var $author$project$Port$saveSession = _Platform_outgoingPort('saveSession', $elm$json$Json$Encode$string);
var $author$project$Login$Components$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var input = msg.a;
			return _Utils_update(
				model,
				{U: input});
		} else {
			var input = msg.a;
			return _Utils_update(
				model,
				{Q: input});
		}
	});
var $author$project$Login$Page$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var subMsg = msg.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						al: A2($author$project$Login$Components$update, subMsg, model.al)
					}),
				$elm$core$Platform$Cmd$none);
		} else {
			var username = model.al.U;
			var password = model.al.Q;
			var _v1 = A2($author$project$Login$Page$getUser, username, password);
			if (!_v1.$) {
				var user = _v1.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bk: $elm$core$Maybe$Nothing}),
					$author$project$Port$saveSession(
						$author$project$Domain$User$encode(user)));
			} else {
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bk: $elm$core$Maybe$Just(0)
						}),
					$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Port$copyToClipboard = _Platform_outgoingPort('copyToClipboard', $elm$json$Json$Encode$string);
var $author$project$Page$PackingSlip$update = F2(
	function (model, msg) {
		var snippet = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{b5: true}),
			$author$project$Port$copyToClipboard(snippet));
	});
var $author$project$Page$PackingSlipV3$update = F2(
	function (model, msg) {
		var snippet = msg;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{b5: true}),
			$author$project$Port$copyToClipboard(snippet));
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Update$EnsureBasicModeProducts = {$: 2};
var $author$project$Page$QuoteBuilder$Greenhouse$Update$InEdition = function (a) {
	return {$: 0, a: a};
};
var $elm_community$list_extra$List$Extra$reverseAppend = F2(
	function (list1, list2) {
		return A3($elm$core$List$foldl, $elm$core$List$cons, list2, list1);
	});
var $elm_community$list_extra$List$Extra$removeHelp = F4(
	function (list, x, xs, previousElements) {
		removeHelp:
		while (true) {
			if (!xs.b) {
				return list;
			} else {
				var y = xs.a;
				var ys = xs.b;
				if (_Utils_eq(x, y)) {
					return A2($elm_community$list_extra$List$Extra$reverseAppend, previousElements, ys);
				} else {
					var $temp$list = list,
						$temp$x = x,
						$temp$xs = ys,
						$temp$previousElements = A2($elm$core$List$cons, y, previousElements);
					list = $temp$list;
					x = $temp$x;
					xs = $temp$xs;
					previousElements = $temp$previousElements;
					continue removeHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$remove = F2(
	function (x, xs) {
		return A4($elm_community$list_extra$List$Extra$removeHelp, xs, x, xs, _List_Nil);
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$Edit = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, list),
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $author$project$Page$QuoteBuilder$CustomField$Form$Update$update = F3(
	function (currency, msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_update(
					model,
					{fk: name});
			case 1:
				var price = msg.a;
				var formattedPrice = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Domain$Total$Amount$free,
					A2(
						$elm$core$Maybe$map,
						$author$project$Domain$Total$Amount$fromCurrency(currency),
						$elm$core$String$toInt(price)));
				return _Utils_update(
					model,
					{$8: formattedPrice});
			case 2:
				var quantity = msg.a;
				var formattedQuantity = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(quantity));
				return _Utils_update(
					model,
					{ih: formattedQuantity});
			case 3:
				var device = msg.a;
				return _Utils_update(
					model,
					{hr: device});
			default:
				var quantity = msg.a;
				var formattedQuantity = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(quantity));
				return _Utils_update(
					model,
					{il: formattedQuantity});
		}
	});
var $author$project$Page$QuoteBuilder$CustomField$Update$update = F3(
	function (currency, msg, model) {
		var initialCustomProduct = {hr: 0, fk: '', $8: $author$project$Domain$Total$Amount$free, ih: 0, il: 0};
		var customProducts = model.hn;
		switch (msg.$) {
			case 0:
				var index = msg.a;
				var customProduct = A2($elm_community$list_extra$List$Extra$getAt, index, customProducts);
				if (!customProduct.$) {
					var c = customProduct.a;
					return _Utils_update(
						model,
						{
							K: A2($author$project$Page$QuoteBuilder$CustomField$Update$Edit, index, c)
						});
				} else {
					return model;
				}
			case 1:
				var _v2 = model.K;
				if (!_v2.$) {
					var index = _v2.a;
					var edited = _v2.b;
					return _Utils_update(
						model,
						{
							hn: A3($elm_community$list_extra$List$Extra$setAt, index, edited, customProducts),
							K: $author$project$Page$QuoteBuilder$CustomField$Update$Edited
						});
				} else {
					return model;
				}
			case 2:
				var _v3 = model.K;
				if (!_v3.$) {
					var index = _v3.a;
					var possibleNewCustomProduct = A2($elm_community$list_extra$List$Extra$getAt, index, customProducts);
					return (!_Utils_eq(
						possibleNewCustomProduct,
						$elm$core$Maybe$Just(initialCustomProduct))) ? _Utils_update(
						model,
						{K: $author$project$Page$QuoteBuilder$CustomField$Update$Edited}) : _Utils_update(
						model,
						{
							hn: A2($elm_community$list_extra$List$Extra$removeAt, index, customProducts),
							K: $author$project$Page$QuoteBuilder$CustomField$Update$Edited
						});
				} else {
					return model;
				}
			case 3:
				var index = msg.a;
				return _Utils_update(
					model,
					{
						hn: A2($elm_community$list_extra$List$Extra$removeAt, index, customProducts)
					});
			case 4:
				var updatedCustomProducts = _Utils_ap(
					customProducts,
					_List_fromArray(
						[initialCustomProduct]));
				var index = $elm$core$List$length(customProducts);
				return _Utils_update(
					model,
					{
						hn: updatedCustomProducts,
						K: A2($author$project$Page$QuoteBuilder$CustomField$Update$Edit, index, initialCustomProduct)
					});
			default:
				var subMsg = msg.a;
				var _v4 = model.K;
				if (!_v4.$) {
					var index = _v4.a;
					var customProduct = _v4.b;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilder$CustomField$Update$Edit, index, m)
							});
					}(
						A3($author$project$Page$QuoteBuilder$CustomField$Form$Update$update, currency, subMsg, customProduct));
				} else {
					return model;
				}
		}
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$Edited = 2;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$InEdition = 1;
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$remove = function (quantity) {
	return A2($elm$core$Basics$max, 0, quantity - 1);
};
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$update = F2(
	function (msg, model) {
		switch (msg) {
			case 0:
				return _Utils_update(
					model,
					{g$: model.g$ + 1});
			case 1:
				return _Utils_update(
					model,
					{
						g$: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$remove(model.g$)
					});
			case 2:
				return _Utils_update(
					model,
					{it: model.it + 1});
			case 3:
				return _Utils_update(
					model,
					{
						it: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$remove(model.it)
					});
			case 4:
				return _Utils_update(
					model,
					{hZ: model.hZ + 1});
			case 5:
				return _Utils_update(
					model,
					{
						hZ: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$remove(model.hZ)
					});
			case 6:
				return _Utils_update(
					model,
					{hY: model.hY + 1});
			default:
				return _Utils_update(
					model,
					{
						hY: $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$remove(model.hY)
					});
		}
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var edited = msg.a;
				var _v1 = model.K;
				if (_v1 === 1) {
					return _Utils_update(
						model,
						{
							ae: $elm$core$Maybe$Just(edited),
							K: 2
						});
				} else {
					return model;
				}
			case 1:
				var _v2 = model.K;
				if (_v2 === 1) {
					return _Utils_update(
						model,
						{ae: $elm$core$Maybe$Nothing, K: 0});
				} else {
					return model;
				}
			case 2:
				var activationUnit = msg.a;
				var sideCurtainUnit = msg.b;
				var multibox = msg.c;
				var multiWithMotorDriver = msg.d;
				var actuator = {g$: activationUnit, hY: multiWithMotorDriver, hZ: multibox, it: sideCurtainUnit};
				return _Utils_update(
					model,
					{
						ae: $elm$core$Maybe$Just(actuator),
						K: 1
					});
			default:
				var subMsg = msg.a;
				var _v3 = model.ae;
				if (!_v3.$) {
					var subModel = _v3.a;
					return function (m) {
						return _Utils_update(
							model,
							{
								ae: $elm$core$Maybe$Just(m)
							});
					}(
						A2($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Form$Update$update, subMsg, subModel));
				} else {
					return model;
				}
		}
	});
var $author$project$Page$QuoteBuilder$Greenhouse$Update$update = F3(
	function (client, msg, model) {
		switch (msg.$) {
			case 0:
				var product = msg.a;
				return _Utils_update(
					model,
					{
						ig: A2($elm$core$List$cons, product, model.ig)
					});
			case 1:
				var product = msg.a;
				var updated = A2($elm_community$list_extra$List$Extra$remove, product, model.ig);
				var noHeater = !A2($elm$core$List$member, 6, updated);
				return noHeater ? _Utils_update(
					model,
					{
						ig: A2(
							$elm$core$List$filter,
							$elm$core$Basics$neq(7),
							updated)
					}) : _Utils_update(
					model,
					{ig: updated});
			case 2:
				return _Utils_update(
					model,
					{
						ig: A2(
							$elm$core$List$cons,
							9,
							A2(
								$elm$core$List$cons,
								9,
								A2(
									$elm$core$List$cons,
									0,
									A2(
										$elm$core$List$filter,
										$elm$core$Basics$neq(9),
										A2(
											$elm$core$List$filter,
											$elm$core$Basics$neq(0),
											model.ig)))))
					});
			case 3:
				return _Utils_update(
					model,
					{d8: !model.d8});
			case 4:
				var toEdition = function (n) {
					return _Utils_update(
						model,
						{
							fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$InEdition(
								{bo: n, fh: n})
						});
				};
				var _v1 = model.fk;
				switch (_v1.$) {
					case 0:
						return model;
					case 1:
						var n = _v1.a;
						return toEdition(n);
					default:
						return toEdition(
							A2($author$project$Page$QuoteBuilder$Greenhouse$Update$defaultName, client.hR, model));
				}
			case 5:
				var newName = msg.a;
				var _v2 = model.fk;
				switch (_v2.$) {
					case 0:
						var initial = _v2.a.bo;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$InEdition(
									{bo: initial, fh: newName})
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 6:
				var _v3 = model.fk;
				switch (_v3.$) {
					case 0:
						var input = _v3.a.fh;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$Edited(input)
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 7:
				var _v4 = model.fk;
				switch (_v4.$) {
					case 0:
						var initial = _v4.a.bo;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilder$Greenhouse$Update$Edited(initial)
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 8:
				return model;
			case 9:
				return model;
			case 10:
				var subMsg = msg.a;
				return function (m) {
					return _Utils_update(
						model,
						{aR: m});
				}(
					A3($author$project$Page$QuoteBuilder$CustomField$Update$update, client.hl, subMsg, model.aR));
			default:
				var subMsg = msg.a;
				return function (m) {
					return _Utils_update(
						model,
						{aM: m});
				}(
					A2($author$project$Page$QuoteBuilder$Greenhouse$Actuator$Update$update, subMsg, model.aM));
		}
	});
var $author$project$Page$QuoteBuilder$Update$ensureBasicModeProducts = function (model) {
	return _Utils_update(
		model,
		{
			aX: A2(
				$elm$core$List$map,
				A2($author$project$Page$QuoteBuilder$Greenhouse$Update$update, model.hg, $author$project$Page$QuoteBuilder$Greenhouse$Update$EnsureBasicModeProducts),
				model.aX)
		});
};
var $author$project$Page$QuoteBuilder$Discount$Update$Edit = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$QuoteBuilder$Discount$Form$Update$build = F2(
	function (currency, model) {
		var discountType = A2(
			$elm$core$Maybe$map,
			function (n) {
				var _v0 = model.fb;
				if (!_v0) {
					return $author$project$Domain$Discount$Custom$Amount(
						A2($author$project$Domain$Total$Amount$fromCurrency, currency, n));
				} else {
					return $author$project$Domain$Discount$Custom$Percentage(n);
				}
			},
			$elm$core$String$toInt(model.bh));
		return A2(
			$elm$core$Maybe$map,
			function (t) {
				return A3($author$project$Domain$Discount$Custom$CustomDiscount, model.fk, t, $elm$core$Maybe$Nothing);
			},
			discountType);
	});
var $author$project$Page$QuoteBuilder$Discount$Form$Update$fromDiscount = F2(
	function (currency, discount) {
		var _v0 = function () {
			var _v1 = discount.fb;
			if (!_v1.$) {
				var p = _v1.a;
				return _Utils_Tuple2(
					1,
					$elm$core$String$fromInt(p));
			} else {
				var a = _v1.a;
				return _Utils_Tuple2(
					0,
					$elm$core$String$fromInt(
						A2($author$project$Domain$Total$Amount$inCurrency, currency, a)));
			}
		}();
		var discountType = _v0.a;
		var amountOrPercentage = _v0.b;
		return {bh: amountOrPercentage, fb: discountType, fk: discount.fk};
	});
var $author$project$Page$QuoteBuilder$Discount$Update$initialCustomDiscount = {
	fb: $author$project$Domain$Discount$Custom$Percentage(10),
	fk: '',
	id: $elm$core$Maybe$Nothing
};
var $author$project$Page$QuoteBuilder$Discount$Form$Update$initialModel = {bh: '10', fb: 1, fk: ''};
var $author$project$Page$QuoteBuilder$Discount$Form$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_update(
					model,
					{fk: name});
			case 1:
				var discountType = msg.a;
				return _Utils_update(
					model,
					{fb: discountType});
			default:
				var str = msg.a;
				return _Utils_update(
					model,
					{bh: str});
		}
	});
var $author$project$Page$QuoteBuilder$Discount$Update$update = F3(
	function (currency, msg, model) {
		switch (msg.$) {
			case 0:
				var index = msg.a;
				var discount = A2($elm_community$list_extra$List$Extra$getAt, index, model.hm);
				if (!discount.$) {
					var d = discount.a;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilder$Discount$Update$Edit, index, m)
							});
					}(
						A2($author$project$Page$QuoteBuilder$Discount$Form$Update$fromDiscount, currency, d));
				} else {
					return model;
				}
			case 1:
				var _v2 = model.K;
				if (!_v2.$) {
					var index = _v2.a;
					var edited = _v2.b;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_update(
							model,
							{K: $author$project$Page$QuoteBuilder$Discount$Update$Edited}),
						A2(
							$elm$core$Maybe$map,
							function (built) {
								return _Utils_update(
									model,
									{
										hm: A3($elm_community$list_extra$List$Extra$setAt, index, built, model.hm),
										K: $author$project$Page$QuoteBuilder$Discount$Update$Edited
									});
							},
							A2($author$project$Page$QuoteBuilder$Discount$Form$Update$build, currency, edited)));
				} else {
					return model;
				}
			case 2:
				var _v3 = model.K;
				if (!_v3.$) {
					var index = _v3.a;
					var possibleNewCustomDiscount = A2($elm_community$list_extra$List$Extra$getAt, index, model.hm);
					return (!_Utils_eq(
						possibleNewCustomDiscount,
						$elm$core$Maybe$Just($author$project$Page$QuoteBuilder$Discount$Update$initialCustomDiscount))) ? _Utils_update(
						model,
						{K: $author$project$Page$QuoteBuilder$Discount$Update$Edited}) : _Utils_update(
						model,
						{
							hm: A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm),
							K: $author$project$Page$QuoteBuilder$Discount$Update$Edited
						});
				} else {
					return model;
				}
			case 3:
				var index = msg.a;
				return _Utils_update(
					model,
					{
						hm: A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm)
					});
			case 4:
				var updatedCustomDiscounts = _Utils_ap(
					model.hm,
					_List_fromArray(
						[$author$project$Page$QuoteBuilder$Discount$Update$initialCustomDiscount]));
				var index = $elm$core$List$length(model.hm);
				return _Utils_update(
					model,
					{
						hm: updatedCustomDiscounts,
						K: A2($author$project$Page$QuoteBuilder$Discount$Update$Edit, index, $author$project$Page$QuoteBuilder$Discount$Form$Update$initialModel)
					});
			case 5:
				var subMsg = msg.a;
				var _v4 = model.K;
				if (!_v4.$) {
					var index = _v4.a;
					var subModel = _v4.b;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilder$Discount$Update$Edit, index, m)
							});
					}(
						A2($author$project$Page$QuoteBuilder$Discount$Form$Update$update, subMsg, subModel));
				} else {
					return model;
				}
			default:
				var discount = msg.a;
				var isChecked = msg.b;
				var otherSelectedDiscounts = A2(
					$elm$core$List$filter,
					function (d) {
						return !_Utils_eq(d, discount);
					},
					model.ie);
				return isChecked ? _Utils_update(
					model,
					{
						ie: A2($elm$core$List$cons, discount, otherSelectedDiscounts)
					}) : _Utils_update(
					model,
					{ie: otherSelectedDiscounts});
		}
	});
var $author$project$Page$QuoteBuilder$Update$setPredefinedDiscount = F2(
	function (predefined, model) {
		return _Utils_update(
			model,
			{
				I: A3(
					$author$project$Page$QuoteBuilder$Discount$Update$update,
					model.hg.hl,
					A2($author$project$Page$QuoteBuilder$Discount$Update$SetPredefined, predefined, true),
					model.I)
			});
	});
var $author$project$Page$QuoteBuilder$Client$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var str = msg.a;
				return _Utils_update(
					model,
					{fZ: str});
			case 1:
				var str = msg.a;
				return _Utils_update(
					model,
					{gh: str});
			case 2:
				var str = msg.a;
				return _Utils_update(
					model,
					{hD: str});
			case 3:
				var address = msg.a;
				return _Utils_update(
					model,
					{g1: address});
			case 4:
				var language = msg.a;
				return _Utils_update(
					model,
					{hR: language});
			default:
				var currency = msg.a;
				return _Utils_update(
					model,
					{hl: currency});
		}
	});
var $author$project$Page$QuoteBuilder$Farm$Update$updateWeatherBox = function (model) {
	if (model.ba) {
		return model;
	} else {
		var withoutWeatherBox = A2(
			$elm$core$List$filter,
			$elm$core$Basics$neq(5),
			model.ig);
		var hasASensor = A2($elm$core$List$member, 4, model.ig) || (A2($elm$core$List$member, 2, model.ig) || A2($elm$core$List$member, 3, model.ig));
		return _Utils_update(
			model,
			{
				ig: hasASensor ? A2($elm$core$List$cons, 5, withoutWeatherBox) : withoutWeatherBox
			});
	}
};
var $author$project$Page$QuoteBuilder$Farm$Update$update = F3(
	function (currency, msg, model) {
		if (!msg.$) {
			var product = msg.a;
			var isChecked = msg.b;
			var withoutProduct = A2(
				$elm$core$List$filter,
				$elm$core$Basics$neq(product),
				model.ig);
			var weatherBoxIndependent = model.ba || (product === 5);
			var newModel = isChecked ? _Utils_update(
				model,
				{
					ig: A2($elm$core$List$cons, product, withoutProduct)
				}) : _Utils_update(
				model,
				{ig: withoutProduct});
			return $author$project$Page$QuoteBuilder$Farm$Update$updateWeatherBox(
				_Utils_update(
					newModel,
					{ba: weatherBoxIndependent}));
		} else {
			var subMsg = msg.a;
			return function (m) {
				return _Utils_update(
					model,
					{aR: m});
			}(
				A3($author$project$Page$QuoteBuilder$CustomField$Update$update, currency, subMsg, model.aR));
		}
	});
var $author$project$Page$ROI$update = function (msg) {
	var url = msg;
	return $author$project$Port$copyToClipboard(url);
};
var $author$project$Page$QuoteBuilder$Update$updateDiscountFromFarmMsg = F2(
	function (farmMsg, model) {
		_v0$2:
		while (true) {
			if ((!farmMsg.$) && farmMsg.b) {
				switch (farmMsg.a) {
					case 3:
						var _v1 = farmMsg.a;
						return A2($author$project$Page$QuoteBuilder$Update$setPredefinedDiscount, 0, model);
					case 4:
						var _v2 = farmMsg.a;
						return A2($author$project$Page$QuoteBuilder$Update$setPredefinedDiscount, 1, model);
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return model;
	});
var $author$project$Page$QuoteBuilder$Update$updateDiscountFromGreenhouseMsg = F3(
	function (user, greenhouseMsg, model) {
		_v0$2:
		while (true) {
			if (!greenhouseMsg.$) {
				switch (greenhouseMsg.a) {
					case 1:
						var _v1 = greenhouseMsg.a;
						var numberOfDryWetSensors = $elm$core$List$sum(
							A2(
								$elm$core$List$map,
								$elm_community$list_extra$List$Extra$count(
									$elm$core$Basics$eq(1)),
								A2(
									$elm$core$List$map,
									function ($) {
										return $.ig;
									},
									model.aX)));
						return (numberOfDryWetSensors === 1) ? A2($author$project$Page$QuoteBuilder$Update$setPredefinedDiscount, 2, model) : model;
					case 8:
						var _v2 = greenhouseMsg.a;
						var numberOfRoofs = $elm$core$List$sum(
							A2(
								$elm$core$List$map,
								$elm_community$list_extra$List$Extra$count(
									$elm$core$Basics$eq(8)),
								A2(
									$elm$core$List$map,
									function ($) {
										return $.ig;
									},
									model.aX)));
						var isHarnois = _Utils_eq(
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.hG;
								},
								user),
							$elm$core$Maybe$Just(2));
						return ((numberOfRoofs === 1) && isHarnois) ? A2($author$project$Page$QuoteBuilder$Update$setPredefinedDiscount, 6, model) : model;
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return model;
	});
var $author$project$Page$QuoteBuilder$Update$update = F3(
	function (user, msg, model) {
		switch (msg.$) {
			case 0:
				var index = $elm$core$List$length(model.aX);
				var newGreenhouse = $author$project$Page$QuoteBuilder$Greenhouse$Update$initialModel(index);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							aX: _Utils_ap(
								model.aX,
								_List_fromArray(
									[newGreenhouse]))
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var subMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hg: A2($author$project$Page$QuoteBuilder$Client$update, subMsg, model.hg)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var subMsg = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							I: A3($author$project$Page$QuoteBuilder$Discount$Update$update, model.hg.hl, subMsg, model.I)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var subMsg = msg.a;
				return _Utils_Tuple2(
					model,
					A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Page$QuoteBuilder$Update$ROIMsg,
						$author$project$Page$ROI$update(subMsg)));
			case 4:
				var index = msg.a;
				var subMsg = msg.b;
				var greenhouse = A2($elm_community$list_extra$List$Extra$getAt, index, model.aX);
				switch (subMsg.$) {
					case 8:
						var i = subMsg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									aX: A2($elm_community$list_extra$List$Extra$removeAt, i, model.aX)
								}),
							$elm$core$Platform$Cmd$none);
					case 9:
						if (!greenhouse.$) {
							var g = greenhouse.a;
							var newIndex = $elm$core$List$length(model.aX);
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aX: _Utils_ap(
											model.aX,
											_List_fromArray(
												[
													_Utils_update(
													g,
													{cY: newIndex})
												]))
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					default:
						if (!greenhouse.$) {
							var g = greenhouse.a;
							var updatedGreenhouse = A3($author$project$Page$QuoteBuilder$Greenhouse$Update$update, model.hg, subMsg, g);
							var updatedGreenhouses = A3($elm_community$list_extra$List$Extra$setAt, index, updatedGreenhouse, model.aX);
							return _Utils_Tuple2(
								A3(
									$author$project$Page$QuoteBuilder$Update$updateDiscountFromGreenhouseMsg,
									user,
									subMsg,
									_Utils_update(
										model,
										{aX: updatedGreenhouses})),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
				}
			case 5:
				var subMsg = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Page$QuoteBuilder$Update$updateDiscountFromFarmMsg,
						subMsg,
						_Utils_update(
							model,
							{
								fd: A3($author$project$Page$QuoteBuilder$Farm$Update$update, model.hg.hl, subMsg, model.fd)
							})),
					$elm$core$Platform$Cmd$none);
			case 6:
				var actuator = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ae: actuator}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var plan = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: plan}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var mode = msg.a;
				return _Utils_Tuple2(
					((mode === 1) ? A2(
						$elm$core$Basics$composeR,
						$author$project$Page$QuoteBuilder$Update$setPredefinedDiscount(5),
						$author$project$Page$QuoteBuilder$Update$ensureBasicModeProducts) : $elm$core$Basics$identity)(
						_Utils_update(
							model,
							{hX: mode})),
					$elm$core$Platform$Cmd$none);
			default:
				var documentation = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ht: documentation}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$QuoteBuilderV3$Client$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var firstName = msg.a;
				return _Utils_update(
					model,
					{fZ: firstName});
			case 1:
				var lastName = msg.a;
				return _Utils_update(
					model,
					{gh: lastName});
			case 2:
				var farmName = msg.a;
				return _Utils_update(
					model,
					{hD: farmName});
			case 3:
				var address = msg.a;
				return _Utils_update(
					model,
					{g1: address});
			case 4:
				var language = msg.a;
				return _Utils_update(
					model,
					{hR: language});
			default:
				var currency = msg.a;
				return _Utils_update(
					model,
					{hl: currency});
		}
	});
var $author$project$Page$QuoteBuilderV3$Discount$Update$Edit = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$build = function (model) {
	var discountType = A2(
		$elm$core$Maybe$map,
		function (n) {
			var _v0 = model.fb;
			if (!_v0) {
				return $author$project$Page$QuoteBuilderV3$Quote$Amount(n);
			} else {
				return $author$project$Page$QuoteBuilderV3$Quote$Percentage(n);
			}
		},
		$elm$core$String$toInt(model.bh));
	return A2(
		$elm$core$Maybe$map,
		function (t) {
			return A3($author$project$Page$QuoteBuilderV3$Quote$Discount, model.fk, t, $elm$core$Maybe$Nothing);
		},
		discountType);
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$fromDiscount = function (discount) {
	var _v0 = function () {
		var _v1 = discount.fb;
		if (!_v1.$) {
			var p = _v1.a;
			return _Utils_Tuple2(
				1,
				$elm$core$String$fromInt(p));
		} else {
			var a = _v1.a;
			return _Utils_Tuple2(
				0,
				$elm$core$String$fromInt(a));
		}
	}();
	var discountType = _v0.a;
	var amountOrPercentage = _v0.b;
	return {bh: amountOrPercentage, fb: discountType, fk: discount.fk};
};
var $author$project$Page$QuoteBuilderV3$Discount$Update$initialDiscount = {
	fb: $author$project$Page$QuoteBuilderV3$Quote$Percentage(10),
	fk: '',
	id: $elm$core$Maybe$Nothing
};
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$initialModel = {bh: '10', fb: 1, fk: ''};
var $author$project$Page$QuoteBuilderV3$Discount$Form$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_update(
					model,
					{fk: name});
			case 1:
				var discountType = msg.a;
				return _Utils_update(
					model,
					{fb: discountType});
			default:
				var str = msg.a;
				return _Utils_update(
					model,
					{bh: str});
		}
	});
var $author$project$Page$QuoteBuilderV3$Discount$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var index = msg.a;
				var discount = A2($elm_community$list_extra$List$Extra$getAt, index, model.hm);
				if (!discount.$) {
					var d = discount.a;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilderV3$Discount$Update$Edit, index, m)
							});
					}(
						$author$project$Page$QuoteBuilderV3$Discount$Form$Update$fromDiscount(d));
				} else {
					return model;
				}
			case 1:
				var _v2 = model.K;
				if (!_v2.$) {
					var index = _v2.a;
					var edited = _v2.b;
					return A2(
						$elm$core$Maybe$withDefault,
						_Utils_update(
							model,
							{K: $author$project$Page$QuoteBuilderV3$Discount$Update$Edited}),
						A2(
							$elm$core$Maybe$map,
							function (built) {
								return _Utils_update(
									model,
									{
										hm: A3($elm_community$list_extra$List$Extra$setAt, index, built, model.hm),
										K: $author$project$Page$QuoteBuilderV3$Discount$Update$Edited
									});
							},
							$author$project$Page$QuoteBuilderV3$Discount$Form$Update$build(edited)));
				} else {
					return model;
				}
			case 2:
				var _v3 = model.K;
				if (!_v3.$) {
					var index = _v3.a;
					var possibleNewCustomDiscount = A2($elm_community$list_extra$List$Extra$getAt, index, model.hm);
					return (!_Utils_eq(
						possibleNewCustomDiscount,
						$elm$core$Maybe$Just($author$project$Page$QuoteBuilderV3$Discount$Update$initialDiscount))) ? _Utils_update(
						model,
						{K: $author$project$Page$QuoteBuilderV3$Discount$Update$Edited}) : _Utils_update(
						model,
						{
							hm: A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm),
							K: $author$project$Page$QuoteBuilderV3$Discount$Update$Edited
						});
				} else {
					return model;
				}
			case 3:
				var index = msg.a;
				return _Utils_update(
					model,
					{
						hm: A2($elm_community$list_extra$List$Extra$removeAt, index, model.hm)
					});
			case 4:
				var updatedCustomDiscounts = _Utils_ap(
					model.hm,
					_List_fromArray(
						[$author$project$Page$QuoteBuilderV3$Discount$Update$initialDiscount]));
				var index = $elm$core$List$length(model.hm);
				return _Utils_update(
					model,
					{
						hm: updatedCustomDiscounts,
						K: A2($author$project$Page$QuoteBuilderV3$Discount$Update$Edit, index, $author$project$Page$QuoteBuilderV3$Discount$Form$Update$initialModel)
					});
			default:
				var subMsg = msg.a;
				var _v4 = model.K;
				if (!_v4.$) {
					var index = _v4.a;
					var subModel = _v4.b;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilderV3$Discount$Update$Edit, index, m)
							});
					}(
						A2($author$project$Page$QuoteBuilderV3$Discount$Form$Update$update, subMsg, subModel));
				} else {
					return model;
				}
		}
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Update$Edit = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Form$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_update(
					model,
					{fk: name});
			case 1:
				var price = msg.a;
				var formattedPrice = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(price));
				return _Utils_update(
					model,
					{$8: formattedPrice});
			default:
				var quantity = msg.a;
				var formattedQuantity = A2(
					$elm$core$Maybe$withDefault,
					0,
					$elm$core$String$toInt(quantity));
				return _Utils_update(
					model,
					{ih: formattedQuantity});
		}
	});
var $author$project$Page$QuoteBuilderV3$CustomField$Update$update = F2(
	function (msg, model) {
		var initialCustomProduct = {fk: '', $8: 0, ih: 0};
		var customProducts = model.hn;
		switch (msg.$) {
			case 0:
				var index = msg.a;
				var customProduct = A2($elm_community$list_extra$List$Extra$getAt, index, customProducts);
				if (!customProduct.$) {
					var c = customProduct.a;
					return _Utils_update(
						model,
						{
							K: A2($author$project$Page$QuoteBuilderV3$CustomField$Update$Edit, index, c)
						});
				} else {
					return model;
				}
			case 1:
				var _v2 = model.K;
				if (!_v2.$) {
					var index = _v2.a;
					var edited = _v2.b;
					return _Utils_update(
						model,
						{
							hn: A3($elm_community$list_extra$List$Extra$setAt, index, edited, customProducts),
							K: $author$project$Page$QuoteBuilderV3$CustomField$Update$Edited
						});
				} else {
					return model;
				}
			case 2:
				var _v3 = model.K;
				if (!_v3.$) {
					var index = _v3.a;
					var possibleNewCustomProduct = A2($elm_community$list_extra$List$Extra$getAt, index, customProducts);
					return (!_Utils_eq(
						possibleNewCustomProduct,
						$elm$core$Maybe$Just(initialCustomProduct))) ? _Utils_update(
						model,
						{K: $author$project$Page$QuoteBuilderV3$CustomField$Update$Edited}) : _Utils_update(
						model,
						{
							hn: A2($elm_community$list_extra$List$Extra$removeAt, index, customProducts),
							K: $author$project$Page$QuoteBuilderV3$CustomField$Update$Edited
						});
				} else {
					return model;
				}
			case 3:
				var index = msg.a;
				return _Utils_update(
					model,
					{
						hn: A2($elm_community$list_extra$List$Extra$removeAt, index, customProducts)
					});
			case 4:
				var updatedCustomProducts = _Utils_ap(
					customProducts,
					_List_fromArray(
						[initialCustomProduct]));
				var index = $elm$core$List$length(customProducts);
				return _Utils_update(
					model,
					{
						hn: updatedCustomProducts,
						K: A2($author$project$Page$QuoteBuilderV3$CustomField$Update$Edit, index, initialCustomProduct)
					});
			default:
				var subMsg = msg.a;
				var _v4 = model.K;
				if (!_v4.$) {
					var index = _v4.a;
					var customProduct = _v4.b;
					return function (m) {
						return _Utils_update(
							model,
							{
								K: A2($author$project$Page$QuoteBuilderV3$CustomField$Update$Edit, index, m)
							});
					}(
						A2($author$project$Page$QuoteBuilderV3$CustomField$Form$Update$update, subMsg, customProduct));
				} else {
					return model;
				}
		}
	});
var $author$project$Page$QuoteBuilderV3$Farm$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var product = msg.a;
				return _Utils_update(
					model,
					{
						ig: A2($elm_community$list_extra$List$Extra$remove, product, model.ig)
					});
			case 1:
				var product = msg.a;
				return _Utils_update(
					model,
					{
						ig: A2($elm$core$List$cons, product, model.ig)
					});
			case 2:
				return _Utils_update(
					model,
					{by: !model.by});
			default:
				var subMsg = msg.a;
				return function (m) {
					return _Utils_update(
						model,
						{aR: m});
				}(
					A2($author$project$Page$QuoteBuilderV3$CustomField$Update$update, subMsg, model.aR));
		}
	});
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$InEdition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$QuoteBuilderV3$Greenhouse$Update$update = F3(
	function (client, msg, model) {
		switch (msg.$) {
			case 0:
				var basePlan = msg.a;
				return _Utils_update(
					model,
					{g9: basePlan});
			case 1:
				var product = msg.a;
				return _Utils_update(
					model,
					{
						ig: A2($elm_community$list_extra$List$Extra$remove, product, model.ig)
					});
			case 2:
				var product = msg.a;
				return _Utils_update(
					model,
					{
						ig: A2($elm$core$List$cons, product, model.ig)
					});
			case 3:
				return _Utils_update(
					model,
					{by: !model.by});
			case 4:
				var subMsg = msg.a;
				return function (m) {
					return _Utils_update(
						model,
						{aR: m});
				}(
					A2($author$project$Page$QuoteBuilderV3$CustomField$Update$update, subMsg, model.aR));
			case 5:
				var toEdition = function (n) {
					return _Utils_update(
						model,
						{
							fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$InEdition(
								{bo: n, fh: n})
						});
				};
				var _v1 = model.fk;
				switch (_v1.$) {
					case 0:
						return model;
					case 1:
						var n = _v1.a;
						return toEdition(n);
					default:
						return toEdition(
							A2($author$project$Page$QuoteBuilderV3$Greenhouse$Update$defaultName, client.hR, model));
				}
			case 6:
				var newName = msg.a;
				var _v2 = model.fk;
				switch (_v2.$) {
					case 0:
						var initial = _v2.a.bo;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$InEdition(
									{bo: initial, fh: newName})
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 7:
				var _v3 = model.fk;
				switch (_v3.$) {
					case 0:
						var input = _v3.a.fh;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Edited(input)
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 8:
				var _v4 = model.fk;
				switch (_v4.$) {
					case 0:
						var initial = _v4.a.bo;
						return _Utils_update(
							model,
							{
								fk: $author$project$Page$QuoteBuilderV3$Greenhouse$Update$Edited(initial)
							});
					case 1:
						return model;
					default:
						return model;
				}
			case 9:
				return model;
			default:
				return model;
		}
	});
var $author$project$Page$QuoteBuilderV3$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var index = $elm$core$List$length(model.aX);
				var newGreenhouse = $author$project$Page$QuoteBuilderV3$Greenhouse$Update$initialModel(index);
				return _Utils_update(
					model,
					{
						aX: _Utils_ap(
							model.aX,
							_List_fromArray(
								[newGreenhouse]))
					});
			case 1:
				var subMsg = msg.a;
				return _Utils_update(
					model,
					{
						hg: A2($author$project$Page$QuoteBuilderV3$Client$update, subMsg, model.hg)
					});
			case 2:
				var subMsg = msg.a;
				return _Utils_update(
					model,
					{
						I: A2($author$project$Page$QuoteBuilderV3$Discount$Update$update, subMsg, model.I)
					});
			case 3:
				var index = msg.a;
				var subMsg = msg.b;
				var greenhouse = A2($elm_community$list_extra$List$Extra$getAt, index, model.aX);
				switch (subMsg.$) {
					case 9:
						var i = subMsg.a;
						return _Utils_update(
							model,
							{
								aX: A2($elm_community$list_extra$List$Extra$removeAt, i, model.aX)
							});
					case 10:
						if (!greenhouse.$) {
							var g = greenhouse.a;
							var newIndex = $elm$core$List$length(model.aX);
							return _Utils_update(
								model,
								{
									aX: _Utils_ap(
										model.aX,
										_List_fromArray(
											[
												_Utils_update(
												g,
												{cY: newIndex})
											]))
								});
						} else {
							return model;
						}
					default:
						if (!greenhouse.$) {
							var g = greenhouse.a;
							var updatedGreenhouse = A3($author$project$Page$QuoteBuilderV3$Greenhouse$Update$update, model.hg, subMsg, g);
							var updatedGreenhouses = A3($elm_community$list_extra$List$Extra$setAt, index, updatedGreenhouse, model.aX);
							return _Utils_update(
								model,
								{aX: updatedGreenhouses});
						} else {
							return model;
						}
				}
			case 4:
				var subMsg = msg.a;
				return _Utils_update(
					model,
					{
						fd: A2($author$project$Page$QuoteBuilderV3$Farm$Update$update, subMsg, model.fd)
					});
			default:
				var plan = msg.a;
				return _Utils_update(
					model,
					{ab: plan});
		}
	});
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Update$updateUrl = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var quoteV3Param = $author$project$Page$QuoteBuilderV3$Update$queryParam(
		$author$project$Page$QuoteBuilderV3$Update$toQuote(model.aD));
	var quoteParam = $author$project$Route$Quote$Entry$queryParam(
		$author$project$Page$QuoteBuilder$Update$toQuote(model.aC));
	return _Utils_Tuple2(
		model,
		$elm$core$Platform$Cmd$batch(
			_List_fromArray(
				[
					cmd,
					A2(
					$elm$browser$Browser$Navigation$replaceUrl,
					model.fl,
					A2(
						$elm$url$Url$Builder$absolute,
						_List_Nil,
						_List_fromArray(
							[quoteParam, quoteV3Param])))
				])));
};
var $author$project$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 1:
				var subMsg = msg.a;
				return $author$project$Update$updateUrl(
					A2(
						$elm$core$Tuple$mapSecond,
						$elm$core$Platform$Cmd$map($author$project$Update$QuoteBuilderMsg),
						A2(
							$elm$core$Tuple$mapFirst,
							function (m) {
								return _Utils_update(
									model,
									{aC: m});
							},
							A3($author$project$Page$QuoteBuilder$Update$update, model.aJ, subMsg, model.aC))));
			case 2:
				var subMsg = msg.a;
				return $author$project$Update$updateUrl(
					_Utils_Tuple2(
						function (m) {
							return _Utils_update(
								model,
								{aD: m});
						}(
							A2($author$project$Page$QuoteBuilderV3$Update$update, subMsg, model.aD)),
						$elm$core$Platform$Cmd$none));
			case 3:
				var subMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Update$PackingSlipMsg),
					A2(
						$elm$core$Tuple$mapFirst,
						function (m) {
							return _Utils_update(
								model,
								{dC: m});
						},
						A2($author$project$Page$PackingSlip$update, model.dC, subMsg)));
			case 4:
				var subMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Update$PackingSlipV3Msg),
					A2(
						$elm$core$Tuple$mapFirst,
						function (m) {
							return _Utils_update(
								model,
								{dD: m});
						},
						A2($author$project$Page$PackingSlipV3$update, model.dD, subMsg)));
			case 5:
				var page = msg.a;
				if (_Utils_eq(model.a2, page)) {
					var _default = $linuss$smooth_scroll$SmoothScroll$defaultConfig;
					var scroll = A2(
						$linuss$smooth_scroll$SmoothScroll$scrollToWithOptions,
						_Utils_update(
							_default,
							{iv: 50}),
						$author$project$Update$topAnchor);
					var cmd = A2(
						$elm$core$Task$attempt,
						$elm$core$Basics$always($author$project$Update$NoOp),
						A2(
							$elm$core$Task$andThen,
							$elm$core$Basics$always(scroll),
							$elm$core$Process$sleep(10)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a2: page}),
						cmd);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{a2: page}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var now = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fn: $elm$core$Maybe$Just(now)
						}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var zone = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fw: $elm$core$Maybe$Just(zone)
						}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var subMsg = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Update$LoginMsg),
					A2(
						$elm$core$Tuple$mapFirst,
						function (m) {
							return _Utils_update(
								model,
								{df: m});
						},
						A2($author$project$Login$Page$update, subMsg, model.df)));
			case 9:
				return _Utils_Tuple2(
					model,
					$author$project$Port$print(0));
			case 10:
				var selectedLanguage = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hR: selectedLanguage}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var token = msg.a;
				var user = $author$project$Domain$User$decode(token);
				var language = A2(
					$elm$core$Maybe$withDefault,
					model.hR,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.hR;
						},
						user));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							hR: language,
							aC: A2(
								$author$project$Page$QuoteBuilder$Update$initialModel,
								user,
								$author$project$Route$Quote$Entry$parseQuote(model.eO)),
							aD: A2(
								$author$project$Page$QuoteBuilderV3$Update$initialModel,
								user,
								$author$project$Page$QuoteBuilderV3$Update$quoteFromUrl(model.eO)),
							aJ: user
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return $author$project$Update$updateUrl(
					_Utils_Tuple2(
						$author$project$Update$resetForms(
							_Utils_update(
								model,
								{aJ: $elm$core$Maybe$Nothing})),
						$author$project$Port$deleteSession(0)));
			case 13:
				var isActuatorPriceShown = (!model.a2) ? (!model.bp) : model.bp;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bp: isActuatorPriceShown}),
					$elm$core$Platform$Cmd$none);
			default:
				return $author$project$Update$updateUrl(
					_Utils_Tuple2(
						$author$project$Update$resetForms(model),
						$elm$core$Platform$Cmd$none));
		}
	});
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		hM: $author$project$Update$init,
		h6: function (_v0) {
			return $author$project$Update$NoOp;
		},
		h7: function (_v1) {
			return $author$project$Update$NoOp;
		},
		iw: function (_v2) {
			return $author$project$Port$sessionSaved($author$project$Update$UserLoggedIn);
		},
		iD: $author$project$Update$update,
		iF: $author$project$Main$mainView
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (isDev) {
					return $elm$json$Json$Decode$succeed(
						{hP: isDev, iB: token});
				},
				A2($elm$json$Json$Decode$field, 'isDev', $elm$json$Json$Decode$bool));
		},
		A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)))(0)}});}(this));