import "../node_modules/font-awesome/css/font-awesome.min.css"
import "../node_modules/bulma/css/bulma.css"

import "./css/main.css"
import "./css/datepicker.css"
import { Elm } from "./Main.elm"
import "./js/autofocus"
import * as Env from "./js/env"
import * as Session from "./js/session"

const flags = {
    token: Session.get().withDefault(""),
    isDev: Env.isDev
}

const app = Elm.Main.init({ flags })

app.ports.print.subscribe(_ => {
    // we need to scroll up before printing because chrome adds a negative
    // vertical margin on all pages when the page is scrolled down even a little
    window.scrollTo(0, 0)
    window.print()
})

app.ports.copyToClipboard.subscribe(_ => {
    navigator.clipboard.writeText(_)
})

app.ports.saveSession.subscribe(token => {
    Session.save(token)
    app.ports.sessionSaved.send(token)
})

app.ports.deleteSession.subscribe(Session.forget)
